<div class="page d-flex flex-column">
    <app-header>
        Project overview
    </app-header>
    <app-content [isTab]='true' [isHeader]='true'>
        <app-dashboard-version>
            <app-viz-testing-set></app-viz-testing-set>
            <app-dashboard-content></app-dashboard-content>
        </app-dashboard-version>
    </app-content>
</div>