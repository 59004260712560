
export const PagesName = {
    FileManager: 'file-manager',
    DataManager: 'data-manager',
    Account: 'account',
    Login: 'login',
    ProjectManager: 'project-manager',
    QualityOverview: 'quality',
    ProjectOverview: 'overview',
    TeamsManager: 'teams-manager',
    LicenseManager: 'licenses-manager',
    LicenseDetails: 'license-details',
    Workflow: 'workflow',
    WorkflowEditor: 'solution-editor',
    WorkflowDebugger: 'workflow-debugger',
    WorflowCreateMethod: 'create-method',
    Home: 'home',
    Notifications: 'notifications',
    ProjectCreationFromWF: 'project-creation-wf',
    ProjectCreation: 'project-creation',
    DefineProject: 'define-project',
    ChooseWorkflow: 'choose-workflows',
    ConfigureWorkflow: 'configure-workflows',
    ChooseDiseases: 'choose-diseases',
    ChooseCluster: 'choose-cluster',
    ChooseDataTypes: 'choose-data-types',
    WorkflowInProgress: 'workflow-in-progress',
    UserCreation: 'user-creation',
    UserNotifications: 'user-notifications',
    NewAccount : 'new-account',
    ResetPassword: 'reset-password',
    DevTest: 'dev-test',
    KnowledgeSearch: 'covsight',
    KnowledgeSearchLong: 'covsight-search',
    Trends: 'trends',
    Query: 'query',
    Results: 'results',
    Edit: 'edit',
    RunsDashboard: 'runs-dashboard',
    FocusGeneSearch: 'gene-search',
    CreateCohort : 'create-cohort'
};

