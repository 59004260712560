import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { Status } from 'src/app/shared/helpers/status';
import { FileManager2Service, ItemAction } from '../file-manager2.service';

@Component({
  selector: 'app-upload-toast',
  templateUrl: './upload-toast.component.html',
  styleUrls: ['./upload-toast.component.scss'],
})
export class UploadToastComponent {
  @Input() uploadingFiles;
  Status = Status;
  ItemAction = ItemAction;
  constructor(public fileManager: FileManager2Service, private router: Router) {}
  handleOpenFolder = (el) => {
    if (!this.router.url.includes(PagesName.FileManager)){
      this.router.navigate([PagesName.FileManager]).then(() => {
        setTimeout(() => {
          this.fileManager.itemEvent.emit([el.parent_folder, ItemAction.OpenFolder]);
        },1000)
      })
    }else{
      this.fileManager.itemEvent.emit([el.parent_folder, ItemAction.OpenFolder])
    }
   
  };
}
