<div class='p-3 mb-3'>

  <div
    *ngIf="itemType === ItemType.File && _.get(openedFolder, 'id') !== _.get(rootFolder, 'id') && !!displayedFiles && navigation">
    <div id="return" class="list-container d-flex justify-content-between">
      <p>
        <button class="btn btn-link" (click)="itemAction.emit([parentFolder,ItemAction.OpenParentFolder])">
          <i class="far fa-arrow-left" aria-hidden="true"></i> Back to {{_.get(parentFolder, 'title')}}
        </button>
      </p>
    </div>
  </div>
  <div *ngIf='!displayedFiles'>
    <mims-loader [style.height.px]="loaderHeight" class='bg-dark' [style.position]="'relative'" [isLoading]="true"
      [enableBackDrop]="false">
    </mims-loader>
  </div>
  <div *ngIf='!!displayedFiles'>
    <div *ngIf='itemType === ItemType.File'>
      <div *ngIf='_.get(displayedFiles, "length")'>
        <div *ngFor="let item of 
      displayedFiles
        trackBy: trackByFn">
          <mims-list-item [item]='item' [selected]='isSelected(_.get(item, "id"))' [navigation]='navigation'
            [highlighted]='_.get(item, "id") === _.get(singleSelection, "id")'
            [architectureEdition]='architectureEdition' (itemAction)='itemAction.emit($event)'></mims-list-item>
        </div>
      </div>
      <div [style.height.px]="127"
        class='empty-display  w-100 justify-content-center align-items-center d-flex font-weight-bold'
        *ngIf='!_.get(displayedFiles, "length") && _.get(openedFolder, "id") !== _.get(rootFolder, "id")'>
        <p>Empty folder.</p>
      </div>
      <div [style.height.px]="127"
        class='empty-display  w-100 justify-content-center align-items-center d-flex font-weight-bold'
        *ngIf='!_.get(displayedFiles, "length") && _.get(openedFolder, "id") === _.get(rootFolder, "id")'>
        <p>All your files and folders will display here.</p>
      </div>
    </div>
    <div *ngIf='itemType === ItemType.Project'>
      <div *ngIf='_.get(displayedFiles, "length")'>

        <div *ngFor="let item of displayedFiles;trackBy: trackByFn2; let index = index">
          <div class="font-weight-bold small text-muted pb-3 pt-4" *ngIf="isNewModifiedDate(index) && !isTrashed(item)">
            {{lastModified(item)}}</div>
          <div class="font-weight-bold small text-muted pb-3 pt-4" *ngIf="isNewTrashedDate(index) && isTrashed(item)">
            {{lastTrashed(item)}}</div>

          <app-project-list [highlighted]='_.get(item, "id") === _.get(singleSelection, "id")'
            (itemEvent)="itemAction.emit($event)" [selected]='isBulkSelected(item.id)' [project]="item">
          </app-project-list>
        </div>
      </div>
      <div [style.height.px]="127"
        class='noProjectMessage empty-display w-100 justify-content-center align-items-center d-flex font-weight-bold'
        *ngIf='!_.get(displayedFiles, "length")'>
        <p *ngIf="viewTab == TabsName.MyProjects">
          All your projects will display here.</p>
        <p *ngIf="viewTab == TabsName.SharedProjects">
          All projects shared with you will display here.
        </p>
        <p *ngIf="viewTab == TabsName.DeletedProjects">
          All deleted projects will display here.
        </p>
      </div>
    </div>
    <div *ngIf='itemType === ItemType.Cohort'>
      <div *ngIf='_.get(displayedFiles, "length")'>

        <div *ngFor="let item of displayedFiles;trackBy: trackByFn2; let index = index">
          <app-data-item [highlighted]='_.get(item, "id") === _.get(singleSelection, "id")' [type]='ItemType.Cohort'
            (itemEvent)="itemAction.emit($event)" [selected]='isBulkSelected(item.id)' [item]="item">
          </app-data-item>
        </div>
        <div [style.height.px]="127"
          class='noProjectMessage empty-display w-100 justify-content-center align-items-center d-flex font-weight-bold'
          *ngIf='!_.get(displayedFiles, "length")'>
          <p *ngIf="viewTab == TabsName.MyData">
            All your cohorts will display here.</p>
          <p *ngIf="viewTab == TabsName.SharedData">
            All cohorts shared with you will display here.
          </p>
          <p *ngIf="viewTab == TabsName.DeletedData">
            All deleted cohorts will display here.
          </p>
        </div>
      </div>
    </div>
    <div *ngIf='itemType === ItemType.FocusGeneList'>
      <div *ngIf='_.get(displayedFiles, "length")'>

        <div *ngFor="let item of displayedFiles;trackBy: trackByFn2; let index = index">
          <app-data-item [highlighted]='_.get(item, "id") === _.get(singleSelection, "id")' [type]='ItemType.FocusGeneList'
            (itemEvent)="itemAction.emit($event)" [selected]='isBulkSelected(item.id)' [item]="item">
          </app-data-item>
        </div>
        <div [style.height.px]="127"
          class='noProjectMessage empty-display w-100 justify-content-center align-items-center d-flex font-weight-bold'
          *ngIf='!_.get(displayedFiles, "length")'>
          <p *ngIf="viewTab == TabsName.MyData">
            All your focus gene lists files will display here.</p>
          <p *ngIf="viewTab == TabsName.SharedData">
            All focus gene lists files shared with you will display here.
          </p>
          <p *ngIf="viewTab == TabsName.DeletedData">
            All deleted focus gene lists files will display here.
          </p>
        </div>
      </div>
    </div>
  </div>
