import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PermissionsHttpService {
  protected basePath = 'XXXX';
  accessUserManager = false;
  accessWorflowEditor = false;
  accessWorkflowDebugger = false;
  accessTeamManager = false;
  accessProjectManager = false;
  accessLittResearch = false;
  accessFileManager = false;
  accessNotificationManager = false;
  accessRunsDashboard = false;
  accessDataManager = false;
  canExploreProjects = false;
  accessLicenseManager = false;
  deleteFilePermission=false
  constructor(private http: HttpClient) {
    this.checkPermission({
      permissions: {
        // accessTeamManager: {},
        // accessWorkflowDebugger: {},
        // accessUserManager: {},
        // accessWorflowEditor: {},
        accessFileManager: {},
        // accessProjectManager: {},
        accessNotifications: {},
        // accessKnowledgeExplorer: {},
        // deleteMultipleFiles: {},
        // uploadMetadata: {},
        // uploadFiles: {},
        // deleteFiles: {},
        // downloadAllFiles: {},
        // downloadProjectFiles: {},
        // deleteProject: {},
        // createUser: {},
        accessLittResearch: {},
        accessProjectManager: {},
        // createProject: {},
        administrateUsers: {},
        createWorkflow: {},
        debugWorkflow: {},
        createTeam: {},
        accessRunsDashboard: {},
        manageFocusGeneLists: {},
        exploreProjects: {},
      },
    }).subscribe((res) => {
      this.accessUserManager = res.administrateUsers;
      this.accessWorflowEditor = res.createWorkflow;
      this.accessWorkflowDebugger = res.debugWorkflow;
      this.accessTeamManager = res.createTeam;
      this.accessProjectManager = res.accessProjectManager;
      this.accessLittResearch = res.accessLittResearch;
      this.accessFileManager = res.accessFileManager;
      this.accessNotificationManager = res.accessNotifications;
      this.accessRunsDashboard = res.accessRunsDashboard;
      this.accessDataManager = res.manageFocusGeneLists;
      this.canExploreProjects = res.exploreProjects;
      this.accessLicenseManager = res.administrateUsers;
    });
  }

  checkPermission(permission): Observable<any> {
    return this.http.post<any>(`${this.basePath}/mas/auth/is_authorized`, permission);
  }
}
