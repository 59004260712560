<li *ngIf='groupItem' class='list-container d-flex justify-content-between subFiles align-items-center'>
  <span class='flex-fill'>

    <span class="title" >
      {{groupItem.title || '' }}
    </span>


  </span>

  <div class="item-icons-container mx-0 px-0 row col-6">
    <div class="item-icon-text mx-0 px-0 col">
      {{ iconText }}
    </div>

    <div class="item-icons col-md-auto mx-0 px-0">


      <button (mouseenter)="setIconText('Remove file')" (mouseleave)="unSetIconText()"
        *ngIf="group.capabilities.can_modify" (click)="handleRemove(group,groupItem)" class="btn btn-link">
        <i class="far fa-minus-circle" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</li>
