import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
import { Tabs } from 'src/app/shared/helpers/tabs';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { TabsPublicName } from 'src/app/shared/helpers/tabsPublicName';
import { TeamsHttpService } from '../../teams-manager/teams-http.service';
import * as _ from 'lodash';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { filter } from 'rxjs/operators';
import { TeamActions, TeamsService, UserActions } from '../../teams-manager/teams.service';
import { PagesName } from 'src/app/shared/helpers/pagesName';
@Component({
  selector: 'app-license-details',
  templateUrl: './license-details.component.html',
  styleUrls: ['./license-details.component.scss'],
})
export class LicenseDetailsComponent {
  _ = _;
  PagesPublicName = PagesPublicName;
  PagesName = PagesName;
  TabsName = TabsName;
  tabsDataSource: Array<Tabs> = [
    { value: TabsName.LicenseInfo, label: TabsPublicName.LicenseInfo },
    { value: TabsName.ManagerAndTeam, label: TabsPublicName.ManagerAndTeam },
  ];
  activeTab = this.tabsDataSource[0];
  form = new FormGroup({});
  model: any = { name: undefined, department: undefined, license_type: undefined };
  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      templateOptions: {
        label: 'Company name',
        placeholder: '',
        required: true,
        max: 100,
      },
    },
    {
      key: 'department',
      type: 'input',
      templateOptions: {
        label: 'Department',
        placeholder: '',
        required: true,
        max: 100,
      },
    },
    {
      key: 'license_type',
      type: 'select',
      templateOptions: {
        label: 'Type of license',
        required: true,
        placeholder: 'Select an option',
        options: [
          { value: 'trial', label: 'Trial' },
          { value: 'premium', label: 'Premium' },
        ],
      },
    },
  ];
  options: FormlyFormOptions = {};
  teamBody = new BehaviorSubject(undefined);
  pageSize = 50;
  page = 1;
  headers = ['', 'Email', 'Role', ''];
  newMemberAdditionForm = new FormGroup({});
  modelNewMember = {};
  fieldsNewMember: FormlyFieldConfig[] = [
    {
      key: 'email',
      type: 'input',
      templateOptions: {
        label: 'Enter email',
        placeholder: '',
        required: true,
        max: 100,
      },
      validators: {
        validation: ['email'],
      },
    },
    {
      key: 'first_name',
      type: 'input',
      templateOptions: {
        label: 'First name',
        placeholder: '',
        required: true,
        max: 100,
      },
    },
    {
      key: 'last_name',
      type: 'input',
      templateOptions: {
        label: 'Last name',
        placeholder: '',
        required: true,
        max: 100,
      },
    },
    {
      key: 'organization',
      type: 'input',
      templateOptions: {
        label: 'Organization',
        placeholder: '',
        required: true,
        max: 100,
      },
    },
  ];
  optionsNewMember: FormlyFormOptions = {};
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private teamHttpService: TeamsHttpService,
    public l: LayoutOrganizationService,
    public teamService: TeamsService
  ) {
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((res: NavigationEnd) => {
      this.teamService.handleTeamEvent({ action: TeamActions.Retrieve, id: this.route.snapshot.paramMap.get('id') });
    });
    this.teamService.formattedSelectedTeam.subscribe((res) => {
      if (res) {
        this.teamBody.next(res);
        this.model = new Object({
          department: this.department,
          name: this.name,
          license_type: this.license_type,
        });
      }
    });
  }

  handleGetInfo(id) {
    this.teamService.handleUserEvent({ action: UserActions.OpenInfo, id: id });
  }
  inviteUser() {
    this.l.open('modalNewMemberAddition');
  }

  get key() {
    return _.get(this.teamBody, 'value.team._key');
  }
  get department() {
    return _.get(this.teamBody, 'value.team.department');
  }
  get license_type() {
    return _.get(this.teamBody, 'value.team.license_type');
  }
  get users() {
    return _.get(this.teamBody, 'value.users', []);
  }
  get name() {
    return _.get(this.teamBody, 'value.team.name');
  }
  get team() {
    return _.get(this.teamBody, 'value.team', {});
  }

  isEditionMode() {
    return _.get(this.teamBody, 'value');
  }
  handleGiveAdminRight(el) {
    this.teamService.handleUserEvent({ id: el.id, value: 'Admin', user: el, team: this.team, action: UserActions.ChangePermission });
  }
  handleRemoveAdminRight(el) {
    this.teamService.handleUserEvent({ id: el.id, value: 'Member', user: el, team: this.team, action: UserActions.ChangePermission });
  }
  handleRemoveUser(el) {
    this.teamService.handleUserEvent({ id: el.id, team: this.team, action: UserActions.Remove });
  }
  handleInvitation = () => {
    this.teamService.handleUserEvent({ team: this.team, value: this.modelNewMember, action: UserActions.AddNew });
  };
  handleSubmit(model) {
    let teamNewBody = _.cloneDeep(this.team);
    _.set(teamNewBody, 'name', model.name);
    _.set(teamNewBody, 'license_type', model.license_type);
    _.set(teamNewBody, 'department', model.department);
    this.isEditionMode()? this.teamService.handleTeamEvent({ id: this.key, value: teamNewBody, action: TeamActions.Modify })
      : this.teamService.handleTeamEvent({ value: teamNewBody, action: TeamActions.CreateWithInfo });
  }
  handleTabChange(event) {
    this.activeTab = event;
  }
}
