import { Component, OnDestroy } from '@angular/core';
import { AdditionalTypes, ShouldNotContainDoubleQuote, MaxLength128, InputJson, getFormErrorSentence} from 'src/app/shared/helpers/formattedInput';

import { Formulation } from '../formulation';
import { KnowledgeSearchService } from '../knowledge-search.service';
import * as _ from 'lodash';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';

@Component({
  selector: 'app-knowledge-search-therapy',
  templateUrl: './knowledge-search-therapy.component.html',
  styleUrls: ['./knowledge-search-therapy.component.scss'],
})
export class KnowledgeSearchTherapyComponent implements OnDestroy  {
  keywords0Object: InputJson;
  keywords1Object: InputJson;
  keywords2Object: InputJson;
  keywords3Object: InputJson;
  PagesPublicName = PagesPublicName
  Formulation = Formulation;
  getFormErrorSentence = getFormErrorSentence;
  _=_;
  constructor(public knowledgeSearchService: KnowledgeSearchService) {

    this.keywords0Object = {
      formControlName: 'keywords0',
      type: AdditionalTypes.ShortText,
      placeholder: 'Patient or population',
      lined_label: 'In',
      showIcon: 'far fa-info-circle',
      validations: [ ShouldNotContainDoubleQuote, MaxLength128],
      fitToContent: true,
    };
    
    this.keywords1Object = {
      formControlName: 'keywords1',
      type: AdditionalTypes.ShortText,
      placeholder: 'Intervention',
      lined_label: 'What is the effet of',
      showIcon: 'far fa-info-circle',
      validations: [ ShouldNotContainDoubleQuote, MaxLength128],
      fitToContent: true,

    };
    this.keywords2Object = {
      formControlName: 'keywords2',
      type: AdditionalTypes.ShortText,
      placeholder: 'Outcome or result',
      lined_label: 'On',
      fitToContent: true,
      showIcon: 'far fa-info-circle',
      validations: [ShouldNotContainDoubleQuote, MaxLength128],

    };
    this.keywords3Object = {
      formControlName: 'keywords3',
      type: AdditionalTypes.ShortText,
      placeholder: 'Comparision or benchmark',
      fitToContent: true,
      lined_label: 'Compared to',
      showIcon: 'far fa-info-circle',
      validations: [ShouldNotContainDoubleQuote, MaxLength128],
    };
    if(_.get(this.knowledgeSearchService.getQueryParams(),'k0')){
      _.set(this.keywords0Object, 'default_value', _.get(this.knowledgeSearchService.getQueryParams(),'k0'))
    }
    if(_.get(this.knowledgeSearchService.getQueryParams(),'k1')){
      _.set(this.keywords1Object, 'default_value', _.get(this.knowledgeSearchService.getQueryParams(),'k1'))
    }
    if(_.get(this.knowledgeSearchService.getQueryParams(),'k2')){
      _.set(this.keywords2Object, 'default_value', _.get(this.knowledgeSearchService.getQueryParams(),'k2'))
    }
    if(_.get(this.knowledgeSearchService.getQueryParams(),'k3')){
      _.set(this.keywords3Object, 'default_value', _.get(this.knowledgeSearchService.getQueryParams(),'k3'))
    }
  }



  ngOnDestroy() {
    this.knowledgeSearchService.resetForm(this.knowledgeSearchService.keywordsForm)

  }

}
