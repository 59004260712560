<form [formGroup]="form" class="d-flex flex-column custom-modal-content">
  <div>
    <label>Select a metadata file</label>
    <label class='text-secondary'>(extension .json, .csv)</label>
    <div>
      <input id="metadataInput" (change)="getMetadata($event)" type="file" formControlName="metadataInput" />
    </div>
  </div>
  <div class="incorrect" *ngIf="metadataInput.hasError('extensionMustBeCsvOrJson')">
    &#10005; Format must be in .json or .csv
  </div>
</form>