<div *ngIf='viz' [hidden]='!imageIsDone' (click)='vizService.setSelectedViz(viz);l.open("modalViz")'
    class='btn btn-light tile' [style.width.px]='getWidth()' [style.height.px]='getHeight()'>
    <div class='expand'><i class="far text-primary fa-expand-alt" aria-hidden="true"></i></div>
    <div class='h-100 w-100 tile-content'>
        <div class='h-100 w-100 d-flex flex-column'>
            <div #thumbnail class='thumbnail rounded-top flex-grow-1'>
            </div>

            <div *ngIf='vizService.getTitle(viz)'
                ngbTooltip="{{vizService.getTitle(viz) ? vizService.getTitle(viz) : ''}}" tooltipClass="custom-tooltip"
                placement="right" class='rounded-bottom legend-container align-items-center d-flex'>
                <div class='w-100 legend text-center text-wrap text-light'>{{vizService.getTitle(viz)}}</div>
            </div>
        </div>
    </div>
</div>
<div #capture *ngIf='!isPlotly' class='capture'>
    <app-viz class='w-100' [vizMode]='VizMode.GeneratedForThumbnail' [viz]='viz'></app-viz>
</div>