import * as _ from 'lodash';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { RawInputTypes } from 'src/app/api/projects.service';
import { BehaviorSubject } from 'rxjs';
export enum AdditionalTypes {
  Object = 'object',
  Title = 'title',
  ShortText = 'shortText',
  Paragraph = 'paragraph',
  Password = 'password',
  File = 'file',
}

export enum Validations {
  IsRequired = 'isRequired',
  MinLength8 = 'MinLength8',
  ShouldHaveSpecialCharacter = 'ShouldHaveSpecialCharacter',
  ShouldNotHaveSpaces = 'ShouldNotHaveSpaces',
  ShouldBeIdentic = 'ShouldBeIdentic',
  ShouldBeAllDifferent = 'ShouldBeAllDifferent',
  ShouldHaveAtLeastOneCheck = 'ShouldHaveAtLeastOneCheck',
  ShouldHaveAtLeastOneAdditionalCheck = 'ShouldHaveAtLeastOneAdditionalCheck',
  ShouldHaveMax2Checks = 'ShouldHaveMax2Checks',
  ShouldHaveMax2AdditionalChecks = 'ShouldHaveMax2AdditionalChecks',
  ShouldBeAnEmail = 'ShouldBeAnEmail',
  MaxLength128 = 'MaxLength128',
  IdHelp = 'IdHelp',
  TeamIdHelp = 'TeamIdHelp',
  ShouldNotContainDoubleQuote = 'ShouldNotContainDoubleQuote',
  ShouldBeADigit = 'ShouldBeADigit',
  ShouldBeHaveAtLeastOneFieldFilled = 'ShouldBeHaveAtLeastOneFieldFilled',
  ShouldBeHaveAtLeastTwoFieldsFilled = 'ShouldBeHaveAtLeastTwoFieldsFilled',
  ShouldStartWithPMC = 'ShouldStartWithPMC',
  ShouldHaveNumbersAfterPMC = 'ShouldHaveNumbersAfterPMC',
}
export function getFormErrorSentence(errorName) {
  let sentence;
  switch (errorName) {
    case Validations.ShouldBeIdentic:
      sentence = "Passwords don't match";
      break;
    case Validations.ShouldBeAllDifferent:
      sentence = 'All fields must be different';
      break;
    case Validations.ShouldBeHaveAtLeastOneFieldFilled:
      sentence = 'At least one field must be filled';
      break;
    case Validations.ShouldBeHaveAtLeastTwoFieldsFilled:
      sentence = 'At least 2 fields must be filled';
      break;
    default:
      sentence = '';
  }
  return sentence;
}

export function getFieldErrorSentence(errorName) {
  let sentence;
  switch (errorName) {
    case 'required':
    case Validators.required:
    case Validations.IsRequired:
      sentence = 'This field is required';
      break;
    case Validations.ShouldHaveMax2Checks:
      sentence = 'A maximum of 2 checkboxes can be checked';
      break;
    case Validations.ShouldHaveMax2AdditionalChecks:
      sentence = 'A maximum of 2 checkboxes can be checked';
      break;
    case Validations.MinLength8:
      sentence = 'Please enter at least 8 characters';
      break;
    case Validations.MaxLength128:
      sentence = 'This field must not be longer than 128 characters';
      break;
    case Validations.ShouldBeAnEmail:
      sentence = 'This field must be an email';
      break;
    case Validations.ShouldBeADigit:
      sentence = 'This field must be a digit';
      break;
    case Validations.ShouldNotHaveSpaces:
      sentence = 'Spaces are forbidden';
      break;
    case Validations.ShouldHaveSpecialCharacter:
      sentence = 'This password must contain at least one symbol, as @#$%';
      break;
    case Validations.ShouldHaveAtLeastOneCheck:
      sentence = 'At least one checkbox must be checked';
      break;
    case Validations.ShouldHaveAtLeastOneAdditionalCheck:
      sentence = 'At least one checkbox must be checked';
      break;
    case Validations.IdHelp:
      sentence = 'ID can be found by clicking on a user ressource in another team, or directly in its own account information page';
      break;
    case Validations.ShouldNotContainDoubleQuote:
      sentence = 'This field must not contain any double quote character';
      break;
    case Validations.TeamIdHelp:
      sentence = 'This is your subscription plan number. Please contact us to subscribe';
      break;
    case Validations.ShouldStartWithPMC:
      sentence = 'This field should start with the prefix PMC';
      break;
    case Validations.ShouldHaveNumbersAfterPMC:
      sentence = 'this field should only have numbers after the prefix PMC';
      break;
    default:
      sentence = errorName;
  }
  return sentence;
}

export function IsRequired(control: UntypedFormControl): ValidationErrors | null {
  if (_.get(control, 'value.length', 0) === 0) {
    let result = {};
    _.set(result, Validations.IsRequired, true);
    return result;
  }

  return null;
}
export function ShouldBeADigit(control: UntypedFormControl): ValidationErrors | null {
  const regex = /\d+/g;
  const value = _.get(control, 'value');
  if (value && !value.match(regex)) {
    let result = {};
    _.set(result, Validations.ShouldBeADigit, true);
    return result;
  }
  return null;
}
export function ShouldNotContainDoubleQuote(control: UntypedFormControl): ValidationErrors | null {
  const value = _.get(control, 'value', '');
  if (_.includes(value, '"')) {
    let result = {};
    _.set(result, Validations.ShouldNotContainDoubleQuote, true);
    return result;
  }

  return null;
}
export function ShouldStartWithPMC(control: UntypedFormControl): ValidationErrors | null {
  const value = _.get(control, 'value', '');
  if (!_.startsWith(value, 'PMC')) {
    let result = {};
    _.set(result, Validations.ShouldStartWithPMC, true);
    return result;
  }
  return null;
}
export function ShouldHaveNumbersAfterPMC(control: UntypedFormControl): ValidationErrors | null {
  const value = _.get(control, 'value', '');
  if (!value || !_.startsWith(value, 'PMC')){
    return null
  }
  let valueNumberPart = value.substr(3)
  const regex ="^[0-9]*$"
  if (!valueNumberPart.match(regex)) {
    let result = {};
    _.set(result, Validations.ShouldHaveNumbersAfterPMC, true);
    return result;
  }
  return null;
}

export function IdHelp(control: UntypedFormControl): ValidationErrors | null {
  if (!_.get(control, 'touched')) {
    let result = {};
    _.set(result, Validations.IdHelp, true);
    return result;
  }

  return null;
}
export function TeamIdHelp(control: UntypedFormControl): ValidationErrors | null {
  if (!_.get(control, 'touched')) {
    let result = {};
    _.set(result, Validations.TeamIdHelp, true);
    return result;
  }

  return null;
}
export function MinLength8(control: UntypedFormControl): ValidationErrors | null {
  if (_.get(control, 'value.length', 0) < 8) {
    let result = {};
    _.set(result, Validations.MinLength8, true);
    return result;
  }
  return null;
}
export function MaxLength128(control: UntypedFormControl): ValidationErrors | null {
  if (_.get(control, 'value.length', 0) > 128) {
    let result = {};
    _.set(result, Validations.MaxLength128, true);
    return result;
  }
  return null;
}
export function ShouldNotHaveSpaces(control: UntypedFormControl): ValidationErrors | null {
  if (_.includes(_.get(control, 'value', ''), ' ')) {
    let result = {};
    _.set(result, Validations.ShouldNotHaveSpaces, true);
    return result;
  }
  return null;
}
export function ShouldHaveSpecialCharacter(control: UntypedFormControl): ValidationErrors | null {
  const regex = /[^a-zA-Z0-9]+/g;
  const value = _.get(control, 'value', '');
  if (value.match(regex)) {
    return null;
  } else {
    let result = {};
    _.set(result, Validations.ShouldHaveSpecialCharacter, true);
    return result;
  }
}
export function ShouldBeIdentic(control1: string, control2: string): (group: UntypedFormGroup) => ValidationErrors | null {
  return (group: UntypedFormGroup): ValidationErrors | null => {
    let control1Value = group.get(control1).value;
    let control2Value = group.get(control2).value;
    if (control1Value === control2Value) {
      return null;
    } else {
      let result = {};
      _.set(result, Validations.ShouldBeIdentic, true);
      return result;
    }
  };
}
export function ShouldBeAllDifferent(): (group: UntypedFormGroup) => ValidationErrors | null {
  return (group: UntypedFormGroup): ValidationErrors | null => {
    let arrayOfValues = _.without(Object.values(group.value), '', null, undefined);
    arrayOfValues = arrayOfValues.map((x) => _.toLower(x));
    if (arrayOfValues.length === _.uniq(arrayOfValues).length) {
      return null;
    } else {
      let result = {};
      _.set(result, Validations.ShouldBeAllDifferent, true);
      return result;
    }
  };
}
export function ShouldBeHaveAtLeastOneFieldFilled(): (group: UntypedFormGroup) => ValidationErrors | null {
  return (group: UntypedFormGroup): ValidationErrors | null => {
    let hasOneFilledField = false;
    for (const [key, value] of Object.entries(group.value)) {
      if (_.get(value, 'length')) {
        hasOneFilledField = true;
        return;
      }
    }
    if (hasOneFilledField) {
      return null;
    } else {
      let result = {};
      _.set(result, Validations.ShouldBeHaveAtLeastOneFieldFilled, true);
      return result;
    }
  };
}
export function ShouldBeHaveAtLeastTwoFieldsFilled(): (group: UntypedFormGroup) => ValidationErrors | null {
  return (group: UntypedFormGroup): ValidationErrors | null => {
    let fieldFilled = 0;
    for (const [key, value] of Object.entries(group.value)) {
      if (_.get(value, 'length')) {
        fieldFilled++;
        if (fieldFilled === 2) {
          return;
        }
      }
    }
    if (fieldFilled >= 2) {
      return null;
    } else {
      let result = {};
      _.set(result, Validations.ShouldBeHaveAtLeastTwoFieldsFilled, true);
      return result;
    }
  };
}
export function ShouldHaveAtLeastOneCheck(control: UntypedFormControl): ValidationErrors | null {
  if (control.value) {
    if (_.countBy(control.value).true === undefined) {
      let result = {};
      _.set(result, Validations.ShouldHaveAtLeastOneCheck, true);
      return result;
    }
  }
  return null;
}
export function ShouldHaveAtLeastOneAdditionalCheck(control: UntypedFormControl): ValidationErrors | null {
  if (control.value) {
    if (_.countBy(control.value).true === undefined || _.countBy(control.value).true === 1) {
      let result = {};
      _.set(result, Validations.ShouldHaveAtLeastOneAdditionalCheck, true);
      return result;
    }
  }
  return null;
}
export function ShouldHaveMax2AdditionalChecks(control: UntypedFormControl): ValidationErrors | null {
  if (control.value) {
    if (_.countBy(control.value).true > 3) {
      let result = {};
      _.set(result, Validations.ShouldHaveMax2AdditionalChecks, true);
      return result;
    }
  }
  return null;
}
export function ShouldHaveMax2Checks(control: UntypedFormControl): ValidationErrors | null {
  if (control.value) {
    if (_.countBy(control.value).true > 2) {
      let result = {};
      _.set(result, Validations.ShouldHaveMax2Checks, true);
      return result;
    }
  }
  return null;
}
export function ShouldBeAnEmail(control: UntypedFormControl): ValidationErrors | null {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (control.value) {
    if (!re.test(String(control.value).toLowerCase())) {
      let result = {};
      _.set(result, Validations.ShouldBeAnEmail, true);
      return result;
    }
  }
  return null;
}
export interface InputJson {
  formControlName: string;
  originalJson?: object | string | null;
  placeholder?: string;
  type?: RawInputTypes | AdditionalTypes;
  formControl?: UntypedFormControl;
  label?: string;
  default_value?: any | null;
  description?: string;
  step?: string | null;
  showIcon?: string | null;
  fitToContent?: boolean;
  charMax?: number;
  is_array?: boolean;
  cluster_selection?: boolean;
  multiple_selection?: boolean;
  allowed_values?: Array<any>;
  allowed_values_descriptions?: Array<any>;
  readonly?: boolean;
  fixed_width?: number;
  validations?: Array<any> | null;
  retrievedValue?: BehaviorSubject<any>;
  retrievedAllowedValues?: BehaviorSubject<any>;
  retrievedAllowedValuesDescription?: BehaviorSubject<any>;
  max_select_height?: number | null;
  tagname?: string;
  lined_label?: string | null;
  useSelect?: boolean;
}
