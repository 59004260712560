import { Component, EventEmitter, Input,  Output } from '@angular/core';

@Component({
  selector: 'mims-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent{
  @Input() page;
  @Input() pageSize;
  @Input() count;
  @Input() boudaryLinks? = true;
  @Input() inputWidth? = 4;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  Math = Math;


  enterNewPageValue(value: string) {
    this.emitPageChange(parseInt(value, 10) || 1);
  }
  emitPageChange(newPageSelected) {
   setTimeout(() =>this.pageChange.emit(newPageSelected), 1)
  }
  formatInput(input: HTMLInputElement) {
    input.value = input.value.replace(/[^0-9]/g, '');
  }
  
}
