import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { ItemType } from 'src/app/shared/helpers/itemType';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { ItemAction } from '../../pages/file-manager2/file-manager2.service';
import moment from 'moment';
@Component({
  selector: 'mims-files-2',
  templateUrl: './files-2.component.html',
  styleUrls: ['./files-2.component.scss', '../layout/managers.scss'],
})
export class Files2Component implements OnChanges {
  @Input() navigation?: boolean = false;
  @Input() rootFolder? = null;
  @Input() openedFolder? = null;
  @Input() parentFolder? = null;
  @Input() architectureEdition?: boolean = false;
  @Input() displayedFiles: any[];
  @Input() uploadingFiles?: any[] = [];
  @Input() singleSelection?;
  TabsName = TabsName;
  ItemType = ItemType;
  ItemAction = ItemAction;
  @Input() bulkSelection: any[];
  _ = _;
  savedLastModified;
  @Output() bulkItemAction: EventEmitter<ItemAction> = new EventEmitter<ItemAction>();
  @Output() itemAction: EventEmitter<any> = new EventEmitter<any>();

  @Input() viewTab?: TabsName;
  @Input() itemType?: ItemType;
  loaderHeight;
  constructor(){
    this.loaderHeight = this.itemType === ItemType.File ? 449 : 200;
  }


  ngOnChanges(changes: SimpleChanges): void {
    const fileHeight = this.itemType === ItemType.File ? 35 + 11 : 45 + 11;
    const change = changes.displayedFiles;
    if (this.itemType !== ItemType.File){
      return
    }
    if (change && !change.isFirstChange() && change.currentValue === undefined) {
      const oldLength = _.get(change, 'previousValue.length') === 0 ? 3 : _.get(change, 'previousValue.length');

      if (changes.openedFolder && _.get(changes, 'openedFolder.previousValue.id') !== _.get(this.rootFolder, 'id')) {
        this.loaderHeight = (oldLength + 1) * fileHeight;
      } else {
        this.loaderHeight = oldLength * fileHeight;
      }
    }
  }
  isSelected(id) {
    return (
      _.findIndex(this.bulkSelection, (o) => {
        return o.id === id;
      }) > -1
    );
  }
  trackByFn(index, item) {
    const key = Object.keys(_.get(item, 'metadata'));
    const metadata = !_.isEmpty(_.get(item, 'metadata')) ? _.get(key, 'length', 0) : null;
    return _.get(item, 'id') + ',' + metadata + ',' + _.get(item, 'status');
  }
  canPerform(item, action: ItemAction) {
    switch (action) {
      case ItemAction.MoveToFolder:
        if (_.get(item, 'capabilities.can_move') && this.architectureEdition) return true;
        break;
      default:
        return false;
    }
  }
  lastModified(item) {
    return this.fromNowOrNow(_.get(item, 'date_of_modification', 0));
  }
  lastTrashed(item) {
    return this.fromNowOrNow(_.get(item, 'date_of_trashing', 0));
  }
  isNewModifiedDate(index) {
    return this.lastModified(_.nth(this.displayedFiles, index)) !== this.lastModified(_.nth(this.displayedFiles, index - 1));
  }
  isNewTrashedDate(index) {
    return this.lastTrashed(_.nth(this.displayedFiles, index)) !== this.lastTrashed(_.nth(this.displayedFiles, index - 1));
  }
  fromNowOrNow(date) {
    date = date < moment().unix() ? date : moment().unix();
    return moment.unix(date).fromNow();
  }
  isTrashed(item) {
    return _.get(item, 'trashed', false);
  }
  trackByFn2(index, item) {
    return _.get(item, 'id');
  }
  isBulkSelected = (id) => {
    return (
      _.findIndex(this.bulkSelection, function (o) {
        return _.get(o, 'id') === id;
      }) > -1
    );
  };
}
