import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-credit-alerts',
  templateUrl: './credit-alerts.component.html',
  styleUrls: ['./credit-alerts.component.scss'],
})
export class CreditAlertsComponent {
  @Input() cost: number;
  @Input() budget: number;
  @Input() theme?: string;
  @Input() error?;

  constructor() {
  }
  getCreditsAfterExecution() {
    return this.budget - this.cost;
  }
}
