<span class='h-100 w-100 d-flex align-items-center'>
  <span class="path w-100 ">
    <span *ngFor="let el of dataSource; let last=last; let first = first">
      <span [class.cursor-pointer]='el.id' class='p-0' [class.text-secondary]="last"
        [class.font-weight-bold]='first' [class.text-light]="!last"  [class.btn-link]='el.id'
        (click)="valueChanged.emit(el)">
        <i aria-hidden="true" *ngIf='!first'  class="fal mx-2 fa-chevron-right"></i>
        {{el.title}}
      </span>
    </span>
  </span>
