import { Component } from '@angular/core';
import { ExplorerManagerService } from 'src/app/api/explorer-manager.service';

@Component({
  selector: 'app-slide-answer-full-description',
  templateUrl: './slide-answer-full-description.component.html',
  styleUrls: ['./slide-answer-full-description.component.scss'],
})
export class SlideAnswerFullDescriptionComponent {
  constructor(public e: ExplorerManagerService) {}
}
