import { Component, HostListener, Input } from '@angular/core';
import * as _ from 'lodash';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { Router } from '@angular/router';

@Component({
  selector: 'app-already-logged-in',
  templateUrl: './already-logged-in.component.html',
  styleUrls: ['./already-logged-in.component.scss'],
})
export class AlreadyLoggedInComponent {
  @Input() user;
  _ = _;
  constructor( private router: Router) {}

  @HostListener('document:keydown', ['$event'])
  loginWithEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.goToHomePage()
    }
  }
  changeUser(){
    localStorage.clear();
    this.router.navigate([PagesName.Login]).then(() => {
      window.location.reload();
    });
  }
  goToHomePage(){
    this.router.navigate([PagesName.Home]);
  }
}
