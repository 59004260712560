import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-top-level-category',
  templateUrl: './top-level-category.component.html',
  styleUrls: ['./top-level-category.component.scss'],
})
export class TopLevelCategoryComponent implements OnInit {
  @Input() category: any;
  @Input() color: string;
  @Output() expand = new EventEmitter<boolean>();

  @HostBinding('style')
  get hostStyle(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`background-color: ${this.color}4D;`);
  }

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  handleExpand() {
    this.expand.emit();
  }
}
