import { Component, Input, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PermissionsHttpService } from 'src/app/shared/permissions-http-service/permissions-http.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';


import { TabsName } from 'src/app/shared/helpers/tabsName';
import { PagesName } from 'src/app/shared/helpers/pagesName';


import * as _ from 'lodash';
import { SolutionService } from '../../solution-manager/solution.service';

@Component({
  selector: 'solution-doc',
  styleUrls: ['./solution.component.scss'],
  templateUrl: './solution.component.html',
})

export class SolutionDocComponent implements OnInit {
  @Input() solutionId?;
  canCreateProject = false;
  solution = {};
  atlassianId = '';
  videos: SafeResourceUrl[] = [];
  workflowId = '';
  title = '';
  executeRoute = [];
  icon = ''
  _=_
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private permissions: PermissionsHttpService,
    private solutionService: SolutionService, 
    private sanitizer: DomSanitizer ) {
    this.permissions
      .checkPermission({
        permissions: {
          createProject: {},
        },
      })
      .subscribe((res) => {
        this.canCreateProject = res.createProject;
      });
  }


  ngOnInit() {
    if (!this.solutionId) _.set(this, 'solutionId', this.route.snapshot.paramMap.get('solutionId'));

    this.solutionService.getSolution(this.solutionId).subscribe((res) => {
      this.solution = res;
      this.atlassianId = this.solution['spec']['documentation']['atlassian'];
      this.videos = _.get(this.solution['spec']['documentation'], 'video', []).map((video) => {
        return this.sanitizer.bypassSecurityTrustResourceUrl(video);
      })

      this.workflowId = this.solution["spec"]["workflow_id"]
      this.executeRoute =  _.get(this.solution["spec"]["documentation"], 'route', []);
      this.icon =  _.get(this.solution["spec"], 'icon', '');
      this.title =  _.get(this.solution["spec"], 'title', '')
      
    });
  }
  @HostListener('document:keydown', ['$event'])
  createWithEnter(event: KeyboardEvent) {
    if (event.key === 'Enter' && document.getElementById('createProject')) {
      document.getElementById('createProject').click()
    }
  }

  getSolution() {
    return this.solution;
  }

  getSolutionTitle() {
    return this.title
  }
  // createProject() {
  //   this.router.navigate(this.executeRoute);
  // }

  getAtlassianID() {
    return this.atlassianId;
  }

  getWorkflowID() {
    return this.workflowId;
  }

  createProject() {
    if(!this.canCreateProject){
      return
    }
    this.router.navigate([PagesName.ProjectCreation, this.solutionId]);
  }
}
