<div class='h-100 w-100 p-4 '>
  <div class="card mx-auto p-4">
    <div class="card-body">
      <div class='mb-4'>
        <h4 class="text-secondary">Congratulations!</h4>
        <div class='mt-4'> The project has been created and the workflows have been started. Once the project is available, a notification will be displayed on <span class='font-weight-bold'>{{environment.platformName}}.</span></div>
      </div>
    </div>
    <div class='btn-container w-100'>
      <div *ngIf='!projectsService.getFromDebugger()' (click)='goToProjects()' class="btn btn-light border border-dark"
        type="button">
        <span class='text-uppercase small '>Back to projects</span>
      </div>
      <div *ngIf='projectsService.getFromDebugger()' (click)='goToDebugger()' class="btn btn-light border border-dark"
        type="button">
        <span class='text-uppercase small '>Back to debugger</span>
      </div>
      <div class="btn btn-dark" (click)='goToProject()' type="button">
        <span class='text-uppercase small'>Open project</span>
      </div>
      
    </div>
  </div>
</div>