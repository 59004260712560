import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { LocalService } from './local-storage.service';
import * as _ from 'lodash';
import { MulticountryService } from './multicountry.service';
interface Notification {
  body: string;
  date_of_creation: number;
  date_of_reading: number;
  id: string;
  links: { mark_as_read: string };
  read: boolean;
  resource_type: string;
  title: string;
  type: string;
}

interface NotificationResponse {
  count: number;
  data: Array<Notification>;
  has_more: boolean;
  links: { self: string; next?: string };
}

@Injectable({ providedIn: 'root' })
export class NotificationHttpService {
  basePath = 'XXXX';
  session;

  constructor(private http: HttpClient, private localService: LocalService, private multicountryService: MulticountryService) {
    this.session = this.localService.getFromLocalStorage('session');
  }

  getNotifications = (): Observable<any> => {
    return this.multicountryService.asyncListing(this._getNotifications, [], 'all');
  };
  _getNotifications = (country?): Observable<NotificationResponse> => {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    let queryParameters = new HttpParams({});
    queryParameters = queryParameters.set('limit', 100 as any);
    return this.http.get<NotificationResponse>(`${this.basePath}/mas/notifs?sortBy=date_of_creation,desc`, {
      params: queryParameters,
      headers: headers,
    });
  }

  // markAllAsRead() {
  //   return this.http.post(`${this.basePath}/mas/notifs/all/read`, {});
  // }
  markAsRead(notifId,country) {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.post(`${this.basePath}/mas/notifs/${notifId}/read`, {headers});
  }
  // markAllAsDismiss() {
  //   return this.http.post(`${this.basePath}/mas/notifs/all/dismiss`, {});
  // }
  // markAsDismiss(notifId) {
  //   return this.http.post(`${this.basePath}/mas/notifs/${notifId}/dismiss`, {});
  // }
  notificationSocket(): WebSocketSubject<Notification> {
    let country = this.localService.getFromLocalStorage('user', 'account_country');
    let frUserId = _.get(this.localService.getFromLocalStorage('user', 'external_user_ids'), 'FR');
    if (!_.isEmpty(frUserId)) {
      country = 'FR';
    }
    const basePath = this.localService.getSpecificCountryUrl(country);
    const accessToken = this.localService.getSpecificCountryAccessToken(country);

    return webSocket(`${basePath.replace('http', 'ws')}/mas/notifs/register?access_token=${accessToken}`);
  }
}
