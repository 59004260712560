import { DropDownModule } from './../../mims-ui-lib/drop-down/drop-down.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RibbonAltActionComponent } from './ribbon/ribbon-alt-action/ribbon-alt-action.component';
import { RibbonButtonComponent } from './ribbon/ribbon-button/ribbon-button.component';
import { RibbonComponent } from './ribbon/ribbon.component';

@NgModule({
  declarations: [RibbonComponent, RibbonButtonComponent, RibbonAltActionComponent],
  imports: [CommonModule, RouterModule, DropDownModule],
  exports: [RibbonComponent, RibbonButtonComponent, RibbonAltActionComponent],
})
export class LayoutModule {}
