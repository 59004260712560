<div class='m-3'>
  <div type="button" (click)='knowledgeSearchService.navigateBackToSelectFormulation()' class="btn btn-light border">
    <span class="text-uppercase small"><i aria-hidden="true" class="text-muted far fa-arrow-to-left mr-1"></i> Change
      formulation</span>
  </div>
</div>
<div class=' m-5 d-flex flex-column align-items-center d-flex'>
  <h3 class='mx-5'> {{PagesPublicName.KnowledgeSearchSingleArticle}}</h3>
  <div class='w-75 my-5 d-flex justify-content-center'>
    <form [formGroup]="knowledgeSearchService.articleForm" class="mx-3 form d-flex flex-column custom-modal-content">
      <app-input-field [parentForm]='knowledgeSearchService.articleForm' [inputJson]='pmcIDObject'>
      </app-input-field>
      <div
        (click)='knowledgeSearchService.getFormValidity(knowledgeSearchService.articleForm) ? [knowledgeSearchService.saveQueryParams(knowledgeSearchService.articleForm), knowledgeSearchService.navigateToResults(Formulation.Article)] : ""'
        ngbTooltip="{{knowledgeSearchService.getFormError(knowledgeSearchService.articleForm)}}" tooltipClass="custom-tooltip" placement="left"
        [class.disabled]="!knowledgeSearchService.getFormValidity(knowledgeSearchService.articleForm)" tabindex="0"
        class='btn  mt-3 mx-auto btn-dark button '>
        <span class='small text-uppercase'> Search </span>
      </div>
    </form>
  </div>
</div>
