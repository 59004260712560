import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Dashboard2Service, Layouts } from '../dashboard2.service';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { VizType } from 'src/app/shared/helpers/vizType';

@Component({
  selector: 'app-project-dashboard2',
  templateUrl: './project-dashboard2.component.html',
  styleUrls: ['./project-dashboard2.component.scss'],
})
export class ProjectDashboard2Component implements OnInit, OnChanges {
  @Input() projectId;
  @Input() isActive;
  @Input() version?;
  @Input() projectTitle?;
  @Input() dashboardTitle?;
  @Input() dashboardId;
  @Input() country?;
  Layouts = Layouts
  sectionArray = [];
  dynamicDashboard = false;
  error = false;
  inProgress = true;
  VizType = VizType
  _=_
  isDownloaded = new BehaviorSubject<boolean>(false);
  @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef;
  isFirstLoaded = false
  constructor(public dashboard2Service: Dashboard2Service) {}

  ngOnInit(): void {
    this.retrieveDashboard();
    setTimeout(() => {
      this.scrollContainer.nativeElement.focus();
    });
  }

  ngOnChanges({ isActive }: SimpleChanges) {
    if (!isActive.previousValue && isActive.currentValue && !this.isFirstLoaded){
      this.isFirstLoaded = true
    }
    if (isActive.currentValue) {
      setTimeout(() => {
        this.scrollContainer.nativeElement.focus();
      });
    }
  }

  retrieveDashboard() {
    this.sectionArray = [];
    if(this.projectId && this.dashboardId && this.dashboardId !== 'in progress') {
      this.dashboard2Service.downloadDashboard(this.projectId, this.dashboardId,this.country).subscribe((res) => {
        this.isDownloaded.next(true);
        const graphs = _.get(res, 'graphs', []);
        this.dynamicDashboard = graphs.some((graph) => graph.interactive);
        graphs.forEach((el) => {
          const layout = _.get(el,'type') === VizType.Select ? Layouts.Small : Layouts.Wide
          this.sectionArray.push({ graphId: _.get(el, 'graph_id'), layout, webGlContexts: undefined });
        });
        _.set(_.nth(this.sectionArray, 0), 'show', true)
        this.inProgress = false
      }, (error) => {
        this.isDownloaded.next(true);
        this.error = true
      });
    } else {
      this.inProgress = true;
    }
  }
  handleSectionChange(event,index) {
    if(event)
   _.set(this.sectionArray[index], 'layout' , event)
  }
  getShow(index) {
    return _.get(_.nth(this.sectionArray, index), 'show', false);
  }
  handleWebGlContexts(event) {
    if (!event) {
      return;
    }
    let el = _.find(this.sectionArray, (o) => {
      return _.get(o, 'graphId') === _.get(event, 'graphId');
    });
    _.set(el, 'webGlContexts', _.get(event, 'value'));
  }
  updateScatterGlPool() {
    let total = 0;
    this.sectionArray.forEach((el) => {
      total = total + _.get(el, 'webGlContexts', 0);
    });
    this.dashboard2Service.scatterGLPool = this.dashboard2Service.maxScatterGLPool - total;
  }
  handleShowChildren(event) {
    if (!event) {
      return;
    }
   
    let index = _.findIndex(this.sectionArray, (o) => {
      return _.get(o, 'graphId') === _.get(event, 'after');
    });
    const areNewChildrenViz = index === this.sectionArray.length - 1;
    this.sectionArray = this.sectionArray.slice(0, index + 1);
    _.get(event, 'children', []).forEach((el) => {
      if (!el.length) {
        return;
      }
      this.sectionArray.push({ graphId: el, webGlContexts: undefined });
    });
    this.scrollContainer.nativeElement.focus();
    if (_.get(event, 'children', []).length && areNewChildrenViz ) {
      setTimeout(() => {
        this.scrollContainer.nativeElement.scrollBy(0, 30);
      }, 150);
    }
  }
}
