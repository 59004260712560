import { Component } from '@angular/core';
import { LoginHttpService } from './login-http-service/login-http.service';
import { LocalService } from 'src/app/api/local-storage.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login2',
  templateUrl: './login2.component.html',
  styleUrls: ['./login2.component.scss'],
})
export class Login2Component {
  user;
  constructor(public loginHttp: LoginHttpService, public localService: LocalService, public router: Router) {
    this.loginHttp.activeStep = 0;
    this.user = this.localService.getFromLocalStorage('user');
    if (this.localService.getFromLocalStorage('session') && this.user && this.localService.getFromLocalStorage('country')) {
      this.loginHttp.verifyAccess().subscribe(
        () => {
          this.loginHttp.activeStep = 3;
        },
        () => {
          localStorage.clear();
          window.location.reload();
        }
      );
    }
  }
}
