import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  Inject,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { fromEvent, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { trigger, style, state, animate, transition } from '@angular/animations';

const panelAnimation = trigger('appearPanel', [
  state('void', style({ transform: 'scaleY(0.8)', opacity: 0.5 })),
  state('opened', style({ transform: 'scaleY(1)', opacity: 1 })),
  transition('void => opened', animate('200ms cubic-bezier(0, 0, 0.2, 1)')),
]);

/**
 * mims-dropdown take any element with <ng-content>, and create an attached overlay
 * to the overlay origin
 *
 * - width: controllable, by default its the same width as origin
 * - height: determined by the passed element height
 * - responsible for close / open the dropdown
 *
 */
@Component({
  selector: 'mims-dropdown',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [panelAnimation],
})
export class DropDownComponent implements AfterViewInit, OnChanges, OnDestroy {
  /** The overlay origin to which the dropdown is connected */
  @Input() overlayOrigin: CdkOverlayOrigin;

  /** Dropdown width, will apply to css: width */
  @Input() width: number;

  /** MinWidth of dropdown, will apply to css: min-width */
  @Input() minWidth: number;

  /** Indicate if the dropdown is opened */
  @Input() opened = false;

  /** Close dropdown when click outside */
  @Input() closeOnClickOutside = true;

  /** Emit every time dropdown opened / closed */
  @Output() toggled = new EventEmitter<boolean>();

  /** Emit every time click outside of dropdown */
  @Output() clickOutside = new EventEmitter<boolean>();

  /** Grab the overlay to control width */
  @ViewChild(CdkConnectedOverlay, { static: false }) connectedOverlay: CdkConnectedOverlay;

  /** keep instance of old overlayOrigin */
  truthyOverlayOrigin: CdkOverlayOrigin;

  /** @ignore patter to unsubscribe */
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(@Inject(DOCUMENT) private _document, private _cdr: ChangeDetectorRef) {}

  ngOnChanges({ opened, overlayOrigin }: SimpleChanges) {
    if (overlayOrigin && overlayOrigin.currentValue) {
      this.truthyOverlayOrigin = overlayOrigin.currentValue;
    }
    if (opened) {
      this.toggled.emit(opened.currentValue);
    }
  }

  ngAfterViewInit() {
    // update width
    this.updateOverlaySize();
    this._watchClickOutside();

    fromEvent(window, 'resize')
      .pipe(
        filter(() => !!this.overlayOrigin),
        filter(() => this.opened),
        debounceTime(200),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(() => {
        this.updateOverlaySize();
        this.connectedOverlay.overlayRef.updatePosition();
      });
  }

  private _watchClickOutside() {
    fromEvent(this._document, 'mousedown')
      .pipe(
        filter(() => !!this.overlayOrigin),
        filter(() => this.opened),
        // outside means neither dropdown nor overlay origin
        filter(
          (event: MouseEvent) =>
            !this.overlayOrigin.elementRef.nativeElement.contains(event.target as any) &&
            !this.connectedOverlay.overlayRef.overlayElement.contains(event.target as any)
        ),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(() => {
        if (this.closeOnClickOutside) {
          this.opened = false;
          this.toggled.emit(false);
          this._cdr.detectChanges();
        }
        this.clickOutside.emit(this.opened);
      });
  }

  /** Update the overlay width, if not set use origin element's width */
  updateOverlaySize() {
    if (!this.overlayOrigin) {
      return;
    }
    const originElement = this.overlayOrigin.elementRef.nativeElement;
    let minWidth = this.minWidth;
    let width = this.width;
    if (!minWidth && !width) {
      minWidth = originElement.getBoundingClientRect().width;
      width = originElement.getBoundingClientRect().width;
    }
    if (this.connectedOverlay && this.connectedOverlay.overlayRef) {
      this.connectedOverlay.overlayRef.updateSize({ width: width + 'px', minWidth: minWidth + 'px' });
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
