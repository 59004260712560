import { ChangeDetectionStrategy, Component } from '@angular/core';
import * as _ from 'lodash';
import { TeamActions, TeamsActions, TeamsService } from './teams.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { availableFeatures } from 'src/environments/environment';
import { Features } from 'src/environments/features';

import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-teams-manager',
  templateUrl: './teams-manager.component.html',
  styleUrls: ['../../shared/layout/managers.scss', './teams-manager.component.scss'],
})
export class TeamsManagerComponent {
  availableFeatures = availableFeatures;
  Features = Features;
  _ = _;
  TeamActions = TeamActions;
  PagesPublicName = PagesPublicName;
  teamSubscriptionForm = new UntypedFormGroup({
    subscriptionId: new UntypedFormControl(null),
  });
  constructor(public teamsService: TeamsService, public layout: LayoutOrganizationService) {
    this.teamsService.initCountryDataSource();
    this.teamsService.initSelectedCountry();
  }
  handleChangeSubscription = () => {
    const team = this.teamsService.selectedTeam;
    const value = _.get(this, 'teamSubscriptionForm.controls.subscriptionId.value');
    this.teamsService.teamEvent.emit({ team, action: TeamActions.AttachSubscription, value });
  };

  ngOnInit() {
    this.teamsService.teamsEvent.emit({ action: TeamsActions.Retrieve });
  }
  getTeams() {
    return _.get(this.teamsService.teams, 'value');
  }
  trackFn(index, item) {
    return _.get(item, 'id', '') + _.get(item, 'name', '');
  }
}
