import { Injectable } from '@angular/core';
import { LocalService } from './local-storage.service';
@Injectable({
  providedIn: 'root',
})
export class ExplorerService {
  protected basePath = 'XXXX';

  constructor(private localService: LocalService) {
  }

  createSocket() {
    let country = this.localService.getFromLocalStorage('user', 'account_country')
    const basePath = this.localService.getSpecificCountryUrl(country)
    const accessToken = this.localService.getSpecificCountryAccessToken(country)
    const address = `${basePath.replace('http', 'ws')}/gas?access_token=${
      accessToken
    }`;

    return new WebSocket(address);
  }
}
