<div class="d-flex flex-column form-group">
  <div *ngFor="let element of field.fieldGroup; let index = index; let first=first" class="my-2">
    <label *ngIf="first">
      {{_.get(field,'templateOptions.label')}}
      <span *ngIf="_.get(element,'templateOptions.required')">*</span>
    </label>
    <div class="my-2 d-flex flex-nowrap">
      <input
        oninput="this.value.length ? this.value = this.value.toUpperCase() : ''"
        [placeholder]="_.get(element,'templateOptions.placeholder')"
        [class.text-danger]="hasError(index) || hasError()"
        [class.field-error]="hasError(index)  || hasError()"
        [formControl]="getControl(index)"
        [formlyAttributes]="element"
        class="form-control"
        type="text"
      />
      <div *ngIf="!first" class="col-sm-2 d-flex align-items-center">
        <div class="btn smooth-border btn-outline-dark px-2" (click)="remove(index)">Remove</div>
      </div>
    </div>
    <div *ngIf="hasError(index)" class="invalid-feedback d-block">
      <formly-validation-message [field]="element"></formly-validation-message>
    </div>
  </div>
  <div
    placement="right"
    ngbTooltip="{{_.get(field, 'fieldGroup.length') > 3 ? 'Impossible to add more than 4 genes': ''}}"
    [class.disabled]="_.get(field, 'fieldGroup.length') > 3"
    class="btn btn-plus mb-4 smooth-border btn-outline-dark mt-2 p-0"
    (click)="_.get(field, 'fieldGroup.length') > 3 ? '' : addField()"
  >
    +
  </div>
  <div *ngIf="hasError()" class="invalid-feedback d-block">
    <formly-validation-message [field]="field"></formly-validation-message>
  </div>
</div>
