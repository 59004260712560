<div
  *ngIf="!orientationLandscape"
  id="page-container"
  class="app-page-container full-height"
  (window:resize)="onResize()"
>
  <router-outlet></router-outlet>
</div>

<div
  *ngIf="orientationLandscape"
  class="landscape-error"
  (window:resize)="onResize()"
>
  <div class="alert alert-danger">
    <p class="error-message">Please turn your device to portrait mode</p>
  </div>
</div>

<!-- site layout needs to be placed in separate component so sidebar, footer, and chat, don't show up on login page-->
