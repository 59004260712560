<app-viz-thumbnail-container [size]='size' *ngIf="data" [title]='title'>
    <div class="table-responsive vizTableContainer vizTableContainerThumbnail d-flex">
        <table aria-describedby="table" class="vizTable vizTableThumbnail">
            <thead *ngIf="data[0].headers">
                <tr>
                    <th *ngFor="let header of data[0].headers">
                        {{ header }}
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="data[0].cells">
                <tr *ngFor="let lines of data[0].cells">
                    <td *ngFor="let cell of lines">
                        {{ cell }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</app-viz-thumbnail-container>