<app-viz-container *ngIf='data && data[0] && dataType' [size]='size' [title]="title">
    <div [style.width.%]='100' [style.height.%]='100' class="">
        <div class="vizTableContainer">
            <div class='legend d-flex flex-wrap'>
                <div class="d-flex flex-nowrap font-weight-bold">Categories :</div>

                <div class='d-flex flex-nowrap' *ngFor='let el of colors'>
                    <span *ngIf="el.type !== 'Blank'">
                        <div [style.background-color]=" el.color" class='colorLegend align-self-center'>
                        </div>
                        {{el.type}}
                    </span>
                </div>
            </div>
            <div class="scroll-section">
                <table aria-describedby="alignment table" class="vizTable">
                    <tbody *ngIf="data[0].cells">
                        <tr *ngFor="let lines of data[0].cells">
                            <td *ngFor="let cell of lines; let isFirst = first" [class.letters]="!isFirst"
                                [class.dot]="cell === '.'" [class.first-column]="isFirst"
                                [style.background-color]="isFirst ? null : getColorFromLetter(cell)">
                                {{ isFirst ? cell + ' - ' : cell}}</td>
                        </tr>
                        <tr>
                            <td *ngFor='let cell of data[0].cells[0]; let i=index ; let isFirst = first' class="numbers"
                                [class.first-column]="isFirst">{{isFirst? '' : showNumber(i-1) ? i-1 : ''}}
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</app-viz-container>