import { Component } from '@angular/core';
import { JsonFormsAngularService, JsonFormsControl } from '@jsonforms/angular';
import { Options } from "@angular-slider/ngx-slider";
import { ControlProps } from '@jsonforms/core';
import _ from 'lodash';


@Component({
  selector: 'slider-control.component',
  styleUrls: ['./slider-control.component.scss'],
  templateUrl: './slider-control.component.html',
})

export class SliderComponent extends JsonFormsControl {
  value: number = 10;
  highValue: number = 90;
  options: Options = {
    floor: 0,
    ceil: 100,
    step: 1,
    showTicks: true,
    tickStep: 10
  };

  constructor(service: JsonFormsAngularService) {
    super(service);
  }

  public mapAdditionalProps(props: ControlProps) {
    this.options.floor = props.schema.minimum?  props.schema.minimum : 0;
    this.options.ceil = props.schema.maximum?  props.schema.maximum : 100;
    this.options.step = props.schema.multipleOf?  props.schema.multipleOf : 1;
    this.options.tickStep = Math.round((this.options.ceil-this.options.floor)/10);
    if (props.data && props.data[0]) {
      this.value = props.data[0]
    }else {
      this.value =  props.schema.default? props.schema.default[0] : this.options.floor;
    }

    if (props.data && props.data[1]) { 
      this.highValue = props.data[1]
    }else {
      this.highValue = props.schema.default? props.schema.default[1]: this.options.ceil;
    }
  }

  onChange() {
    let state = this.jsonFormsService.getState()
    let data =  state.jsonforms.core.data
    _.set(data, this.propsPath, [ this.value, this.highValue])
    this.jsonFormsService.setData(data)
  }

}
