import { Component, OnInit } from '@angular/core';
import { ProjectsService } from '../../api/projects.service';
import moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Files2Service } from 'src/app/api/files2.service';
import { ProjectManagerService } from 'src/app/api/project-manager.service';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { ModelsPostObject } from 'src/app/model/modelsPostObject';
import { DashboardService } from 'src/app/api/dashboard.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { TimeAgoPipe } from 'src/app/mims-ui-lib/core/pipes/time-ago.pipe';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Status } from 'src/app/shared/helpers/status';
import { PermissionsHttpService } from 'src/app/shared/permissions-http-service/permissions-http.service';
import { CreditAlertsService } from '../project-creation2/credit-alerts/credit-alerts.service';

enum Solution {
  Biomark = 1,
  Popseg,
  Indication_Prioritization,
  Genetic_Interaction_Discovery,
}

@Component({
  selector: 'project-overview',
  styleUrls: [ './project-overview.component.scss'],
  templateUrl: './project-overview.component.html',
})

export class ProjectOverviewComponent implements OnInit {
  Solution = Solution;
  TimeAgoPipe = TimeAgoPipe;
  PagesName = PagesName;
  tabIndex = 0;
  projectId = undefined;
  currentInstanceIndex = 0;
  showPopSegLegend = false;
  Status = Status;
  project = new BehaviorSubject<any>(undefined);
  _ = _;
  legendfile1 = new BehaviorSubject<any>(null);
  legendfile2 = new BehaviorSubject<any>(null);
  legendfile3 = new BehaviorSubject<any>(null);
  genes = [];
  isSavingFocusGeneList = false;
  accessGuidedUserFlow = false;
  country = undefined
  accessRerunProject = false;
  fileTitle = ""
  seedGene = ""
  instanceToSolution = {};
  isGiDiscovery = false
  moreGenes = false;
  hasMoreGenes = false;

  creditBudget = undefined;
  creditCost = undefined;
  workflowId = undefined;
  errorCredit = undefined;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private filesService: Files2Service,
    public projectManagerService: ProjectManagerService,
    public dashboardService: DashboardService,
    public layout: LayoutOrganizationService,
    private permissions: PermissionsHttpService,
    private creditAlertsService: CreditAlertsService
  ) {
    this.permissions
    .checkPermission({
      permissions: {
        accessGuidedUserFlow: {},
        accessRerunProject: {}
      },
    })
    .subscribe((res) => {
      this.accessGuidedUserFlow = res.accessGuidedUserFlow;
      this.accessRerunProject = res.accessRerunProject;
    });
    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.projectId = params['id'];
      }
      if (params['country']){
        this.country  = params['country']
      }
    });

    this.creditAlertsService.getBudget().then((budget) => {
      this.creditBudget = budget;
    });
    this.creditAlertsService.updateCost.subscribe((value) => {
      if (value !== null) {
        this.updateCreditCost(value);
      }
    });
  }

  updateCreditCost(value?) {
    this.creditCost = undefined;
    this.creditAlertsService.getCost(this.workflowId, value ? value : undefined).then((cost) => {
      this.creditCost = cost;
      this.creditCost = 0;
      this.errorCredit = undefined
    }, (error)=>{
      this.creditCost = undefined;
      this.errorCredit = error;
    });
  }


  handleTabSelection(project, event) {
    if (event === undefined) {
      return;
    }
    this.tabIndex = event;
  }
  handleInstanceSelection(project, event) {
    this.currentInstanceIndex = _.findIndex(this.getFormattedInstances(project), (o) => {
      this.workflowId = this.getWorkflowId(project, o.value)
      return o.value === event.value;

    });
    this.tabIndex = 0;
  }

  getTitle(project) {
    return _.get(project, 'value.title', '');
  }
  getDateOfCreation(project) {
    return moment.unix(_.get(project, 'value.date_of_creation', 0));
  }
  getCanExecute(project) {
    return _.get(project, 'value.capabilities.can_execute', false);
  }

  retrieveProject() {
    this.projectsService
      .projectGet(this.projectId, this.country)
      .pipe(
        map((res) => {
          let formattedInstances = this.formatInstances(
            _.get(res, 'instances').sort((a, b) => {
              return b.date_of_creation - a.date_of_creation;
            })
          );
          _.set(res, 'formattedInstances', formattedInstances);
          return res;
        })
      )
      .subscribe((res) => {
        this.project.next(res);
        this.checkForPopSegLegends(this.project, this.currentInstanceIndex);
        this.mapInstanceToSolution(this.project, this.currentInstanceIndex);
        
        this.tabIndex = 0;
      });
  }

  mapInstanceToSolution(project , currentInstanceIndex) {
    if (this.getWorkflowId(project, currentInstanceIndex))
        this.projectsService.getWorkflow(this.getWorkflowId(project, currentInstanceIndex),this.country).subscribe((res) => {
          const wfName = _.get(res, 'name').toLowerCase();
          if (_.includes(wfName, 'biomark')) {
            this.instanceToSolution[currentInstanceIndex] = Solution.Biomark
            this.showPopSegLegend = true;
            return
          }
          if (_.includes(wfName, 'popseg')) {
            this.instanceToSolution[currentInstanceIndex] = Solution.Popseg
            this.showPopSegLegend = true;
            return
          }
          if (_.includes(wfName, 'indication_prioritization')) {
            this.instanceToSolution[currentInstanceIndex] = Solution.Indication_Prioritization
            this.showPopSegLegend = true;
            return
          }
          if (_.includes(wfName, 'gi-discovery')) {
            this.instanceToSolution[currentInstanceIndex] = Solution.Genetic_Interaction_Discovery
            this.showPopSegLegend = true;
            return
          }        

        });
  }

  isSolution(currentInstanceIndex, solution:Solution): boolean {
    if (this.instanceToSolution[currentInstanceIndex] == solution) {
      return true;
    }
    return false
  }

  isBiomark(currentInstanceIndex):boolean {
    if (this.instanceToSolution[currentInstanceIndex] == Solution.Biomark) {
      return true;
    }
    return false
  }

  isIndicationPrioritization(currentInstanceIndex):boolean {
    if (this.instanceToSolution[currentInstanceIndex] == Solution.Indication_Prioritization) {
      return true;
    }
    return false
  }

  isOld(currentInstanceIndex):boolean {
    if (!this.isBiomark(currentInstanceIndex) &&
        !this.isIndicationPrioritization(currentInstanceIndex) ) {

      return true;
    }
    return false
  }

  checkForPopSegLegends(project, currentInstanceIndex) {
    if (this.getWorkflowId(project, currentInstanceIndex))
      this.projectsService.getWorkflow(this.getWorkflowId(project, currentInstanceIndex),this.country).subscribe((res) => {
        const wfName = _.get(res, 'name').toLowerCase();
     
        const isPopseg = _.includes(wfName, 'popseg');
        const isBiomark = _.includes(wfName, 'biomark');
        const isOnco = _.includes(wfName, 'onco');
        this.isGiDiscovery = wfName == "gi-discovery";
        if (isPopseg || isBiomark) {
          this.showPopSegLegend = true;

          let folder = "PopSeg_Solution_21122021"
          if (isBiomark) {
            folder = "Biomark_Solution"
          } else  if (isOnco) {
            folder = "PopSeg_Onco_Solution"
          }

          let fileURL1 = '../../../assets/docs/'+folder+'/dashboard_1.md';
          let fileURL2 = '../../../assets/docs/'+folder+'/dashboard_2.md';
          let fileURL3 = '../../../assets/docs/'+folder+'/dashboard_3.md';

          fetch(fileURL1)
            .then((response) => {
              return response.text();
            })
            .then((data) => {
              this.legendfile1.next(data);
            });
          fetch(fileURL2)
            .then((response) => {
              return response.text();
            })
            .then((data) => {
              this.legendfile2.next(data);
            });

          fetch(fileURL3)
            .then((res) => res.text())
            .then((data) => {
              this.legendfile3.next(data);
            });
        }
      });
  }
  ngOnInit() {
    this.retrieveProject();
  }

  formatInstances = (instances) => {
    let result = [];
    instances.forEach((el) => {
      let array = [];
      if (_.get(el, 'dashboards_shorts')) {
        _.get(el, 'dashboards_shorts').forEach((el) => {
          array.push({ tabName: _.get(el, 'name'), dashboardId: _.get(el, 'id') });
        });
      } else if (_.get(el, 'dashboard_files')) {
        for (const [key, value] of Object.entries(_.get(el, 'dashboard_files'))) {
          array.push({ tabName: key, dashboardId: value });
        }
      }

      result.push({
        name: el.name,
        id: el.id,
        label: el.name == "" ? 
            moment.unix(_.get(el, 'date_of_creation', 0)).format('YYYY/MM/DD - HH:mm') : 
            el.name,
        value: array,
        forms: el.forms,
        extra: { workflowId: _.get(el, 'workflow_id'), endTime: _.get(el, 'end_time', 0) * 1000,
        error: _.get(el, 'error'), status: _.get(el, 'status') },
      });
    });
    return result;
  };


  getTabNames(project, currentInstanceIndex) {
    return _.get(this.getInstance(project, currentInstanceIndex), 'value').map((tab) => tab.tabName);
  }
  getTabs(project, currentInstanceIndex) {
    return _.get(this.getInstance(project, currentInstanceIndex), 'value')
  }
  getLinks(project, currentInstanceIndex) {
    return _.get(this.getInstance(project, currentInstanceIndex), 'value').map((tab) => tab.dashboardId);
  }
  getWorkflowId(project, currentInstanceIndex) {
    return _.get(this.getInstance(project, currentInstanceIndex), 'extra.workflowId');
  }
  getInstance(project, currentInstanceIndex) {
    return  _.nth(this.getFormattedInstances(project), currentInstanceIndex);
  }
  getFormattedInstances(project) {
    return _.get(project, 'value.formattedInstances');
  }
  getInstanceEndTime(project, currentInstanceIndex) {
    return _.get(this.getInstance(project, currentInstanceIndex), 'extra.endTime');
  }
  getInstanceStatus(project, currentInstanceIndex) {
    return _.get(this.getInstance(project, currentInstanceIndex), 'extra.status');
  }
  getError(project,currentInstanceIndex) {
    return _.get(this.getInstance(project, currentInstanceIndex), 'extra.error');
  }
 


  // Forms
  hasInstanceForm(project,currentInstanceIndex) {
    let inst = this.getInstance(project, currentInstanceIndex);
    if (inst == undefined) {
      return false;
    }
    if (inst.forms  == undefined) {
      return false;
    }
    if (inst.forms  == null) {
      return false;
    }
    return Object.keys(inst.forms).length > 0
  }
  getProjectID(project) {
    return project.value.id;
  }
  getInstanceID(project, currentInstanceIndex) {
    return this.getInstance(project, currentInstanceIndex).id;
  }
  getForm(project, currentInstanceIndex) {
    let inst = this.getInstance(project, currentInstanceIndex);
    if (inst == undefined) {
      return undefined;
    }
    if (inst.forms  == undefined) {
      return undefined;
    }
    if (inst.forms  == null) {
      return undefined;
    }
    let keys = Object.keys(inst.forms);
    if (keys.length == 0) {
      return undefined
    }
    return inst.forms
  }

  getFormKey(project, currentInstanceIndex) {
    let forms = this.getForm(project, currentInstanceIndex);
    return Object.keys(forms)[0];
  }
  getDataID(project, currentInstanceIndex) {
    let forms = this.getForm(project, currentInstanceIndex);
    let firstKey = Object.keys(forms)[0];
    return forms[firstKey].data;
  }
  getSchemaID(project, currentInstanceIndex) {
    let forms = this.getForm(project, currentInstanceIndex);
    let firstKey = Object.keys(forms)[0];
    return forms[firstKey].schema;
  }
  getUISchemaID(project, currentInstanceIndex) {
    let forms = this.getForm(project, currentInstanceIndex);
    let firstKey = Object.keys(forms)[0];
    return forms[firstKey].uischema;
  }
  getFormDescription(project, currentInstanceIndex) {
    let forms = this.getForm(project, currentInstanceIndex);
    let firstKey = Object.keys(forms)[0];
    return forms[firstKey].description;
  }
  getFormTitle(project, currentInstanceIndex) {
    let forms = this.getForm(project, currentInstanceIndex);
    let firstKey = Object.keys(forms)[0];
    return forms[firstKey].title;
  }


  handleReRun(project) {
    this.workflowId = this.getWorkflowId(project, this.getInstanceID(project, this.currentInstanceIndex) )
    this.projectsService.projectGet(this.projectId).subscribe((res) => {
      let r = res["instances"].find(element => { return element.id == this.getInstanceID(project, this.currentInstanceIndex)  } )
      for (var i = 0; i < r["parameters"].length; i++) { 
        if (r["parameters"][i].input_kind == "filegroups") {   
          this.creditAlertsService.updateCost.next(r["parameters"][i].input_value);
          this.layout.open('modalRerun'); 
          return
        }
      }

      this.creditAlertsService.updateCost.next(undefined);
      this.layout.open('modalRerun'); 
    })
  }

  handleReRunInstance(project, currentInstanceIndex) {
    let inst = this.getInstance(project, currentInstanceIndex);
    this.projectsService.triggerRerun(project.value.id, inst.id, this.name).subscribe(
      (res) => { 
        alert('Operation succeeded');
      },
      (error) => {
        alert('Code : ' + error.error.code + '. Message : ' + _.get(error, 'error.message')  + '. Reason  : ' + error.error.reason + '.');
      }
    );
  }

  handleLegend() {
    this.layout.open('modalLegend');
  }


  name = "";
  onVersionNameChange(event: any) { // without type info
      this.name = event.target.value;
  }
  
  onSave() {
    // Create focus gene file
    this.genes = [];
    let rows = document.getElementsByTagName('table')[0].children[1].children;
    let seedGeneCol = 0;
    let geneCol = 1;
    
    // depending on the version, seed gene column is not at the same index
    if (rows[0].children[geneCol].innerHTML == rows[1].children[geneCol].innerHTML) {
      seedGeneCol = 1;
      geneCol = 0;
    }
    for(let x = 0; x < rows.length; x++) {
      if (rows[x].children[rows[x].children.length-1].innerHTML) { // selected row
          this.seedGene = rows[x].children[seedGeneCol].innerHTML; 
          this.genes.push(rows[x].children[geneCol].innerHTML);
      }
    }

    this.isSavingFocusGeneList = true;
  }

  onGeneFileTitleChange(event) {
    this.fileTitle = event.target.value;
  }
  saveFocusGeneList() {
    let file = new File([JSON.stringify(this.genes)], this.fileTitle, {
      type: "text/plain",
    });
    this.uploadFile(file)
  }
  uploadFile(file) {
    file.metadata = { "hidden": "true", "data_type": "focus_gene_list", "seed_gene": this.seedGene};
    let self = this;
    let upload = function () {
      self.projectsService.uploadSmallFile(file, "").subscribe(
        (res) => {
          self.isSavingFocusGeneList = false;
          self.layout.toast("Focus Gene List successfully saved as " + self.fileTitle, null, 5000, '', 'success');
  
        },
        (err) => {
          console.log(err);
        }
      )
    }

    this.filesService.getFileFromPath('/Focus Gene Lists', this.country).subscribe(res => {
      file.parentId = res.id;
      upload();
    },
    err => {
      if (err.status == 404) {
        const newFolder: ModelsPostObject = {
          description: '',
          is_folder: true,
          metadata: {},
          size: 0,
          title: "Focus Gene Lists",
        };

        this.filesService.postFile('root', newFolder, 'response', this.country).subscribe(res => {
          file.parentId = res.body.id;
          upload();
        },
        err => {
          console.error(err);
       });
      }
    });
  }
  getGenesSummary() {
    return this.genes.reduce((prev,current) => {
      prev +=  ", " + current
      return prev;
    })
  }
  showSaveFocusGeneList() {
    return this.isGiDiscovery;
  }
  closeFocusGeneListForm() {
    this.isSavingFocusGeneList = false;
  }
}
