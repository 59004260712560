<app-header2 [title]="PagesPublicName.LicenseManager" [titleRedirection]="[PagesName.LicenseManager]" iconClass='fa-solid fa-flip-horizontal fa-key-skeleton ' iconContainerStyle="display: inline-block; --fa-rotate-angle: -45deg;">
</app-header2>
<app-horizontal-nav-tab-2 [fullWidth]="true" [selectedTab]='activeTab' (valueChanged)="handleTabChange($event)"
  [dataSource]="tabsDataSource">
</app-horizontal-nav-tab-2>
<app-content *ngIf="activeTab.value === TabsName.LicenseInfo" [isTab]="true" [isHeader]="true" class="bg-ivory px-5">
  <div class='w-100  d-flex flex-column p-5 '>
    <div class="w-50 mx-auto ">
      <div class="font-weight-bold title text-dark">
        <span class=" text-uppercase">{{isEditionMode()? 'Edit license' : 'Create a new license'}}</span>
      </div>
      <form [formGroup]="form">
        <formly-form [form]="form" [fields]="fields" [options]="options" [model]="model"></formly-form>
      </form>
      <div class="d-flex justify-content-end">
        <div placement="bottom" ngbTooltip="{{ _.get(this.form, 'valid') ? '' : 'Please verify your form'}}"
          class='btn  text-light btn-primary rounded' [class.disabled]="!_.get(form, 'valid')"
          (click)="_.get(form, 'valid')? handleSubmit(model) : ''">
          {{isEditionMode()? 'Save' :  'Create'}}
        </div>
      </div>
    </div>
  </div>
</app-content>
<app-content *ngIf="activeTab.value === TabsName.ManagerAndTeam" [isTab]="true" [isHeader]="true"
  class='bg-ivory scrollable px-5'>
  <div class='w-100  d-flex flex-column p-5 '>
    <button (click)="inviteUser()"
      class="smooth-border button mb-5 align-self-end btn text-center btn-primary text-light">
      <span class="cursor-info">Invite
      </span>
    </button>
    <div class="mb-3  d-flex justify-content-between align-items-end">
      <div class="font-weight-bold title text-dark">
        <span class=" text-uppercase">License members</span>
        <span class="badge ml-2 text-light badge-pill    badge-primary">{{_.get(users, 'length')}}</span>
      </div>
      <div class='d-flex flex-nowrap'>
      </div>

    </div>
    <table *ngIf="users && users.length > 0" aria-describedby="table"
      class="table table-design-rounded  w-100 mx-auto my-4 ">
      <thead class="table-header">
        <tr>
          <th *ngFor="let header of headers">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let el of users | slice: (page - 1) * pageSize:page * pageSize;">
          <td class="cursor-pointer" (click)="handleGetInfo(el.id)">
            <div class="d-flex justify-content-end w-100">
              <mims-initials-bubble ngbTooltip="{{el.name}}" theme='dark' tooltipClass="custom-tooltip" placement="top"
                [fullName]='_.first(_.split(el.email,"@"))'></mims-initials-bubble>
            </div>
          </td>
          <td class="cursor-pointer" (click)="handleGetInfo(el.id)">
            {{el.email}}
          </td>
          <td class="cursor-pointer" (click)="handleGetInfo(el.id)">
            {{el.role}}
          </td>
          <td class="d-flex justify-content-start">
            <div class="item-icons col-md-auto mx-0 px-0">
              <div class="dropdown pr-2">
                <button class="smooth-border btn btn-link border no-focus dropdown" type="button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  <i class=" far fa-ellipsis" aria-hidden="true"></i>
                </button>
                <div class="dropdown-menu  smooth-border dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                  <div>
                    <a (click)='handleGetInfo(el.id)' class="dropdown-item noselect">Get info</a>
                    <a *ngIf="el.role === 'Member'" (click)='handleGiveAdminRight(el)' class="dropdown-item noselect">Give Admin rights</a>
                    <a *ngIf="el.role !== 'Member'" (click)='handleRemoveAdminRight(el)' class="dropdown-item noselect">Remove Admin rights</a>

                    <a (click)='handleRemoveUser(el)' class="dropdown-item noselect">Remove</a>
                    <a class="disabled dropdown-item noselect">Deactivate</a>


                  </div>
                </div>
              </div>
            </div>
          </td>

        </tr>
      </tbody>
    </table>
    <div *ngIf="!users.length" [style.height.px]="127"
      class='empty-display w-100 justify-content-center align-items-center d-flex font-weight-bold'>
      <p>
        All the members of the license will display here.</p>
    </div>
    <ngb-pagination *ngIf="_.get(users, 'length',0) > pageSize" class="my-3 d-flex justify-content-center"
      [(page)]="page" [pageSize]="pageSize" [collectionSize]="users.length">
      <ng-template ngbPaginationPrevious><i class="far fa-chevron-left" aria-hidden="true"></i></ng-template>
      <ng-template ngbPaginationNext><i class="far fa-chevron-right" aria-hidden="true"></i> </ng-template>
    </ngb-pagination>
  </div>
</app-content>


<div id="info-content" [class.active]="l.isUserCardOpen">
  <div id="modalUserCard" class='m-3'>
    <div class="d-flex justify-content-between my-4">
      <div class="d-flex align-items-center">
        <div class="icon-info-bg d-flex align-items-center justify-content-center   mr-3 smooth-border">
          <i class="fa-solid  fa-grid-2 " aria-hidden="true"></i>
        </div>
        <div class='font-weight-bold'>Info</div>
      </div>
      <button type="button" class="close" aria-label="Close" (click)="l.close('modalUserCard')">
        <span><i class="fa-regular fa-1x  fa-circle-xmark text-primary" aria-hidden="true"></i></span>
      </button>
    </div>
    <app-user-card2 [version]='2' *ngIf='teamService.selectedUser' [hidden]='!l.isUserCardOpen' (userEvent)='teamService.handleUserEvent($event)'
      [selectedTeam]='teamService.selectedTeam' [selectedUser]='teamService.selectedUser' [formattedSelectedUser]='teamService.formattedSelectedUser.value'>
    </app-user-card2>
  </div>
</div>
<app-custom-modal modalTitle="Invite a new user" idName="modalNewMemberAddition"
  [show]="l.isNewMemberAdditionModalOpen" confirmTitle="Save" [confirmActions]="[handleInvitation]"
  confirmTooltip='Please verify your form.' [form]="newMemberAdditionForm" [cancelActions]="[]">
  <div class="custom-modal-content">
    <form [formGroup]="newMemberAdditionForm">
      <formly-form [form]="newMemberAdditionForm" [fields]="fieldsNewMember" [options]="optionsNewMember" [model]="modelNewMember"></formly-form>
    </form>
    <!-- <app-new-member-addition-form [(form)]='newMemberAdditionForm' *ngIf='l.isNewMemberAdditionModalOpen'></app-new-member-addition-form> -->
  </div>

</app-custom-modal>