import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TabsName } from 'src/app/shared/helpers/tabsName';

@Component({
  selector: 'app-metadata-upload-form',
  templateUrl: './metadata-upload-form.component.html',
  styleUrls: ['../../../shared/styles/forms.component.scss'],
})
export class MetadataUploadFormComponent  {
  manager;
  @Input() form
  @Output() formChanged = new EventEmitter();
  @Output() files = new EventEmitter();
  TabsName = TabsName
  getMetadata(event) {
    this.files.next(event.target.files[0]);
  }
  get metadataInput() {
    return this.form.get('metadataInput');
  }
}
