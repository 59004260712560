<div  class="container"  *ngIf="theme === 'light' && !error || error === 'errorWithSearch'">
  <div *ngIf="!error">
    <div *ngIf="cost === undefined || budget === undefined" class="border smooth-border bg-white p-4 text-center w-100">
      <mims-loader class="w-100 pos-relative" [enableBackDrop]="false" [isLoading]="true"> </mims-loader>
    </div>
    <div *ngIf="cost !== undefined && budget !== undefined && cost !== null" [style.minHeight.rem]="9">
      <div class="border smooth-border bg-ultra-light-primary p-4 text-center w-100">
        <div class="text-uppercase small font-weight-bold">
          This project will cost
          <span [style.fontSize.%]="100" class="mx-2 p-2 badge badge-primary text-light font-weight-bold"
            >{{ cost }} credit{{ cost > 1 ? 's' : '' }}
          </span>
        </div>
        <div class="text-uppercase small">
          Number of credits after execution
          <span [style.fontSize.%]="100" class="ml-2 mt-3 p-2 badge badge-light border font-weight-bold"
            >{{ getCreditsAfterExecution() }} credit{{ getCreditsAfterExecution() == 0 ? '' : 's' }}</span
          >
        </div>
      </div>
      <div *ngIf="cost > budget" class="border smooth-border bg-ultra-light-danger p-4 mt-3 text-center w-100">
        <div class="text-uppercase small font-weight-bold text-danger">Unfortunately, not enough credits to run this project</div>
      </div>
    </div>
  </div>
  <div *ngIf="error === 'errorWithSearch' " class="border smooth-border bg-ultra-light-danger p-4 mt-3 text-center w-100">
    <div class="text-uppercase small font-weight-bold text-danger">Error with credit cost calculation, impossible to launch project</div>
  </div>
</div>

<div *ngIf="theme !== 'light'">
  <div *ngIf="cost !== 0 && budget !== -1" class="alert alert-primary">
    <br />
    <p>
      This project will cost <strong>{{ cost }} credit(s)</strong>
    </p>
    <p>
      Number of credits after execution <strong>{{ getCreditsAfterExecution() }} credit(s)</strong>
    </p>
  </div>
  <div *ngIf="cost > budget && budget != -1" class="alert alert-danger">
    <br />
    <p>Unfortunately, not enough credits to run this project</p>
  </div>
</div>
