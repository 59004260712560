import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { ProjectsService } from 'src/app/api/projects.service';
import { BehaviorSubject } from 'rxjs';
import { LocalService } from 'src/app/api/local-storage.service';

@Component({
  selector: 'app-available-workflows',
  templateUrl: './available-workflows.component.html',
  styleUrls: ['./available-workflows.component.scss'],
})
export class AvailableWorkflowsComponent implements OnInit {
  projectId = null;
  workflows = new BehaviorSubject<any>(null);
  submitted = false
  filegroupId
  _=_
  teamId
  selectedWorkflowId;
  subjects = new BehaviorSubject<Number>(0)
  dataTypes = {
    transcriptome: { description: 'Transcriptome', count: new BehaviorSubject<Number>(0) },
    genome: { description: 'Genome', count: new BehaviorSubject<Number>(0) },
    epigenome: { description: 'Epigenome', count: new BehaviorSubject<Number>(0) }
  }
  isMimsUser
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectsService: ProjectsService,
    private localService: LocalService
  ) {
    _.set(this, 'projectId', this.route.snapshot.paramMap.get('projectId'));

    if (this.route.snapshot.paramMap.get('country')) {
      _.set(this.projectsService, 'projectStorageCountry', this.route.snapshot.paramMap.get('country'))
    }
    this.route.queryParams.subscribe((params) => {
      if (params['fromDebugger'] === 'false' || !_.get(params, 'fromDebugger')) {
        projectsService.setFromDebugger(false)
      } else if (params['fromDebugger'] === 'true') {
        projectsService.setFromDebugger(true)
      }
      _.set(this, 'teamId', params['teamId']);

      _.set(this, 'filegroupId', params['filegroupId']);
    })
    this.isMimsUser = _.endsWith(this.localService.getFromLocalStorage('user', 'email'), '@mims.ai')
  }
  ngOnInit() {

    if (this.filegroupId) {
      let body = {
        filegroup_id: this.filegroupId,
      }
      this.projectsService.searchFiles(body).subscribe(res => {
        this.dataTypes['transcriptome'].count.next(_.get(res, 'counts.transcriptome'))
        this.dataTypes['genome'].count.next(_.get(res, 'counts.genome'))
        this.dataTypes['epigenome'].count.next(_.get(res, 'counts.epigenome'))
        this.subjects.next(_.get(res, 'counts.patients'))
      })
    }


    this.projectsService.getWorkflows(this.projectId).subscribe((res) => {
      let availableWorkflows = res.filter((workflow) => {
        return workflow.is_a_match;
      });
      availableWorkflows = _.sortBy(availableWorkflows, ['name', 'version']);
      this.workflows.next(availableWorkflows || []);
    });
    this.projectsService.projectGet(this.projectId).subscribe((res) => {
      this.projectsService.setProjectName(res.title)
      let instances = res.instances
      instances = _.sortBy(instances, ['date_of_creation']);
      _.set(this.selectedWorkflowId, _.get(_.last(instances), 'workflow_id'))
    })
  }

  next(workflowId) {
    if (workflowId) {
      this.submitted = true;
      this.router.navigate([PagesName.ProjectCreationFromWF, PagesName.ChooseWorkflow, this.projectId, workflowId, this.projectsService.getProjectStorageCountry()], { queryParams: { fromDebugger: this.projectsService.getFromDebugger(), filegroupId: this.filegroupId,  teamId: this.teamId 
    } })
    }
  }
  previous() {
    this.submitted = true;
    this.router.navigate([PagesName.ProjectCreationFromWF, PagesName.DefineProject, this.projectId, this.projectsService.getProjectStorageCountry()], { queryParams: { fromDebugger: this.projectsService.getFromDebugger() } })
  }
  getWorkflows() {
    return _.get(this, 'workflows.value')
  }
  getSelectedWorkflowId() {
    return _.get(this, 'selectedWorkflowId', '')
  }
}
