<div *ngIf='viz' [attr.isSeen]='false' [class.vizPie]="vizService.getSubType(viz) === VizSubType.Pie"
  [class.vizScatter]="vizService.getSubType(viz) === VizSubType.Scatter"
  [class.vizHeatmap]="vizService.getSubType(viz) === VizSubType.Heatmap"
  [class.vizTreemap]="vizService.getType(viz) === VizType.Treemap"
  [class.vizTreemap]="vizService.getSubType(viz) === VizSubType.Treemap"
  [class.vizContent]="vizService.getType(viz) === VizType.Content"
  [class.vizBar]="vizService.getSubType(viz) === VizSubType.Bar"
  [class.vizSelect]="vizService.getType(viz) === VizType.Select" class='viz'
  [class.vizPlotly]='vizService.isPlotly(viz)'
  [class.fullScreen]='vizMode === VizMode.FullScreen || vizMode === VizMode.GeneratedForThumbnail'>
  <div class='h-100 w-100 d-flex flex-column align-items-center'
    *ngIf="vizService.getType(viz) === VizType.Plot || vizService.getType(viz) === VizType.Treemap">
    <h3 *ngIf='vizMode === VizMode.Regular' [innerHTML]=' vizService.getTitle(viz)'></h3>

    <div class='flex-grow-1 w-100'>
      <div class='w-100 h-100' id={{vizService.getId(viz)}} #vizRef></div>
    </div>
    <app-viz-select-box *ngIf='vizMode === VizMode.Regular && vizService.getSubType(viz) === VizSubType.Scatter'
      [viz]='viz'></app-viz-select-box>
  </div>
  <div *ngIf="vizService.getType(viz) === VizType.Image" class='d-flex flex-column align-items-center'>
    <h3 *ngIf='vizMode === VizMode.Regular' [innerHTML]=' vizService.getTitle(viz)'></h3>
    <div>
      <a *ngIf='vizService.getValues(viz)' [href]="sanitizedUrl(vizService.getValues(viz))"
        [download]="vizService.getId(viz)">
        <img alt='Image' class="mw-100 mh-100" [src]="sanitizedUrl(vizService.getValues(viz))" />
      </a>
    </div>
    <app-viz-select-box *ngIf='vizMode === VizMode.Regular' [viz]='viz'></app-viz-select-box>
  </div>

  <div *ngIf="vizService.getType(viz) === VizType.ColorscaledImage"
    class='h-100 w-100 d-flex flex-column align-items-center'>
    <h3 *ngIf='vizMode === VizMode.Regular' [innerHTML]=' vizService.getTitle(viz)'></h3>

    <div class='d-flex flex-grow-1 h-100 w-100 flex-nowrap justify-content-center align-items-center'>
      <div class='h-100 w-100 d-flex flex-nowrap justify-content-center '>
        <a class='colorscaleLink ' [href]="sanitizedUrl(vizService.getValues(viz))" [download]="vizService.getId(viz)">
          <img alt='Image' class="mw-100 h-100" [src]="sanitizedUrl(vizService.getValues(viz))" />
        </a>
        <div class=" vizColorscale" #vizRef>
        </div>
      </div>
    </div>
    <app-viz-select-box *ngIf='vizMode === VizMode.Regular' [viz]='viz'></app-viz-select-box>
  </div>

  <div *ngIf="vizService.getType(viz) === VizType.GenomicSequence">
    <div class="vizTextZone">
      <h3 *ngIf='vizMode === VizMode.Regular' [innerHTML]=' vizService.getTitle(viz)'></h3>
      <div class="vizTextZoneContent">
        <div *ngFor="let textArray of vizService.getValues(viz)">
          <div *ngFor="let text of textArray" class='genomic' [class.emphasize]="text === textArray[0]"
            [innerHTML]="setTextLayoutAutoBr(text)"></div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="vizService.getType(viz) === VizType.Select && vizMode === VizMode.Regular">
    <h3  [innerHTML]=' vizService.getTitle(viz)'></h3>

    <app-viz-select-box [viz]='viz'></app-viz-select-box>
  </div>
  <div *ngIf="vizService.getType(viz) === VizType.Table" class='mh-100 w-100 d-flex flex-column align-items-center'>
    <h3 *ngIf='vizMode === VizMode.Regular' [innerHTML]=' vizService.getTitle(viz)'></h3>
    <a *ngIf='vizMode === VizMode.Regular || vizMode === VizMode.FullScreen' (click)="generateHref(viz)"
      [download]="vizService.getTitle(viz) + '.csv'" class="btn btn-light my-3"
      [href]="_DomSanitizer.bypassSecurityTrustUrl(octet_stream)" [id]="vizService.getId(viz) + '_to_csv'">Download
      CSV</a>
    <div class="table-responsive flex-grow-1 vizTableContent">
      <table aria-describedby="table" class="vizTable table-hover table-striped">
        <thead>
          <tr>
            <th [scope]='header' *ngFor="let header of vizService.getTableHeaders(viz)" [innerHTML]='header'>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let column of vizService.getTableCells(viz)">
            <td *ngFor="let cell of column" [innerHTML]='cell'>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="vizService.getType(viz) === VizType.Content">
    <h3 *ngIf='vizMode === VizMode.Regular' [innerHTML]=' vizService.getTitle(viz)'></h3>
    <div class="table-responsive ">
      <table role='presentation' aria-describedby="table" class="vizTable vizTableInfo table-hover">
        <tbody>
          <tr *ngFor="let column of vizService.getTableContentValues(viz)">
            <td *ngFor="let cell of column" [innerHTML]='cell'>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>