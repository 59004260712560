import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import moment from 'moment';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { LocalService } from 'src/app/api/local-storage.service';
import { ProjectsService, RawInputTypes } from 'src/app/api/projects.service';
import { Formulation } from '../formulation';
import { KnowledgeSearchService } from '../knowledge-search.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-knowledge-search-results-action-toolbar',
  templateUrl: './knowledge-search-results-action-toolbar.component.html',
  styleUrls: ['./knowledge-search-results-action-toolbar.component.scss'],
})
export class KnowledgeSearchResultsActionToolbarComponent implements OnInit {
  projectTitle = '';
  saveMode: 'new' | 'edit' = 'new'

  constructor(
    private projectService: ProjectsService,
    private knowledgeSearchService: KnowledgeSearchService,
    private layout: LayoutOrganizationService,
    private localService: LocalService
  ) {}

  ngOnInit(): void {
    this.saveMode = this.hasCurrentProject() ? 'edit' : 'new';

    const queryParams = this.knowledgeSearchService.getQueryParams();
    const projectId = this.getProjectId(queryParams);
    if (projectId && !this.hasCurrentProject()) {
      this.projectService.projectGet(projectId, this.getProjectCountry(queryParams)).subscribe(
        (res) => {
          this.setCurrentProject(res);
          this.projectTitle = this.knowledgeSearchService.currentProject?.title;
        }
      );
    } else {
      this.projectTitle = this.knowledgeSearchService.currentProject?.title || this.getProjectTitle();
    }
  }

  hasCurrentProject() {
    return !!this.knowledgeSearchService.currentProject;
  }

  toggleSaveMode() {
    if (this.saveMode === 'new') {
      this.saveMode = 'edit';
    } else {
      this.saveMode = 'new';
    }
  }

  getProjectTitle = () => {
    const titlePrefix =
      'Search | ' + this.knowledgeSearchService.getFormulationFullName(this.knowledgeSearchService.selectedFormulation) + ' - ';
    const queryParams = this.knowledgeSearchService.getQueryParams();

    switch (this.knowledgeSearchService.selectedFormulation) {
      case Formulation.Keywords:
      case Formulation.Article:
        return `${titlePrefix} ${this.knowledgeSearchService.getQueryParamAsString(queryParams)}`;
      case Formulation.Therapy:
        return `${titlePrefix} In [${queryParams['k0']}], what is the effet of [${queryParams['k1']}] on [${queryParams['k2']}] compared with [${queryParams['k3']}] ?`;
      case Formulation.Prevention:
        return `${titlePrefix} For [${queryParams['k0']}], does the use of [${queryParams['k1']}] reduce the future risk of [${queryParams['k2']}] compared with [${queryParams['k3']}] ?`;
      case Formulation.Diagnosis:
        return `${titlePrefix} Is [${queryParams['k0']}] more accurate in diagnosing [${queryParams['k1']}] compared with [${queryParams['k2']}] ?`;
      case Formulation.Prognosis:
        return `${titlePrefix} Does [${queryParams['k0']}] influence [${queryParams['k1']}] in patients who have [${queryParams['k2']}] ?`;
      case Formulation.Etiology:
        return `${titlePrefix} Are [${queryParams['k0']}] who have [${queryParams['k1']}] at [${queryParams['k2']}] risk for/of [${queryParams['k3']}] compared with [${queryParams['k4']}] with/without [${queryParams['k5']}] ?`;
      default:
        return titlePrefix;
    }
  };

  save(popover: NgbPopover) {
    if (this.saveMode === 'edit' && this.getProjectId(this.knowledgeSearchService.getQueryParams())) {
      let country = this.getProjectCountry(this.knowledgeSearchService.getQueryParams());
      this.updateQuery(this.getProjectId(this.knowledgeSearchService.getQueryParams()), country, popover);
    } else {
      let country = this.localService.getFromLocalStorage('user', 'account_country');
      this.projectService
        .createProject(
          {
            title: this.projectTitle.trim() || this.getProjectTitle(),
            description: 'COVsight query',
            kind: 'research',
            metadata: { filters: JSON.stringify(this.knowledgeSearchService.getActiveFilters()) },
          },
          country
        )
        .subscribe(
          (res) => {
            this.setProjectId(res.id);
            this.setProjectCountry(country);
            this.setCurrentProject(res);
            this.addnewInput(res.id, [], country).subscribe(
              () => {
                this.projectService.projectGet(res.id, country).subscribe(
                  (res) => {
                    this.setCurrentProject(res);
                    this.layout.toast('New project created', null, 8000, '', 'success');
                    this.success(popover);
                    if (!this.knowledgeSearchService.editMode) {
                      this.knowledgeSearchService.editMode = true
                      this.knowledgeSearchService.navigateToResults(this.knowledgeSearchService.selectedFormulation);
                    }
                  }
                );
              },
              () => {
                this.failure();
              }
            );
          },
          () => this.failure()
        );
    }
  }

  updateQuery(projectId, country, popover:NgbPopover) {
    this.retrieveInput(projectId, country).subscribe(
      (res) => {
        let formerInputId = res?.id;
        this.projectService.removeInput(projectId, formerInputId, country).subscribe(
          () => {
            this.addnewInput(projectId, res?.tags?.savedQueries || [], country).subscribe(
              () => {
                this.success(popover);
                this.projectService
                  .projectPatch(projectId, this.projectTitle.trim() || this.getProjectTitle(), 'title', country)
                  .subscribe();
                this.projectService
                  .projectPatch(projectId, { filters: JSON.stringify(this.knowledgeSearchService.getActiveFilters()) }, 'metadata', country)
                  .subscribe();
              },
              () => {
                this.failure();
              }
            );
          },
          () => {
            this.failure();
          }
        );
      },
      () => {
        this.failure();
      }
    );
  }

  addnewInput(projectId, savedQueries, country) {
    return new Observable<any>((observer) => {
      let newSavedQueries = savedQueries;
      let query = _.omit(this.knowledgeSearchService.getQueryParams(), 'id');
      query = _.omit(query, 'country');
      newSavedQueries.push({
        date: moment().unix(),
        formulation: this.knowledgeSearchService.selectedFormulation,
        query: query,
      });
      let tags = {
        savedQueries: newSavedQueries,
      };
      this.projectService.appendInput(projectId, RawInputTypes.String, 'query', undefined, tags, country).subscribe(
        (res) => {
          observer.next(res.id);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  retrieveInput(projectId, country?) {
    return new Observable<any>((observer) => {
      this.projectService.projectGet(projectId, country ? country : undefined).subscribe(
        (res) => {
          observer.next(
            _.find(res.input_list, (o) => {
              return o.value === 'query';
            })
          );
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  success(popover: NgbPopover) {
    this.layout.toast('Query successfully saved', null, 8000, '', 'success');
    popover.close();
  }

  failure() {
    this.layout.toast('Impossible to save the query', null, 8000, '', 'warning');
  }

  getProjectId(queryParams) {
    return queryParams?.id;
  }

  setProjectId(id) {
    let newObject = _.set(_.clone(this.knowledgeSearchService.getQueryParams()), 'id', id);
    this.knowledgeSearchService.setQueryParams(newObject);
  }

  getProjectCountry(queryParams) {
    return queryParams?.country || this.localService.getFromLocalStorage('user', 'account_country');
  }

  setProjectCountry(country) {
    let newObject = _.set(_.clone(this.knowledgeSearchService.getQueryParams()), 'country', country);
    this.knowledgeSearchService.setQueryParams(newObject);
  }

  setCurrentProject(project) {
    this.saveMode = 'edit';
    this.knowledgeSearchService.setCurrentProject(project);
  }
}
