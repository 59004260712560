<div class="explorer-container flex-column" [hidden]="!l.explorerIsOpen" [class.expanded-full]="
    l.explorerIsExpanded && !l.isTopBarOpen && !l.isTopNavOpen
  " [class.expanded-with-top-bar]="
    l.explorerIsExpanded && l.isTopBarOpen && !l.isTopNavOpen
  " [class.expanded-with-top-bar-and-nav-bar]="
    l.explorerIsExpanded && l.isTopBarOpen && l.isTopNavOpen
  ">
  <app-explorer-icon [class.opened]="l.explorerIsOpen" (click)="l.switchExplorerOpenStatus()"></app-explorer-icon>
  <div class="bar-container d-flex align-items-center justify-content-end">
    <button class="btn btn-link bar-buttons" *ngIf="l.explorerIsOpen" (click)="l.switchExplorerOpenStatus()">
      <i class="fas fa-window-minimize text-white" aria-hidden="true"></i>
    </button>
    <button class="btn btn-link bar-buttons" *ngIf="l.explorerIsExpanded" (click)="l.switchExplorerExpansion()">
      <i class="fad fa-2x fa-external-link-square rotate expand" aria-hidden="true"></i>
    </button>
    <button class="btn btn-link bar-buttons" *ngIf="!l.explorerIsExpanded" (click)="l.switchExplorerExpansion()">
      <i class="fad fa-2x fa-external-link-square expand" aria-hidden="true"></i>
    </button>
  </div>

  <div class="answer-container" [style.height.%]="e.chatView !== e.ChatView.NoQuestion ? 92 : 73">
    <app-slide-short-list-answers *ngIf="e.chatView === e.ChatView.ShortListOfAnswers" [answers]="e.getAnswers(0)">
    </app-slide-short-list-answers>
    <app-slide-answer-full-description *ngIf="e.chatView === e.ChatView.AnswerInFullDescription">
    </app-slide-answer-full-description>
    <app-slide-no-question *ngIf="e.chatView === e.ChatView.NoQuestion">
    </app-slide-no-question>
  </div>
  <div [hidden]="e.chatView !== e.ChatView.NoQuestion" class="bottom-box-container">
    <app-question-form></app-question-form>
  </div>
</div>