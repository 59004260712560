<div class="layout-container">
  <app-sidebar></app-sidebar>
  <div (click)="l.close()" *ngIf="l.isOverlayOpen" class="modal-overlay" id="modal-overlay"></div>

<div class="main-content" [class.w-100]="l.sidebarHidden" [hidden]="l.mobile && l.isSideBarOpen">
    <router-outlet> </router-outlet>

    <app-explorer *ngIf="_.includes(availableFeatures, Features.Explorer)" [hidden]="!l.explorerIsOpen"> </app-explorer>
    <app-explorer-icon *ngIf="_.includes(availableFeatures, Features.Explorer)" [class.opened]="l.explorerIsOpen"
      (click)="l.switchExplorerOpenStatus()"></app-explorer-icon>
  </div>
</div>

<div (click)="l.close('modalConfirm')" *ngIf="l.isConfirmModalOverlayOpen" class="modal-overlay-light"
  id="modal-overlay-confirm"></div>

<app-custom-modal modalTitle="Confirm" idName="modalConfirm" [show]="l.isConfirmOpen" class="border" confirmTitle="ok"
  [confirmActions]="l.confirmCallback" [confirmValidator]="true" [cancelActions]="l.cancelCallback">
  <div *ngIf="l.confirmMessage.length" class="custom-modal-content">
    <div class="my-5" [innerHtml]="l.confirmMessage"></div>
  </div>
</app-custom-modal>

<div #toastContainer class="toast-container" aria-live="polite" aria-atomic="true"></div>


<div id='cookies' class="modal" tabindex="-1">
  <div class="modal-dialog" id="cookies-dialog">
    <div class="modal-content" id="cookies-content">
      <div class="modal-body text-center">
        We use cookies to provide and improve our services. By using our platform, you consent to our <a target='_blank'
          href="https://myintmachines.atlassian.net/servicedesk/customer/portal/6/article/3027927091?src=429347470">Cookies
          policy</a> 
          <button id='acceptCookies' (click)="handleCookieAcceptation()" type="button" data-bs-dismiss="modal" class="mx-4 px-3 btn btn-add btn-outline-dark" 
          value="" >OK</button>
      </div>
    </div>
  </div>
</div>
