<app-viz-container *ngIf='data' [size]='size' [title]="title">
    <div [style.width.%]='100' [style.height.%]='100' class="d-flex flex-column">
        <div class="vizTextZone">
            <div class="vizTextZoneContent" *ngIf="data[0].values">
                <div *ngFor="let textArray of data[0].values" id="id">
                    <div *ngFor="let text of textArray" class='genomic' [class.emphasize]="text === textArray[0]"
                        [innerHTML]="setTextLayoutAutoBr(text)"></div>
                </div>
            </div>
        </div>
    </div>
</app-viz-container>