import { Component, OnInit, ViewChild } from '@angular/core';
import { ExplorerManagerService } from '../../api/explorer-manager.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { Router } from '@angular/router';
import { availableFeatures } from 'src/environments/environment';
import { Features } from 'src/environments/features';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie';
declare var $;

@Component({
  selector: 'app-logged-in-layout',
  templateUrl: './logged-in-layout.component.html',
  styleUrls: ['./logged-in-layout.component.scss'],
})
export class LoggedInLayoutComponent implements OnInit {
  availableFeatures = availableFeatures;
  Features = Features;
  _ = _;
  @ViewChild('toastContainer', { static: true }) ToastContainer;

  constructor(
    public e: ExplorerManagerService,
    public l: LayoutOrganizationService,
    public router: Router,
    private cookieService: CookieService
  ) {}
  ngOnInit() {
    this.l.toastContainer = this.ToastContainer;
    const areCookiesAccepted = this.cookieService.get('cookies_acceptation');
    if (areCookiesAccepted !== 'true') {
      $('#cookies').modal({ show: true, backdrop: 'static', keyboard: false });
    }
  }
  handleCookieAcceptation() {
    this.cookieService.put('cookies_acceptation', 'true');
    $('#cookies').modal('hide');
  }
}
