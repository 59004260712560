import { Component, Input, OnInit } from '@angular/core';
import { Expandable } from 'src/app/shared/expandable/expandable';
import { CategoryModel, KnowledgeSearchService } from '../knowledge-search.service';

@Component({
  selector: 'app-knowledge-search-results-category-tree',
  templateUrl: './knowledge-search-results-category-tree.component.html',
  styleUrls: ['./knowledge-search-results-category-tree.component.scss'],
})
export class KnowledgeSearchResultsCategoryTreeComponent extends Expandable implements OnInit {
  @Input() category: CategoryModel;
  @Input() depth: number;

  constructor(public knowledgeSearchService: KnowledgeSearchService) {
    super();
  }

  ngOnInit(): void {}

  handleExpand() {
    this.toggleExpanded();
  }
}
