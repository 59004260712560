import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import { ItemType } from '../helpers/itemType';

@Component({
  selector: 'app-rename-2',
  templateUrl: './rename2.component.html',
  styleUrls: ['./rename2.component.scss',]
})
export class Rename2Component implements OnInit {
  @Input() value
  @Input() itemType? : ItemType;
  ItemType = ItemType
  @Output() valueChanged = new EventEmitter();
  renameFormGroup: UntypedFormGroup;
  ngOnInit() {
    this.createForm();
  }
  get isProject(){
    return this.itemType === ItemType.Project
  }
  listenKeyEvent(event) {
    const enter = 13;
    const escape = 27;
    const code = event.keyCode || event.which;
    if (code === enter) {
      this.blurAll()
    }
    else if (code === escape) {
      this.inputField.setValue(this.value)
    }
  }
  blurAll() {
    var tmp = document.createElement("input");
    document.body.appendChild(tmp);
    tmp.focus();
    document.body.removeChild(tmp);
  }

  createForm() {
    this.renameFormGroup = new UntypedFormGroup({
      inputField: new UntypedFormControl(this.value, [Validators.required]),
    });

  }

  saveInput() {
      this.valueChanged.emit(this.inputField.value)
  }

  handleInput() {
    if (!this.inputField.valid) {
      return;
    }
    this.saveInput();
  }

  get inputField() {
    return this.renameFormGroup.get('inputField');
  }
}