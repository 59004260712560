<app-back text="Back to Question" [reset]="true" [goToView]="e.ChatView.NoQuestion"></app-back>
<div *ngIf="!e.getPendingState() && !e.getErrorState()">
  <div class="d-flex flex-column" *ngIf="answers?.length">
    <ul class="my-3">
      <li *ngFor="let answer of answers; let i = index">
        <app-explorer-response (click)="e.setAnswerIndex(i)" [results]="answer.Result" [focus]="answer.Focus"
          [unused]="answer.Unused" [score]="answer.Score" [date]="e.getTime(0)" [answer]="answer"
          [opened]="i === e.openedAnswer" [index]="i" [class.selected-answer]="i === e.indexAnswer">
        </app-explorer-response>
      </li>
    </ul>
  </div>
  <div class="d-flex flex-column" *ngIf="!answers?.length">
    <div class="my-3">
      <app-explorer-response [results]="[['No answer is available for this question.']]" [focus]="[]" [unused]="[]"
        [score]="" [date]="e.getTime(0)" [answer]="[]" [opened]="false" [index]="0" [class.selected-answer]="false">
      </app-explorer-response>
    </div>
  </div>
</div>