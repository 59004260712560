    <form [formGroup]="renameFormGroup">
      <div [class.smooth-border]='isProject' [class.bg-ivory]='isProject' (focusout)="handleInput()" class="input-icon-container d-flex align-items-center flex-nowrap">
        <input (keydown)=listenKeyEvent($event) class="value-input" 
        [class.small]='isProject'
        [class.big]='!isProject'
        type="text" formControlName="inputField" 
        [class.text-dark]='isProject'/>
        <i *ngIf="itemType !== ItemType.Project" class="user-icon far fa-edit" aria-hidden="true"></i>
      </div>
      <small>
        <span class='text-danger' *ngIf="!inputField.valid && (inputField.dirty || inputField.touched)">
          This field is required.
        </span>
      </small>
    </form>
