import { Component, OnDestroy } from '@angular/core';
import {
  AdditionalTypes,
  ShouldNotContainDoubleQuote,
  MaxLength128,
  InputJson,
  getFormErrorSentence,
} from 'src/app/shared/helpers/formattedInput';
import { Formulation } from '../formulation';
import { KnowledgeSearchService } from '../knowledge-search.service';
import * as _ from 'lodash';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';

@Component({
  selector: 'app-knowledge-search-keywords',
  templateUrl: './knowledge-search-keywords.component.html',
  styleUrls: ['./knowledge-search-keywords.component.scss'],
})
export class KnowledgeSearchKeywordsComponent implements OnDestroy {
  keywords0Object: InputJson;
  keywords1Object: InputJson;
  keywords2Object: InputJson;
  keywords3Object: InputJson;
  keywords4Object: InputJson;
  PagesPublicName = PagesPublicName
  Formulation = Formulation;
  _=_
  getFormErrorSentence = getFormErrorSentence

  constructor(public knowledgeSearchService: KnowledgeSearchService) {

    this.keywords0Object = {
      formControlName: 'keywords0',
      type: AdditionalTypes.ShortText,
      placeholder: 'term 1',
      lined_label: 'Terms (between 2 and 5)',
      showIcon: 'far fa-info-circle',
      validations: [ShouldNotContainDoubleQuote, MaxLength128],
      fitToContent: true,
    }; 
    this.keywords1Object = {
      formControlName: 'keywords1',
      type: AdditionalTypes.ShortText,
      placeholder: 'term 2',
      lined_label: ' ',
      showIcon: 'far fa-info-circle',
      validations: [ShouldNotContainDoubleQuote,MaxLength128],
      fitToContent: true,
    };
    this.keywords2Object = {
      formControlName: 'keywords2',
      type: AdditionalTypes.ShortText,
      placeholder: 'term 3',
      fitToContent: true,
      lined_label: ' ',
      showIcon: 'far fa-info-circle',
      validations: [ShouldNotContainDoubleQuote,MaxLength128],
    };
    this.keywords3Object = {
      formControlName: 'keywords3',
      type: AdditionalTypes.ShortText,
      placeholder: 'term 4',
      fitToContent: true,
      lined_label: ' ',
      showIcon: 'far fa-info-circle',
      validations: [ShouldNotContainDoubleQuote, MaxLength128],
    };
    this.keywords4Object = {
      formControlName: 'keywords4',
      type: AdditionalTypes.ShortText,
      placeholder: 'term 5',
      fitToContent: true,
      lined_label: ' ',
      showIcon: 'far fa-info-circle',
      validations: [ShouldNotContainDoubleQuote, MaxLength128],
    };
    if (_.get(this.knowledgeSearchService.getQueryParams(),'k0')) {
      _.set(this.keywords0Object, 'default_value', _.get(this.knowledgeSearchService.getQueryParams(),'k0'));
    }
    if (_.get(this.knowledgeSearchService.getQueryParams(),'k1')) {
      _.set(this.keywords1Object, 'default_value', _.get(this.knowledgeSearchService.getQueryParams(),'k1'));
    }
    if (_.get(this.knowledgeSearchService.getQueryParams(),'k2')) {
      _.set(this.keywords2Object, 'default_value', _.get(this.knowledgeSearchService.getQueryParams(),'k2'));
    }
    if (_.get(this.knowledgeSearchService.getQueryParams(),'k3')) {
      _.set(this.keywords3Object, 'default_value', _.get(this.knowledgeSearchService.getQueryParams(),'k3'));
    }
    if (_.get(this.knowledgeSearchService.getQueryParams(),'k4')) {
      _.set(this.keywords4Object, 'default_value', _.get(this.knowledgeSearchService.getQueryParams(),'k4'));
    }
  }
  
 
  ngOnDestroy() {
    this.knowledgeSearchService.resetForm(this.knowledgeSearchService.keywordsForm);
  }
}
