import { Component, OnDestroy } from '@angular/core';
import { ProjectsService } from '../../api/projects.service';
import { SessionStorage } from 'ngx-webstorage';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import * as _ from 'lodash';
import { Action, ProjectItemAction, ProjectManagerService } from 'src/app/api/project-manager.service';
import { ActivatedRoute } from '@angular/router';
import { ShareService } from 'src/app/api/share.service';
import { TabsPublicName } from 'src/app/shared/helpers/tabsPublicName';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
import { PermissionsHttpService } from 'src/app/shared/permissions-http-service/permissions-http.service';
import { ItemType } from 'src/app/shared/helpers/itemType';
import { SortType } from 'src/app/shared/helpers/sortType';
import { SortName } from 'src/app/shared/helpers/sortName';
import { SortDirection } from 'src/app/shared/helpers/sortDirection';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { SolutionService } from '../solution-manager/solution.service';

@Component({
  selector: 'app-project-manager',
  styleUrls: ['../../shared/layout/managers.scss', './project-manager.component.scss'],
  templateUrl: './project-manager.component.html',
})
export class ProjectManagerComponent implements OnDestroy {
  @SessionStorage() projects: any;
  createProject = false;
  canExploreProjects = false;
  _ = _;
  usergroups = [];
  Action = Action;
  TabsName = TabsName;
  TabsPublicName = TabsPublicName;
  SortType = SortType;
  SortName = SortName;
  PagesPublicName = PagesPublicName;
  ItemType = ItemType;
  ProjectItemAction = ProjectItemAction;
  previousUrlState = { id: '', viewTab: '' };
  loading = true;

  model: any = {};
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [
    {
      key: 'solution',
      type: 'multiselect_with_tags',
      templateOptions: {
        placeholder: 'project Solution',
        multiple: true,
        options: this.solutionService.multiselectOptions,
        originalOptions: this.solutionService.solutions,
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          field.form.get('solution').valueChanges.subscribe((value: any) => {
            this.filterProject(value);
          });
        },
      },
    },
  ];
  options: FormlyFormOptions = {};

  constructor(
    public shareService: ShareService,
    private solutionService: SolutionService,
    public projectManagerService: ProjectManagerService,
    public l: LayoutOrganizationService,
    private route: ActivatedRoute,
    private permissions: PermissionsHttpService
  ) {
    _.set(this, 'l.isTopBarOpen', true);
    _.set(this, 'l.isTopNavOpen', true);

    if (_.get(this, 'permissions')) {
      let func = this.permissions.checkPermission({
        permissions: {
          createProject: {},
          exploreProjects: {},
        },
      });
      if (func) {
        func.subscribe((res) => {
          this.canExploreProjects = res.exploreProjects;
          this.createProject = res.createProject;
          this.loading = false;
        });
      }
    }

    if (_.get(this, 'projectManagerService.actionEvent')) {
      this.projectManagerService.actionEvent.emit([Action.InitManager]);
    }
    if (_.get(this, 'route.params')) {
      this.route.params.subscribe((params) => {
        const viewTab = params['viewTab'];
        if (viewTab === TabsName.MyProjects) {
          this.projectManagerService.actionEvent.emit([Action.SelectTab, TabsName.MyProjects]);
        } else if (viewTab === TabsName.SharedProjects) {
          this.projectManagerService.actionEvent.emit([Action.SelectTab, TabsName.SharedProjects]);
        } else if (viewTab === TabsName.DeletedProjects) {
          this.projectManagerService.actionEvent.emit([Action.SelectTab, TabsName.DeletedProjects]);
        }
      });
    }
  }
  filterProject(value) {
    this.trigger([Action.Filter, value]);
  }
  trigger(event) {
    this.projectManagerService.actionEvent.emit(event);
  }
  toggleDirection(value: SortDirection) {
    if (value === SortDirection.Asc) {
      return SortDirection.Desc;
    } else {
      return SortDirection.Asc;
    }
  }
  handleSortBy(newSortingType, actualSortDirection) {
    this.trigger([Action.ChangeSortingType, newSortingType]);
    this.trigger([Action.ChangeSortingDirection, this.toggleDirection(actualSortDirection)]);
    this.trigger([Action.Refresh]);
  }
  ngOnDestroy() {
    this.l.isTopBarOpen = false;
    this.l.isTopNavOpen = false;
  }
}
