import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Expandable } from 'src/app/shared/expandable/expandable';

@Component({
  selector: 'app-expander-button',
  templateUrl: './expander-button.component.html',
  styleUrls: ['./expander-button.component.scss'],
})
export class ExpanderButtonComponent extends Expandable implements OnInit {
  @Output() clicked = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  ngOnInit(): void {}

  handleClick() {
    this.toggleExpanded();
    this.clicked.emit(this.expanded);
  }
}
