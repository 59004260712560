import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { ItemType } from  'src/app/shared/helpers/itemType'

@Component({
  selector: 'app-rename',
  templateUrl: './rename.component.html',
  styleUrls: ['./rename.component.scss'],
})
export class RenameComponent implements OnInit {
  @Input() item;
  @Input() type: ItemType;
  @Input() field: string;
  @Input() renameFunction?;
  @Output() renameEvent: EventEmitter<String> = new EventEmitter<String>();
  renameFormGroup: UntypedFormGroup;
  storeFormat = '';
  fieldCopy = '';
  constructor(private layout: LayoutOrganizationService) {}

  ngOnInit() {
    if (!this.item) {
      return;
    }
    this.createForm();
  }

  saveName() {
    this.fieldCopy = _.cloneDeep(this.inputField.value);
    if (this.type === ItemType.File && !this.item.is_folder) {
      if (_.split(this.fieldCopy, '.').length >= 2) {
        this.storeFormat = '.' + _.last(_.split(this.fieldCopy, '.'));
        let formatLength = this.storeFormat.length;
        this.fieldCopy = this.fieldCopy.slice(0, this.fieldCopy.length - formatLength);
      }
    }
  }

  listenKeyEvent(event) {
    const enter = 13;
    const escape = 27;
    const code = event.keyCode || event.which;
    if (code === enter) {
      this.blurAll();
    } else if (code === escape) {
      this.inputField.setValue(this.fieldCopy);
    }
  }
  blurAll() {
    var tmp = document.createElement('input');
    document.body.appendChild(tmp);
    tmp.focus();
    document.body.removeChild(tmp);
  }

  createForm() {
    this.renameFormGroup = new UntypedFormGroup({
      inputField: new UntypedFormControl(this.item[this.field], [Validators.required]),
    });
    this.saveName();
    this.inputField.setValue(this.fieldCopy);
  }

  saveInput() {
    if (!this.renameFunction) {
      this.renameEvent.emit(this.inputField.value);
      return;
    }
    if (this.type === ItemType.Project && _.get(this.item, 'host_country')) {
      this.renameFunction(
        this.item.id ? this.item.id : this.item._key,
        this.inputField.value + this.storeFormat,
        this.field,
        _.get(this.item, 'host_country')
      ).subscribe(
        (res) => {return undefined},
        () => {
          this.layout.toast('<b>Impossible</b> to rename the project <b>' + this.item.title + '</b>', null, 5000, '', 'danger');
        }
      );
    } else {
      this.renameFunction(this.item.id ? this.item.id : this.item._key, this.inputField.value + this.storeFormat, this.field).subscribe(
        (res) => {return undefined},
        () => {
          this.layout.toast('<b>Impossible</b> to rename the item <b>' + this.item.title + '</b>', null, 5000, '', 'danger');
        }
      );
    }
  }
  getCanModify = () => {
    return _.get(this, 'item.capabilities.canModify', true);
  };

  handleInput() {
    if (!this.inputField.valid) {
      return;
    }
    this.saveInput();
  }

  get inputField() {
    return this.renameFormGroup.get('inputField');
  }
}
