<div class="list-group-item scrollable h-100 w-100">
  <span *ngIf="!can_modify">{{_.get(item,"title")}}</span>
  <app-rename-2 *ngIf="can_modify" [value]='_.get(item,"title")'
    (valueChanged)='itemAction.emit([item, ItemAction.Rename, undefined, $event])' class='h5 text-primary'>
  </app-rename-2>
  <div class='my-3'>

    <button type="button" [class.disabled]="!_.includes(availableFeatures,Features.UploadFile)" placement="bottom"
      ngbTooltip="{{getTooltip('upload')}}" tooltipClass="custom-tooltip"
      (click)="_.includes(availableFeatures,Features.UploadFile) ? itemAction.emit([item, ItemAction.SelectMetadata]) : '' "
      class="modal-btn btn btn-outline-danger upload-btn separated-button">
      <i class="fas fa-arrow-circle-up" aria-hidden="true"></i>
      Update Metadata
      <i class="fas fa-exclamation-circle text-danger cornerIcon" *ngIf="_.isEmpty(metadata)" aria-hidden="true"></i>
    </button>
    <small>
      <div class="text-danger" [hidden]="!_.isEmpty(metadata)">File not found.
      </div>
    </small>
  </div>


  <ul class='mt-4'>

    <small>
      <li class='my-2 text-uppercase'><span class='text-muted'>status : </span><span
          [class.text-warning]="_.get(item, 'status')  === Status.Processing" [class.text-primary]="_.get(item, 'status')  === Status.Ready"
          class='font-weight-bold'> {{_.get(item, 'status') }}</span></li>

      <li class='my-2 text-uppercase'><span class='text-muted'>creation : </span><span class='font-weight-bold'
          [class.text-warning]='dateCreation === "a few seconds ago"'>{{dateCreation}}</span></li>
      <li class='my-2 text-uppercase'><span class='text-muted'>last modification : </span><span class='font-weight-bold'
          [class.text-warning]='dateModification === "a few seconds ago"'>{{dateModification}}</span></li>

      <li *ngIf="!is_folder" class='my-2 text-uppercase'><span class='text-muted'>format : </span><span
          class='font-weight-bold'>.{{_.last(_.split(title, '.'))
            }}</span>
      <li class='my-2 text-uppercase'><span class='text-muted'>size : </span><span
          class='font-weight-bold'>{{size}}</span>
      <li *ngIf='teamName' class='my-2 text-uppercase'><span class='text-muted'>team : </span><span
          class='font-weight-bold'>{{teamName}}</span>
        <br>
      <li class="text-uppercase" *ngIf="!_.isEmpty(metadata)"><span class='text-muted'>metadata : </span>
        <ul class="details">
          <li *ngFor="let element of metadata | keyvalue "><span>{{element.key | uppercase }} :
            </span><span>{{element.value}}</span></li>
        </ul>
      </li>

      <li class='text-uppercase my-4 font-weight-bold'>
        <span>{{is_shared ? "This item is shared" : "This item isn't shared"}}</span>
      </li>
      <li class='my-2 text-uppercase'><span class='text-muted'>ID : </span><span class="text-lowercase">{{item?.id}}</span></li>
    </small>

  </ul>
  <div class="spacer">&nbsp;</div>

</div>
