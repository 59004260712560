<div class="list ">
  <div  class="list-container border  d-flex flex-nowrap align-items-center justify-content-between">
    <div class=" h-100 align-items-center mx-0 px-0 d-flex flex-nowrap   ">
      <span (click)='open()' class='h-100 cursor-pointer d-flex countIcon align-items-center px-3'>
        <i class="far fa-user-friends pr-1" aria-hidden="true"></i>
        {{ _.get(team, 'user_ids.length', 0)}}
      </span>
      <span class="item-text cursor-pointer m-0 h-100 font-weight-bold">
        <span (click)='open()' class=' h-100 m-0 d-flex align-items-center '
          *ngIf='showName'>{{_.get(this, 'team.name', '')}}</span>
        <div (click)="_.set(this, 'showName',true)" *ngIf="!showName" class="overlay"></div>
        <span class=' h-100 m-0 d-flex align-items-center '>
          <app-rename *ngIf='!showName' (renameEvent)='handleRename($event)' class='h5 text-primary'
            [type]='ItemType.Team' [item]='team' field='name'></app-rename>
        </span>


      </span>

    </div>
    <div class="mx-0 px-0 cursor-pointer h-100 flex-grow-1" (click)='open()'></div>
    <div class=" mx-0 px-0  d-flex flex-nowrap align-items-center justify-content-between ">


      <div (click)='hasSubscription() ? manageSubscription() : addSubscription()' class="btn btn-link">
        <i class="far px-2 fa-file-invoice-dollar" aria-hidden="true"></i>
        {{hasSubscription() ? 'Manage subscription' : 'Add subcription'}}
      </div>


      <div (click)="showName ? _.set(this, 'showName',false) : _.set(this, 'showName',true)" class=" btn btn-link">
        <i class="far px-2 fa-edit" aria-hidden="true"></i> Rename
      </div>
      <div (click)='handleDelete()' class="btn btn-link">
        <i class="far px-2 fa-trash-alt" aria-hidden="true"></i> Delete Team
      </div>
    </div>
  </div>
</div>
