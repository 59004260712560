<div class='m-3 d-flex flex-align-center justify-content-between mx-7'>
  <div class='no-wrap d-flex flex-align-center'>
    <div type="button"
      (click)='knowledgeSearchService.navigateBackToSearchForm(knowledgeSearchService.selectedFormulation)'
      class="btn btn-light border"><span class="text-uppercase small"><i aria-hidden="true"
          class="text-muted far fa-arrow-to-left mr-1"></i> Edit search form</span></div>
    <div *ngIf='knowledgeSearchService.selectedFormulation === Formulation.Keywords'
      class='mx-4 row align-items-center'>
      <span class='font-weight-bold'>{{PagesPublicName.KnowledgeSearchSimpleKeyword}}: </span>
      <span class='mx-3'> {{knowledgeSearchService.getQueryParamAsString(knowledgeSearchService.getQueryParams())}}
      </span>
    </div>
    <div *ngIf='knowledgeSearchService.selectedFormulation === Formulation.Article' class='mx-4 row align-items-center'>
      <span class='font-weight-bold'>{{PagesPublicName.KnowledgeSearchSingleArticle}}: </span>
      <span class='mx-3'> {{knowledgeSearchService.getQueryParamAsString(knowledgeSearchService.getQueryParams())}}
      </span>
    </div>
    <div *ngIf='knowledgeSearchService.selectedFormulation === Formulation.Therapy'
      class='mx-4  row align-items-center'>
      <span class='font-weight-bold'>{{PagesPublicName.KnowledgeSearchPicoTherapy}}: </span>
      <span class='ml-2 '> In </span><span
        class='font-weight-bold mx-2'>[{{knowledgeSearchService.getQueryParams()['k0']}}],</span><span> what is the
        effet of</span>
      <span class='font-weight-bold mx-2'>[{{knowledgeSearchService.getQueryParams()['k1']}}]</span><span> on
      </span><span class='font-weight-bold mx-2'>[{{knowledgeSearchService.getQueryParams()['k2']}}]</span><span>
        compared to </span><span
        class='font-weight-bold mx-2'>[{{knowledgeSearchService.getQueryParams()['k3']}}]</span><span> ?</span>
    </div>
  </div>
  <app-knowledge-search-results-action-toolbar></app-knowledge-search-results-action-toolbar>
</div>

<div *ngIf='!knowledgeSearchService.debugMode'>


  <div *ngIf='!getClusterLength()' class=' w-100 justify-content-center align-items-center d-flex font-weight-bold'>
    <ng-container *ngIf="!knowledgeSearchService.isActivelySearching">
      <p *ngIf="knowledgeSearchService.backendError">{{ knowledgeSearchService.backendError }}</p>
      <p *ngIf="!knowledgeSearchService.backendError && !knowledgeSearchService.hasActiveFilters()">No result</p>
      <div *ngIf="!knowledgeSearchService.backendError && knowledgeSearchService.hasActiveFilters()" class="d-flex flex-column align-items-center">
        <p>No result</p>
        <button class="view-all-button text-primary" (click)="knowledgeSearchService.resetFilters();knowledgeSearchService.search()">View all results</button>
      </div>
    </ng-container>
    <mims-loader [isLoading]="knowledgeSearchService.isActivelySearching" [enableBackDrop]="false"></mims-loader>
  </div>

  <div *ngIf='getClusterLength()' class="d-flex mx-3">
    <app-knowledge-search-results-navigation-panel class="navigation-wrapper" [categories]="categories"
      [journals]="journals"></app-knowledge-search-results-navigation-panel>
    <div class="ml-4 d-flex flex-column">
      <app-knowledge-search-results-filters></app-knowledge-search-results-filters>
      <div class="results-wrapper">
        <div class="results-header">
          <div class="d-flex flex-column">
            <div class="results-header-results">
              <div class="results-header-title">Results</div>
              <sup class="results-header-count">{{ getClusterLength() }}</sup>
            </div>
            <div class="results-header-articles">From {{ getUniqueArticleLength() }} articles</div>
          </div>
          <div class="results-sort-wrapper">
            <div>Sorted by</div>
            <select (change)="knowledgeSearchService.handleSortChange()" [(ngModel)]="knowledgeSearchService.sortedBy">
              <option value="publication_date,desc">Publication date (Descending)</option>
              <option value="publication_date,asc">Publication date (Ascending)</option>
              <option value="journal,desc">Journal (Descending)</option>
              <option value="journal,asc">Journal (Ascending)</option>
            </select>
          </div>
        </div>
        <div class="results-container">
          <div class="result-items">
            <div
              *ngFor="let semantic of semantics | slice: (page - 1) * pageSize:page * pageSize; first as isFirst; index as index;"
              class="result-item" [class.active]="selectedSemantic === semantic" [id]="'cluster-cluster_' + index" data-toggle="list"
              role="tab" [attr.aria-controls]="'cluster_'+ index" (click)="handleSemanticClick(semantic)">
              <div class="result-item-content">
                <div class="result-item-text" [innerHtml]="_DomSanitizer.bypassSecurityTrustHtml(semantic.sentence)"></div>
                <div>
                  <div class="result-item-relevancy">Relevancy score: {{getRelevancyScore(semantic)}}%</div>
                  <div class="result-item-related">Related to: {{showFocus(semantic)}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="result-article-container">
            <ng-container *ngIf="currentArticle$ | async as currentArticle; else loading">
              <div class="result-article-title">
                <a [href]="currentArticle.url" target="_blank">{{currentArticle.title}}</a>
              </div>
              <div class="result-article-meta">{{currentArticle.authors}}</div>
              <div class="result-article-meta">
                {{currentArticle.journal}} - {{moment.unix(currentArticle.publication_date).format('YYYY MMM D')}}
              </div>
              <div class="result-article-meta">{{currentArticle.pmcid}} ({{currentArticle.type}})</div>
              <div class="result-article-content">{{currentArticle.abstract}}</div>
            </ng-container>
            <ng-template #loading>
              <mims-loader [isLoading]="true" [enableBackDrop]="false"></mims-loader>
            </ng-template>
          </div>
        </div>
        <ngb-pagination (pageChange)="onPageChange($event)" [maxSize]="5"
          class="bg-white pt-3 d-flex justify-content-center" [(page)]="page" [pageSize]="pageSize"
          [collectionSize]="getClusterLength()">
          <ng-template ngbPaginationPrevious><i class="far fa-chevron-left" aria-hidden="true"></i></ng-template>
          <ng-template ngbPaginationNext><i class="far fa-chevron-right" aria-hidden="true"></i> </ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>

<div *ngIf='knowledgeSearchService.debugMode' class='code-container mx-auto'>
  <pre>
    <code [innerHTML]="_.get(knowledgeSearchService, 'retrievedResult.value')  | json "></code>
  </pre>

</div>
