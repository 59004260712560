<ngb-pagination *ngIf='count' class="my-3" 
[page]="page" [collectionSize]="count" 
(pageChange)="emitPageChange($event)" 
  [boundaryLinks]="boudaryLinks">
  <ng-template ngbPaginationPages let-page let-pages="pages">
      <div class="ngb-custom-pages-item">
        <div class="form-group d-flex flex-nowrap px-2">
          <label id="paginationInputLabel" for="paginationInput" class="col-form-label mr-2 ml-1">Page</label>
          <input #i type="text" inputmode="numeric" pattern="[0-9]*" class="form-control" id="paginationInput"
            [value]="page" (keyup.enter)="enterNewPageValue(i.value)"
            (blur)="enterNewPageValue(i.value)" (input)="formatInput($any($event).target)"
            aria-labelledby="paginationInputLabel paginationDescription" [style.width.rem]="inputWidth" />
          <span id="paginationDescription" class="col-form-label text-nowrap px-2">
            of {{Math.ceil(count / pageSize)}}</span>
        </div>
      </div>
  </ng-template>
</ngb-pagination>
<div *ngIf='!count' [style.height.px]="70">
</div>
