import { Component, OnInit, Input } from '@angular/core';
import moment from 'moment';
import * as _ from 'lodash';
import { ExplorerManagerService } from 'src/app/api/explorer-manager.service';
@Component({
  selector: 'app-explorer-response',
  templateUrl: './explorer-response.component.html',
  styleUrls: [
    './explorer-response.component.scss',
    '../../../shared/layout/managers.scss',
  ],
})
export class ExplorerResponseComponent implements OnInit {
  @Input() results;
  @Input() score;
  @Input() focus;
  @Input() unused;
  @Input() date;
  @Input() index;
  @Input() answer;
  @Input() opened;
  public moment = moment;
  public resultsShortened: any;
  public isRead = false;
  public readableDate: any;
  public itemPluralMapping = {
    response: {
      '=0': 'No answer available for this question.',
      '=1': 'Response : ',
      other: 'Responses : ',
    },
  };
  constructor(public e: ExplorerManagerService) { }
  ngOnInit() {
    this.date = this.fixDate(this.date);
    this.readableDate = this.fromNowOrNow(this.date);
    this.keepReadableDateUpdated();
    this.resultsShortened = this.shortenResult(this.results);
  }
  keepReadableDateUpdated() {
    setInterval(() => {
      this.readableDate = this.fromNowOrNow(this.date);
    }, 1000);
  }
  fixDate = (date) => {
    return date < moment() ? date : moment();
  };

  fromNowOrNow(date) {
    const result = moment(date).fromNow();
    if (result === 'a few seconds ago') {
      return 'Now';
    } else {
      return moment(date).format('HH:mm');
    }
  }
  shortenResult = (res) => {
    if (res) {
      res = _.flatten(res);
      if (res.length > 3) {
        return [res[0], res[1], res[3], ['...']];
      } else {
        return res;
      }
    }
  };

  toggleOpening() {
    this.read();
    if (this.opened) {
      this.e.openedAnswer = null;
    } else {
      this.e.openedAnswer = this.index;
    }
  }

  read() {
    this.isRead = true;
  }
}
