import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import * as _ from 'lodash';
@Component({
  selector: 'formly-field-hugo',
  templateUrl: './formly-field-hugo.html',
  styleUrls: ['./formly-field-hugo.scss','../../../../styles.scss'],
})
export class FormlyFieldHugoInput extends FieldType<FieldTypeConfig>{
  _=_
}

