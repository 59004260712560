import { Component, HostListener } from '@angular/core';
import { ProjectsService } from 'src/app/api/projects.service';
import _ from 'lodash';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { LocalService } from 'src/app/api/local-storage.service';
import { CreditAlertsService } from './credit-alerts/credit-alerts.service';
import { Files2Service } from 'src/app/api/files2.service';
import { SolutionService } from '../solution-manager/solution.service';
import { FileGroupsService } from 'src/app/api/groups.service';
export interface Step {
  model: any;
  form: FormGroup;
  fields: FormlyFieldConfig[];
  title: string;
  options: FormlyFormOptions;
}
@Component({
  selector: 'app-project-creation2',
  templateUrl: './project-creation2.component.html',
  styleUrls: ['./project-creation2.component.scss'],
})
export class ProjectCreation2Component {
  solution;
  _ = _;
  page = 0;
  steps = [];
  solutionId;
  submitted = false;

  constructor(
    private localService: LocalService,
    private projectsService: ProjectsService,
    private router: Router,
    private solutionService: SolutionService,
    public layout: LayoutOrganizationService,
    public creditAlertsService: CreditAlertsService,
    public groupService: FileGroupsService,
    private filesService: Files2Service,
    private route: ActivatedRoute
  ) {
    this.filesService.getFileFromPath('/Focus Gene Lists').subscribe((result) => {
      this.filesService.focusGeneListFolderId = _.get(result, 'id');
    });
    this.route.params.subscribe((params) => {
      if (params['solution']) {
        this.solutionId = params['solution'];
        this.creditAlertsService.error.next('noFileGroupId');
        this.steps = [];
        this.goToPage(0);
        this.solutionService.getSolution(this.solutionId).subscribe((res) => {
          this.solution = res;
          this.creditAlertsService.workflow_id = _.get(this.solution, 'spec.workflow_id');
          this.creditAlertsService.updateCost.next(undefined);
          _.get(this.solution, 'spec.parameters', []).forEach((el) => {
            _.set(el, 'form', new FormGroup({}));
            this.steps.push(el);
          });
        });
      }
    });
  }

  @HostListener('document:keydown', ['$event'])
  nextWithEnter(event: KeyboardEvent) {
    if (event.key === 'Enter' && document.getElementById('next')) {
      document.getElementById('next').click();
    }
  }
  canExecute(step) {
    if (step.form) {
      return (
        step.form.status === 'VALID' &&
        !this.creditAlertsService.isOverBudget() &&
        !this.creditAlertsService.isImpossibleToCalculate() &&
        this.creditAlertsService.creditCost.value !== undefined
      );
    }
  }

  getExecuteTooltip(step) {
    if (step.form.status !== 'VALID') {
      return 'Please verify your form';
    }
    if (this.creditAlertsService.isOverBudget()) {
      return 'Not enough credits to run this project';
    }
    if (this.creditAlertsService.isImpossibleToCalculate()) {
      return 'Impossible to launch project';
    }
    if (this.creditAlertsService.creditCost.value === undefined) {
      return 'Waiting for cost to be calculated';
    }
  }
  execute() {
    const country = this.localService.getFromLocalStorage('user', 'account_country');
    const workflowId = _.get(this.solution, 'spec.workflow_id');

    let inputs = {};
    this.steps.forEach((step) => {
      for (let [key, value] of Object.entries(step.model)) {
        if (Array.isArray(value)) {
          value = _.compact(value)
        }
        _.set(inputs, key, value);
      }
    });
    let internalInputs = _.get(this.solution, 'spec.inputs', undefined);
    if (internalInputs !== undefined) {
      for (const [key, value] of Object.entries(internalInputs)) {
        _.set(inputs, key, value);
      }
    }
    this.projectsService
      .createProject(
        { title: _.get(inputs, 'project.name', 'Unnamed project'), description: _.get(inputs, 'project.description') },
        country
      )
      .subscribe(
        (projectRes) => {
          this.projectsService.triggerWorkflow(projectRes.id, workflowId, inputs, country, this.solutionId).subscribe(
            () => {
              this.submitted = true;
              this.router.navigate([PagesName.ProjectManager, TabsName.MyProjects]);
              this.layout.toast('Running in progress', null, 8000, '', 'success');
              this.creditAlertsService.updateBudget();
            },
            () => {
              this.layout.toast('Error executing workflow', null, 8000, '', 'danger');
            }
          );
        },
        () => {
          this.layout.toast('Error creating project', null, 8000, '', 'danger');
        }
      );
  }
  goToPage(page: number) {
    this.page = page;
  }
}
