import { Component, Input } from '@angular/core';
import { AtlassianHttpService } from 'src/app/api/atlassian.service';
import _ from 'lodash';
@Component({
  selector: 'documentation-atlassian',
  templateUrl: './documentation-atlassian.component.html',
  styleUrls: ['./documentation-atlassian.component.scss'],
})
export class DocumentationAtlassian {
  @Input() docAtlassianID:string;
  processed = false;
  private countError = 0;
  
  constructor(
    private atlassianService:  AtlassianHttpService,
  ) { }

  ngOnInit() {
    if (this.processed && this.countError<2) {
      return
    }

    this.atlassianService.getPage(this.docAtlassianID).subscribe(
      (result) => {
        this.processed = true;
        // Fill title
        if (result.title != undefined){
          _.set(document.getElementById('title'), 'innerText', result.title)
        }

        // Fill body
        let value ;
        if (result.body.styled_view.value != undefined) {
          value = result.body.styled_view.value;
        } else {
          value = result.body.export_view.value;
        }

        if (value != undefined) {
          // document.getElementById('doc').innerHTML = result.body.styled_view.value
          let updatedContent = ""
          let rest = value
          while(1) {
            let idx = rest.indexOf("<img") // detect start of img content
            if (idx == -1) {
              break
            }
            updatedContent += rest.substring(0, idx)
            updatedContent += `<img style="width:80%;" `
            rest = rest.substring(idx+4)
      
          }
          updatedContent += rest
      
          _.set(document.getElementById('doc'), 'innerHTML',updatedContent)
        } else {
          _.set(document.getElementById('doc'), 'innerText', "404 : content not found")
        }
      },
      (error) => {
        this.countError++
        console.log("error : ", error)
        _.set(document.getElementById('doc'), 'innerText', "404 : content not found")
      }
    );   
  }
}