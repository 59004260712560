import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as _ from 'lodash';
import { TeamActions, UserActions } from '../teams.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-team-manager',
  templateUrl: './team-manager.component.html',
  styleUrls: ['../../../shared/layout/managers.scss', './team-manager.component.scss'],
})
export class TeamManagerComponent  {
  @Input() selectedTeam;
  @Input() formattedSelectedTeam;
  @Input() selectedUser;
  @Input() formattedSelectedUser;
  @Output() teamEvent: EventEmitter<any> = new EventEmitter();
  @Output() userEvent: EventEmitter<any> = new EventEmitter();
  _ = _;

  PagesPublicName = PagesPublicName;

  memberAdditionForm = new UntypedFormGroup({
    memberInput: new UntypedFormControl(null),
  });
  newMemberAdditionForm = new UntypedFormGroup({
    email: new UntypedFormControl(null),
    first_name: new UntypedFormControl(null),
    last_name: new UntypedFormControl(null),
    ontological_domain: new UntypedFormControl(null),
    organization: new UntypedFormControl(null),
    file_hosting_country: new UntypedFormControl(null),
  });
  constructor(public layout: LayoutOrganizationService) {}
  resetNewMemberAdditionForm = () =>{
    this.newMemberAdditionForm.reset()
  }
  resetMemberAdditionForm = () =>{
    this.memberAdditionForm.reset()
  }
  handleInvitation = () => {
    this.userEvent.emit({ team: this.selectedTeam, value: this.newMemberAdditionForm.value, action: UserActions.AddNew });
  }
  openUserCard = (user) => {
    this.userEvent.emit({ id: user.id, action: UserActions.OpenInfo })
  };
  backToTeams = () => {
    this.teamEvent.emit({action: TeamActions.Exit });
  };
  handleAddUserById = () => {
    this.userEvent.emit({
      team: this.selectedTeam,
      id: this.memberAdditionForm.controls['memberInput'].value,
      action: UserActions.Add,
    });
  };

  trackFn(index, user) {
    return _.get(user.value, 'id') + _.get(user.value, 'role');
  }
}
