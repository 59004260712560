import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { environment } from 'src/environments/environment';
import { Formulation } from './formulation';
import { KnowledgeSearchService } from './knowledge-search.service';
import * as _ from 'lodash';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';

@Component({
  selector: 'app-knowledge-search',
  templateUrl: './knowledge-search.component.html',
  styleUrls: ['../../shared/layout/managers.scss', './knowledge-search.component.scss'],
})
export class KnowledgeSearchComponent {
  PagesPublicName = PagesPublicName;
  Formulation = Formulation;
  environment = environment;
  _ = _;
  constructor(
    public knowledgeSearchService: KnowledgeSearchService,
    private route: ActivatedRoute,
    private router: Router,
    public layout: LayoutOrganizationService
  ) {
    this.route.params.subscribe((params) => {
      if (this.knowledgeSearchService.updateActiveTab) {
        this.knowledgeSearchService.updateActiveTab(_.get(params, 'tab'));
      }
      this.knowledgeSearchService.selectedFormulation = _.get(params, 'formulation');
    });
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((res: NavigationEnd) => {
      const url = res.urlAfterRedirects;
      this.knowledgeSearchService.results = _.includes(url, PagesName.Results);
      this.knowledgeSearchService.editMode = _.includes(url, PagesName.Edit);
      if (!this.knowledgeSearchService.editMode) {
        this.knowledgeSearchService.setCurrentProject(null);
      }
    });
  }
  closeModal() {
    this.layout.close('modalQuerySettings');
  }
}
