import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TeamsService } from '../../teams.service';
import { AdditionalTypes, InputJson, IsRequired, TeamIdHelp } from 'src/app/shared/helpers/formattedInput';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-team-subscription-form',
  templateUrl: './team-subscription-form.component.html',
  styleUrls: [],
})
export class TeamSubscriptionFormComponent {
  @Input() teamSubscriptionForm;
  @Input() team;
  @Output() teamSubscriptionFormChanged = new EventEmitter();
  teamIdFieldObject;

  constructor(public teamsService: TeamsService) {}

  ngOnInit() {
    this.teamIdFieldObject = {
      formControlName: 'subscriptionId',
      type: AdditionalTypes.ShortText,
      placeholder: '',
      showIcon: 'far fa-info-circle',
      validations: [TeamIdHelp],
      label: 'Enter a subscription ID',
      default_value: _.get(this.team, 'subscription_id'),
    };
  }

  get subscriptionId() {
    return this.teamSubscriptionForm.get('subscriptionId');
  }
}
