import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { LocalService } from 'src/app/api/local-storage.service';
import * as _ from 'lodash';
import { LoginHttpService } from '../login-http-service/login-http.service';
@Injectable({ providedIn: 'root' })
export class LoginAuthGuard implements CanActivate {
  public basePath = 'XXXX';
  constructor(private router: Router, private loginHttpService: LoginHttpService, private localService: LocalService) {
  }
  canActivate(): Observable<boolean> {
    const user = this.localService.getFromLocalStorage('user')
    if (!_.get(user, 'file_systems.length')) {
      localStorage.clear();
      this.router.navigate([PagesName.Login]).then(() => {
        window.location.reload();
      });
      return of(false);
    }
    return new Observable((observer) => {
      this.loginHttpService.verifyAccess().subscribe(
        () => {
          observer.next(true);
        },
        () => {
          localStorage.clear();
          this.router.navigate([PagesName.Login]).then(() => {
            window.location.reload();
          });
          observer.next(false);
        }
      );
    });
  }
}
