<div class="form-wrapper" #formWrapper>
    <h4><b>{{title}}</b></h4>
    <p>{{description}}</p>
    <jsonforms *ngIf="formIsReady"
        [(data)]="data"
        [schema]="schema"
        [uischema]="uischema"
        [renderers]="renderers"
        [ajv]="ajv"
        (errors)="onFormErrorsChange($event)"

    ></jsonforms>
    <br>
    <hr>
    <h4><b>Name version</b></h4>
    <input (keyup)="onVersionNameChange($event)" maxlength="20em" width="20em">
    <br><br>
    <app-credit-alerts
        theme="light"
        [error]="errorCredit"
        [cost]="creditCost"
        [budget]="creditBudget">
    </app-credit-alerts>
    <br>
    <div *ngIf="getErrorCount() == 0">
        <button (click)="onSubmit()" class="submit">
            <i class="far fa-sync-alt" aria-hidden="true"></i>
            Run
        </button>
    </div>
    <div *ngIf="getErrorCount() != 0">
        <button class="submit deactivated">
            <i class="far fa-sync-alt" aria-hidden="true"></i>
            Run
        </button>
    </div>
</div>