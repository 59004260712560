import { Injectable } from '@angular/core';
import { NotificationHttpService } from './notification-http.service';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { Status } from 'src/app/shared/helpers/status';
import { TabsName } from 'src/app/shared/helpers/tabsName';

export interface SavedUser {
  userId: string;
  userName: BehaviorSubject<string>;
}
export interface SavedProject {
  projectId: string;
  projectName: BehaviorSubject<string>;
}

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  compressionToast;
  viewTab = TabsName.MyNotifications;
  compressionToastTitle = new BehaviorSubject<any>('Preparing download  . . .   ');
  compressionToastContent = new BehaviorSubject<any>('Initialization  . . .  ');
  isCompressionToastOpened = new BehaviorSubject<boolean>(true);
  downloadSubscription = [];
  pendingFolders = [];

  usersRelatedToNotifications: Array<SavedUser> = [{ userId: undefined, userName: new BehaviorSubject<string>('Unknown user') }];
  projectsRelatedToNotifications: Array<SavedProject> = [{ projectId: undefined, projectName: new BehaviorSubject<any>(undefined) }];
  workflowsRelatedToNotifications = [];
  newNotification = new BehaviorSubject<any>(undefined);
  userNotifications = new BehaviorSubject<any>([]);
  notificationGroupedByExecutionId = new BehaviorSubject<any>([]);

  constructor(private notifHttp: NotificationHttpService) {}

  markAsRead = (notificationId, country) => {
    return this.notifHttp.markAsRead(notificationId,country);
  };
  getUsersRelatedToNotificationPerUserId = () => {
    return _.keyBy(this.usersRelatedToNotifications, 'userId');
  };
  hideSystemNotifications = (notifications) => {
    return _.reject(notifications, (o) => {
      return this.getProjectId(o) === 'Compression';
    });
  };
  isSystemNotification = (notification) => {
    return this.getProjectId(notification) === 'Compression';
  };

  hideReadNotifications = (notifications) => {
    return _.reject(notifications, (o) => {
      return o.read === true;
    });
  };

  getNotifications() {
    let retrievedNotifications = []
    this.notifHttp.getNotifications().subscribe((response) => {
      let result = this.hideSystemNotifications(response.data);
      // let result = response.data; 
      result.map((el) => {
        _.set(el, '_body', this.getParsedBody(el));
      });
      retrievedNotifications = _.concat( retrievedNotifications, result)
      this.userNotifications.next(retrievedNotifications);
      this.notificationGroupedByExecutionId.next(
        _.groupBy(retrievedNotifications, function (o) {
          return o._body.execution_message_id;
        })
      );
    });
  }
  getPendingFoldersExecutionIds = () => {
    return _.map(this.pendingFolders, 'execution_id');
  };

  getToastContent = () => {
    let content = '<ul class="list-unstyled mb-0">';
    this.pendingFolders.forEach((el) => {
      if (el.status === Status.Pending) {
        content =
          content +
          '<li><i class="far mx-3 h6 fa-folder-download" aria-hidden="true"> </i>Compressing <b>' +
          el.name +
          '</b> in zip format' +
          ' <div class="ml-3 spinner-border text-primary text-muted smallSpinner "  role="status"></div> </li>';
      }
      if (el.status === Status.Done) {
        content =
          content +
          ' <li><i class="far mx-3 h6 fa-folder-download" aria-hidden="true"> </i><b>' +
          el.name +
          '</b> folder has been compressed. <a  download="' +
          el.name +
          '.zip"  href="' +
          el.link +
          '"> Download</a></li>';
      }
      if (el.status === Status.Error || el.status === Status.Failed) {
        content =
          content +
          '<li><span class="text-danger"> <i class="far mx-3 h6 fa-folder-times" aria-hidden="true"></i>Impossible to download folder  <b>' +
          el.name +
          '</b></span></li> ';
      }
      if (el.status === Status.Cancelled) {
        content =
          content +
          '<li><i class="far mx-3 h6 fa-folder-times" aria-hidden="true"> </i> Folder  <b>' +
          el.name +
          '</b> download has been cancelled.</li> ';
      }
    });
    content = content + '</ul>';
    return content;
  };
  updateCompressionToastMessage = () => {
    this.compressionToastContent.next(this.getToastContent());
  };
  getPendingFoldersFileIds = () => {
    return _.map(this.pendingFolders, 'file_id');
  };
  getParsedBody(notif) {
    let body = _.get(notif, 'body', null);
    if (body) {
      try {
        return JSON.parse(body);
      } catch (e) {
        return null;
      }
    }
    return null;
  }
  getExecutionMessageId(notif) {
    return _.get(this.getParsedBody(notif), 'execution_message_id', null);
  }
  getUserId(notif) {
    return _.get(this.getParsedBody(notif), 'user_id');
  }
  getFolderTitle(execution_id) {
    let pendingFolder = this.getFolder(execution_id);
    return _.get(pendingFolder, 'name', null);
  }
  getFolder(execution_id) {
    return _.find(this.pendingFolders, function (o) {
      return o.execution_id === execution_id;
    });
  }
  getFolderById(id) {
    return _.filter(this.pendingFolders, function (o) {
      return o.file_id === id;
    });
  }

  isSuccessfullNotif(notif) {
    return this.getType(notif) === 'filelink';
  }
  isFailedNotif(notif) {
    return this.getState(notif) === 'failed';
  }
  getState(notif) {
    return _.get(this.getParsedBody(notif), 'state');
  }
  getUrl(notif) {
    return _.get(this.getParsedBody(notif), 'download_url');
  }
  getProjectId(notif) {
    return _.get(this.getParsedBody(notif), 'project_id');
  }
  getType(notif) {
    return _.get(notif, 'type');
  }
}
