import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { ProjectsService } from 'src/app/api/projects.service';
import _ from 'lodash';
import { CreditAlertsService } from 'src/app/pages/project-creation2/credit-alerts/credit-alerts.service';
@Component({
  selector: 'formly-field-projects',
  styleUrls: ['../../../styles.scss'],
  template: `
    <div *ngIf='to.options' class="form-group">
      <label *ngIf="_.get(field, 'templateOptions.label.length')">
        {{ _.get(field, 'templateOptions.label') }}
        <span *ngIf="_.get(field, 'templateOptions.required')">*</span>
      </label>
      <select
        class="form-control bg-white w-100"
        [formControl]="formControl"
        [formlyAttributes]="field"
        (change)="onChange($event)"
        [class.invalid]="formControl.status == 'INVALID'"
      >
        <option *ngFor="let item of to.options" disabled="{{ _.get(item, 'disabled') }}" [value]="item.value">{{ item.label }}</option>
      </select>
      <div *ngIf="showError" class="invalid-feedback d-block">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>
    </div>
  `,
})
export class FormlyFieldProjects extends FieldType implements OnInit {
  _ = _;
  constructor(private projectsService: ProjectsService, private creditAlertsService: CreditAlertsService) {
    super();
  }
  onChange(event) {
    this.creditAlertsService.updateCost.next(undefined)
  }
  ngOnInit() {
    this.options.updateInitialValue();
    this.formControl.setValue('');
    this.projectsService._projectList(_.get(this, 'to.sortBy'), _.get(this, 'to.filters')).subscribe((res) => {
      let options = res.data.map((el) => {
        if (!el.owned_by_me) {
          return { label: el.title + ' –– shared with me  ', value: el.id };
        }
        return { label: el.title, value: el.id };
      });
      options.unshift({ label: _.get(this, 'to.placeholder', 'Select'), value: '', disabled: true });
      this.to.options = options;
      this.formControl.setValue('');
    });
  }
}
