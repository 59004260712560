<button *ngIf='bulkType === BulkType.File' class="btn btn-icon" (click)="handleClick()">
    <i *ngIf='fileType === FileType.File' [class.fa-file-check]="selected" [class.duotone]="selected"
        [class.duotone-danger]="selected && status === Status.Unusable"
        [class.duotone-warning]="selected && status === Status.Processing" [class.fad]="selected"
        [class.fa-file]="!selected" [class.fal]="!selected" class='fa-2x icon-file' aria-hidden="true"></i>
    <span *ngIf='fileType === FileType.Folder' class="fa-stack icon-folder">
        <i class="fa-folder fa-stack-2x" [class.text-primary]='selected' [class.fas]="selected" [class.fal]="!selected"
            aria-hidden="true"></i>
        <i class="fas fa-check fa-stack-1x fa-inverse" *ngIf="selected" aria-hidden="true"></i>
    </span>
</button>
<button *ngIf='bulkType === BulkType.Project'  (click)="handleClick()"
class="btn btn-icon text-primary">
<i [class.fa-check-circle]="selected" [class.fas]="selected" [class.fa-circle]="!selected"
  [class.fal]="!selected" aria-hidden="true"></i>
</button>
<button *ngIf="bulkType === BulkType.AllFile" class="btn btn-icon text-primary" (click)="handleAllClick()"><i
        [class.fa-check-circle]="selected" [class.fas]="selected" [class.fa-circle]="!selected" [class.fal]="!selected"
        class='text-light' aria-hidden="true"></i>
</button>