import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectsService } from 'src/app/api/projects.service';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import * as _ from 'lodash';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-workflow-in-progress',
  templateUrl: './workflow-in-progress.component.html',
  styleUrls: ['./workflow-in-progress.component.scss'],
})
export class WorkflowInProgressComponent {
  projectId
  environment = environment

  constructor(private router: Router, private route: ActivatedRoute, public projectsService: ProjectsService) {
    if (!this.projectId)
      _.set(this, 'projectId', this.route.snapshot.paramMap.get('projectId'));
    if (this.route.snapshot.paramMap.get('country')) {
      _.set(this.projectsService, 'projectStorageCountry', this.route.snapshot.paramMap.get('country'))
    }
    this.route.queryParams.subscribe((params) => {
      if (params['fromDebugger'] === 'false' || !_.get(params, 'fromDebugger')) {
        projectsService.setFromDebugger(false)
      } else if (params['fromDebugger'] === 'true') {
        projectsService.setFromDebugger(true)
      }
    })
  }
  
  goToProjects() {
    this.router.navigate([PagesName.ProjectManager])
  }
  goToProject() {
    this.router.navigate([PagesName.ProjectManager, TabsName.MyProjects, this.projectId, this.projectsService.getProjectStorageCountry(), PagesName.ProjectOverview])
  }
  goToDebugger() {
    this.router.navigate([PagesName.WorkflowDebugger])
  }
}
