<app-viz-thumbnail-container [size]='size' *ngIf="data" [title]='title'>
    <div [style.width.%]='100' [style.height.%]='100' class="">
        <div class="vizTableContainer">
            <div class="scroll-section">
                <table aria-describedby="alignment table" class="vizTable">
                    <tbody *ngIf="dataModified.cells">
                        <tr *ngFor="let lines of dataModified.cells">
                            <td *ngFor="let cell of lines; let isFirst = first" class="letters letters-thumbnails"
                                [class.dot]="cell === '.'" [hidden]="isFirst"
                                [style.background-color]="isFirst ? null : getColorFromLetter(cell)">
                                {{cell}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</app-viz-thumbnail-container>