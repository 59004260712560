import { Component, OnInit } from '@angular/core';
import _ from 'lodash';
import { FieldType } from '@ngx-formly/core';
import { CreditAlertsService } from 'src/app/pages/project-creation2/credit-alerts/credit-alerts.service';
@Component({
  selector: 'formly-field-credits',
  template: ` <app-credit-alerts
    theme="light"
    [error]="error"
    [cost]="cost"
    [budget]="budget"
  ></app-credit-alerts>`,
})
export class FormlyFieldCredits extends FieldType implements OnInit {
  _ = _;

  constructor(public creditAlertsService: CreditAlertsService) {
    super();
  }
  get cost() {
    return this.creditAlertsService.creditCost.value;
  }
  get error() {
    return this.creditAlertsService.error.value;
  }
  get budget() {
    return this.creditAlertsService.creditBudget.value;
  }
  ngOnInit() {}
}
