import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';
import { LayoutOrganizationService } from './layout-organization.service';
import { LocalService } from './local-storage.service';
import { ItemType } from 'src/app/shared/helpers/itemType';
import { InfiniteListingService } from './infiniteListing.service';

export enum RoleType {
  None = 'none',
  Contributor = 'contributor',
  Reader = 'reader',
  Downloader = 'downloader',
  Writer = 'writer',
  Owner = 'owner',
}
export var RoleTypeProject = [RoleType.Reader, RoleType.Writer, RoleType.Contributor];
export var RoleTypeFile = [RoleType.Reader, RoleType.Downloader, RoleType.Writer, RoleType.Contributor];
export enum PermissionType {
  None = 'none',
  Reader = 'reader',
  Downloader = 'downloader',
  Writer = 'writer',
  Contributor = 'contributor',
  Owner = 'owner',
}
export enum AutoCompleteType {
  TeamsMembers,
  UsergroupNames,
  UsergroupsMembers,
  NewMembers,
}
// export enum ItemType {
//   Team = 'team',
//   File = 'file',
//   Project = 'project',
//   FileGroup = 'filegroups',
//   Folder = 'folder',
//   Other = 'other',
// }
export enum UserType {
  User = 'users',
  Usergroup = 'usergroups',
}
export interface autoComplete {
  value: string;
  id?: string;
  team?: string;
  role?: string;
  type?: AutoCompleteType;
  country?: string;
}

@Injectable()
export class ShareService {
  user;
  AutoCompleteType = AutoCompleteType;
  RoleType = RoleType;
  PermissionType = PermissionType;
  ItemType = ItemType;
  UserType = UserType;
  usersSelected = [];
  autoCompleteDataUsers = new BehaviorSubject<any>(undefined);
  autoCompleteDataUsergroups = new BehaviorSubject<any>(undefined);
  protected basePath = 'XXXX';
  constructor(
    private localService: LocalService,
    private readonly http: HttpClient,
    private l: LayoutOrganizationService,
    private infiniteListingService: InfiniteListingService
  ) {
    this.user = this.localService.getFromLocalStorage('user');
  }

  getUsergroupNames() {
    return this.autoCompleteDataUsergroups.value;
  }
  getTeamsMembers() {
    return this.autoCompleteDataUsers.value;
  }
  getAutocompleteData() {
    return _.sortBy(_.concat(this.getUsergroupNames(), this.getTeamsMembers()), ['value']);
  }
  getTeams = (country?): Observable<any> => {
    if (!!country) {
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.get<any>(`${this.basePath}/mas/teams`, { headers });
    }
    return this.http.get<any>(`${this.basePath}/mas/teams`);
  };
  getTeam = (id, country?): Observable<any> => {
    if (!!country) {
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.get<any>(`${this.basePath}/mas/teams/teamusers/${id}`, { headers });
    }
    return this.http.get<any>(`${this.basePath}/mas/teams/teamusers/${id}`);
  };
  retrieveUserGroup(usergroupId: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/mas/fs/usergroups/${usergroupId}`, {});
  }
  listTeamsMembers = (country) => {
    this.retrieveTeamsMembers(country).then((response: Array<any>) => {
      let teamsMembers = [];
      response.forEach((team) => {
        const emails = _.get(team, 'user_emails', []);
        _.get(team, 'team.user_ids', []).forEach((el2, index2) => {
          teamsMembers.push({
            id: el2,
            value: _.nth(emails, index2),
            team: null,
            country: country,
            type: AutoCompleteType.TeamsMembers,
          });
        });
      });
      teamsMembers = _.uniqBy(teamsMembers, (e) => {
        return e.id;
      });
      teamsMembers = _.reject(teamsMembers, (o) => {
        return o.id === this.user.id;
      });
      this.autoCompleteDataUsers.next(teamsMembers);

    });
  };
  retrieveTeamsMembers = (country) => {
    return new Promise((resolve) => {
      this.getTeams(country).subscribe((res) => {
        let observableBatch = [];
        res.forEach((el) => {
          observableBatch.push(this.getTeam(_.get(el, '_key'), country));
        });
        forkJoin(observableBatch).subscribe((response) => {
          resolve(response);
        });
      });
    });
  };
  retrieveUsergroups = (limit?: number, sortBy?: string) => {
    return new Promise((resolve) => {
      const func = () => {
        return this.getUsergroups(limit, sortBy);
      };
      return this.infiniteListingService.infiniteListing(func).then((res) => {
        resolve(res);
      });
    });
  };

  listUsergroups = (country, limit?: number, sortBy?: string) => {
    if (country !== _.get(this.user, 'account_country')) {
      this.autoCompleteDataUsergroups.next([]);
      return;
    }
    this.retrieveUsergroups(limit, sortBy).then((res) => {
      let data = [];
      _.get(res, 'data').forEach((el) => {
        data.push({
          id: _.get(el, 'id'),
          value: _.get(el, 'name', null),
          team: null,
          country: country,
          type: AutoCompleteType.UsergroupNames,
        });
      });
      this.autoCompleteDataUsergroups.next(data);
    });
  };
  getUsergroups = (limit?: number, sortBy?: string): Observable<any> => {
    let queryParameters = new HttpParams({});
    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', sortBy as any);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', limit as any);
    }
    queryParameters = queryParameters.set('filters', `ownedByMe=true,sharedWithMe=true`);

    return this.http.get<any>(`${this.basePath}/mas/fs/usergroups`, {
      params: queryParameters,
    });
  };
  createUserGroup(name?, description?): Observable<any> {
    const body = {
      description: description ? description : 'test',
      metadata: {},
      name: name ? name : 'test',
    };
    return this.http.post<any>(`${this.basePath}/mas/fs/usergroups`, body, {});
  }
  deleteUserGroup(usergroupId: string): Observable<any> {
    return this.http.delete<any>(`${this.basePath}/mas/fs/usergroups/${usergroupId}`, {});
  }
  addUserToUserGroup(usergroupId, email: string, role: RoleType): Observable<any> {
    const body = {
      role: role,
      email: email,
    };

    return this.http.post<any>(`${this.basePath}/mas/fs/usergroups/${usergroupId}/users`, body, {});
  }
  removeUserFromUserGroup(usergroupId: string, userId: string): Observable<any> {
    return this.http.delete<any>(`${this.basePath}/mas/fs/usergroups/${usergroupId}/users/${userId}`, {});
  }
  updateUserPermissionInUsergroup(usergroupId: string, userId: string, role: RoleType): Observable<any> {
    const body = {
      role: role,
    };
    return this.http.patch<any>(`${this.basePath}/mas/fs/usergroups/${usergroupId}/users/${userId}`, body, {});
  }

  postPermissionOfItem = (
    itemType: string,
    userType: string,
    permissionType: string,
    itemId: string,
    userId: string,
    country?: string
  ): Observable<any> => {
    const body = {
      date_of_expiration: 0,
      grantee_id: userId,
      grantee_kind: userType,
      role: permissionType,
    };
    if (country) {
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.post<any>(`${this.basePath}/mas/fs/${itemType}/${itemId}/permissions`, body, { headers });
    }
    return this.http.post<any>(`${this.basePath}/mas/fs/${itemType}/${itemId}/permissions`, body, {});
  };
  // removePermissionFromProject(permId: string, projectId): Observable<any> {
  //   return this.http.delete<any>(`${this.basePath}/mas/fs/projects/${projectId}/permissions/${permId}`, {});
  // }

  resetNonSentInvitations() {
    this.usersSelected = [];
  }
  shareToUsers = (itemType, itemId, usersSelected?, country?) => {
    return new Promise((resolve) => {
      let usertype = function (el) {
        return el.userType === UserType.User ? 'users' : 'usergroups';
      };
      let type 
      if(itemType === ItemType.File || itemType === ItemType.Files){
        type = 'files'
      }
      else if(itemType === ItemType.Project){
        type = 'projects'
      }
      else {
        type = 'filegroups'
      }
   
      let observableBatch = [];
      if (!usersSelected) {
        usersSelected = this.usersSelected;
      }
      usersSelected.forEach((el) => {
        observableBatch.push(this.postPermissionOfItem(type, usertype(el), el.role, itemId, el.id, country ? country : undefined));
      });
      return forkJoin(observableBatch).subscribe(
        (response) => {
          this.l.toast('Item successfully </b> shared with selected users', null, 8000, 'Profile', 'success');
          this.usersSelected = [];
          resolve(true);
        },
        (error) => {
          this.l.toast(_.get(error, 'error.message'), null, 8000, 'Profile', 'danger');
        }
      );
    });
  };
}
