import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { Component, Input, OnInit } from '@angular/core';
import { CategoryModel, KnowledgeSearchService, SearchResultDTO } from '../../knowledge-search.service';

@Component({
  selector: 'app-knowledge-search-results-navigation-panel',
  templateUrl: './knowledge-search-results-navigation-panel.component.html',
  styleUrls: ['./knowledge-search-results-navigation-panel.component.scss'],
})
export class KnowledgeSearchResultsNavigationPanelComponent implements OnInit {
  @Input() categories: CategoryModel[];
  @Input() journals: SearchResultDTO['top_journals'];

  options: Options = {
    floor: 2000,
    ceil: new Date().getFullYear(),
    step: 1,
    showTicks: true,
  };

  constructor(public knowledgeSearchService: KnowledgeSearchService) {}

  ngOnInit(): void {}

  getSelectedCategoriesLength() {
    return Object.values(this.knowledgeSearchService.selectedCategoryFilters).filter(Boolean).length
  }

  handleAllResults() {
    this.knowledgeSearchService.clearCategoryFilters();
    this.knowledgeSearchService.clearJournalFilters();
    this.knowledgeSearchService.clearDateFilters();
    this.knowledgeSearchService.search();
  }

  handleClearCategorySelection() {
    this.knowledgeSearchService.clearCategoryFilters();
  }

  handleClearJournalSelection() {
    this.knowledgeSearchService.clearJournalFilters();
  }
}
