import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { ShareService, UserType, PermissionType, RoleType, RoleTypeFile, RoleTypeProject } from 'src/app/api/share.service';
import * as _ from 'lodash';
import { UntypedFormGroup, Validators, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { ProjectManagerService } from 'src/app/api/project-manager.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { FileGroupAction, GroupsManagerService } from 'src/app/api/groups-manager.service';
import { LocalService } from 'src/app/api/local-storage.service';
import { ItemAction } from 'src/app/pages/file-manager2/file-manager2.service';
import { dataItemAction }from 'src/app/pages/data-manager/data-manager.service';

import { ItemType } from 'src/app/shared/helpers/itemType';
import { ProjectItemAction } from 'src/app/api/project-manager.service';
@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss', '../layout/managers.scss'],
})
export class ShareComponent implements OnInit {
  @Input() type: ItemType;
  @Input() item;
  @Input() country;
  @Input() autocompleteData? = [];
  @Output() itemEvent: EventEmitter<any> = new EventEmitter();
  @Output() itemEventNew: EventEmitter<any> = new EventEmitter();

  @Output() groupItemEvent: EventEmitter<any> = new EventEmitter();
  autocompleteDataFiltered;
  user;
  _ = _;
  ProjectItemAction = ProjectItemAction;
  usergroups = [];
  keyword = 'value';
  emailToSearch = '';
  searchUser: UntypedFormGroup;
  UserType = UserType;
  ItemType = ItemType;
  RoleType = RoleType;
  pending = false;
  PermissionType = PermissionType;
  userNotFound = false;
  addType: UserType;
  constructor(private localService: LocalService, public shareService: ShareService, private l: LayoutOrganizationService) {
    if (this.localService){
    this.user = this.localService.getFromLocalStorage('user');
    }
    this.initForm();
  }

  @ViewChild('select') select: ElementRef;
  @ViewChild('autocompleteField') autocompleteField: ElementRef;

  ngOnInit(): void {
    if (!this.item) {
      return;
    }
    this.autocompleteDataFiltered = _.cloneDeep(this.autocompleteData);
  }
  onChangeSearch(event) {
    this.autocompleteDataFiltered = _.filter(this.autocompleteData, (data) => {
      return _.includes(_.get(data, 'value'), event);
    });
  }
  canShare() {
    return _.get(this.item, 'capabilities.can_share');
  }
  permissions() {
    return _.get(this.item, 'permissions', []);
  }
  permissionGranteeKind(permission) {
    return _.get(permission, 'grantee.kind');
  }
  initForm() {
    this.searchUser = new UntypedFormGroup({
      email: new UntypedFormControl(null, [
        Validators.required,
        Validators.min(1),
        this.granteeHasNotAlreadyAccess,
        this.granteeIsNotMe,
        this.granteeIsNotOwner,
        this.customValidator,
      ]),
      permission: new UntypedFormControl(null, [Validators.required]),
    });
  }

  submitForm() {
    if (this.addType === UserType.Usergroup) {
      this.addUserGroup(_.get(this, 'email.value'), this.permission.value);
    } else {
      this.addUserByEmail(_.get(this, 'email.value'), this.permission.value);
    }
  }
  hasAtLeastOneError(type) {
    if (type === 'email') {
      if ((!_.get(this, 'email.valid') || this.userNotFound) && (_.get(this, 'email.dirty') || _.get(this, 'email.touched'))) {
        return true;
      }
    }
    return false;
  }
  addUserByEmail(email, role) {
    const description = this.describeRole(role);
    let user;
    if (this.autocompleteData) {
      user = _.find(this.autocompleteData, { value: email });
    } else {
      user = _.find(this.shareService.getTeamsMembers(), { value: email });
    }
    if (!user) {
      this.l.toast('Impossible to share this item to <b>' + email + '</b>. (Future feature)', null, 8000, 'Profile', 'danger');
      this.searchUser.reset();
      return;
    }
    this.shareService.usersSelected.push({
      email: email,
      id: _.get(user, 'id'),
      userType: UserType.User,
      role: role,
      role_description: description,
    });
    this.searchUser.reset();
  }
  manuallyTriggerValidation() {
    const input = this.autocompleteField.nativeElement.querySelectorAll('input')[0];
    setTimeout(() => {
      input.dispatchEvent(new Event('input'));
    }, 10);
  }
  describeAllRoles() {
    let text = '';
    let model: any = RoleTypeProject;
    if (this.type === ItemType.File || this.type === ItemType.Files) {
      model = RoleTypeFile;
    }
    for (let el of model) {
      text = text + ' ' + el.toUpperCase() + ' => ' + this.describeRole(el);
    }
    return text;
  }
  describeRole(role) {
    if (this.type === ItemType.Project) {
      switch (role) {
        case RoleType.Reader:
          return 'can read';
        case RoleType.Writer:
          return 'can read, execute, and modify';
        case RoleType.Contributor:
          return 'can read, execute, modify and share';
      }
    }
    if (this.type === ItemType.File || this.type === ItemType.Files) {
      switch (role) {
        case RoleType.Reader:
          return 'can list and use in workflow';
        case RoleType.Downloader:
          return 'can list and use in workflow';
        case RoleType.Writer:
          return 'can list, use in workflow and modify';
        case RoleType.Contributor:
          return 'can list, use in workflow and modify and share';
      }
    }
    if (this.type === ItemType.FileGroup || this.type === ItemType.FileGroups) {
      switch (role) {
        case RoleType.Reader:
          return 'can list and use in workflow';
        case RoleType.Writer:
          return 'can list, use in workflow and modify';
        case RoleType.Contributor:
          return 'can list, use in workflow and modify and share';
      }
    }
  }
  addUserGroup(name, role) {
    let usergroup = _.find(this.shareService.getUsergroupNames(), { value: name });
    let description = this.describeRole(role);
    this.shareService.usersSelected.push({
      email: name,
      userType: UserType.Usergroup,
      id: usergroup.id,
      role: role,
      role_description: description,
    });
    this.searchUser.reset();
  }
  removeInvited(index) {
    this.shareService.usersSelected.splice(index, 1);
  }

  existingUserGroupValidator(control: UntypedFormControl): ValidationErrors | null {
    return _.findIndex(this.shareService.getUsergroupNames(), { value: control.value }) > -1 ? null : { userGroupDoesNotExist: true };
  }
  emailValidator(control: UntypedFormControl): ValidationErrors | null {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(control.value).toLowerCase()) ? null : { notAnEmail: true };
  }
  removePermission = (permissionId, itemId, country?) => {
    if (this.type === ItemType.Project) {
      this.itemEvent.emit([this.item, ProjectItemAction.RemovePermission, undefined, permissionId]);
    } else if (this.type === ItemType.File || this.type === ItemType.Files) {
      this.itemEventNew.emit({ item: this.item, itemAction: dataItemAction.RemovePermission, value: permissionId, country: country });
      this.itemEvent.emit([this.item, ItemAction.RemovePermission, undefined, permissionId, country]);
    } else {
      this.itemEventNew.emit({ item: this.item, itemAction: dataItemAction.RemovePermission, value: permissionId, country: country });
      this.groupItemEvent.emit({
        item: this.item,
        fileGroupAction: FileGroupAction.RemovePermission,
        value: permissionId,
        country: country,
      });
    }
  };

  granteeIsNotMe = (control: UntypedFormControl): ValidationErrors | null => {
    if (control.value === _.get(this, 'user.email')) {
      return { userIsMe: true };
    } else {
      return null;
    }
  };
  granteeIsNotOwner = (control: UntypedFormControl): ValidationErrors | null => {
    if (control.value === _.get(this.item, 'owner.email')) {
      return { userIsOwner: true };
    } else {
      return null;
    }
  };
  granteeHasNotAlreadyAccess = (control: UntypedFormControl): ValidationErrors | null => {
    let permissions = _.get(this, 'item.permissions', []);
    let userAlreadyAdded = _.find(permissions, function (o) {
      return o.grantee.email === control.value;
    });
    let usergroupAlreadyAdded = _.find(permissions, function (o) {
      return o.grantee.name === control.value;
    });
    if (userAlreadyAdded === undefined && usergroupAlreadyAdded === undefined) {
      return null;
    } else {
      return { userIsAlreadyAdded: true };
    }
  };

  getTooltipContent(type) {
    let response = '';
    if (type === 'email') {
      if ((this.email.hasError('required') && _.get(this, 'email.dirty')) || _.get(this, 'email.touched')) {
        response = 'This field is required';
      } else if (this.email.hasError('userIsOwner')) {
        response = 'This user is already the owner.';
      } else if (this.email.hasError('userIsMe')) {
        response = "You can't share this to yourself.";
      } else if (this.userNotFound) {
        response = 'User not found';
      } else if (this.email.hasError('userIsAlreadyAdded')) {
        response = 'Already added.';
      } else if ((!_.get(this, 'email.valid') && _.get(this, 'email.dirty')) || _.get(this, 'email.touched')) {
        response = "You can't share to this user or usergroup.";
      }
    }
    return response;
  }
  resetEmailError() {
    this.userNotFound = false;
  }

  customValidator = (control: UntypedFormControl): ValidationErrors | null => {
    this.addType = null;

    if (this.emailValidator(control) === null) {
      this.addType = UserType.User;
      return null;
    }
    if (this.existingUserGroupValidator(control) === null) {
      this.addType = UserType.Usergroup;
      return null;
    }

    return { userNorUserGoupExist: true };
  };

  get email() {
    return this.searchUser.get('email');
  }
  get permission() {
    return this.searchUser.get('permission');
  }
}
