<div class="d-flex header bg-primary-dark" >
  <div class="flex-fill d-flex flex-nowrap align-items-center" [class.bg-light]="theme === 'light'">
    <div class="h-100 flex-fill">
      <div class="w-100 h-100 px-5 d-flex align-items-center justify-content-between text-light">
        <div *ngIf="title.length" (click)='onTitleClick()' [class.cursor-pointer]='titleRedirection.length' class="d-flex align-items-center">
          <app-page-icon *ngIf="!img && iconClass" [iconClass]="iconClass" [iconContainerStyle]="iconContainerStyle"></app-page-icon>
          <app-page-icon *ngIf="!iconClass && img" [img]="img"></app-page-icon>

          <h3 class="m-0 ml-3">{{ title }}</h3>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <div  *ngIf="showNotifs || showInitialBubble" class="d-flex align-items-center flex-nowrap mx-5">
    <div *ngIf="showNotifs" class="iconContainer">
      <i class="fas fa-bell mr-2 text-light cursor-pointer" (click)="router.navigate([PagesName.UserNotifications])" aria-hidden="true"></i>
      <span *ngIf="_.get(unreadNotificationsNumber, 'value') > 0" class="notif d-flex flex-column font-weight-bold align-items-center"
        ><span>{{ _.get(unreadNotificationsNumber, 'value')}}</span></span
      >
    </div>
    <div *ngIf="showInitialBubble" id='dropdown'  class="dropdown">
      <mims-initials-bubble
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        theme="dark"
        [fullName]="userName"
      ></mims-initials-bubble>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" >
        <div class="m-3 font-weight-bold">{{ userName }}</div>
        <a class="dropdown-item" [href]="'/' + PagesName.Account">Profile</a>
        <a class="dropdown-item" [class.disabled]="!permissions.accessLicenseManager" [href]="'/' + PagesName.LicenseManager"
          >Manage license</a
        >
        <a class="dropdown-item" (click)="logOut()">Log out</a>
        <div *ngIf="_.get(creditAlertsService, 'creditBudget.value') !== undefined" class="m-3 font-weight-bold text-center">
          <div class="badge badge-info p-3">{{ _.get(creditAlertsService, 'creditBudget.value') }} Credits</div>
        </div>
      </div>
    </div>
  </div>
</div>
