import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { LocalService } from 'src/app/api/local-storage.service';
import { PagesName } from '../../helpers/pagesName';
import _ from 'lodash';
import { NotificationsService } from 'src/app/api/notifications.service';
import { PermissionsHttpService } from '../../permissions-http-service/permissions-http.service';
import { CreditAlertsService } from 'src/app/pages/project-creation2/credit-alerts/credit-alerts.service';

@Component({
  selector: 'app-header2',
  templateUrl: './header2.component.html',
  styleUrls: ['./header2.component.scss'],
})
export class Header2Component implements OnInit, OnDestroy {
  @Input() theme?;
  @Input() showNotifs? = true;
  @Input() showInitialBubble? = true;
  @Input() title? = '';
  @Input() iconContainerStyle? = '';
  @Input() iconClass? = '';
  @Input() img? = '';
  @Input() titleRedirection? = [];

  userName = _.get(this.localService.getFromLocalStorage('user'), 'name');
  creditBudget = new BehaviorSubject<Number>(undefined);
  unreadNotificationsNumber = new BehaviorSubject<Number>(undefined);
  PagesName = PagesName;
  notificationSubscription: Subscription;
  _ = _;
  constructor(
    private l: LayoutOrganizationService,
    public router: Router,
    private localService: LocalService,
    private notificationsService: NotificationsService,
    public permissions: PermissionsHttpService,
    public creditAlertsService: CreditAlertsService
  ) {}

  ngOnInit() {
    if (this.notificationsService.userNotifications) {
      this.notificationSubscription = this.notificationsService.userNotifications.subscribe((notifications) => {
        this.updateNotificationsNumber(notifications);
      });
    }
  }
  onTitleClick() {
    if (this.titleRedirection) {
      this.router.navigate(this.titleRedirection);
    }
  }
  updateNotificationsNumber(notifications) {
    const unreadNotifs = _.reject(notifications, (o) => {
      return o.read === true;
    });
    this.unreadNotificationsNumber.next(Math.min(unreadNotifs.length, 99));
  }
  logOut(): void {
    this.l.toggleSideBar();
    localStorage.clear();
    this.router.navigate([PagesName.Login]).then(() => {
      window.location.reload();
    });
  }
  ngOnDestroy(): void {
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
  }
}
