<div>
  <div class="d-flex justify-content-between mb-3">
    <div class="d-flex">
      <div class="title font-weight-bold mr-2">Refine results</div>
      <sup *ngIf="getSelectedCategoriesLength() > 0" class="selected-categories-count">{{ getSelectedCategoriesLength()
        }}</sup>
    </div>
    <button class="all-button" (click)="handleAllResults()">All results</button>
  </div>
  <app-card>
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center mb-3">
        <button class="clear-button" (click)="handleClearCategorySelection()">Clear selection</button>
      </div>
      <!-- <div class="radio-filter-label">
        <p>or</p>
        <p>and</p>
        <p>not</p>
      </div> -->
    </div>
    <div class="categories-wrapper">
      <app-category-list *ngFor="let category of categories" [category]="category">
      </app-category-list>
    </div>
  </app-card>

  <div class="d-flex justify-content-between my-3">
    <div class="title font-weight-bold mr-2">Journals</div>
  </div>
  <app-card>
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center mb-3">
        <button class="clear-button" (click)="handleClearJournalSelection()">Clear selection</button>
      </div>
    </div>
    <div class="journal-list">
      <ul>
        <li *ngFor="let journal of journals">
          <div class="d-flex justify-content-between align-items-center mr-3">
            <label class="d-flex align-items-center" [attr.for]="journal[0]">
              <div class="d-flex">
                <div class="title">{{ journal[0] }}</div>
                <div class="count ml-2">{{ journal[1] }}</div>
              </div>
            </label>
            <div class="d-flex align-items-center">
              <div class="mr-2 ml-4">
                <input type="checkbox" [value]="journal[0]" [name]="journal[0]" [id]="journal[0]"
                    [(ngModel)]="this.knowledgeSearchService.selectedJournalFilters[journal[0]]" />
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </app-card>

  <div class="date-filter">
    <div class="date-filter-title">Date</div>
    <div class="date-filter-controls">
      <ngx-slider [(value)]="this.knowledgeSearchService.minDateFilterValue"
        [(highValue)]="this.knowledgeSearchService.maxDateFilterValue" [options]="options"></ngx-slider>
    </div>
  </div>
  <div class="refine-button-wrapper">
    <button class="refine-button" (click)="this.knowledgeSearchService.search()">Refine</button>
  </div>
</div>
