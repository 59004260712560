import { Component, Input } from '@angular/core';
import { GroupsManagerService } from 'src/app/api/groups-manager.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import * as _ from 'lodash';
import { FileGroupsService } from 'src/app/api/groups.service';
@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss', '../../../shared/layout/managers.scss']
})
export class GroupsComponent {
  @Input() sharedWithMe? = false
  @Input() marginBottom? = false
  @Input() groups? = []
  @Input() country?
  @Input() activeTab
  _=_

  constructor(public groupsManagerService: GroupsManagerService, public fileGroupsService: FileGroupsService, public layout: LayoutOrganizationService) { }

  trackByFn(index, item) {
    let metadata = !_.isEmpty(item.metadata) ? Object.keys(item.metadata).length : null
    return item.id + ',' + metadata + ',' + item.status
  }
  impossibleDropErrorMessage(event) {
    event.preventDefault()
    this.layout.toast("This file group is in <b>read-only </b> state. <b>Impossible to modify</b> its content", null, 5000, '', 'danger')
  }
  filteredGroup() {
    if (!this.sharedWithMe) {
      return _.filter(_.get(this, 'groups', []), (o) => { return o.owned_by_me })
    } else {
      return _.reject(_.get(this, 'groups', []), (o) => { return o.owned_by_me })
    }
  }

}
