export enum SortType {
  Title = 'title',
  Name = 'name',
  IsFolder = 'is_folder',
  DateCreation = 'date_of_creation',
  DateModification = 'date_of_modification',
  DateTrashing = 'date_of_Trashing',
  Author = 'UNVALID_field_name_for_the_moment',
  CompanyName = 'name',
  Type= 'license_type',
  Status = 'status'
}
