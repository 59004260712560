import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Status } from '../shared/helpers/status';
import { LayoutOrganizationService } from './layout-organization.service';
import { LocalService } from './local-storage.service';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root',
})
export class MulticountryService {
  status: Status = Status.NotStarted;
  Status = Status;
  constructor(private layout: LayoutOrganizationService, private localService: LocalService) {}

  asyncListing(functionExecution: Function, params, countryParameter): Observable<any> {
    let countries = [];
    switch (countryParameter) {
      case 'all':
        countries = this.localService.getAvailableCountries();
        break;
      case countryParameter.isArray():
        countries = countryParameter;
        break;
      default:
        countries.push(countryParameter);
    }
    let completedArray = countries.map(() => false);
    return new Observable((observer) => {
      countries.forEach((country, index) => {
        let parameters = params.length? _.cloneDeep(params) : []
        parameters.push(country);
        functionExecution(...parameters)
          .pipe(
            finalize(() => {
              completedArray[index] = true;
              if (completedArray.every((el) => el)) {
                observer.complete();
              }
            })
          )
          .subscribe(
            (response) => {
              if (!_.isArray(response)){
              _.set(response, 'country', country);
              }else{
                response.forEach((el) => {
                  _.set(el, 'country', country);
                });
              }
              if (!_.isArray(response) && _.get(response, 'data') && _.get(response, 'count')) {
                  _.get(response, 'data').forEach((el) => {
                    _.set(el, 'country', country);
                })
              }
              observer.next(response);
            },
            (error) => {
              this.layout.toast('Error with country : ' + country, null, 8000, '', 'danger');
            }
          );
      });
    });
  }
}
