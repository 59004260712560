<div *ngIf="item">
    <span *ngIf='!getCanModify()'>{{inputField.value}}</span>
    <form *ngIf='getCanModify()' [formGroup]="renameFormGroup">
        <div (focusout)="handleInput()" class="input-icon-container d-flex align-items-center flex-nowrap">
            <input (keydown)=listenKeyEvent($event) class="value-input" type="text" formControlName="inputField" />
            <i class="user-icon far fa-edit" aria-hidden="true"></i>
        </div>
        <small>
            <span class='text-danger' *ngIf="!inputField.valid && (inputField.dirty || inputField.touched)">
                This field is required.
            </span>
        </small>
    </form>
</div>