import { WorkflowDebuggerAuthGuard } from './modules/workflow-debugger/services/workflow-debugger-auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { SolutionDocComponent } from './pages/documentation/solution/solution.component';
import { RunsDashboardComponent } from './pages/runs-dashboard/runs-dashboard.component';
import { ProjectManagerComponent } from './pages/project-manager/project-manager.component';
import { LoginAuthGuard } from './pages/login/login-auth-guard/login-auth.guard';
import { LoggedInLayoutComponent } from './shared/logged-in-layout/logged-in-layout.component';
import { UserCreationComponent } from './pages/user-creation/user-creation.component';
import { AccountComponent } from './pages/account/account.component';
import { CanDeactivateProjectGuard } from './pages/project-creation2/can-deactivate.guard';
import { UserCreationAuthGuard } from './pages/user-creation/user-creation-auth-guard/user-creation-auth.guard';

import { NotFoundComponent } from './shared/not-found/not-found.component';
import { ProjectOverviewNewComponent } from './pages/project-overview-demo/project-overview-new.component';
import { TabsName } from '../app/shared/helpers/tabsName';
import { PagesName } from '../app/shared/helpers/pagesName';
import { TeamsManagerComponent } from './pages/teams-manager/teams-manager.component';
import { TeamAuthGuard } from './pages/teams-manager/team-manager/team-auth.guard';
import { NewAccountComponent } from './pages/new-account/new-account.component';
import { WorkflowAuthGuard } from './modules/work-flow/workflow-auth.guard';
import { UserNotificationsPageComponent } from './pages/user-notifications-page/user-notifications-page.component';
import { DlProxyComponent } from './modules/shared/proxy/dl-proxy.component';
import { KnowledgeSearchComponent } from './pages/knowledge-search/knowledge-search.component';
import { KnowledgeSearchAuthGuard } from './pages/knowledge-search/knowledge-search-auth.guard';
import { FileAuthGuard } from './pages/file-manager2/file-auth.guard';
import { ProjectAuthGuard } from './pages/project-manager/project-auth.guard';
import { NotifAuthGuard } from './pages/user-notifications-page/notif-auth.guard';
import { InputFieldTestComponent } from './shared/devtest/input-field-test/input-field-test.component';
import { Login2Component } from './pages/login/login2.component';
import { FileManager2Component } from './pages/file-manager2/file-manager2.component';
import { ProjectOverviewComponent } from './pages/project-overview2/project-overview.component';
import { RunsDashboardAuthGuard } from './pages/runs-dashboard/runs-dashboard-auth-guard/runs-dashboard-auth.guard';
import { GroupCreationFormComponent } from './pages/file-manager2/group-creation-form/group-creation-form.component';
import { DataManagerComponent } from './pages/data-manager/data-manager.component';
import { DataAuthGuard } from './pages/data-manager/data-auth.guard';
import { LicensesComponent } from './pages/licenses/licenses.component';
import { LicenseDetailsComponent } from './pages/licenses/license-details/license-details.component';
import { ProjectCreation2Component } from './pages/project-creation2/project-creation2.component';
import { ProjectCreationComponent } from './pages/project-creation/project-creation.component';
import { ProjectCreationFormComponent } from './pages/project-creation/project-creation-form/project-creation-form.component';
import { AvailableWorkflowsComponent } from './pages/project-creation/available-workflows/available-workflows.component';
import { PreselectedWorkflowComponent } from './pages/project-creation/preselected-workflow/preselected-workflow.component';
import { ProjectConfigurationComponent } from './pages/project-creation/project-configuration/project-configuration.component';
import { WorkflowInProgressComponent } from './pages/project-creation/workflow-in-progress/workflow-in-progress.component';

const routes: Routes = [
  // component logged in layout contains all site pages, is guarded by authguard.
  {
    path: '',
    canActivate: [LoginAuthGuard],
    component: LoggedInLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: PagesName.Home,
        pathMatch: 'full',
      },
      {
        path: PagesName.Home,
        component: HomepageComponent,
      },
      {
        path: 'inputs',
        component: InputFieldTestComponent,
      },
      {
        path: PagesName.KnowledgeSearch + '/:tab',
        component: KnowledgeSearchComponent,
        canActivate: [KnowledgeSearchAuthGuard],
      },
      {
        path: PagesName.KnowledgeSearch + '/:tab' + '/edit',
        component: KnowledgeSearchComponent,
        canActivate: [KnowledgeSearchAuthGuard],
      },
      {
        path: PagesName.KnowledgeSearch + '/:tab' + '/:formulation',
        component: KnowledgeSearchComponent,
        canActivate: [KnowledgeSearchAuthGuard],
      },
      {
        path: PagesName.KnowledgeSearch + '/:tab' + '/:formulation' + '/edit',
        component: KnowledgeSearchComponent,
        canActivate: [KnowledgeSearchAuthGuard],
      },
      {
        path: PagesName.KnowledgeSearch + '/:tab' + '/:formulation' + '/results',
        component: KnowledgeSearchComponent,
        canActivate: [KnowledgeSearchAuthGuard],
      },
      {
        path: PagesName.KnowledgeSearch + '/:tab' + '/:formulation' + '/results' + '/edit',
        component: KnowledgeSearchComponent,
        canActivate: [KnowledgeSearchAuthGuard],
      },
      {
        path: PagesName.ProjectCreation + '/:solution',
        component: ProjectCreation2Component,
        canDeactivate: [CanDeactivateProjectGuard],
      },
      {
        path: PagesName.RunsDashboard,
        component: RunsDashboardComponent,
        canActivate: [RunsDashboardAuthGuard],
      },
      {
        path: PagesName.ProjectManager,
        redirectTo: PagesName.ProjectManager + '/' + TabsName.MyProjects,
        pathMatch: 'full',
      },
      {
        path: PagesName.ProjectCreationFromWF,
        component: ProjectCreationComponent,
        children: [
          { path: '', redirectTo: PagesName.DefineProject, pathMatch: 'full' },
          {
            path: PagesName.DefineProject,
            component: ProjectCreationFormComponent,
            canDeactivate: [CanDeactivateProjectGuard],
          },
          {
            path: PagesName.DefineProject + '/:solution',
            component: ProjectCreationFormComponent,
            canDeactivate: [CanDeactivateProjectGuard],
          },
          {
            path: PagesName.DefineProject + '/:projectId' + '/:country',
            component: ProjectCreationFormComponent,
            canDeactivate: [CanDeactivateProjectGuard],
          },
          {
            path: PagesName.ChooseWorkflow + '/:projectId' + '/:country',
            component: AvailableWorkflowsComponent,
            canDeactivate: [CanDeactivateProjectGuard],
          },
          {
            path: PagesName.ChooseWorkflow + '/:projectId' + '/:workflowId' + '/:country',
            component: PreselectedWorkflowComponent,
            canDeactivate: [CanDeactivateProjectGuard],
          },
          {
            path: PagesName.ConfigureWorkflow + '/:projectId' + '/:workflowId' + '/:country',
            component: ProjectConfigurationComponent,
            canDeactivate: [CanDeactivateProjectGuard],
          },
          {
            path: PagesName.WorkflowInProgress + '/:projectId' + '/:country',
            component: WorkflowInProgressComponent,
          },
        ],
      },
      {
        path: 'explore-files',
        component: GroupCreationFormComponent,
      },
      {
        path: 'doc/solution' + '/:solutionId',
        component: SolutionDocComponent,
      },
      {
        path: PagesName.ProjectManager + '/:viewTab',
        component: ProjectManagerComponent,
        canActivate: [ProjectAuthGuard],
      },
      {
        path: PagesName.ProjectManager + '/:viewTab/:id/:country' + '/' + PagesName.ProjectOverview,
        component: ProjectOverviewComponent,
        canActivate: [ProjectAuthGuard],
      },
      {
        path: PagesName.FileManager,
        component: FileManager2Component,
        canActivate: [FileAuthGuard],
      },
      {
        path: PagesName.DataManager,
        component: DataManagerComponent,
        canActivate: [DataAuthGuard],
      },
      { path: PagesName.FileManager + ':id/dl', component: DlProxyComponent },
      { path: 'project-overview-new', component: ProjectOverviewNewComponent },
      { path: PagesName.UserNotifications, component: UserNotificationsPageComponent, canActivate: [NotifAuthGuard] },
      {
        path: PagesName.TeamsManager,
        component: TeamsManagerComponent,
        canActivate: [TeamAuthGuard],
      },
      {
        path: PagesName.LicenseManager,
        component: LicensesComponent,
        canActivate: [UserCreationAuthGuard],
      },
      {
        path: PagesName.LicenseManager + '/' + PagesName.LicenseDetails,
        component: LicenseDetailsComponent,
        canActivate: [UserCreationAuthGuard],
      },
      {
        path: PagesName.LicenseManager + '/' + PagesName.LicenseDetails + '/:id',
        component: LicenseDetailsComponent,
        canActivate: [UserCreationAuthGuard],
      },
      {
        path: PagesName.UserCreation,
        component: UserCreationComponent,
        canActivate: [UserCreationAuthGuard],
      },
      { path: PagesName.Account, component: AccountComponent },
      { path: 'explorer', component: HomepageComponent },
      {
        path: PagesName.Workflow,
        loadChildren: () => import('./modules/work-flow/work-flow.module').then((m) => m.WorkFlowModule),
        canActivate: [WorkflowAuthGuard],
      },
      {
        path: PagesName.WorkflowDebugger,
        loadChildren: () => import('./modules/workflow-debugger/workflow-debugger.module').then((m) => m.WorkflowDebuggerModule),
        canActivate: [WorkflowDebuggerAuthGuard],
      },
      {
        component: ProjectManagerComponent,
        canActivate: [ProjectAuthGuard],
        path: PagesName.ProjectManager + '/' + TabsName.MyProjects + '/:gene',
      },
    ],
    // runGuardsAndResolvers: 'always'
  },
  { path: PagesName.Login, component: Login2Component },
  {
    path: PagesName.NewAccount + '/:xAccessToken',
    component: NewAccountComponent,
  },
  {
    path: PagesName.ResetPassword + '/:xAccessToken',
    component: NewAccountComponent,
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
