<div class="page d-flex flex-column">
  <app-header2 [title]="PagesPublicName.RunsDashboard" iconClass='fas fa-poll-h'>
  </app-header2>
  <div class="runs-container" #runscontainer >
    <table aria-describedby="runs table" class="table table-bordered table-striped">

      <thead class="thead-dark">
        <tr >
          <th *ngFor="let column of columns" scope="{{ column.columnDef }}">{{ column.header }}</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let run of runs">
          <td>
            <div *ngIf="run.shared">
              <a href="/project-manager/shared-projects/{{run.key}}/{{run.env}}/overview"> <p class="pcenter"> {{ run.title }} </p></a>
            </div>
            <div *ngIf="!run.shared">
              <p class="pcenter"> {{ run.title }} </p>
            </div>
          </td>
          <td>
            <p > {{ run.description }} </p>
          </td>
          <td>
            <p class="pcenter"> {{ run.wf_name }} </p>
          </td>
          <td>
            <p class="pcenter"> {{ run.wf_version }} </p>
          </td>
          <td>
            <p class="pcenter">
              <a href="mailto:s{{ run.email }}">{{ run.email }}</a>
            </p>
          </td>
          <td>
            <p class="pcenter"> {{ run.start }} </p>
          </td>
          <td>
            <p class="pcenter"> {{ run.end }} </p>
          </td>
          <td>
            <p tooltipClass="custom-tooltip" disabled="!run.shared"
              [ngClass]="run.shared ? 'pcenter fas fa-project-diagram' : 'pcenterdisable fas fa-project-diagram'"
              [ngbTooltip]="run.shared ? 'See workflow progression' : 'Not shared'"
              (click)="openModal(run.key, run.shared)" ></p>
          </td>
          <td>
            <div *ngIf="run.status === 'failed' ">
              <i class="fas fa-exclamation-triangle" ngbTooltip="failed" tooltipClass="custom-tooltip" aria-hidden="true"></i>
            </div>
            <div *ngIf="run.status === 'done' ">
              <i class="fas fa-check" ngbTooltip="done" tooltipClass="custom-tooltip" aria-hidden="true"></i>
            </div>
            <div *ngIf="run.status === 'cancelled' ">
              <i class="fas fa-times" ngbTooltip="cancelled" tooltipClass="custom-tooltip" aria-hidden="true"></i>
            </div>
            <div *ngIf="run.status === 'in_treatment'">
              <i class="fad fa-spinner" ngbTooltip="in_treatment" tooltipClass="custom-tooltip" aria-hidden="true"></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-custom-modal modalTitle="Progression" idName="modalProgress" [show]="layout.isProgressModalOpen">
  <div class="custom-modal-content">
    <app-progress-form></app-progress-form>
  </div>
</app-custom-modal>
