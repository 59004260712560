import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import moment from 'moment';
import { Status } from 'src/app/shared/helpers/status';
import { availableFeatures } from 'src/environments/environment';
import { Features } from 'src/environments/features';
import { ItemAction } from '../file-manager2.service';
import { ItemType } from 'src/app/shared/helpers/itemType';

@Component({
  selector: 'mims-list-item',
  templateUrl: './file2.component.html',
  styleUrls: ['./file2.component.scss'],
})
export class File2Component implements OnInit {
  @Input() item;
  @Input() navigation;
  @Input() architectureEdition;
  @Input() selected;
  @Input() highlighted;
  @Output() itemAction: EventEmitter<any> = new EventEmitter<any>();
  Status = Status;
  FileType = ItemType;
  moment = moment;
  ItemAction = ItemAction;
  isRecent;
  iconText;
  _ = _;
  ItemType = ItemType;

  drag(event) {
    // event.dataTransfer.setData('shortItem', this.shortItem);
  }
  getType() {
    if (this.get('is_folder')) return ItemType.Folder;
    else return ItemType.File;
  }
  ngOnInit(): void {
    this.isRecent = this.checkIfRecent(60 * 10);
    if (this.isRecent) {
      setInterval(() => {
        this.isRecent = this.checkIfRecent(60 * 10);
      }, 60);
    }
  }

  checkIfRecent(value) {
    return moment().unix() - _.get(this.item, 'date_of_creation') < value;
  }

  get(variableName) {
    return _.get(this.item, variableName);
  }
  getTooltip(action: ItemAction) {
    switch (action) {
      case ItemAction.Download:
        if (!this.canPerform(ItemAction.Download)) return "You don't have the permission to download";
        if (this.canPerform(ItemAction.Download) && this.getType() === ItemType.Folder) return 'You can´t download folder';
        break;
      case ItemAction.Trash:
        if (!this.canPerform(ItemAction.Trash)) return "You don't have the permission to trash";
        break;
      case ItemAction.SelectShare:
        if (!this.canPerform(ItemAction.SelectShare)) return "You don't have the permission to share";
        break;
      default:
        return '';
    }
  }
  getIconText(action: ItemAction) {
    switch (action) {
      case ItemAction.Download:
        return 'Download item';
      case ItemAction.SelectShare:
        return 'Share item';
      case ItemAction.Info:
        return 'Read description';
      case ItemAction.Trash:
        return 'Trash item';
      case ItemAction.Quality:
        return 'Show quality dashboard';
      default:
        return '';
    }
  }
  setIconText(value) {
    this.iconText = value;
  }
  canPerform(action: ItemAction) {
    switch (action) {
      case ItemAction.OpenFolder:
        if (this.getType() === ItemType.Folder && this.navigation) return true;
        break;
      case ItemAction.Download:
        if (this.get('capabilities.can_download') && this.getType() === ItemType.File) return true;
        break;
      case ItemAction.MoveToFolder:
        if (this.get('capabilities.can_move') && this.architectureEdition) return true;
        break;
      case ItemAction.Modify:
        if (this.get('capabilities.can_modify')) return true;
        break;
      case ItemAction.Info:
        return true;
      case ItemAction.Trash:
        if (this.get('capabilities.can_trash') && _.includes(availableFeatures, Features.DeleteFile)) return true;
        break;
      case ItemAction.SelectShare:
        if (this.get('capabilities.can_share')) return true;
        break;
      case ItemAction.Quality:
        if (this.getType() === ItemType.File && this.get('metadata') ) return true;
        break;
      default:
        return false;
    }
  }
}