import { HttpClient } from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { Configuration } from './configuration';

import { ProjectsService } from './api/projects.service';
import { ExplorerService } from './api/explorer.service';
import { ExplorerManagerService } from './api/explorer-manager.service';
import { DashboardService } from './api/dashboard.service';
import { ShareService } from './api/share.service';
import { ProjectManagerService } from './api/project-manager.service';
import { VizService } from './api/viz.service';
import { GroupsManagerService } from './api/groups-manager.service';
import { TeamsService } from './pages/teams-manager/teams.service';
import { LocalService } from './api/local-storage.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Dashboard2Service } from './pages/project-overview2/dashboard2.service';
import { KnowledgeSearchService } from './pages/knowledge-search/knowledge-search.service';
import { FileManager2Service } from './pages/file-manager2/file-manager2.service';
import { MulticountryService } from './api/multicountry.service';
import { Files2Service } from './api/files2.service';
import { InfiniteListingService } from './api/infiniteListing.service';
import { AtlassianHttpService } from './api/atlassian.service';
import { CookieModule } from 'ngx-cookie';
import { CreditAlertsService } from "./pages/project-creation2/credit-alerts/credit-alerts.service"
import { SolutionService } from './pages/solution-manager/solution.service';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CookieModule.withOptions() 
  ],
  declarations: [],
  exports: [],
  providers: [
    ProjectsService,
    DashboardService,
    Dashboard2Service,
    ExplorerManagerService,
    ExplorerService,
    ShareService,
    ProjectManagerService,
    VizService,
    GroupsManagerService,
    TeamsService,
    LocalService,
    KnowledgeSearchService,
    FileManager2Service,
    Files2Service,
    MulticountryService,
    InfiniteListingService,
    AtlassianHttpService,
    CreditAlertsService,
    SolutionService,
  ]
})
export class ApiModule {
  static forRoot(
    configurationFactory: () => Configuration
  ): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    };
  }

  constructor(
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import in your base AppModule only.'
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
        'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
