<div class='d-flex my-4'>
  <div class='d-flex flex-column'>
    <div class='btn border btn-light mb-3 mx-3' (click)="backToTeams()"><i
      class="fas mx-2 fa-arrow-to-left" aria-hidden="true"></i> Back to teams</div>
    <div class='btn bg-dark text-light mb-3 mx-3' (click)="layout.open('modalMemberAddition')"><i
        class="fas mx-2 fa-plus" aria-hidden="true"></i> Add existing
      member</div>
    <div class='btn bg-dark text-light mb-3 mx-3' (click)="layout.open('modalNewMemberAddition')"><i
        class=" fas mx-2 fa-plus" aria-hidden="true"></i> Invite new user
    </div>
  </div>
  <div class='d-flex flex-column flex-grow-1 mr-3'>
    <div class="table-responsive">
      <table aria-describedby="team content table" class="table table-hover w-100 mx-auto table-striped">
        <thead>
          <tr>
            <th [scope]="header? header : index"
              *ngFor="let header of ['', 'Email', 'Role']; let first = first, let index = index"
              [class.small-col]='first'>
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class='cursor-pointer'
            *ngFor="let user of _.get(formattedSelectedTeam, 'users', []) ;trackBy : trackFn">
            <td (click)='openUserCard(user)' class='align-middle'>
              <i class='far mx-2  fa-user' aria-hidden="true"></i>
            </td>
            <td (click)='openUserCard(user)' class='align-middle'>
              {{ _.get(user, 'email', '') }}
            </td>

            <td (click)='openUserCard(user)' class='align-middle'>
              {{  _.get(user, 'role', '') }}
            </td>


          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<app-custom-modal modalTitle="Enter a member's ID" idName="modalMemberAddition"
  [show]="layout.isMemberAdditionModalOpen" confirmTitle="Save" [confirmActions]="[handleAddUserById,resetMemberAdditionForm]"
  [form]="memberAdditionForm" [cancelActions]="[resetMemberAdditionForm]">
  <div class="custom-modal-content">
    <app-member-addition-form [(form)]='memberAdditionForm'></app-member-addition-form>
  </div>

</app-custom-modal>
<app-custom-modal modalTitle="Invite a new user" idName="modalNewMemberAddition"
  [show]="layout.isNewMemberAdditionModalOpen" confirmTitle="Save" [confirmActions]="[handleInvitation, resetNewMemberAdditionForm]"
  confirmTooltip='Please verify your form.' [form]="newMemberAdditionForm" [cancelActions]="[resetNewMemberAdditionForm]">
  <div class="custom-modal-content">
    <app-new-member-addition-form [(form)]='newMemberAdditionForm' *ngIf='layout.isNewMemberAdditionModalOpen'></app-new-member-addition-form>
  </div>

</app-custom-modal>
<app-custom-modal idName="modalUserCard" [show]="layout.isUserCardOpen" confirmTitle="Ok" [confirmActions]="[]"
  [confirmValidator]='true' [buttons]='false' [cancelActions]="[]">

  <app-user-card2  *ngIf='selectedUser'  [hidden]='!layout.isUserCardOpen'  (userEvent)='userEvent.emit($event)' [selectedTeam]='selectedTeam'
    [selectedUser]='selectedUser' [formattedSelectedUser]='formattedSelectedUser'>
  </app-user-card2>
</app-custom-modal>
