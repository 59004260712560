import { Injectable, Injector, ComponentFactoryResolver, ApplicationRef, ComponentRef, EmbeddedViewRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TabsName } from '../shared/helpers/tabsName';
import { ToastComponent } from '../shared/toast/toast.component';
import * as _ from 'lodash';

export function constructObject(defaultValue, defaultValue2?, defaultValue3?) {
  let newVariable = {};
  _.set(newVariable, TabsName.MyFiles, defaultValue);
  _.set(newVariable, TabsName.SharedFiles, defaultValue2 ? defaultValue2 : defaultValue);
  _.set(newVariable, TabsName.DeletedFiles, defaultValue3 ? defaultValue3 : defaultValue);
  return newVariable;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutOrganizationService {
  public sidebarHidden = false;
  public isVizModalOpen = false;
  public isLegendModalOpen = false;
  public isRerunModalOpen = false;

  public isWfDescriptionOpen = false;
  public isSideBarOpen = false;
  public isFileModalOpen = false;
  public isFolderModalOpen = false;
  public isGroupModalOpen = false;

  public isMetaDataModalOpen = false;
  public isOverlayOpen = false;
  public isSideBarOverlayOpen = false;
  public isConfirmModalOverlayOpen = false;
  public isBlurryOverlayOpen = false;
  public isManageSubscriptionOpen = false;

  public isProjectInfosModalOpen = false;
  public isProjectShareModalOpen = false;
  public isGroupShareModalOpen = false;

  public isMemberAdditionModalOpen = false;
  public isNewMemberAdditionModalOpen = false;

  public isUserCardOpen = false;

  public isFilesShareModalOpen = false;
  public isFilesInfosModalOpen = false;
  public isErrorModalOpen = false;
  public isFilesInfosModalMobileOpen = false;

  public hover = false;
  public mobile = false;
  public tablet = false;
  public desktop = false;

  public unreadNotifs = 0;
  public isTopBarOpen = false;
  public isTopNavOpen = false;

  public explorerIsOpen = false;
  public explorerIsExpanded = false;

  public isQuerySettingModalOpen = false;

  public isProgressModalOpen = false;
  public isProjectExecuteModalOpen = false;


  public isConfirmOpen = false;
  public confirmMessage = '';
  public cancelCallback = [];
  public confirmCallback = [];
  toastContainer;

  public isModalMoveOpen = false
  public isModalMoveToGroupOpen = false
  public fmPannelIsOpened = constructObject(false);

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector) {}

  toast(content, date, dataDelay, title, color?, onClose?, show?, uploadingFiles?) {
    if (this.toastContainer) {
      let appRef;
      appRef = this.createComponent(ToastComponent, {
        content: content,
        date: date,
        dataDelay: dataDelay,
        title: title,
        color: color || null,
        onClose: onClose || null,
        show: show || new BehaviorSubject<boolean>(true),
        uploadingFiles : uploadingFiles
      });

      this.attachComponent(appRef, this.toastContainer.nativeElement);

      if (dataDelay)
        setTimeout(() => {
          if (appRef) {
            this.appRef.detachView(appRef.hostView);
            appRef.destroy();
          }
        }, dataDelay + 1000);
      return appRef;
    }
  }

  createComponent(component: any, componentProps?: object) {
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);
    if (componentProps && typeof componentRef.instance === 'object') {
      Object.assign(componentRef.instance, componentProps);
    }
    return componentRef;
  }

  attachComponent(componentRef: ComponentRef<unknown>, appendTo: Element) {
    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    appendTo.appendChild(domElem);
    return;
  }
  destroyComponent(appRef) {
    this.appRef.detachView(appRef.hostView);
    appRef.destroy();
  }

  switchExplorerOpenStatus() {
    this.explorerIsOpen = !this.explorerIsOpen;
  }
  switchExplorerExpansion() {
    this.explorerIsExpanded = !this.explorerIsExpanded;
  }

  toggleSideBar() {
    if (this.isSideBarOpen) {
      this.close('sidebar');
    } else {
      this.open('sidebar');
    }
    // this.isSideBarOpen = !this.isSideBarOpen;
  }

  customConfirm(message, functionName, cancelCallback?) {
    this.confirmCallback = [];
    this.cancelCallback = [];
    this.confirmMessage = message;
    this.confirmCallback.push(functionName);
    if (cancelCallback) {
      this.cancelCallback.push(cancelCallback);
    }
    this.isConfirmOpen = true;
    this.isConfirmModalOverlayOpen = true;
  }
  close(id?) {
    if (id) {
      switch (id) {
        case 'modalGroupMove':
          this.isModalMoveToGroupOpen = false
          this.isOverlayOpen = false
          break;
        case 'modalMove':
          this.isModalMoveOpen = false;
          this.isOverlayOpen = false;
          break;
        case 'modalQuerySettings':
          this.isQuerySettingModalOpen = false;
          this.isOverlayOpen = false;
          break;
        case 'modalLegend':
          this.isLegendModalOpen = false;
          this.isBlurryOverlayOpen = false;
          break;
        case 'modalRerun':
          this.isRerunModalOpen = false;
          this.isOverlayOpen = false;
          break;          
        case 'modalManageSubscription':
          this.isManageSubscriptionOpen = false;
          this.isOverlayOpen = false;
          break;
        case 'modalUserCard':
          this.isUserCardOpen = false;
          break;
        case 'modalWfDescription':
          this.isWfDescriptionOpen = false;
          this.isOverlayOpen = false;
          break;
        case 'modalMemberAddition':
          this.isMemberAdditionModalOpen = false;
          this.isOverlayOpen = false;
          break;
        case 'modalNewMemberAddition':
          this.isNewMemberAdditionModalOpen = false;
          this.isOverlayOpen = false;
          break;

        case 'modalViz':
          this.isVizModalOpen = false;
          this.isBlurryOverlayOpen = false;
          break;
        case 'modalConfirm':
          this.isConfirmOpen = false;
          this.isConfirmModalOverlayOpen = false;
          break;
        case 'modalError':
          this.isErrorModalOpen = false;
          this.isOverlayOpen = false;
          break;
        case 'modalMetaData':
          this.isMetaDataModalOpen = false;
          this.isOverlayOpen = false;
          break;
        case 'modalFile':
          this.isFileModalOpen = false;
          this.isOverlayOpen = false;
          break;
        case 'modalFolder':
          this.isFolderModalOpen = false;
          this.isOverlayOpen = false;
          break;
        case 'modalProgress':
          this.isProgressModalOpen = false;
          this.isOverlayOpen = false;
          break;
        case 'modalProjectExecute':
          this.isProjectExecuteModalOpen = false;
          this.isOverlayOpen = false;
          break;
        case 'modalFilesInfos':
          this.isFilesInfosModalOpen = false;
          break;
        case 'modalFilesShare':
          this.isFilesShareModalOpen = false;
          break;
        case 'modalProjectsInfos':
          this.isProjectInfosModalOpen = false;
          break;
        case 'modalProjectsShare':
          this.isProjectShareModalOpen = false;
          break;
        case 'modalGroupsShare':
          this.isGroupShareModalOpen = false;
          break;
        case 'modalFilesInfosMobile':
          this.isFilesInfosModalMobileOpen = false;
          break;
        case 'modalGroup':
          this.isGroupModalOpen = false;
          this.isOverlayOpen = false;
          break;
        case 'sidebar':
          this.isSideBarOpen = false;
          this.isSideBarOverlayOpen = false;
          break;
      }
    } else {
      this.isModalMoveToGroupOpen = false;
      this.isFilesInfosModalOpen = false;
      this.isProjectInfosModalOpen = false;
      this.isProjectShareModalOpen = false;
      this.isGroupShareModalOpen = false;
      this.isFilesInfosModalMobileOpen = false;
      this.isFilesShareModalOpen = false;
      this.isSideBarOverlayOpen = false;
      this.isQuerySettingModalOpen = false;
      this.isLegendModalOpen = false;
      this.isRerunModalOpen = false;
      this.isManageSubscriptionOpen = false;
      this.isMemberAdditionModalOpen = false;
      this.isVizModalOpen = false;
      this.isConfirmOpen = false;
      this.isGroupModalOpen = false;
      this.isFileModalOpen = false;
      this.isMetaDataModalOpen = false;
      this.isFolderModalOpen = false;
      this.isBlurryOverlayOpen = false;
      this.isWfDescriptionOpen = false;
      this.isNewMemberAdditionModalOpen = false;
      this.isUserCardOpen = false;
      this.isGroupShareModalOpen = false;
      this.isConfirmModalOverlayOpen = false;
      this.isModalMoveOpen = false
      this.isOverlayOpen = false;
    }
  }
  open(id) {
    setTimeout(() => {
      switch (id) {
        case 'modalGroupMove':
          this.isModalMoveToGroupOpen = true;
          this.isOverlayOpen = true;
          break
        case 'modalMove':
          this.isModalMoveOpen = true;
          this.isOverlayOpen = true;
          break;
        case 'modalQuerySettings':
          this.isQuerySettingModalOpen = true;
          this.isOverlayOpen = true;
          break;
        case 'modalLegend':
          this.isLegendModalOpen = true;
          this.isBlurryOverlayOpen = true;
          break;
        case 'modalRerun':
          this.isRerunModalOpen = true;
          this.isOverlayOpen = true;
          break;          
        case 'modalManageSubscription':
          this.isManageSubscriptionOpen = true;
          this.isOverlayOpen = true;
          break;
        case 'modalUserCard':
          this.isUserCardOpen = true;
          break;
        case 'modalNewMemberAddition':
          this.isNewMemberAdditionModalOpen = true;
          this.isOverlayOpen = true;
          break;
        case 'modalWfDescription':
          this.isWfDescriptionOpen = true;
          this.isOverlayOpen = true;
          break;
        case 'modalMemberAddition':
          this.isMemberAdditionModalOpen = true;
          this.isOverlayOpen = true;
          break;
        case 'modalViz':
          this.isVizModalOpen = true;
          this.isBlurryOverlayOpen = true;
          break;
        case 'modalConfirm':
          this.isConfirmOpen = true;
          this.isConfirmModalOverlayOpen = true;
          break;
        case 'modalError':
          this.isErrorModalOpen = true;
          this.isOverlayOpen = true;
          break;
        case 'modalMetaData':
          this.isMetaDataModalOpen = true;
          this.isOverlayOpen = true;
          break;
        case 'modalFile':
          this.isFileModalOpen = true;
          this.isOverlayOpen = true;
          break;
        case 'modalFolder':
          this.isFolderModalOpen = true;
          this.isOverlayOpen = true;
          break;
        case 'modalProgress':
          this.isProgressModalOpen = true;
          this.isOverlayOpen = true;
          break;
        case 'modalProjectExecute':
          this.isProjectExecuteModalOpen = true;
          this.isOverlayOpen = true;
          break;          
        case 'modalFilesInfos':
          this.isFilesInfosModalOpen = true;
          break;
        case 'modalFilesShare':
          this.isFilesShareModalOpen = true;
          break;
        case 'modalProjectsInfos':
          this.isProjectInfosModalOpen = true;
          break;
        case 'modalProjectsShare':
          this.isProjectShareModalOpen = true;
          break;
        case 'modalGroupsShare':
          this.isGroupShareModalOpen = true;
          break;
        case 'modalFilesInfosMobile':
          this.isFilesInfosModalMobileOpen = true;
          break;
        case 'sidebar':
          this.isSideBarOpen = true;
          this.isSideBarOverlayOpen = true;
          break;
        case 'modalGroup':
          this.isGroupModalOpen = true;
          this.isOverlayOpen = true;
          break;
      }
    }, 150);
  }
}
