<div class="list-group-item scrollable h-100" id={{item.id}}>
  <!-- <div class='d-flex justify-content-between flex-nowrap'>
   
   <div *ngIf='item.is_shared' class='d-flex align-items-center' ngbTooltip="This project is shared."
      tooltipClass="custom-tooltip" placement="left">
      <i aria-hidden="true" class="text-primary fal fa-user-friends"></i>
    </div>
    <div *ngIf='!item.is_shared' class='d-flex align-items-center' ngbTooltip="This project isn't shared."
      tooltipClass="custom-tooltip" placement="left">
      <i aria-hidden="true" class="fal text-muted fa-user-friends"></i>
    </div> 
  </div> -->
  <ul class='mt-3'>

    <small>
      <li class='my-4  text-uppercase'>
        <div class='my-4  text-uppercase text-muted'>name of project : </div>
        <div *ngIf="!_.get(item, 'capabilities.can_modify')">{{_.get(item,"title")}}</div>
        <div>
          <app-rename-2 *ngIf="_.get(item, 'capabilities.can_modify')" [value]="_.get(item,'title')" [itemType]="ItemType.Project"
            (valueChanged)="projectManagerService.itemActionEvent.emit([item, ProjectItemAction.Rename, undefined, $event])"
            class='h5 font-weight-bold smooth-border'>
          </app-rename-2>
        </div>
      </li>

      <li *ngIf='getFileGroupName()' class='my-4  text-uppercase'><span class='text-muted '>Filegroup input :
        </span><span class='font-weight-bold'>{{getFileGroupName()}}</span></li>

      <li *ngIf='getWorkflowName()' class='my-4 text-uppercase'><span class='text-muted '> Selected Solution :
        </span><span class='font-weight-bold'>
          {{getWorkflowName()}}</span>
        <ul class='details'>
          <li *ngIf='isServier()' class='my-4 text-uppercase'>
            <span class='text-muted '>
              Documentation : </span>
            <i aria-hidden="true" class="far mx-1 fa-arrow-alt-to-bottom"></i>
            <span ngbTooltip="Click to download" tooltipClass="custom-tooltip" placement="bottom"
             >
              <a href="../../../../assets/docs/PopSeg_Solution_21122021/solution.pdf" download>Solution.pdf</a>
            </span>
          </li>
          <li *ngFor='let parameter of parameters' class='my-4 text-uppercase'>
            <span class='text-muted '>
              {{getName(parameter)}} :
            </span>
            <span class='font-weight-bold'>{{getInputValue(parameter)}}</span>
          </li>
        </ul>
      </li>
      <li *ngIf='filteredSubjects.value !== null' class='my-4 text-uppercase'><span class='text-muted '>
          Number of selected subjects : </span><span class='font-weight-bold'>
          {{filteredSubjects.value}}</span>
      </li>

      <li *ngIf='getStatus()' class='my-4  text-uppercase'><span class='text-muted '>status : </span><span
          [class.text-danger]='getStatus() === Status.Failed' [class.text-warning]='getStatus() === Status.Running'
          class='font-weight-bold'>{{ getStatus()}}</span></li>
      <li *ngIf='getStatus() == "failed" && getError()' class='my-4 text-uppercase'><span class='text-muted '>error :
        </span> {{getError()}}</li>
      <li class='my-4  text-uppercase'><span class='text-muted '>creation : </span><span class='font-weight-bold'
          [class.text-warning]='dateCreation === "a few seconds ago"'>{{dateCreation}}</span></li>
      <li class='my-4 text-uppercase'><span class='text-muted '>last modification : </span><span
          class='font-weight-bold'
          [class.text-warning]='dateModification === "a few seconds ago"'>{{dateModification}}</span></li>
      <li *ngIf='getLocation()' class='my-4 text-uppercase'><span class='text-muted '>Files and processing location :
        </span><span class='font-weight-bold'>{{getLocation()}}</span></li>
      <li *ngIf='getDescription()'>
        <div class="my-4 dropdown-divider"></div>
      </li>
      <li *ngIf='getDescription()' class='my-4 text-uppercase'><span class='text-muted '>Description :
        </span><span class='font-weight-bold'>
          <app-input-field [parentForm]='descriptionFormGroup' [inputJson]='getDescriptionFieldObject()'>
          </app-input-field>
        </span></li>
      <li class='my-4 my-4 text-uppercase'><span class='text-muted '>owner : </span><span class='font-weight-bold'>
          {{item.owner.name}}</span>
      </li>
       <br>



    </small>

  </ul>
</div>
