<div class='h-100 w-100 d-flex justify-content-around flex-column'>
  <div class='d-flex align-items-center my-4 justify-content-between'>
    <div class='mx-4 cellphone'>
    </div>
    <span class='mx-4'>Enter the 6-digit number shown on your Google Authenticator app. </span>
  </div>
  <div class='text-danger my-2'>{{backendError ? backendError : '&nbsp;'}}
    <a *ngIf='backendError' (click)='reset()' class="text-danger cursor-pointer card-link"> - (<u>Reset</u>)</a>
  </div>
  <div class='form-container'>
    <form *ngIf='!userIsVerified' [formGroup]="digitForm" autocomplete="off">
      <div class='justify-content-around d-flex flex-nowrap my-4'>
        <app-input-field id="{{'digit-container-'+ index}}" [attr.index]='index' *ngFor='let el of digitFields; let index=index'
          (valueSelected)='triggerAutoTab($event, index)' [backendError]='backendError' class='mx-3 digitField'
          [parentForm]='digitForm' [inputJson]='el'>
        </app-input-field>
      </div>
    </form>
    <div *ngIf='userIsVerified' class='text-center '>
      <i class="fal fa-5x text-primary fa-check-circle" aria-hidden="true"></i>
    </div>
  </div>

  <div class='d-flex flex-nowrap justify-content-around w-100 mt-5 '>
    <div class="btn btn-light border flex-fill mx-2" (click)='previous()'>Previous</div>
    <div class="btn btn-dark flex-fill mx-2" id='verifyBtn' ngbTooltip="{{!digitsFormIsValid() ? 'Verify your form' : ''}}"
      tooltipClass="custom-tooltip" placement="right" [class.disabled]="!digitsFormIsValid()" tabindex='0'
      (click)='digitsFormIsValid()? verify() : ""'>Verify</div>
  </div>
</div>
