import { Component, OnInit, Input } from '@angular/core';
import moment from 'moment';
import { ExplorerManagerService } from 'src/app/api/explorer-manager.service';
@Component({
  selector: 'app-explorer-previous-question',
  templateUrl: './explorer-previous-question.component.html',
  styleUrls: ['./explorer-previous-question.component.scss'],
})
export class ExplorerPreviousQuestionComponent implements OnInit {
  @Input() text;
  @Input() date;
  @Input() index;
  public readableDate;
  constructor(public e: ExplorerManagerService) { }
  ngOnInit() {
    this.readableDate = this.fromNowOrNow(this);
  }
  fromNowOrNow(date) {
    date = date < moment().unix() ? date : moment().unix();
    let result = moment.unix(date).fromNow();
    result = result === 'a few seconds ago' ? 'Now' : result;
    return result;
  }
}
