import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FileGroupAction, GroupsManagerService } from 'src/app/api/groups-manager.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { FileManager2Service } from '../file-manager2.service';

@Component({
  selector: 'group',
  styleUrls: ['../../../shared/layout/managers.scss'],
  templateUrl: './group.component.html',
})
export class GroupComponent implements OnInit, OnDestroy {
  @Input() group: any;
  @Input() index: any;
  @Input() country: any;
  @Input() activeTab: any;

  iconText;
  refreshSubscription;
  public groupContent = new BehaviorSubject<any>(null);
  public iconClass: string[] = ['far fa-circle'];
  iterator;
  FileGroupAction = FileGroupAction;
  hiddenContent = [];
  hasMore = new BehaviorSubject<Boolean>(false);
  constructor(
    public fileManager: FileManager2Service,
    public layout: LayoutOrganizationService,
    public groupsManagerService: GroupsManagerService
  ) {}
  ngOnInit() {
    this.refreshSubscription = this.groupsManagerService.eventRefreshGroup.subscribe((res) => {
      if (_.get(res, 'id') !== this.group.id) {
        return;
      }
      this.listContent(_.get(res, 'country'), true);
    });
  }

  listContent = (country, bool?) => {
    if (bool) {
      this.iterator = this.groupsManagerService.getGroup(country.value, this.group, 10);
    }
    // else {
    //   this.listMoreTimesCount++
    // }
    this.iterator.next().then((obj) => {
      if (bool) {
        this.groupContent.next(null);
        this.hasMore.next(null);
      }
      if (!obj.value) return;
      this.groupContent.next(_.get(obj.value, 'data'));
      this.hasMore.next(_.get(obj.value, 'has_more'));
    });
  };
  getGroupContent = () => {
    return _.get(this, 'groupContent.value', []);
  };
  getGroupContentLength = () => {
    return _.get(this, 'groupContent.value.length', 0);
  };

  contentVisibilitySwitcher() {
    if (this.groupsManagerService.openedGroupId === this.group.id) {
      this.groupsManagerService.openedGroupId = null;
    } else {
      this.groupsManagerService.openedGroupId = this.group.id;
      this.groupsManagerService.eventRefreshGroup.next({ id: this.group.id, country: this.country });
    }
  }
  handleDeleteGroup = (id) => {
    this.groupsManagerService.handleGroupDelete(id, this.country.value).then((res) => {
      this.groupsManagerService.eventRefreshGroups.next({ country: this.country });
    });
  };
  handleRemove = (group, groupItem) => {
    this.groupsManagerService.handleRemoveFromGroup(this.country, group.id, groupItem.id, group.name, groupItem.title).then((res) => {
      this.groupsManagerService.eventRefreshGroup.next({ id: this.group.id, country: this.country });
    });
  };
  setIconText(text) {
    this.iconText = text;
  }
  unSetIconText() {
    this.iconText = '';
  }

  trackFn(index, item) {
    return _.get(item, 'id') + ',' + _.get(item, 'title');
  }
  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }
}
