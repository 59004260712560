import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserActions } from '../../teams.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-user-card2',
  templateUrl: './user-card2.component.html',
  styleUrls: ['./user-card2.component.scss'],
})
export class UserCard2Component {
  @Input() selectedUser;
  @Input() selectedTeam;
  @Input() formattedSelectedUser;
  _=_
  @Input() version? =1 
  @Output() userEvent: EventEmitter<any> = new EventEmitter<any>();
  handleRemove = () => {
    this.userEvent.emit({ id: this.selectedUser.id, team: this.selectedTeam, action: UserActions.Remove });
  };
  handleChangePermission = (value) => {
    this.userEvent.emit({ user: this.selectedUser, value, team: this.selectedTeam, action: UserActions.ChangePermission });
  }
  handleDeactivate = () => {
    this.userEvent.emit({ user: this.selectedUser, action: UserActions.Deactivate });
  };
}
