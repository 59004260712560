import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortDirection } from 'src/app/shared/helpers/sortDirection';
import { Action } from '../data-manager.service';

@Component({
  selector: 'app-sort-by',
  templateUrl: './sort-by.component.html',
  styleUrls: ['./sort-by.component.scss']
})
export class SortByComponent  {
  @Output() onClick = new EventEmitter<any>()
  Action = Action
  @Input() direction
  @Input() name
  @Input() selected
  SortDirection = SortDirection
  constructor() { }

}
