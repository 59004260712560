import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import _ from 'lodash';
import { merge } from 'rxjs';
import { ProjectsService } from 'src/app/api/projects.service';
import { CreditAlertsService } from 'src/app/pages/project-creation2/credit-alerts/credit-alerts.service';
import { areArgumentsDefined } from '../helpers/checkArgumentsValidity';
@Component({
  selector: 'formly-field-responders',
  styleUrls: ['./responders.wrapper.scss', '../../../styles.scss'],
  templateUrl: './responders.wrapper.type.html',
})
export class FormlyRespondersWrapperInput extends FieldWrapper implements OnInit {
  _ = _;
  savedClusterComposition;
  totalPatientsPerField = undefined;

  constructor(private projectsService: ProjectsService, private creditAlertsService: CreditAlertsService) {
    super();
  }
  get projects() {
    return this.field.parent.formControl.get('pso_path.project_id');
  }
  get instances() {
    return this.field.parent.formControl.get('pso_path.instance_id');
  }
  get step_name() {
    return this.field.parent.formControl.get('pso_path.step_name');
  }
  get filename() {
    return this.field.parent.formControl.get('pso_path.filename');
  }
  get selection() {
    return this.field.parent.formControl.get('pso_path.selection');
  }
  ngOnInit() {
    merge(this.projects.valueChanges, this.instances.valueChanges).subscribe(() => {
      this.fields.forEach((el) => {
        el.templateOptions.options = [];
      });
      this.selection.setValue([]);
      this.getClusterComposition(this.projects.value, this.instances.value, this.filename.value, this.step_name.value);
    });
  }

  getClusterComposition(projectId, instanceId, filename, step_name) {
    if (!areArgumentsDefined(arguments)) {
      return;
    }
    this.projectsService.getClusterComposition(projectId, instanceId, filename, step_name).subscribe(
      (val) => {
        this.savedClusterComposition = val;
        let options = _.get(val, 'data', []);
        options = options.map((el) => {
          return { label: 'Cluster' + el.id + ' ( ' + el.len + ' patients' + ' ) ', value: el.id };
        });
        this.fields.forEach((el) => {
          el.templateOptions.options = options;
        });
      },
      (error) => {
        this.fields.forEach((el) => {
          el.templateOptions.options = undefined;
        });
      }
    )
  }
  get fieldsetName() {
    return this.fields.map((el) => {
      return el.key;
    });
  }
  get fields() {
    return _.get(this, 'field.fieldGroup');
  }
  toggleSibling(event) {
    const sibling = event.target.parentNode.children[0];
    sibling.click();
  }

  onSelection(event) {
    const checkboxIndex = event.target.value;
    const isChecked = event.currentTarget.checked;
    const fieldsetName = event.target.parentNode.parentNode.parentNode.id;
    const oppositeFielset = fieldsetName === this.fieldsetName[0] ? this.fieldsetName[1] : this.fieldsetName[0];
    const parent = event.target.parentNode;
    const oppositeCheckbox = document.getElementById(oppositeFielset).children[1].children[checkboxIndex].children[0];
    let formControl = _.find(this.field.fieldGroup, (o) => {
      return o.key == fieldsetName;
    }).formControl;
    if (!_.get(formControl, 'value')) {
      formControl.setValue([]);
    }
    let newValue = _.cloneDeep(formControl.value);

    if (isChecked) {
      parent.style.backgroundColor = '#bbece9';
      oppositeCheckbox.setAttribute('disabled', '');
      newValue.push(checkboxIndex);
      formControl.setValue(newValue);
    } else {
      parent.style.backgroundColor = 'transparent';
      oppositeCheckbox.removeAttribute('disabled');
      _.remove(newValue, (o) => {
        return o === checkboxIndex;
      });
      formControl.setValue(newValue);
    }
    this.totalPatientsPerField = [];
    this.selection.setValue(_.union(this.field.fieldGroup[0].formControl.value, this.field.fieldGroup[1].formControl.value));
    this.creditAlertsService.updateCostWithPatientsCount.next(this.countPatient(this.selection.value));
    this.totalPatientsPerField.push(this.countPatient(this.field.fieldGroup[0].formControl.value));
    this.totalPatientsPerField.push(this.countPatient(this.field.fieldGroup[1].formControl.value));
  }
  countPatient(selection) {
    let total = 0;
    selection.forEach((value) => {
      total =
        total +
        _.find(this.savedClusterComposition.data, (o) => {
          return o.id === value;
        }).len;
    });
    return total;
  }
}
