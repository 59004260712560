<div class='w-100 h-100 d-flex flex-column' *ngIf="fileManager.quality && fileManager._get('activeItem')">
  <app-header>
    <div class="text-light h-100 w-100 d-flex justify-content-between bg-dark">
      <button (click)="_.set(fileManager, 'quality',false)" class="h-100 btn btn-link">
        <i aria-hidden='true' class="fal text-info fa-long-arrow-left"></i>
        <span class="text-info"> Back to file manager</span>
      </button>

      <div class="h-100 mx-3  d-flex align-items-center">
        <h3>{{fileManager._get('activeItem').title}}</h3>
      </div>
      <div class="h-100 mx-3  d-flex align-items-center">
        <h3 class='text-primary'> Quality dashboard</h3>
      </div>
    </div>
  </app-header>
  <app-content [isTab]='false' [isHeader]='true'>
    <app-quality-overview [item]="fileManager._get('activeItem')" [country]='fileManager.activeCountry'>
    </app-quality-overview>
  </app-content>
</div>
<div *ngIf="!fileManager.quality" class='w-100 h-100 d-flex flex-column'>
  <app-header>
    <app-path-2 (valueChanged)='fileManager.itemEvent.emit([$event, ItemAction.OpenFolder])'
      [dataSource]="fileManager._getAsync('path')">
    </app-path-2>
  </app-header>
  <app-horizontal-nav-tab-2 [selectedTab]='fileManager.activeTab'
    (valueChanged)="fileManager.actionEvent.emit([Action.ChangeTab,$event])" [dataSource]="fileManager.tabsDataSource">
    <mims-select [selectedOption]="fileManager.activeCountry" [dataSource]="fileManager.countriesDataSource"
      (valueChanged)="fileManager.actionEvent.emit([Action.ChangeCountry,$event])">
    </mims-select>
  </app-horizontal-nav-tab-2>
  <app-content [isHeader]='true' [isTab]='true'>
    <div class='d-flex h-100 pt-3'>
      <div class='buttons p-3'>

        <div *ngIf='!fileManager.isMyDeletedFiles()'>
          <button [class.disabled]="!fileManager.canPerform(Action.UploadSelect)" placement="right"
            ngbTooltip="{{fileManager.getTooltip(Action.UploadSelect)}}" tooltipClass="custom-tooltip"
            (click)="fileManager.canPerform(Action.UploadSelect) ? fileManager.actionEvent.emit([ Action.UploadSelect]) : '' "
            class="mb-2 w-100 text-left btn btn-dark">
            <i class="mx-2 far fa-file-upload" aria-hidden="true"></i>
            <span class="cursor-info"> Upload files</span>
          </button>


          <div class="d-flex flex-column mb-2" role="group">
            <button ngbTooltip="{{fileManager.getTooltip(Action.CreateFolder)}}" tooltipClass="custom-tooltip"
              [class.disabled]="!fileManager.canPerform(Action.CreateFolder)" placement="right"
              (click)="fileManager.canPerform(Action.CreateFolder) ? fileManager.actionEvent.emit([ Action.CreateFolder]) : ''"
              class="btn top-rounded text-left btn-dark">
              <i class="mx-2 far fa-folder-plus" aria-hidden="true"></i>
              <span class="cursor-info"> Create folder</span>
            </button>
            <button ngbTooltip="{{fileManager.getTooltip(Action.CreateGroup)}}"
              [class.disabled]="!fileManager.canPerform(Action.CreateGroup)" tooltipClass="custom-tooltip"
              placement="right"
              (click)="fileManager.canPerform(Action.CreateGroup) ? fileManager.actionEvent.emit([ Action.CreateGroup]): ''"
              class="btn bottom-rounded  text-left btn-dark">
              <i class="mx-2 far fa-object-group" aria-hidden="true"></i>
              <span class="cursor-info"> Create group
              </span>
            </button>

          </div>
          <div class="d-flex flex-column mb-2" role="group">
            <button [class.disabled]="!fileManager.canPerform(Action.MoveSelect)" placement="right"
              ngbTooltip="{{fileManager.getTooltip(Action.MoveSelect)}}" tooltipClass="custom-tooltip"
              (click)="fileManager.canPerform(Action.MoveSelect) ? fileManager.actionEvent.emit([ Action.MoveSelect]) : '' "
              class=" w-100 text-left btn btn-dark"
              [class.top-rounded]='_.includes(availableFeatures, Features.MoveManuallyToGroup)'
              [class.rounded]='!_.includes(availableFeatures, Features.MoveManuallyToGroup) && !_.includes(availableFeatures, Features.DeleleMultipleFiles)'>
              <i class="mx-2 far fa-file-export" aria-hidden="true"></i>
              <span class="cursor-info"> Move selected</span>
            </button>
            <button *ngIf='_.includes(availableFeatures, Features.MoveManuallyToGroup)'
              [class.disabled]="!fileManager.canPerform(Action.AddToGroupSelect)" placement="right"
              ngbTooltip="{{fileManager.getTooltip(Action.AddToGroupSelect)}}" tooltipClass="custom-tooltip"
              (click)="fileManager.canPerform(Action.AddToGroupSelect) ? fileManager.actionEvent.emit([ Action.AddToGroupSelect]) : '' "
              class=" w-100 text-left btn btn-dark"
              [class.border-none]='_.includes(availableFeatures, Features.DeleleMultipleFiles)'>
              <i class="mx-2 far fa-plus-square" aria-hidden="true"></i>
              <span class="cursor-info"> Add selected to group</span>
            </button>
            <button ngbTooltip="{{fileManager.getTooltip(Action.TrashMultiple)}}" tooltipClass="custom-tooltip"
              placement="right" *ngIf='_.includes(availableFeatures, Features.DeleleMultipleFiles)'
              [class.disabled]="!fileManager.canPerform(Action.TrashMultiple)"
              (click)="fileManager.canPerform(Action.TrashMultiple)? fileManager.actionEvent.emit([ Action.TrashMultiple]) : ''"
              class=" w-100 text-left bottom-rounded btn btn-dark">
              <i class="mx-2 far top-rounded fa-trash-alt" aria-hidden="true"></i>
              <span class="cursor-info"> Trash selected</span>
            </button>
          </div>
        </div>
        <div *ngIf='fileManager.isMyDeletedFiles()' class="d-flex flex-column" role="group">
          <button ngbTooltip="{{fileManager.getTooltip(Action.DeletePermanently)}}"
            [class.disabled]="!fileManager.canPerform(Action.DeletePermanently)" tooltipClass="custom-tooltip"
            placement="right"
            (click)="fileManager.canPerform(Action.DeletePermanently) ? fileManager.actionEvent.emit([ Action.DeletePermanently]): ''"
            class="btn top-rounded text-left btn-dark">
            <i class="mx-2 far fa-trash" aria-hidden="true"></i>
            <span class="cursor-info"> Permanently delete selected
            </span>
          </button>
          <button ngbTooltip="{{fileManager.getTooltip(Action.Restore)}}"
            [class.disabled]="!fileManager.canPerform(Action.Restore)" tooltipClass="custom-tooltip" placement="right"
            (click)="fileManager.canPerform(Action.Restore) ?fileManager.actionEvent.emit([ Action.Restore]): ''"
            class="btn bottom-rounded text-left btn-dark">
            <i class="mx-2 far fa-trash-restore-alt" aria-hidden="true"></i>
            <span class="cursor-info"> Restore selected
            </span>
          </button>
        </div>
      </div>
      <div class='files  ease-out p-3 scrollable flex-fill'>
        <div class="w-100 h-100 pr-2   ">
          <mims-top-utility-bar
            [sortingData]="[{ sortType: SortType.Title, sortName: SortName.Name },{ sortType: SortType.DateCreation, sortName: SortName.Date }]"
            [bulkSelectionLength]="_.get(fileManager._get('bulkSelection'), 'length')"
            [bulkSize]="fileManager.getBulkSize()" [sortingDirection]="fileManager._get('sortByDirection')"
            [visibleBulkLength]="fileManager.getVisibleBulkLength()"
            (sortingDirectionChange)="fileManager.actionEvent.emit([Action.ChangeSortingDirection,$event])"
            (sortingTypeChange)="fileManager.actionEvent.emit([Action.ChangeSortingType,$event])"
            [sortingType]=" fileManager.getSortByType()"
            (selection)="fileManager.actionEvent.emit([Action.SelectDisplayedFiles])"
            (unselection)="fileManager.actionEvent.emit([Action.UnSelectDisplayedFiles ])">
          </mims-top-utility-bar>
          <mims-files-2 [navigation]='activeTabValue !== TabsName.DeletedFiles'
            [architectureEdition]='activeTabValue === TabsName.MyFiles' [rootFolder]='fileManager.getRootFolder()'
            [openedFolder]="fileManager._get('openedFolder')" [parentFolder]="_.nth(fileManager._getAsync('path'), -2)"
            [uploadingFiles]="fileManager._get('uploadingFiles') " [itemType]="ItemType.File"
            [displayedFiles]="fileManager._getAsync('displayedFiles')" [singleSelection]="item"
            [bulkSelection]="fileManager._get('bulkSelection')" (itemAction)="fileManager.handleItemAction($event)">
          </mims-files-2>
          <mims-pagination [page]="fileManager._get('page')" [pageSize]="fileManager._get('limit')"
            [count]="fileManager._getAsync('count')"
            (pageChange)="fileManager.actionEvent.emit([Action.ChangePage,fileManager._get('openedFolder'), $event ])">
          </mims-pagination>
          <app-groups *ngIf="activeTabValue === TabsName.SharedFiles"
            [groups]="fileManager._getAsync('displayedGroups')" [activeTab]='fileManager.activeTab'
            [country]="fileManager.activeCountry" [sharedWithMe]='false'>
          </app-groups>
          <app-groups *ngIf="activeTabValue !== TabsName.DeletedFiles"
            [groups]="fileManager._getAsync('displayedGroups')" [activeTab]='fileManager.activeTab'
            [country]="fileManager.activeCountry" [sharedWithMe]='fileManager.isSharedFiles()'>
          </app-groups>

          <div class=" spacer">&nbsp;
          </div>

        </div>

      </div>
      <div *ngIf="_.get(l.fmPannelIsOpened, activeTabValue) " [class.opened]="_.get(l.fmPannelIsOpened, activeTabValue)"
        class='pannel ease-out p-3'>
        <div *ngIf="l.isFilesInfosModalOpen" id="modalFilesInfos">
          <div class="d-flex justify-content-end">
            <button type="button" class="close" aria-label="Close" (click)="fileManager.closePannel()">
              <span><i class="far fa-times" aria-hidden="true"></i></span>
            </button>
          </div>
          <mims-loader *ngIf="!item" class='w-100 pos-relative' [enableBackDrop]='false' [isLoading]="!item">
          </mims-loader>
          <info-2 *ngIf='item && _.get(fileManager, "teamsDataSource.value")' [country]='fileManager.activeCountry'
            (itemAction)='fileManager.handleItemAction($event)'
            [teamsDataSource]='_.get(fileManager, "teamsDataSource.value")' [item]="item">
          </info-2>
        </div>

        <div *ngIf="l.isGroupShareModalOpen" id="modalGroupsShare">
          <div class="d-flex justify-content-end">
            <button type="button" class="close" aria-label="Close" (click)="fileManager.closePannel()">
              <span><i class="far fa-times" aria-hidden="true"></i></span>
            </button>
          </div>
          <mims-loader *ngIf="!groupItem" class='w-100 pos-relative' [enableBackDrop]='false' [isLoading]="!groupItem">
          </mims-loader>
          <div *ngIf="groupItem" class='d-flex flex-column'>
            <span *ngIf="!_.get(groupItem, 'capabilities.can_modify')">{{_.get(groupItem,"name")}}</span>
            <app-rename-2 *ngIf="_.get(groupItem, 'capabilities.can_modify')" [value]="_.get(groupItem,'name')"
              (valueChanged)="groupsManagerService.groupItemEvent.emit({item: groupItem, fileGroupAction: FileGroupAction.Rename,value: $event, country : fileManager.activeCountry})"
              class='h5 text-primary'>
            </app-rename-2>
            <ul class='list-unstyled '>
              <small>
                <li class='py-2 text-uppercase'><span>owner : </span><span class='font-weight-bold'>
                    {{_.get(groupItem, 'owner.name')}}</span>
                </li>
              </small>
            </ul>
            <app-share [autocompleteData]="fileManager.getAutocompleteData()" [country]='fileManager.activeCountry'
              [style.height.rem]="30" (groupItemEvent)='groupsManagerService.groupItemEvent.emit($event)'
              [item]="groupItem" [type]="ItemType.FileGroup">
            </app-share>
            <div class="mt-5 w-100">
              <div class="d-flex flex-nowrap">
                <button type="button" class="flex-fill mx-2 btn btn-dark" (click)="fileManager.closePannel()">
                  Cancel
                </button>
                <button type="button" class="flex-fill mx-2 btn btn-dark"
                  [disabled]="!groupsManagerService.getUsersSelected().length"
                  (click)="groupsManagerService.groupItemEvent.emit(  {item: groupItem, fileGroupAction: FileGroupAction.Share,value: groupsManagerService.getUsersSelected(), country: fileManager.activeCountry})">


                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="l.isFilesShareModalOpen" id="modalFilesShare">
          <div class="d-flex justify-content-end">
            <button type="button" class="close" aria-label="Close" (click)="fileManager.closePannel()">
              <span><i class="far fa-times" aria-hidden="true"></i></span>
            </button>
          </div>
          <mims-loader *ngIf="!item" class='w-100 pos-relative' [enableBackDrop]='false' [isLoading]="!item">
          </mims-loader>
          <div *ngIf="item" class='d-flex flex-column'>
            <span *ngIf="!_.get(item, 'capabilities.can_modify')">{{_.get(item,"title")}}</span>
            <app-rename-2 *ngIf="_.get(item, 'capabilities.can_modify')" [value]="_.get(item,'title')"
              (valueChanged)="fileManager.itemEvent.emit([item, ItemAction.Rename, undefined, $event])"
              class='h5 text-primary'>
            </app-rename-2>
            <ul class='list-unstyled '>
              <small>
                <li class='py-2 text-uppercase'><span>owner : </span><span class='font-weight-bold'>
                    {{_.get(item, 'owner.name')}}</span>
                </li>
              </small>
            </ul>
            <app-share [autocompleteData]="fileManager.getAutocompleteData()" [style.height.rem]="30"
              (itemEvent)='fileManager.itemEvent.emit($event)' [item]="item" [type]="ItemType.File">
            </app-share>
            <div class="mt-5 w-100">
              <div class="d-flex flex-nowrap">
                <button type="button" class="flex-fill mx-2 btn btn-dark" (click)="fileManager.closePannel()">
                  Cancel
                </button>
                <button type="button" class="flex-fill mx-2 btn btn-dark"
                  [disabled]="!fileManager.getUsersSelected().length"
                  (click)="fileManager.itemEvent.emit([item, ItemAction.Share, undefined, fileManager.getUsersSelected()])">
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-content>
</div>
<app-custom-modal modalTitle="Enter a name for a new folder" idName="modalFolder" [show]="l.isFolderModalOpen"
  confirmTitle="Save" (confirmEvent)="fileManager.handleCreateFolder()" [confirmValidator]="_.get(folderInput, 'valid')"
  (cancelEvent)="fileManager.folderCreationForm.reset()">
  <div class="custom-modal-content">
    <form [formGroup]="fileManager.folderCreationForm" class="d-flex flex-column">
      <div class='d-flex flex-column'>
        <input class="form-control" id="folderInput" type="text" formControlName="folderInput"
          placeholder="enter folder's name" />
      </div>

    </form>
  </div>
</app-custom-modal>

<app-custom-modal modalTitle="Select a destination group" idName="modalGroupMove" [show]="l.isModalMoveToGroupOpen"
  confirmTitle="Save" (confirmEvent)="fileManager.actionEvent.emit([Action.MoveToGroupMultiple])"
  [confirmValidator]="_.get(group, 'valid')" (cancelEvent)="fileManager.moveGroupForm.reset()">
  <div class="custom-modal-content">
    <form [formGroup]="fileManager.moveGroupForm" class="d-flex flex-column">
      <div class='d-flex flex-column'>
        <mims-select formControlName="group" [selectedOption]="_.nth(fileManager._getAsync('groupDataSource'),0)"
          [dataSource]="fileManager._getAsync('groupDataSource')">
        </mims-select>
      </div>
    </form>
  </div>
</app-custom-modal>
<app-custom-modal modalTitle="Select a destination folder" idName="modalMove" [show]="l.isModalMoveOpen"
  confirmTitle="Save" (confirmEvent)="fileManager.actionEvent.emit([Action.MoveMultiple])"
  [confirmValidator]="_.get(folder, 'valid')" (cancelEvent)="fileManager.moveForm.reset()">
  <div class="custom-modal-content">
    <form [formGroup]="fileManager.moveForm" class="d-flex flex-column">
      <div class='d-flex flex-column'>
        <mims-select formControlName="folder" [selectedOption]="_.nth(_.get(fileManager,'folderDataSource.value'),0)"
          [dataSource]="_.get(fileManager,'folderDataSource.value')">
        </mims-select>
      </div>
    </form>
  </div>
</app-custom-modal>

<app-custom-modal modalTitle="Upload new file(s)" idName="modalFile" [show]="l.isFileModalOpen" confirmTitle="Save"
  (confirmEvent)="fileManager.actionEvent.emit([Action.Upload])" confirmTooltip='Please verify your form.'
  [form]='fileManager.fileUploadForm' (cancelEvent)='fileManager.fileUploadForm.reset()'>
  <form [formGroup]="fileManager.fileUploadForm" class="d-flex  flex-column ">
    <label class='small text-muted font-weight-normal text-uppercase'>
      Select a team
    </label>
    <mims-select formControlName="team" [selectedOption]='_.nth(_.get(fileManager, "teamsDataSource.value"),0)'
      [dataSource]='_.get(fileManager, "teamsDataSource.value")'>
    </mims-select>
    <app-input-field class='my-2' [parentForm]='fileManager.fileUploadForm' [writeError]='true'
      [inputJson]='fileInputFieldObject'></app-input-field>
  </form>
</app-custom-modal>

<app-custom-modal modalTitle="Upload meta data relative to your file" idName="modalMetaData"
  [show]="l.isMetaDataModalOpen" confirmTitle="Save"
  (confirmEvent)="fileManager.itemEvent.emit([item, ItemAction.UploadMetadata])"
  [confirmValidator]="_.get( fileManager, 'metadataUploadForm.controls.metadataInput.valid')"
  (cancelEvent)="fileManager.metadataUploadForm.reset()">
  <div class="custom-modal-content">
    <app-metadata-upload-form [(form)]='fileManager.metadataUploadForm' (files)='fileManager.saveMetadataFiles($event)'>
    </app-metadata-upload-form>
  </div>
</app-custom-modal>
