<div>
  <div [id]="idName" class="full-screen-modal border d-flex flex-column" #modal>
    <div class='my-2 d-flex flex-nowrap'>
      <div class='title-container flex-grow-1 align-items-center d-flex'>
        <div class='w-100 title h3 text-center text-wrap'>{{getTitle()}}</div>
      </div>
      <div class="btn  close" aria-label="Close" (click)="layout.close(idName)">
        <span class='h1 mx-3' aria-hidden="true">&times;</span>
      </div>
    </div>
    <ng-content class='flex-grow-1'></ng-content>

  </div>
  <div (click)="layout.close(idName)" *ngIf="layout.isBlurryOverlayOpen" class="modal-blurry-overlay"
    id="modal-blurry-overlay">
  </div>
</div>