
<div  class="list-group-item scrollable h-100 w-100">
  <ul>
    <small>
      <li  class='mb-5 text-uppercase'><span class='text-info font-weight-bold'>Name of {{itemType === ItemType.FileGroups ? "cohort" : "file"}}  </span>
        <div class='font-weight-bold my-2'>{{title}}</div>
      </li>
      <!-- <li *ngIf="_.get(this.item, 'team_id')" class='mb-5 text-uppercase'><span class='text-info font-weight-bold'>Team </span>
        <div class='font-weight-bold my-2'>{{teamName}}</div>
      </li> -->
      <!-- <li  *ngIf="cohortFiles.value !== undefined && itemType === ItemType.FileGroups" class="mb-5 text-uppercase">
        <span class='text-info font-weight-bold'>Content ({{cohortFiles.value.length}})</span> 
          <ul class=" my-2 border smooth-border  cohort-files-details details my-2 list-group-item scrollable">
              <li *ngFor="let item of cohortFiles.value">
                  {{_.get(item, 'title')}}
              </li>
          </ul>
      </li> -->
      <li class="text-uppercase" *ngIf="!_.isEmpty(metadata)"><span class='text-info font-weight-bold'>Metadata  </span>
        <ul class="border smooth-border details py-1 my-2">
          <li *ngFor="let element of metadata | keyvalue "><span class="text-info font-weight-bold">{{element.key | uppercase }} :
            </span><span class="font-weight-bold">{{element.value}}</span></li>
        </ul>
      </li>
      <li class='my-5 text-uppercase'>
        <span class='text-info font-weight-bold'>Owner  </span>
        <div class='w-100 align-items-center my-2 d-flex'>
          <mims-initials-bubble ngbTooltip="{{owner}}" theme='dark' tooltipClass="custom-tooltip" placement="top"
            [fullName]='owner'></mims-initials-bubble>
          <span class='font-weight-bold'>
            {{owner}} </span>
        </div>
      </li>
      <li class='d-flex my-5'>
        <div class='mr-2 w-50 flex-column d-flex text-uppercase'><span class='text-info font-weight-bold'>Created  </span><span
            class='font-weight-bold my-2'
            [class.text-warning]='isRecent(dateCreation)'>{{dateCreation | timeAgo}}</span>
        </div>
        <div class='w-50 flex-column d-flex  text-uppercase'><span class='text-info font-weight-bold'>Last update  </span><span
            class='font-weight-bold my-2'
            [class.text-warning]='isRecent(dateModification)'>{{dateModification | timeAgo}}</span>
        </div>
      </li>
    </small>

  </ul>

</div>
