export enum Status {
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
  InTreatment = 'in_treatment',
  Failed = 'failed',
  Done = 'done',
  Pending = 'pending',
  Ready = 'ready',
  Processing = 'processing',
  NotStarted = 'not_started',
  Unusable = 'unusable',
  Temp = 'temp',
  Uploading = 'uploading',
  Paused = 'paused',
  WaitingForRefresh = 'waitingForRefresh',
  Running = 'running',
  Cancelled = 'cancelled',
  NetworkError = 'networkError',
}

export type StatusDecoration = { [key in Status]?: { name: string; icon: string; color?: string } };

/** Status decoration, if icon defined, use icon with color
 *  otherwise display name
 */
export const STATUS_DECORATIONS: StatusDecoration = {
  loading: { name: 'Loading', icon: undefined },
  success: { name: 'Success', icon: 'fas fa-check-circle', color: '' },
  error: { name: 'Error', icon: 'fas fa-exclamation-circle', color: '#e94545' },
  in_treatment: { name: 'In Treatment', icon: undefined, color: '#b87812' },
  failed: { name: 'Failed', icon: 'fas fa-exclamation-circle', color: '#e94545' },
  done: { name: 'Done', icon: 'fas fa-check-circle', color: 'green' },
  pending: { name: 'Pending', icon: undefined },
  ready: { name: 'Ready', icon: undefined },
  processing: { name: 'Processing', icon: undefined },
  not_started: { name: 'Not Started', icon: undefined },
  unusable: { name: 'Unusable', icon: undefined },
  temp: { name: 'Temp', icon: undefined },
  uploading: { name: 'Uploading', icon: undefined },
  paused: { name: 'Paused', icon: 'far fa-pause-circle', color: '#b87812' },
  waitingForRefresh: { name: 'Waiting for refresh', icon: undefined },
  running: { name: 'Running', icon: undefined },
  cancelled: { name: 'Cancelled', icon: undefined },
  networkError: { name: 'Network error', icon: undefined },
};
