import { Component } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import * as _ from 'lodash';
import { TabsPublicName } from 'src/app/shared/helpers/tabsPublicName';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
import { ProjectsService } from 'src/app/api/projects.service';
import { FileGroupsService } from 'src/app/api/groups.service';

@Component({
  selector: 'app-project-creation',
  templateUrl: './project-creation.component.html',
  styleUrls: ['./project-creation.component.scss'],
})
export class ProjectCreationComponent {
  PagesPublicName = PagesPublicName;
  TabsPublicName = TabsPublicName;
  PagesName = PagesName;
  solution
  _ = _;
  constructor(public projectsService: ProjectsService, private router: Router,    private route: ActivatedRoute,public fileGroupsService:FileGroupsService ) {
    this.solution = document.location.pathname.split('/').pop();
    this.fileGroupsService.isLoading = false;
    
    // Demo
    if (this.solution == 'popseg') {
      this.projectsService.stepsList =  ['Create Cohort Stratification', 'Workflow in progress'];
      this.projectsService.preselectedSolution = this.solution;
    } else if (this.solution == "biomark") {
      this.projectsService.stepsList =  ['Create Biomarker Discovery', 'Configure parameters', 'Workflow in progress'];
      this.projectsService.preselectedSolution = this.solution;
    } else {
      this.projectsService.stepsList = ['Create Project', 'Select solution', 'Configure parameters', 'Workflow in progress'];
      this.projectsService.preselectedSolution = '';
    }
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((res: NavigationEnd) => {
        const url = res.urlAfterRedirects;
        if (url.includes(PagesName.DefineProject)) {
          this.projectsService.setActiveStep(0)
        }
        if (url.includes(PagesName.ChooseWorkflow)) {
          this.projectsService.setActiveStep(1)
        }
        if (url.includes(PagesName.ConfigureWorkflow)) {
          this.projectsService.setActiveStep(2)
        }
        if (url.includes(PagesName.WorkflowInProgress)) {
          this.projectsService.setActiveStep(3)

        }
      })
  }
}
