<div class="d-flex flex-column">
  <div [hidden]="e.getErrorState() || e.getPendingState()" (click)="e.goToView(goToView)"
    class="back-container text-primary">
    <i class="fas fa-2x fa-arrow-square-left" aria-hidden="true"></i>
    <span class="mx-2 font-weight-bold back-text">{{ text }}</span>
  </div>
  <div class="my-1 font-weight-bold">
    <span>{{ e.interaction.text }}</span>
    <span class="text-info font-italic">• {{ moment(e.interaction.queryTime).format(format) }}</span>
  </div>
  <div *ngIf="e.getErrorState()" class="mx-2 back text-info">
    An error was encountered while exploring the knowledge.
    <div>
      <span *ngIf="e.interaction.errorCause.length">Cause : </span> {{ e.interaction.errorCause }}
    </div>
  </div>
  <div class="mx-2 back text-info" *ngIf="!e.getErrorState() && e.getPendingState()">
    <div class="spinner-grow" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    Waiting for answer ...
  </div>
</div>