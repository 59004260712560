import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import _ from 'lodash';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'ngx-bootstrap-multiselect';
@Component({
  selector: 'multiselect_with_tags',
  templateUrl: './formly-multiselect.type.html',
  styleUrls: ['./formly-multiselect.type.scss'],
})
export class FormlyFieldMultiselectInput extends FieldType<FieldTypeConfig> {
  myModel: number[] = [];
  mySettings: IMultiSelectSettings = {
    checkedStyle: 'fontawesome',
    buttonClasses: 'badge btn bg-light border justify-content-end text-dark ml-auto d-flex align-items-center p-2',
    itemClasses: 'ml-2',
    dynamicTitleMaxItems: 6,
    displayAllSelectedText: false,
    showUncheckAll: true,
  };
  myTexts: IMultiSelectTexts = {
    uncheckAll: 'Remove all filters',
    defaultTitle: 'Filter solutions',
  };
  myOptions = [];

  constructor() {
    super();
  }
  onSelectionChange(event: any) {
    let selectedOptions = [];
    event.forEach((index) => {
      const options = _.get(this, 'field.templateOptions.originalOptions.value')
      const option = _.nth(options, index)
      const optionName = _.get(option, 'name')
      selectedOptions.push(optionName);
    });
    this.formControl.setValue(selectedOptions);
  }
  ngOnInit(): void {
    _.get(this, 'field.templateOptions.options').subscribe((res) => {
      if (res){
        this.myOptions = res;
      }
      
    });
  }
}
