<div [class]="'depth-' + depth">
  <div class="d-flex justify-content-between align-items-center mr-3">
    <div class="d-flex align-items-center">
      <div class="d-flex">
        <div class="title">{{ category.name }}</div>
        <div class="count ml-1">{{ category.count }}</div>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div *ngIf="category.children.length === 0" class="mr-2 ml-4">
        <input type="checkbox" [value]="category.id" [name]="category.id"
          [(ngModel)]="this.knowledgeSearchService.selectedCategoryFilters[category.id]" />
      </div>
      <app-expander-button [ngStyle]="{ 'visibility': category?.children?.length ? 'visible' : 'hidden' }"
        (clicked)="handleExpand()"></app-expander-button>
    </div>
  </div>
  <ul *ngIf="expanded" class="mb-3">
    <li *ngFor="let category of category.children">
      <app-knowledge-search-results-category-tree [category]="category" [depth]="depth + 1">
      </app-knowledge-search-results-category-tree>
    </li>
  </ul>
</div>
