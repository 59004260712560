<div class='m-3'>
  <div type="button" (click)='knowledgeSearchService.navigateBackToSelectFormulation()' class="btn btn-light border">
    <span class="text-uppercase small"><i aria-hidden="true" class="text-muted far fa-arrow-to-left mr-1"></i> Change
      formulation</span>
  </div>
</div>
<div class=' m-5 d-flex flex-column align-items-center d-flex'>
  <h3 class='mx-5'> {{PagesPublicName.KnowledgeSearchSimpleKeyword}}</h3>
  <div class='w-75 my-5 d-flex justify-content-center'>
    <form [formGroup]="knowledgeSearchService.keywordsForm" class="mx-3 form d-flex flex-column custom-modal-content">
      <app-input-field [parentForm]='knowledgeSearchService.keywordsForm' [inputJson]='keywords0Object'>
      </app-input-field>
      <app-input-field [parentForm]='knowledgeSearchService.keywordsForm' [inputJson]='keywords1Object'>
      </app-input-field>
      <app-input-field [parentForm]='knowledgeSearchService.keywordsForm' [inputJson]='keywords2Object'>
      </app-input-field>
      <app-input-field [parentForm]='knowledgeSearchService.keywordsForm' [inputJson]='keywords3Object'>
      </app-input-field>
      <app-input-field [parentForm]='knowledgeSearchService.keywordsForm' [inputJson]='keywords4Object'>
      </app-input-field>
      <div class='error-box'>
        <ul *ngIf='_.get(knowledgeSearchService, "keywordsForm.errors.ShouldBeAllDifferent")'
          class='small text-center list-unstyled text-danger '>
          <li>
            <span>
              {{getFormErrorSentence('ShouldBeAllDifferent')}}
            </span>
          </li>
        </ul>
      </div>
      <div
        (click)='knowledgeSearchService.getFormValidity(knowledgeSearchService.keywordsForm) ? [knowledgeSearchService.saveQueryParams(knowledgeSearchService.keywordsForm), knowledgeSearchService.navigateToResults(Formulation.Keywords)] : ""'
        ngbTooltip="{{knowledgeSearchService.getFormError(knowledgeSearchService.keywordsForm)}}" tooltipClass="custom-tooltip" placement="left"
        [class.disabled]="!knowledgeSearchService.getFormValidity(knowledgeSearchService.keywordsForm)" tabindex="0"
        class='btn  mt-3 mx-auto btn-dark button '>
        <span class='small text-uppercase'> Search </span>
      </div>
    </form>
  </div>
</div>
