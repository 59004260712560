import { Component, EventEmitter, OnInit } from '@angular/core';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { Action, FileManager2Service, ItemAction } from './file-manager2.service';
import * as _ from 'lodash';
import { Features } from 'src/environments/features';
import { availableFeatures } from 'src/environments/environment';
import { TabsPublicName } from 'src/app/shared/helpers/tabsPublicName';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { FileGroupAction, GroupsManagerService } from 'src/app/api/groups-manager.service';
import { AdditionalTypes, InputJson } from 'src/app/shared/helpers/formattedInput';
import { Validators } from '@angular/forms';
import { ItemType } from 'src/app/shared/helpers/itemType';
import { SortType } from 'src/app/shared/helpers/sortType';
import { SortName } from 'src/app/shared/helpers/sortName';

@Component({
  selector: 'app-file-manager2',
  templateUrl: './file-manager2.component.html',
  styleUrls: ['./file-manager2.component.scss', '../../shared/styles/forms.component.scss'],
})
export class FileManager2Component implements OnInit {
  Features = Features;
  availableFeatures = availableFeatures;
  TabsName = TabsName;
  TabsPublicName = TabsPublicName;
  Action = Action;
  ItemAction = ItemAction;
  SortType = SortType;
  ItemType = ItemType;
  SortName = SortName;
  FileGroupAction = FileGroupAction;
  _ = _;
  fileInputFieldObject: InputJson = {
    formControlName: 'fileInput',
    type: AdditionalTypes.File,
    label: 'Select files',
    fitToContent: true,
    multiple_selection: true,
    validations: [Validators.required],
  };
  constructor(
    public fileManager: FileManager2Service,
    public l: LayoutOrganizationService,
    public groupsManagerService: GroupsManagerService
  ) {}

  ngOnInit(): void {
    _.get(this.fileManager, 'actionEvent', new EventEmitter()).emit([Action.InitManager]);
  }
  get item() {
    return this.fileManager._get('activeItem');
  }
  get groupItem() {
    return _.get(this.groupsManagerService, 'activeItem');
  }
  get itemShort() {
    return this.fileManager._get('activeItemShort');
  }
  get folderInput() {
    return _.get(this.fileManager, 'folderCreationForm.controls.folderInput');
  }
  get folder() {
    return _.get(this.fileManager, 'moveForm.controls.folder');
  }
  get group() {
    return _.get(this.fileManager, 'moveGroupForm.controls.group');
  }
  get activeTabValue() {
    return _.get(this.fileManager, 'activeTab.value');
  }
}
