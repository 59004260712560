import { Component, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { VizService, VizMode } from 'src/app/api/viz.service';
import { DashboardService } from 'src/app/api/dashboard.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { VizType } from 'src/app/shared/helpers/vizType';
import html2canvas from 'html2canvas';
declare let Plotly: any;
@Component({
  selector: 'app-viz-tile',
  templateUrl: './viz-tile.component.html',
  styleUrls: ['./viz-tile.component.scss']
})
export class VizTileComponent implements AfterViewInit {
  @Input() viz;
  isPlotly = false
  contentSubscription;
  VizType = VizType;
  VizMode = VizMode;
  imageIsDone = false
  @ViewChild('thumbnail') thumbnail: ElementRef;
  @ViewChild('capture') capture: ElementRef;

  constructor(public l: LayoutOrganizationService, public vizService: VizService, public dashboardService: DashboardService) { }
  ngAfterViewInit(): void {
    if (!this.viz) {
      return
    }
    this.contentSubscription = this.vizService.getVizContent(this.viz).subscribe((res) => {
      if (_.isEmpty(res.data)) {
        return;
      }
      this.viz = this.vizService.modifyData(this.viz)
      this.viz = this.vizService.modifyLayout(this.viz);
      this.isPlotly = this.vizService.isPlotly(this.viz)
      if (this.isPlotly) {
        Plotly.toImage({ data: this.vizService.getData(this.viz), layout: this.vizService.getLayout(this.viz) }).then(
          (src) => {
            this.imageIsDone = true;
            return new Promise((resolve, reject) => {
              this.getThumbnail().onload = resolve;
              this.getThumbnail().onerror = reject;
              this.getThumbnail().style.margin = '0.5rem';
              this.getThumbnail().style.imageRendering = 'auto';
              this.getThumbnail().style.backgroundImage = "url(" + src + ")";
            });
          }
        );
      } else if (this.vizService.getType(this.viz) === VizType.Select) {
        //
      }
      else if (this.vizService.getType(this.viz) === VizType.Image) {
        var img = "url('" + this.vizService.getValues(this.viz) + "')";
        this.getThumbnail().style.imageRendering = 'auto';
        this.getThumbnail().style.backgroundImage = img;
        this.getThumbnail().style.margin = "0.5rem";
        this.imageIsDone = true;
      }
      else {
        setTimeout(() => {
          html2canvas(this.capture.nativeElement).then(canvas => {
            this.getThumbnail().style.imageRendering = 'auto';
            this.getThumbnail().style.backgroundImage = "url(" + canvas.toDataURL() + ")";
            this.getThumbnail().style.margin = "0.5rem";
            this.imageIsDone = true;
            this.capture.nativeElement.remove();
          });
        }, 1)
      }
    })
  }
  getThumbnail() {
    return _.get(this, 'thumbnail.nativeElement')
  }
  getWidth() {
    return _.get(this, 'dashboardService.thumbnailSize.width', 0)
  }
  getHeight() {
    return _.get(this, 'dashboardService.thumbnailSize.height', 0)
  }


}
