import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ModelsPostObject } from '../model/modelsPostObject';
import { ModelsNewID } from '../model/modelsNewID';
import { ModelsFileShortList } from '../model/modelsFileShortList';
import { environment } from 'src/environments/environment';
import _ from 'lodash';
export interface PathPartsModel {
  name: string;
  id: string;
}
export interface PathModel {
  path: string;
  parts: Array<PathPartsModel>;
}

@Injectable({ providedIn: 'root' })
export class Files2Service {
  public basePath = 'XXXX';
  focusGeneListFolderId;
  constructor(private readonly http: HttpClient) {
   
  }
  listFolder(folderId: string): Observable<ModelsFileShortList> {
    return this.http.get<ModelsFileShortList>(`${this.basePath}/mas/fs/files/${folderId}/ls`, {});
  }

  listSystemFiles(folderId) {
    let queryParameters = new HttpParams({});
    queryParameters = queryParameters.set('filters', `parent="${folderId}",ownedByMe=true,sharedWithMe=true,sharedWithMyUserGroups=true`);

    const headers = new HttpHeaders({
      'X-User-Id': environment.name == 'prod' ? '9f8d47e8-d99a-455f-83b2-6e56ae64d330' : '41632a54-fe86-49a8-aaf6-fc110f96f5cf',
    });

    return this.http.get<ModelsFileShortList>(`${this.basePath}/mas/fs/files`, {
      params: queryParameters,
      headers,
    });
  }

  getSystemFile(fileId: string) {
    const headers = new HttpHeaders({
      'X-User-Id': environment.name == 'prod' ? '9f8d47e8-d99a-455f-83b2-6e56ae64d330' : '41632a54-fe86-49a8-aaf6-fc110f96f5cf',
    });

    return this.http.get<any>(`${this.basePath}/mas/fs/files/${fileId}`, {
      headers,
    });
  }

  removePermissionFromFile(permId: string, fileId, country?): Observable<any> {
    if (country) {
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.delete<any>(`${this.basePath}/mas/fs/files/${fileId}/permissions/${permId}`, { headers });
    }
    return this.http.delete<any>(`${this.basePath}/mas/fs/files/${fileId}/permissions/${permId}`, {});
  }

  postMetaDataFile(fileId: string, body: any, observe: any, country): Observable<any> {
    let headers = new HttpHeaders({});
    let type;
    const isCsv = (name) => {
      return !!name.match(/.csv$/i);
    };
    if (isCsv(body.name)) {
      type = 'text/csv';
    } else {
      type = 'application/json';
    }
    headers = headers.set('Content-Type', type);
    headers = headers.set('country', country);
    return this.http.patch<any>(`${this.basePath}/mas/fs/files/${fileId}/metadata`, body, {
      observe,
      headers,
    });
  }

  modify(itemId, newItem, country) {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.patch<any>(`${this.basePath}/mas/fs/files/${itemId}`, newItem, { headers });
  }
  upload(uploadSessionId: string, file: Blob, observe: any, country): Observable<any> {
    let reportChunkProgress;
    let headers = new HttpHeaders();
    headers = headers.set('accept', 'application/json');
    headers = headers.set('Content-Type', 'application/octet-stream');
    const getChunkData = (fileToUpload, currentChunk, chunkSize, totalNbChunks) => {
      let dataToUpload: any;
      let contentRange = '';
      const offset = currentChunk * chunkSize;
      if (currentChunk < totalNbChunks - 1) {
        dataToUpload = fileToUpload.slice(offset, offset + chunkSize);
        contentRange = 'bytes ' + offset + '-' + (offset + chunkSize) + '/' + fileToUpload.size;
      } else {
        dataToUpload = fileToUpload.slice(offset, fileToUpload.size);
        contentRange = 'bytes ' + offset + '-' + fileToUpload.size + '/' + fileToUpload.size;
      }
      return { dataToUpload, contentRange };
    };
    const uploadChunk = (observer, file, currentChunk, uploadSessionId, headers, observe, country) => {
      const chunkSize = file.size <= 100000000 ? file.size : 5000000;
      const totalNbChunks = Math.ceil(file.size / chunkSize);
      const { dataToUpload, contentRange } = getChunkData(file, currentChunk, chunkSize, totalNbChunks);
      headers = headers.set('Content-Range', contentRange);
      headers = headers.set('country', country);
      this.http
        .patch<any>(`${this.basePath}/mas/fs/upload/${encodeURIComponent(String(uploadSessionId))}`, dataToUpload, {
          headers,
          observe,
        })
        .subscribe(
          (res) => {
            reportChunkProgress = ((currentChunk + 1) / totalNbChunks) * 100;
            observer.next(reportChunkProgress);
            if (reportChunkProgress < 100) {
              uploadChunk(observer, file, currentChunk + 1, uploadSessionId, headers, observe, country);
            } else {
              observer.complete(res);
            }
          },
          (error) => {
            observer.error(error);
          }
        );
    };
    return new Observable((observer) => {
      uploadChunk(observer, file, 0, uploadSessionId, headers, observe, country);
    });
  }

  postFile(fileId: string, body: ModelsPostObject, observe: any, country, dataType?: string): Observable<any> {
    let params = new HttpParams({});
    if (!!dataType) {
      params = params.set('data_type', dataType as any);
    }
    if (country) {
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.post<ModelsNewID>(`${this.basePath}/mas/fs/files/${fileId}`, body, {
        params,
        headers,
        observe,
      });
    }
    return this.http.post<ModelsNewID>(`${this.basePath}/mas/fs/files/${fileId}`, body, {
      params,
      observe,
    });
  }

  getPathFromFileId(fileId: string, country?): Observable<PathModel> {
    if (fileId === 'root') {
      return new Observable((observer) => {
        observer.next({ path: null, parts: [] });
      });
    } else {
      if (country) {
        let headers = new HttpHeaders({});
        headers = headers.set('country', country);
        return this.http.get<PathModel>(`${this.basePath}/mas/fs/files/${fileId}/path`, { headers });
      } else {
        return this.http.get<PathModel>(`${this.basePath}/mas/fs/files/${fileId}/path`);
      }
    }
  }

  listFiles = (sortBy: string, filters: string, limit: number, page: number, country?: string): Observable<any> => {
    let params = new HttpParams({});
    if (!!sortBy) params = params.set('sortBy', sortBy as any);
    if (!!filters) params = params.set('filters', filters as any);
    if (!!limit) params = params.set('limit', limit as any);
    if (!!page) params = params.set('page', (page - 1) as any);
    if (country) {
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.get<any>(`${this.basePath}/mas/fs/files`, {
        params,
        headers,
      });
    }
    return this.http.get<any>(`${this.basePath}/mas/fs/files`, {
      params,
    });
  };
  getFileFromPath = (path: string, country?: string): Observable<any> => {
    let params = new HttpParams({});

    if (!!path) params = params.set('path', path as any);
    if (country) {
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.get<any>(`${this.basePath}/mas/fs/files/frompath`, {
        params,
        headers,
      });
    }
    return this.http.get<any>(`${this.basePath}/mas/fs/files/frompath`, {
      params,
    });
  };

  moveFile(fileId: string, destinationId: string, country): Observable<any> {
    if (fileId === destinationId) {
      return throwError({ error: { message: 'Impossible to move a folder on itself' } }) as any;
    }
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.patch<any>(`${this.basePath}/mas/fs/files/${fileId}/move?dest_id=${destinationId}`, { headers });
  }

  retrieveFileGroup(groupId: string, country): Observable<any> {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.get<any>(`${this.basePath}/mas/fs/filegroups/${groupId}`, { headers });
  }
  downloadBlobFile(fileId: string, dataType?: string): Observable<any> {
    let queryParameters = new HttpParams({});
    if (!!dataType) {
      queryParameters = queryParameters.set('data_type', dataType as any);
    }
    return this.http.get(`${this.basePath}/mas/fs/files/${fileId}/data`, {
      params: queryParameters,
      responseType: 'blob',
    });
  }
  retrieveInfo(id, country): Observable<any> {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.get<any>(`${this.basePath}/mas/fs/files/${id}`, {
      headers,
    });
  }
  count(filters: string, country): Observable<any> {
    let params = new HttpParams({});
    let headers = new HttpHeaders({});
    params = params.set('filters', filters as any);
    headers = headers.set('country', country);
    return this.http.get<any>(`${this.basePath}/mas/fs/files/count`, {
      params,
      headers,
    });
  }
  restore(fileId: string, country): Observable<any> {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.post<any>(`${this.basePath}/mas/fs/files/${fileId}/restore`, {}, { headers });
  }
  trash(fileId: string, country): Observable<any> {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.delete<any>(`${this.basePath}/mas/fs/files/${fileId}`, { headers });
  }
  permanentlyDelete(fileId: string, country): Observable<any> {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    let params = new HttpParams({});
    params = params.set('notrash', true as any);
    return this.http.delete<any>(`${this.basePath}/mas/fs/files/${fileId}`, {
      params,
      headers,
    });
  }

  addToGroup(groupId: string, file_id: string, country): Observable<any> {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.post<any>(`${this.basePath}/mas/fs/filegroups/${groupId}/files/${file_id}`, {}, { headers });
  }
}
