import { Component, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-full-screen-modal',
  templateUrl: './full-screen-modal.component.html',
  styleUrls: ['./full-screen-modal.component.scss'],
})
export class FullScreenModalComponent implements AfterViewInit {
  @Input() title?: any;
  @Input() idName: any;
  @ViewChild('modal', { static: false }) modal: ElementRef;

  constructor(
    public layout: LayoutOrganizationService,
  ) { }
  ngAfterViewInit() {
    this.modal.nativeElement.classList.add('expand')
  }
  getTitle() {
    return _.get(this, 'title', null)
  }

}
