<app-header2>
  <div *ngIf="title" class="h-100 w-100 d-flex align-items-center justify-content-between text-light">
    <div class="d-flex flex-fill justify-self-start align-items-center">
      <app-page-icon img="{{ _.get(solution, 'spec.icon') }}"></app-page-icon>
      <h3 class="m-0 ml-3">{{ _.get(solution, 'spec.title') }}</h3>
    </div>
  </div>
</app-header2>
<app-content [isTab]="false" [isHeader]="true">
  <div [style.gap.rem]="3" class="bg-ivory d-flex flex-nowrap h-100 p-5">
    <div class="w-50 border p-5 smooth-border scrollable bg-white">
      <div class="h-100" *ngIf="atlassianId">
        <documentation-atlassian [docAtlassianID]="getAtlassianID()"></documentation-atlassian>
      </div>
    </div>
    <div class="align-items-center d-flex flex-column flex-fill">
      <button
        id="createProject"
        (click)="createProject()"
        class="btn btn-primary font-weight-bold small text-light soft-border my-5"
        [disabled]="!canCreateProject"
      >
        Create Project
      </button>
      <div class="smooth-border scrollable w-100 flex-fill" [class.border]="videos.length > 1" [class.bg-white]="videos.length > 1">
        <div [style.gap.rem]="3" class="p-5 h-100 d-flex flex-column">
          <div *ngFor="let video of videos; index as i" class="player bg-dark smooth-border">
            <iframe
              class="w-100 h-100"
              [src]="video"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-content>
