import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class InfiniteListingService {
  public basePath = 'XXXX';
  constructor(private readonly http: HttpClient) {}
  executeLink(link, country?) {
    if(country){
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.get<any>(link, { headers }); 
    }
    return this.http.get<any>(link);
  }
  infiniteListing = (func, country?) => {
    const concatResponse = (res, res2) => {
      let newRes = res2;
      let newCount = _.get(res, 'count') + _.get(res2, 'count');
      _.set(newRes, 'count', newCount);
      let newData = _.concat(_.get(res, 'data'), _.get(res2, 'data'));
      _.set(newRes, 'data', newData);
      return newRes;
    };
    const loopExecution = (link, concatenedResponse, resolve, reject) => {
      const exec = country ? this.executeLink(link, country) : this.executeLink(link)
      return exec.subscribe(
        (res2) => {
          concatenedResponse = concatResponse(concatenedResponse, res2);
          if (_.get(res2, 'links.next')) {
            return loopExecution(_.get(res2, 'links.next'), concatenedResponse, resolve, reject);
          } else {
            resolve(concatenedResponse);
          }
        },
        (error) => {
          reject(_.get(error, 'error.message'));
        }
      );
    };
    const startExecution = (func) => {
      return new Promise((resolve, reject) => {
        return func().subscribe(
          (res) => {
            if (_.get(res, 'links.next')) {
              return loopExecution(_.get(res, 'links.next'), res, resolve, reject);
            } else {
              resolve(res);
            }
          },
          (error) => {
            reject(_.get(error, 'error.message'));
          }
        );
      });
    };
    return startExecution(func);
  };
}
