import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { PermissionsHttpService } from 'src/app/shared/permissions-http-service/permissions-http.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DataAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private permissions: PermissionsHttpService
  ) { }

  canActivate():
    | boolean
    | UrlTree
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree> {
    return this.permissions
      .checkPermission({ permissions: { manageFocusGeneLists: {} } })
      .pipe(
        map((res) => {
          if (res.manageFocusGeneLists) {
            return true;
          } else {
            return this.router.createUrlTree(['/']);
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
