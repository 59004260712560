import { Component, OnChanges } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ProjectsService } from 'src/app/api/projects.service';
import _ from 'lodash';
@Component({
  selector: 'formly-field-file',
  template: `<div>
    <label class="btn small font-weight-bold btn-outline-primary soft-border">
      Choose file
      <input
        class="btn btn-outline-primary soft-border"
        [hidden]="true"
        (change)="uploadFile($event)"
        type="file"
        [formControl]="formControl"
        [formlyAttributes]="field"
      />
    </label>
    <span *ngIf="file" class="mx-3 font-italic font-weight-bold small">{{ _.get(file, 'name') }}</span>

    <div *ngIf="formControl.status === 'INVALID'" class="invalid-feedback d-block">
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>
  </div>`,
})
export class FormlyFieldFile extends FieldType<FieldTypeConfig> {
  file;
  _ = _;
  constructor(private projectsService: ProjectsService) {
    super();
  }
  uploadFile(event) {
    if (this.formControl.status === 'INVALID') {
        return;
      }
    this.file = new File(event.target.files, event.target.files[0].name, {
      type: 'text/plain',
    });
   
    this.projectsService.uploadSmallFile(this.file, '').subscribe(
      (res) => {
        this.formControl.setValue(res);
      },
      (err) => {
        console.log(err);
      },
      () => {
        this.file = undefined;
        this.formControl.reset();
      }
    );
  }
}
