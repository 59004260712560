import { distinctUntilChanged, filter, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { Component, Input, ElementRef, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { animate, animation, AnimationBuilder, AnimationFactory, AnimationPlayer, group, query, style } from '@angular/animations';
import { Subject } from 'rxjs';

@Component({
  selector: 'mims-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements AfterViewInit, OnDestroy {
  /** Img path of logo */
  @Input() imgPath = 'assets/images/logo/mims_logo_little_white.png';

  /** Display text */
  @Input() text: string;

  /** Indicate if enable backdrop */
  @Input() enableBackDrop = true;

  /** Loading state */
  @Input() set isLoading(loading: boolean) {
    this.loadingStateSubject.next(!!loading);
  }

  /** Indicate if show the overlay */
  showOverlay = false;

  /** Subject indicate the loading state */
  private loadingStateSubject = new BehaviorSubject<boolean>(false);

  /** indicate if animation is playing */
  private animationPlayingSubject = new BehaviorSubject<boolean>(false);

  /** Animation player */
  private player: AnimationPlayer;

  private componentDestroyed$: Subject<void> = new Subject();

  constructor(private animationBuilder: AnimationBuilder, private elementRef: ElementRef, private _cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    // ignore the unloading request until the loader is show at least once
    const startLoading$ = this.loadingStateSubject.asObservable().pipe(
      filter((state) => !!state),
      take(1)
    );

    const loadingStateAfterAnimationFinish$ = this.animationPlayingSubject.asObservable().pipe(
      filter((state) => !state),
      switchMap(() => of(this.loadingStateSubject.value)),
      takeUntil(this.componentDestroyed$)
    );

    // make sure the animation completed before then next opposite animation
    startLoading$
      .pipe(
        switchMap(() =>
          this.loadingStateSubject.pipe(
            distinctUntilChanged(),
            withLatestFrom(this.animationPlayingSubject.asObservable()),
            switchMap(([loading, animationPlaying]) => {
              return animationPlaying ? loadingStateAfterAnimationFinish$ : of(loading);
            }),
            distinctUntilChanged(),
            takeUntil(this.componentDestroyed$)
          )
        )
      )
      .subscribe((loading) => {
        this.playAnimation(loading);
      });
  }

  /** Play animation by the state */
  playAnimation(loadingState: boolean) {
    if (this.player) {
      this.player.destroy();
    }

    let animationFactory: AnimationFactory;
    if (loadingState) {
      animationFactory = this.animationBuilder.build(
        animation([
          query('.loader-container, p', [style({ visibility: 'hidden' })]),
          group([
            query('.loader-container, p', [
              style({ transform: 'scale(0)' }),
              animate(
                `300ms cubic-bezier(0.4, 0, 0.2, 1)`,
                style({
                  visibility: 'visible',
                  transform: 'scale(1)',
                })
              ),
            ]),
          ]),
        ])
      );
    } else {
      animationFactory = this.animationBuilder.build(
        animation([
          query('.loader-container, p', [style({ visibility: 'visible' })]),
          query('.spinner', [style({ visibility: 'visible' }), animate('400ms', style({ visibility: 'hidden' }))]),
          group([
            query('.loader-container, p', [
              style({ transform: 'scale(1)' }),
              animate(
                `200ms cubic-bezier(0.4, 0, 0.2, 1)`,
                style({
                  visibility: 'hidden',
                  transform: 'scale(0)',
                })
              ),
            ]),
          ]),
        ])
      );
    }

    this.player = animationFactory.create(this.elementRef.nativeElement);

    this.player.onStart(() => {
      // adding overlay when loading start
      this.showOverlay = loadingState ? loadingState : this.showOverlay;
      this.onAnimationStart();
      this._cdr.detectChanges();
    });

    this.player.onDone(() => {
      // remove overlay when loading finish
      this.showOverlay = loadingState ? this.showOverlay : loadingState;
      this.onAnimationEnd();
      this._cdr.detectChanges();
    });
    this.player.play();
  }

  onAnimationStart() {
    this.animationPlayingSubject.next(true);
  }

  onAnimationEnd() {
    this.animationPlayingSubject.next(false);
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.destroy();
    }
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
