<div class="mb-5 d-flex flex-column">
  <div class="d-flex" *ngIf="_.get(fields, '0.templateOptions.options.length') && projects.value && instances.value" [style.gap.rem]="2">
    <div
      *ngFor="let field of fields; let i = index"
      [id]="field.key"
      class="form-group flex-fill d-flex flex-column"
      [class.float-left]="i === 0"
      [class.float-right]="i === 1"
    >
      <label *ngIf="_.get(field, 'templateOptions.label.length')"
        >{{ _.get(field, 'templateOptions.label') }}
        <span *ngIf="_.get(field, 'templateOptions.required')">*</span>
        <a
          *ngIf="_.get(field, 'templateOptions.info-link')"
          target="_blank"
          [href]="_.get(field, 'templateOptions.info-link')"
          class="cursor-info text-lowercase bg-primary ml-1 border badge badge-pill"
          ><i aria-hidden="true" class="fa-solid text-light fa-info"></i></a
      ></label>
      <div class="border bg-white form-check-container py-4">
        <div
          class="form-check px-4 d-flex flex-nowrap cursor-pointer my-2"
          *ngFor="let cluster of _.get(field, 'templateOptions.options'); let index= index "
        >
          <input class="form-check-input ml-3 mr-2 position-relative" type="checkbox" (change)="onSelection($event)" [value]="index" />
          <div class="form-check-label noselect position-relative" (click)="toggleSibling($event)">{{cluster.label}}</div>
        </div>
      </div>
      <div class="text-italic text-center text-muted mt-3" *ngIf="_.nth(totalPatientsPerField,i)">
        {{_.nth(totalPatientsPerField,i)}} patients selected
      </div>
    </div>
  </div>
</div>
