import { Component, OnInit, Input } from '@angular/core';
import { VizService } from 'src/app/api/viz.service';
import { DashboardService } from 'src/app/api/dashboard.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-viz-select-box',
  templateUrl: './viz-select-box.component.html',
  styleUrls: ['./viz-select-box.component.scss']
})
export class VizSelectBoxComponent implements OnInit {
  @Input() viz;
  constructor(public vizService: VizService, public dashboardService: DashboardService) { }

  ngOnInit(): void {
  }
  onOptionsSelected = (event) => {
    const value = event.target.value;
    if (value.length > 0) {
      this.dashboardService.showChildrenEvent.emit({
        parentId: this.viz.id,
        ids: _.split(value, ','),
      });
    }
  }

}
