<!-- <div class='tile' *ngIf='size && size.width && size.height' [style.width.px]='size.width'
    [style.height.px]='size.height'>
    <div class='overlay'></div>
    <div class="thumbnail-container d-flex flex-column">
        <div class="vizTitleThumbnail">{{ title }}</div>
        <div class="thumbnail">
            <ng-content></ng-content>
        </div>
    </div>
</div> -->

<div class='tile' [style.width.px]='getWidth()' [style.height.px]='getHeight()'>
    <div class="thumbnail-container d-flex flex-column">
        <!-- <div class="vizTitleThumbnail">{{ title }}</div> -->
        <div class="thumbnail">
            <ng-content></ng-content>
        </div>
        <div class='legend text-light bg-darker text-center text-wrap'>toto
        </div>

    </div>
</div>