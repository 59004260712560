import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-icon',
  templateUrl: './page-icon.component.html',
  styleUrls: ['./page-icon.component.scss']
})
export class PageIconComponent{
  @Input() iconContainerStyle? = ''
  @Input() iconClass? = ''
  @Input() img? = ''

  constructor() { }
}
