import { OverlayModule } from '@angular/cdk/overlay';
import { DropDownModule } from './../drop-down/drop-down.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select.component';

@NgModule({
  declarations: [SelectComponent],
  imports: [CommonModule, DropDownModule, OverlayModule],
  exports: [SelectComponent],
})
export class SelectModule {}
