import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { col1, col2, col3, col4, col5, col6 } from '../../plotly-templating';
import * as _ from 'lodash';

export enum DataType {
  AA = 'AA',
  DNA = 'DNA',
  RNA = 'RNA',
}
export interface LegendType {
  type: string;
  color: string;
}
export interface LetterType {
  letter: string;
  type: string;
}
export const lettersAA: Array<LetterType> = [
  { letter: 'I', type: 'Hydrophobic' },
  { letter: 'M', type: 'Hydrophobic' },
  { letter: 'T', type: 'Polar' },
  { letter: 'N', type: 'Polar' },
  { letter: 'K', type: 'Positive' },
  { letter: 'S', type: 'Polar' },
  { letter: 'R', type: 'Positive' },
  { letter: 'L', type: 'Hydrophobic' },
  { letter: 'P', type: 'Special' },
  { letter: 'H', type: 'Positive' },
  { letter: 'Q', type: 'Polar' },
  { letter: 'V', type: 'Hydrophobic' },
  { letter: 'A', type: 'Hydrophobic' },
  { letter: 'D', type: 'Negative' },
  { letter: 'E', type: 'Negative' },
  { letter: 'G', type: 'Special' },
  { letter: 'S', type: 'Polar' },
  { letter: 'F', type: 'Hydrophobic' },
  { letter: 'L', type: 'Hydrophobic' },
  { letter: 'Y', type: 'Hydrophobic' },
  { letter: '*', type: 'Stop' },
  { letter: '.', type: 'Blank' },
  { letter: 'C', type: 'Special' },
  { letter: 'W', type: 'Hydrophobic' },
];
export const lettersDNA: Array<LetterType> = [
  { letter: 'A', type: 'Adenine' },
  { letter: 'C', type: 'Cytosine' },
  { letter: 'G', type: 'Guanine' },
  { letter: 'T', type: 'Thymine' },
  { letter: 'U', type: 'Thymine' },
  { letter: 'M', type: 'Ambiguity' },
  { letter: 'R', type: 'Ambiguity' },
  { letter: 'W', type: 'Ambiguity' },
  { letter: 'S', type: 'Ambiguity' },
  { letter: 'Y', type: 'Ambiguity' },
  { letter: 'K', type: 'Ambiguity' },
  { letter: 'V', type: 'Ambiguity' },
  { letter: 'H', type: 'Ambiguity' },
  { letter: 'D', type: 'Ambiguity' },
  { letter: 'B', type: 'Ambiguity' },
  { letter: 'N', type: 'Ambiguity' },
  { letter: '.', type: 'Blank' },
];
export const lettersRNA: Array<LetterType> = [
  { letter: 'A', type: 'Adenine' },
  { letter: 'C', type: 'Cytosine' },
  { letter: 'G', type: 'Guanine' },
  { letter: 'T', type: 'Thymine/Uracil' },
  { letter: 'U', type: 'Thymine/Uracil' },
  { letter: 'M', type: 'Ambiguity' },
  { letter: 'R', type: 'Ambiguity' },
  { letter: 'W', type: 'Ambiguity' },
  { letter: 'S', type: 'Ambiguity' },
  { letter: 'Y', type: 'Ambiguity' },
  { letter: 'K', type: 'Ambiguity' },
  { letter: 'V', type: 'Ambiguity' },
  { letter: 'H', type: 'Ambiguity' },
  { letter: 'D', type: 'Ambiguity' },
  { letter: 'B', type: 'Ambiguity' },
  { letter: 'N', type: 'Ambiguity' },
  { letter: '.', type: 'Blank' },
];
export const colorsAA: Array<LegendType> = [
  { type: 'Hydrophobic', color: col1 },
  { type: 'Polar', color: col2 },
  { type: 'Positive', color: col3 },
  { type: 'Negative', color: col4 },
  { type: 'Special', color: col5 },
  { type: 'Stop', color: col6 },
  { type: 'Blank', color: null },
];
export const colorsDNA: Array<LegendType> = [
  { type: 'Adenine', color: col1 },
  { type: 'Cytosine', color: col2 },
  { type: 'Guanine', color: col3 },
  { type: 'Thymine', color: col4 },
  { type: 'Ambiguity', color: col5 },
  { type: 'Blank', color: null },
];
export const colorsRNA: Array<LegendType> = [
  { type: 'Adenine', color: col1 },
  { type: 'Cytosine', color: col2 },
  { type: 'Guanine', color: col3 },
  { type: 'Thymine/Uracil', color: col4 },
  { type: 'Ambiguity', color: col5 },
  { type: 'Blank', color: null },
];

@Component({
  selector: 'app-viz-alignment',
  templateUrl: './viz-alignment.component.html',
  styleUrls: ['../viz-style.scss', './viz-alignment.scss'],
})
export class VizAlignmentComponent implements OnInit {
  @Input() data;
  @Input() layout;
  @Input() id: string;
  @Input() size;
  DataType = DataType;
  @Input() dataType: DataType;
  @Input() title;
  lettersAA = lettersAA;
  lettersDNA = lettersDNA;
  lettersRNA = lettersRNA;
  colorsAA = colorsAA;
  colorsDNA = colorsDNA;
  colorsRNA = colorsRNA;

  letters: Array<LetterType>;
  colors: Array<LegendType>;



  getTypeFromLetter(letter) {
    return _.find(this.letters, { letter }, 'Blank').type;
  }
  getColorFromType(type) {
    return _.find(this.colors, { type }, null).color;
  }
  getColorFromLetter(letter) {
    const type = this.getTypeFromLetter(letter);
    return this.getColorFromType(type);
  }
  showNumber(i) {
    return i % 10 === 0 ? true : false;
  }
  setRefData() {
    if (this.dataType === this.DataType.AA) {
      this.letters = this.lettersAA;
      this.colors = this.colorsAA;
    }
    if (this.dataType === this.DataType.RNA) {
      this.letters = this.lettersRNA;
      this.colors = this.colorsRNA;
    }
    if (this.dataType === this.DataType.DNA) {
      this.letters = this.lettersDNA;
      this.colors = this.colorsDNA;
    }
  }
  ngOnInit(): void {
    this.setRefData();
  }
}
