<div class="page d-flex flex-column">
  <app-header2
    title="Create {{ solution == 'popseg' ? 'cohort stratification' : solution == 'biomark' ? 'biomarker discovery' : '' }}  project "
    img="{{
      solution == 'popseg'
        ? '/assets/images/icon-popseg.svg'
        : solution == 'biomark'
        ? '/assets/images/icon-biomark.svg'
        : undefined
    }}"
    iconClass="{{solution == 'popseg' || solution == 'biomark'? undefined : 'fad fa-folder-open' }}"
  >
  </app-header2>
  <app-content [isHeader]="true">
    <div id="grid">
      <div id="steps">
        <ul class="list-unstyled">
          <li
            class="step mb-5"
            [class.active]="index === projectsService.getActiveStep()"
            *ngFor="let step of projectsService.getStepsList(); let index = index; let last = last"
          >
            <span *ngIf="!last">
              <i class="fa fal fa-check" [class.text-transparent]="!(projectsService.getActiveStep() > index)" aria-hidden="true"></i>
              {{ step }}
            </span>
          </li>
        </ul>
      </div>
      <div id="router" class="border">
        <router-outlet></router-outlet>
      </div>
    </div>
  </app-content>
  <mims-loader id="left-loader" [isLoading]="this.fileGroupsService.isLoading" [enableBackDrop]="true"></mims-loader>
</div>
