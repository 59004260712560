<div class='mt-3 mb-4 input-field-container ' [class.row]='getLinedLabel()'>
  <div class='col-4 my-auto text-right'>
    <label *ngIf='getLinedLabel()' [for]="getFormControlName()">
      {{getLinedLabel()}}
    </label>
  </div>


  <div [formGroup]='parentForm' class='client' [class.col-8]='getLinedLabel()' [class.my-auto]='getLinedLabel()'>
    <label *ngIf='getDescription()' [for]="getFormControlName()">
      {{getDescription()}}
    </label>
    <label *ngIf='!getDescription() && getLabel()' [for]="getFormControlName()"
      class='small text-muted  text-uppercase'>
      {{getLabel()}}
    </label>
    <span *ngIf='HtmlTypes.Text === getHtmlType()'>
      <span [style.width]='getWidth()' class="input-icon-container d-flex align-items-center flex-nowrap bg-blue"
        [class.title-container]='getEmphasize()' [class.field-error]='hasError()'>
        <input   *ngIf='getCharMax()' (click)="updateField()" maxlength="{{getCharMax()}}" (keyup)='valueSelected.next($event)' [readonly]='getReadOnly()'
          [style.width]='getDisplayedIcon() ? "90%" : "100%"' class='value-input form-control ml-2 mb-0'
          [id]="getFormControlName()" [class.title-input]='getEmphasize()' [class.text-error]="hasError()"
          [formControlName]="getFormControlName()" [value]='getDefaultValue()'
          [type]='getPassword() ? showPassword ? "text" : "password" : "text"' [placeholder]="getPlaceholder()" />
          <input *ngIf='!getCharMax()'  (click)="updateField()"  (keyup)='valueSelected.next($event)' [readonly]='getReadOnly()'
          [style.width]='getDisplayedIcon() ? "90%" : "100%"' class='value-input form-control ml-2 mb-0'
          [id]="getFormControlName()" [class.title-input]='getEmphasize()' [class.text-error]="hasError()"
          [formControlName]="getFormControlName()" [value]='getDefaultValue()'
          [type]='getPassword() ? showPassword ? "text" : "password" : "text"' [placeholder]="getPlaceholder()" />
        <span *ngIf='!getFormControl().valid && getDisplayedIcon()' class='d-flex  align-items-center'
          ngbTooltip="{{getError()}}" tooltipClass="custom-tooltip" placement="left">
          <i class='icon' [class.text-error]="hasError()" aria-hidden='true' [ngClass]='getDisplayedIcon()'></i>
        </span>
        <span *ngIf='getPassword()' tabindex="-1" (click)="passwordVisibilitySwitcher()"
          class="focus-none btn btn-icon cursor-pointer ">
          <i class="users-icon cursor-pointer far" [class.fa-eye]='showPassword' [class.fa-eye-slash]='!showPassword'
            aria-hidden="true"></i>
        </span>
      </span>
    </span>
    <span *ngIf='HtmlTypes.Number === getHtmlType() '>
      <span [style.width]='getWidth()' class="input-icon-container d-flex align-items-center flex-nowrap bg-blue"
        [class.field-error]='hasError()'>
        <input (click)="updateField()"  [readonly]='getReadOnly()' [style.width]='getDisplayedIcon() ? "90%" : "100%"'
          class='value-input form-control ml-2 mb-0' [id]="getFormControlName()" [class.text-error]="hasError()"
          [formControlName]="getFormControlName()" [value]='getDefaultValue()' type='number' [step]='getStep()'
          [placeholder]="getPlaceholder()" />
        <span *ngIf='!getFormControl().valid && getDisplayedIcon()' class='d-flex align-items-center'
          ngbTooltip="{{getError()}}" tooltipClass="custom-tooltip" placement="left">
          <i class='icon' [class.text-error]="hasError()" aria-hidden='true' [ngClass]='getDisplayedIcon()'></i>
        </span>
      </span>
    </span>
   
    <span *ngIf='HtmlTypes.TextArea === getHtmlType() '>
      <span [style.width]='getWidth()'
        class="input-icon-container textarea-container d-flex align-items-center flex-nowrap bg-blue"
        [class.field-error]='hasError()'>
        <textarea [readonly]='getReadOnly()' [style.width]='getDisplayedIcon() ? "90%" : "100%"'
          class='value-input form-control ml-2 mb-0' [id]="getFormControlName()" [class.text-error]="hasError()"
          [formControlName]="getFormControlName()" [value]='getDefaultValue()' type='text'
          [placeholder]="getPlaceholder()" (blur)='valueSelected.next(true)' (keyup)="updateCount()"></textarea>
        <span *ngIf='!getFormControl().valid && getDisplayedIcon()' class='d-flex  align-items-center'
          ngbTooltip="{{getError()}}" tooltipClass="custom-tooltip" placement="left">
          <i class='icon' [class.text-error]="hasError()" aria-hidden='true' [ngClass]='getDisplayedIcon()'></i>
        </span>
      </span>
      <p *ngIf='getCharMax()' class="mt-1 ml-2 text-primary small text-italic font-weight-bold ">&#9679;
        {{ getCharRemaining() }} characters left</p>
    </span>
    <span *ngIf='HtmlTypes.Select === getHtmlType() '>
      <span [style.width]='getWidth()'
        class="input-icon-container border-0 d-flex align-items-center flex-nowrap bg-blue"
        [class.field-error]='hasError()' [class.px-0]='!getDisplayedIcon()'>
        <select [id]='getFormControlName()' (change)='valueSelected.next(true)'
          [style.width]='getDisplayedIcon() ? "90%" : "100%"'  [class.field-error]="hasError()"
          [class.text-error]="hasError()" [formControlName]="getFormControlName()" class="form-control ">
          <option [ngValue]="null" *ngIf='getPlaceholder()'>
            {{getPlaceholder()}}</option>
          <option *ngFor='let opt of getAllowedValues()' [ngValue]="opt">
            {{ _.get(opt,'name',opt)}}</option>
        </select>
        <span *ngIf='!getFormControl().valid && getDisplayedIcon()' class='d-flex  align-items-center'
          ngbTooltip="{{getError()}}" tooltipClass="custom-tooltip" placement="left">
          <i class='icon' [class.text-error]="hasError()" aria-hidden='true' [ngClass]='getDisplayedIcon()'></i>
        </span>
      </span>

    </span>
    <span *ngIf='HtmlTypes.MultiSelect === getHtmlType() '>
      <span [style.width]='getWidth()'
        class="input-icon-container border-0 d-flex align-items-center flex-nowrap bg-blue"
        [class.field-error]='hasError()' [class.px-0]='!getDisplayedIcon()' >
        <select [id]='getFormControlName()' (change)='checkMultipleSelectValue()' [size]='getMaxSelectHeight()'
          [style.width]='getDisplayedIcon() ? "90%" : "100%"' [class.field-error]="hasError()" multiple
          [class.text-error]="hasError()" [formControlName]="getFormControlName()" class="form-control multiple">
          <option *ngFor='let opt of getAllowedValues(); let index = index' [ngValue]="opt">
            {{ _.get(opt,'name',getAllowedValuesDescriptions(index))}}</option>
        </select>
        <span *ngIf='!getFormControl().valid && getDisplayedIcon()' class='d-flex  align-items-center'
          ngbTooltip="{{getError()}}" tooltipClass="custom-tooltip" placement="left">
          <i class='icon' [class.text-error]="hasError()" aria-hidden='true' [ngClass]='getDisplayedIcon()'></i>
        </span>
      </span>

    </span>

    <div *ngIf='getHtmlType() === HtmlTypes.Radio' class='mr-3'>
      <div [id]="getFormControlName()"  class="form-check" *ngFor='let el of getAllowedValues(); let index=index'>
        <input class="form-check-input" [readonly]='getReadOnly()' type="radio" [checked]='getValue() === el'
          [name]="getFormControlName()" [id]="el" (change)='selectValue(index)'>
        <label class="form-check-label" [for]="el">
          {{getAllowedValuesDescriptions(index)}}
        </label>
      </div>

    </div>
    <div *ngIf='getHtmlType() === HtmlTypes.Checkboxes'
      class='mr-3 d-flex align-items-center justify-content-between flex-nowrap'>
      <div>
        <div [id]="getFormControlName()" class="form-check " [class.text-error]="hasError()" *ngFor='let el of getAllowedValues(); let index=index'>
          <input [disabled]='getReadOnly()' class="form-check-input " type="checkbox" [checked]='getValue(index)'
            [value]='getValue(index)' (change)='checkValue(index)' [name]="getFormControlName()" [id]='el' >
          <label class="form-check-label" [for]="el">
            {{getAllowedValuesDescriptions(index)}}
          </label>

        </div>
      </div>
      <span *ngIf='!getFormControl().valid && getDisplayedIcon()' class='d-flex  align-items-center'
        ngbTooltip="{{getError()}}" tooltipClass="custom-tooltip" placement="left">
        <i class='icon' aria-hidden='true' [ngClass]='getDisplayedIcon()' [class.text-error]="hasError()"></i>
      </span>

    </div>
    <div *ngIf='getHtmlType() === HtmlTypes.Checkbox' class='mr-3'>
      <div class="form-check ">
        <input [readonly]='getReadOnly()' class="form-check-input " type="checkbox" [checked]='getValue()'
          [value]='getValue()' (change)='checkValue()' [name]="getFormControlName()" [id]="getFormControlName()">
        <label class="form-check-label" [for]="getFormControlName()">
          {{getFormControlName()}}
        </label>
      </div>

    </div>
    <div *ngIf='getHtmlType() === HtmlTypes.File'>
      <div class='mr-3 d-flex align-items-center justify-content-between flex-nowrap'
        [class.text-primary]='getFormControl().valid' [class.text-warning]='!getFormControl().valid'>
        <input *ngIf='!getMultipleSelection()' (change)="checkFiles($event)" id="{{getFormControlName()}}" type="file"
          [name]="getFormControlName()" />
        <input *ngIf='getMultipleSelection()' (change)="checkFiles($event)" id="{{getFormControlName()}}" type="file"
          [name]="getFormControlName()" multiple='true' />
        <span *ngIf='!getFormControl().valid && getDisplayedIcon()' class='d-flex  align-items-center'
          ngbTooltip="{{getError()}}" tooltipClass="custom-tooltip" placement="left">
          <i class='icon' aria-hidden='true' [ngClass]='getDisplayedIcon()' [class.text-error]="hasError()"></i>
        </span>
      </div>
    </div>
    <div *ngIf='writeError' class='mt-2 mb-4 error-box'>
      <ul class='list-unstyled  small text-muted '>
        <li [class.text-error]='hasError()' *ngFor='let error of getErrorsList()'>
          {{getSpecificErrorSentence(error)}}
        </li>
      </ul>
    </div>
  </div>
