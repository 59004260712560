export enum ItemType {
    Folder = 'folder',
    File = 'file',
    Files = 'files',
    Group = 'group',
    Temp = 'temp',
    Project = 'project',
    Other = 'other',
    FileGroup = 'filegroup',
    FileGroups = 'filegroups',
    Team = 'team',
    Cohort = 'cohort',
    FocusGeneList = 'focus_gene_list'
  }