import { Injectable, OnDestroy } from '@angular/core';
import { ProjectsService } from 'src/app/api/projects.service';
import { TeamsHttpService } from '../../teams-manager/teams-http.service';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CreditAlertsService implements OnDestroy {
  updateCost: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  updateCostWithPatientsCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  workflow_id;
  creditCost : BehaviorSubject<Number> = new BehaviorSubject<Number>(undefined);
  creditBudget : BehaviorSubject<Number> = new BehaviorSubject<Number>(undefined);
  error :  BehaviorSubject<any> = new BehaviorSubject<any>('noFileGroupId')
  costSubscription
  constructor(public projectsService: ProjectsService, private teamsService: TeamsHttpService) {

    this.updateCostWithPatientsCount.subscribe((value) => {
      this.updateCreditCostWithPatientCount(value);
    });
    this.updateCost.subscribe((value) => {
      if (value !== null) {
        this.updateCreditCost(value);
      }
    });
    this.updateBudget()
  }
  ngOnDestroy(){
    if(this.costSubscription){
      this.costSubscription.unsubscribe();
    }
  }
  updateBudget(){
    this.getBudget().then((budget) => {
      this.creditBudget.next(budget)
    });
  }
  updateCreditCostWithPatientCount(value) {
    this.creditCost.next(undefined)
    this.error.next(undefined)
    if (value === undefined || !this.workflow_id) {
      return
    }
    this.costSubscription  = this.getCostWithPatientCount(this.workflow_id, value).then(
      (cost) => {
        this.creditCost.next(cost)
      },
      (error) => {
        this.error.next(error)
      }
    );
  }

  updateCreditCost(value?) {
        this.creditCost.next(undefined);
    this.error.next(undefined)
    if (!this.workflow_id) {
      return
    }

    this.costSubscription = this.getCost(this.workflow_id, value ? value : undefined).then(
      (cost) => {
        this.creditCost.next(cost)
      },
      (error) => {
        this.error.next(error)
      }
    );
  }

  isImpossibleToCalculate() {
    if (this.creditCost.value === null) {
      return true;
    }
  }

  isOverBudget() {
    if (this.creditBudget.value === undefined || this.creditCost.value === undefined) {
      return false;
    }
    return this.creditBudget.value < this.creditCost.value;
  }
  getCostWithPatientCount(workflowId: string, count) {
    return new Promise<number>((resolve, reject) => {
      this.projectsService.getWorkflow(workflowId, this.projectsService.getProjectStorageCountry()).subscribe((res) => {
        const creditThresholds = _.get(res, 'credit.thresholds', {});
        const patients = count;
        let cost;
        const entries = Object.entries(creditThresholds).sort(function (a, b) {
          return JSON.parse(a[0]) - JSON.parse(b[0]);
        });
        for (const [key, value] of entries) {
          if (patients >= JSON.parse(key)) {
            cost = value;
          }
        }
        resolve(cost);
        return;
      });
    });
  }
  getCost(workflowId: string, filegroupId) {
    return new Promise<number>((resolve, reject) => {
      this.projectsService.getWorkflow(workflowId, this.projectsService.getProjectStorageCountry()).subscribe((res) => {
        const creditThresholds = _.get(res, 'credit.thresholds', {});
        if (_.keys(creditThresholds).length == 0) {
          resolve(0);
          return;
        } else if (_.keys(creditThresholds).length == 1) {
          resolve(_.nth(_.values(creditThresholds), 0));
          return;
        } else {
          if (!filegroupId) {
            reject('noFileGroupId');
            return;
          }
          this.projectsService.searchFiles({ filegroup_id: filegroupId }).subscribe(
            (searchRes) => {
              const patients = _.get(searchRes, 'counts.patients', 0);
              let cost;
              const entries = Object.entries(creditThresholds).sort(function (a, b) {
                return JSON.parse(a[0]) - JSON.parse(b[0]);
              });
              for (const [key, value] of entries) {
                if (patients >= JSON.parse(key)) {
                  cost = value;
                }
              }
              resolve(cost);
              return;
            },
            (error) => {
              reject('errorWithSearch');
              return;
            }
          );
        }
      });
    });
  }

  getBudget() {
    return new Promise<number>((resolve) => {
      this.teamsService.getTeams().subscribe((teams) => {
        if (teams[0].subscription_id) {
          this.teamsService.getSubscription(teams[0].subscription_id).subscribe((sub) => {
            resolve(sub.credits);
          });
        } else {
          resolve(0);
        }
      });
    });
  }
}
