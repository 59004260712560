<div class="h-100 d-flex flex-column">
  <!-- <mims-ribbon *ngIf="showRibbon" [title]="TabsPublicName.MyNotifications" [navigations]="[{ label: 'Home', nav: '/' }]"> </mims-ribbon> -->
  <app-header2 *ngIf="showRibbon" [title]="PagesPublicName.Notifications" iconClass='fad fa-bell'>
  </app-header2>
  
  <app-content [isTab]="false" [isHeader]="true">
    <div class="h-100 w-100 scrollable p-5 notifications-content">
      <ul *ngIf='getUserNotifications().length' class="list-unstyled border">
        <li *ngFor="let el of getUserNotifications() | slice: (page - 1) * pageSize:page * pageSize; trackBy: trackByFn">
          <app-user-notification [notification]="el" [multicountryUser]="multicountryUser"></app-user-notification>
        </li>
      </ul>
      <ngb-pagination
        *ngIf="getUserNotifications().length"
        class="my-3 d-flex justify-content-center"
        [(page)]="page"
        [pageSize]="pageSize"
        [collectionSize]="getUserNotifications().length"
      >
        <ng-template ngbPaginationPrevious><i class="far fa-chevron-left" aria-hidden="true"></i></ng-template>
        <ng-template ngbPaginationNext><i class="far fa-chevron-right" aria-hidden="true"></i> </ng-template>
      </ngb-pagination>

      <div
        class="empty-display w-100 justify-content-center align-items-center d-flex font-weight-bold"
        *ngIf="!getUserNotifications().length"
      >
        <p>All your notifications will display here.</p>
      </div>
    </div>
  </app-content>
</div>
