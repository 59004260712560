<nav (mouseenter)="resetTimer()" *ngIf="!l.sidebarHidden" [class.no-width]="l.sidebarHidden"
  class="h-100 sidebar-container bg-darker d-flex flex-column align-items-center" [class.full-width]="l.isSideBarOpen"
  [class.shadow-custom]="l.isSideBarOpen">
  <div class="d-flex flex-nowrap sidebar-top-container" (click)="l.toggleSideBar()">
    <div class="d-flex flex-nowrap">
      <app-m-logo-btn></app-m-logo-btn>
      <span *ngIf="l.isSideBarOpen" class="ml-2 current-page">{{ getPagesPublicName(currentPage) }}</span>
    </div>
  </div>

  <div class="d-flex flex-grow-1 flex-column sidebar-bottom-container w-100">
    <div>
        <app-sidebar-button  [isActive]="currentPage === PagesName.Home"
        [isSideBarWide]="l.isSideBarOpen" [goToArray]="[PagesName.Home]" hoveredIconClass="fad fa-home-heart"
        unhoveredIconClass="fas fa-home-heart" [title]="PagesPublicName.Home">
      </app-sidebar-button>
      <app-sidebar-button *ngIf="permissions.accessLittResearch && _.includes(availableFeatures, Features.KnowledgeSearch)"
        [isActive]="currentPage === PagesName.KnowledgeSearch" [isSideBarWide]="l.isSideBarOpen"
        [goToArray]="[PagesName.KnowledgeSearch, PagesName.Query]"  hoveredIconClass="fad fa-search"
        unhoveredIconClass="fas fa-search"
        [title]="PagesPublicName.KnowledgeSearchLong">
      </app-sidebar-button>
      <app-sidebar-button *ngIf="permissions.accessRunsDashboard" [isActive]="currentPage === PagesName.RunsDashboard"
        [isSideBarWide]="l.isSideBarOpen" [goToArray]="[PagesName.RunsDashboard]" hoveredIconClass="fas fa-poll-h"
        unhoveredIconClass="fas fa-poll-h" [title]="PagesPublicName.RunsDashboard">
      </app-sidebar-button>
      <app-sidebar-button *ngIf="permissions.accessProjectManager && !permissions.canExploreProjects" [isActive]="currentPage === PagesName.ProjectManager"
        [isSideBarWide]="l.isSideBarOpen" [goToArray]="[PagesName.ProjectManager]" hoveredIconClass="fad fa-folder-open"
        unhoveredIconClass="fas fa-folder" [title]="PagesPublicName.ProjectManager">
      </app-sidebar-button>
      <app-sidebar-button *ngIf="permissions.accessProjectManager && permissions.canExploreProjects" [isActive]="currentPage === PagesName.ProjectManager"
        [isSideBarWide]="l.isSideBarOpen" [goToArray]="[PagesName.ProjectManager, 'shared-projects']" hoveredIconClass="fad fa-folder-open"
        unhoveredIconClass="fas fa-folder" [title]="PagesPublicName.ProjectManager">
      </app-sidebar-button>
      <app-sidebar-button *ngIf="permissions.accessFileManager" [isActive]="currentPage === PagesName.FileManager"
        [isSideBarWide]="l.isSideBarOpen" [goToArray]="[PagesName.FileManager]" hoveredIconClass="fad fa-file"
        unhoveredIconClass="fas fa-file" [title]="PagesPublicName.FileManager">
      </app-sidebar-button>
      <app-sidebar-button *ngIf="permissions.accessDataManager" [isActive]="currentPage === PagesName.DataManager"
      [isSideBarWide]="l.isSideBarOpen" [goToArray]="[PagesName.DataManager]" hoveredIconClass="fad fa-file"
    unhoveredIconClass="fas fa-file" [title]="PagesPublicName.DataManager">
    </app-sidebar-button>
      <!-- <app-sidebar-button *ngIf="permissions.accessNotificationManager"  [isActive]="currentPage === PagesName.UserNotifications" [isSideBarWide]="l.isSideBarOpen"
        [notifications]="notifService.userNotifications" hoveredIconClass="fad fa-bell"
        unhoveredIconClass=" fas fa-bell" [goToArray]="[PagesName.UserNotifications]" [isPurple]="true"
        [title]="PagesPublicName.NotificationsUnread">
      </app-sidebar-button> -->
      <app-sidebar-button *ngIf="permissions.accessUserManager" [isActive]="currentPage === PagesName.UserCreation"
        [isSideBarWide]="l.isSideBarOpen" [goToArray]="[PagesName.UserCreation]" hoveredIconClass="fad fa-user-plus"
        unhoveredIconClass="fas fa-user-plus" [title]="PagesPublicName.UserCreation">
      </app-sidebar-button>
      <app-sidebar-button *ngIf="permissions.accessTeamManager" [isActive]="currentPage === PagesName.TeamsManager" 
        [isSideBarWide]="l.isSideBarOpen" [goToArray]="[PagesName.TeamsManager]" hoveredIconClass="fad fa-user-friends"
        unhoveredIconClass="fas fa-user-friends" [title]="PagesPublicName.TeamsManager">  
      </app-sidebar-button>
      <!-- <app-sidebar-button *ngIf="permissions.accessLicenseManager" [isActive]="currentPage === PagesName.LicenseManager" 
      [isSideBarWide]="l.isSideBarOpen" [goToArray]="[PagesName.LicenseManager]" hoveredIconClass="fad fa-user-plus"
      unhoveredIconClass="fas fa-user-plus" [title]="PagesPublicName.LicenseManager">
    </app-sidebar-button> -->
      <app-sidebar-button *ngIf="permissions.accessWorflowEditor" [isSideBarWide]="l.isSideBarOpen"
        [goToArray]="[PagesName.Workflow]" hoveredIconClass="fad fa-network-wired"
        unhoveredIconClass="fas fa-network-wired" [title]="PagesPublicName.WorkflowEditor">
      </app-sidebar-button>
      <app-sidebar-button *ngIf="permissions.accessWorkflowDebugger" [isActive]="currentPage === PagesName.WorkflowDebugger"
        [isSideBarWide]="l.isSideBarOpen" [goToArray]="[PagesName.WorkflowDebugger]" hoveredIconClass="fad fa-bug"
        unhoveredIconClass="fas fa-bug" [title]="PagesPublicName.WorkflowDebugger">
      </app-sidebar-button>
    </div>
  </div>
</nav>
<div (mouseenter)="handleSideBarClosingOnHover()" *ngIf="l.isSideBarOverlayOpen && !l.sidebarHidden && !mouseIsOut"
  class="sidebar-overlay" id="sidebar-overlay"></div>
<div *ngIf="!l.sidebarHidden" class="sidebar-placeholder"></div>

<DlProxyComponent [resetFunction]="handleDownloadReset()" [fileLink]="dlLink" *ngIf="download"> </DlProxyComponent>
