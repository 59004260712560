<div class="my-3 box d-flex">
  <div class="flex-fill p-2 response-container ">
    <div class="response">
      <span>
        {{ results?.length | i18nPlural: itemPluralMapping.response }} : </span>
      <div *ngFor="let result of results">
        <div *ngIf="result.length>1">
          <div class="word-result font-weight-bold" *ngFor="let el of result">
            {{ el }}
          </div>
        </div>
        <div *ngIf="result.length === 1">
          <div class="word-result font-weight-bold">
            {{ result }}
          </div>
        </div>

      </div>
    </div>
    <div class="response" *ngIf="focus?.length">
      <span class="cursor-info" ngbTooltip="matched words" tooltipClass="custom-tooltip" placement="right">Focus
        : </span>
      <span class="word font-weight-bold" *ngFor="let el of focus; let last = last">{{ el }}<span
          *ngIf="!last">,</span></span>
    </div>
    <div class="response" *ngIf="unused?.length">
      <span class="cursor-info" ngbTooltip="mismatched words" tooltipClass="custom-tooltip" placement="right">Unused :
      </span>

      <span class="word font-weight-bold" *ngFor="let el of unused; let last = last">{{ el }}<span
          *ngIf="!last">,</span></span>
    </div>
    <div class="response" *ngIf="!!score">
      <span class="cursor-info" ngbTooltip="relevance of this answer" tooltipClass="custom-tooltip"
        placement="right">Relevance :</span>
      <span class="word font-weight-bold">{{ score }}</span>
    </div>
    <div class="response">
      <span class="cursor-info" ngbTooltip="Reception time of this answer" tooltipClass="custom-tooltip"
        placement="right">Time :
      </span>
      <span class="word font-weight-bold">
        {{moment(date).format('MM/DD/YYYY - HH:mm')}}
      </span>
    </div>
  </div>
</div>