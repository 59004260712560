<label *ngIf="label">{{ label }}</label>
<div class="input-container" #overlayOrigin="cdkOverlayOrigin" cdkOverlayOrigin>
  <input #inputField [id]="id" type="text" autocomplete="off" [placeholder]="placeholder" />
  <i *ngIf="showCloseIcon$ | async" class="fal fa-times close-icon" (click)="onCloseIconClick($event)" aria-hidden="true"></i>
</div>
<mims-dropdown
  #dropdown
  [minWidth]="listWidth"
  [width]="listWidth"
  [overlayOrigin]="overlayOrigin"
  [opened]="opened"
  (toggled)="onDropdownToggled($event)"
>
  <mims-dropdown-list
    [dataSource]="filteredOptions"
    [selectedOption]="selectedOption"
    [maxOptionsVisibleInList]="maxOptionsVisibleInList"
    [optionHeight]="optionHeight"
    (optionSelected)="onOptionSelected($event)"
  ></mims-dropdown-list>
</mims-dropdown>
