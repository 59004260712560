import { Component, OnInit, Input, ViewChild } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
declare var $: any;
import * as _ from 'lodash';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  @Input() content;
  @Input() date;
  @Input() dataDelay;
  @Input() title;
  @Input() color?
  _=_
  @Input() uploadingFiles?
  @Input() onClose?;
  @Input() show? = new BehaviorSubject<boolean>(true);
  contentIsVisible = true;

  moment = moment;
  @ViewChild("toast", { static: true }) toast;

  warning = false;
  danger = false;
  information = false;

  constructor(private l: LayoutOrganizationService) { }

  ngOnInit(): void {
    this.init()
  }
  
  handleClose(){
    if (this.onClose){
      this.onClose.forEach((func) => func())
    }
  }
  switchContentVisibility(){
    this.contentIsVisible = !this.contentIsVisible
  }
  init() {
    if (this.color && this.color === 'danger') {
      this.danger = true
    }
    if (this.color && this.color === 'information') {
      this.information = true
    }
    if (this.color && this.color === 'warning') {
      this.warning = true
    }
    if (this.dataDelay) {
      $(this.toast.nativeElement).toast({
        delay: this.dataDelay,
      })

    } else {
      $(this.toast.nativeElement).toast({
        autohide: false,
      })

    }


    $(this.toast.nativeElement).toast('show')
  }


}
