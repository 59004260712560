import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginHttpService } from '../login-http-service/login-http.service';
@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent {
  constructor(public loginHttp: LoginHttpService, public _DomSanitizer: DomSanitizer) {}
  previous() {
    this.loginHttp.activeStep = 0;
  }
  next() {
    this.loginHttp.activeStep = 2;
  }
}
