import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-group-content',
  styleUrls: ['../../../../shared/layout/managers.scss'],
  templateUrl: './group-content.component.html',
})
export class GroupContentComponent {
  @Input() groupItem;
  @Input() group;

  @Input() handleRemove;
  iconText;

  setIconText(text) {
    this.iconText = text;
  }
  unSetIconText() {
    this.iconText = '';
  }
}
