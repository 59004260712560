<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="truthyOverlayOrigin"
  [cdkConnectedOverlayOpen]="!!overlayOrigin && opened"
  [cdkConnectedOverlayHasBackdrop]="false"
  (attach)="updateOverlaySize()"
>
  <div class="dropdown-overlay-container" [@appearPanel]="'opened'">
    <ng-content></ng-content>
  </div>
</ng-template>
