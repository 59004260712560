import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as _ from 'lodash';
import { Router } from '@angular/router';
@Component({
  selector: 'app-path-2',
  styleUrls: ['./path-2.component.scss'],
  templateUrl: './path-2.component.html',
})
export class Path2Component {
  @Input() dataSource: Array<any>;
  @Input() theme?;

  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  _ = _;
  constructor(public router: Router) {
    
  }
}
