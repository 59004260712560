<form (ngSubmit)="addUser()" [formGroup]="userCreationForm">
  <div class="flex-wrap">
    <div class="form-group">
      <label for="email">
        Email
      </label>
      <div class="input-icon-container">
        <input class="form-control" id="email" type="text" formControlName="email" />
        <div *ngIf="!email.valid && (email.dirty || email.touched)" class="icon-container email-error">
          <span *ngIf="email.hasError('required')" class="tooltiptext email-error">This field is required</span>
          <span *ngIf="email.hasError('email')" class="tooltiptext">Invalid email format</span>
          <span *ngIf="email.hasError('userExists')" class="tooltiptext">User already exists</span>
          <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="first-name">
        First Name
      </label>
      <div class="input-icon-container">
        <input class="form-control" id="first-name" type="text" formControlName="firstName" />
        <div *ngIf="!firstName.valid && (firstName.dirty || firstName.touched)" class="icon-container">
          <span *ngIf="firstName.hasError('required')" class="tooltiptext">This field is required</span>
          <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="last-name">
        Last Name
      </label>
      <div class="input-icon-container">
        <input class="form-control" id="last-name" type="text" formControlName="lastName" />
        <div *ngIf="!lastName.valid && (lastName.dirty || lastName.touched)" class="icon-container">
          <span *ngIf="lastName.hasError('required')" class="tooltiptext">This field is required</span>
          <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="input-icon-container">
        <label for="ontological-domain">
          Ontological Domain
        </label>
        <div class="icon-container-info">
          <span class="tooltiptext">Possible values:<br />Human<br />Plant</span>
          <i class="fas fa-info-circle" aria-hidden="true"></i>
        </div>
      </div>
      <div class="input-icon-container">
        <input class="form-control" id="ontological-domain" type="text" formControlName="ontologicalDomain" />
        <div *ngIf="
            !ontologicalDomain.valid &&
            (ontologicalDomain.dirty || ontologicalDomain.touched)
          " class="icon-container">
          <span *ngIf="ontologicalDomain.hasError('required')" class="tooltiptext">This field is required</span>
          <span *ngIf="
              ontologicalDomain.hasError('wrongValue') &&
              !ontologicalDomain.hasError('required')
            " class="tooltiptext">Invalid value</span>
          <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="input-icon-container">
        <label for="file-hosting-country">
          File Hosting Country
        </label>
        <div class="icon-container-info">
          <span class="tooltiptext">Possible values:<br />CA<br />USA</span>
          <i class="fas fa-info-circle" aria-hidden="true"></i>
        </div>
      </div>
      <div class="input-icon-container">
        <input class="form-control" id="file-hosting-country" type="text" formControlName="fileHostingCountry" />

        <div *ngIf="
            !fileHostingCountry.valid &&
            (fileHostingCountry.dirty || fileHostingCountry.touched)
          " class="icon-container">
          <span *ngIf="fileHostingCountry.hasError('required')" class="tooltiptext">This field is required</span>
          <span *ngIf="
              fileHostingCountry.hasError('wrongValue') &&
              !fileHostingCountry.hasError('required')
            " class="tooltiptext">Invalid value</span>
          <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="organization">
        Organization
      </label>
      <div class="input-icon-container">
        <input class="form-control" id="organization" type="text" formControlName="organization" />
        <div *ngIf="
            !organization.valid && (organization.dirty || organization.touched)
          " class="icon-container">
          <span *ngIf="organization.hasError('required')" class="tooltiptext">This field is required</span>

          <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
        </div>
      </div>
    </div>

    <div id="password-data" formGroupName="passwordData" class="flex">
      <div class="form-group">
        <label for="password">
          Password
        </label>
        <div class="input-icon-container">
          <input class="form-control" id="password" type="password" formControlName="password" />
          <div *ngIf="!password.valid && (password.dirty || password.touched)" class="icon-container password-error">
            <span *ngIf="password.hasError('required')" class="tooltiptext">This field is required</span>
            <span *ngIf="password.hasError('minlength')" class="tooltiptext">Minimum 8 characters</span>
            <span *ngIf="password.hasError('shouldNotHaveSpaces')" class="tooltiptext">May not contain spaces</span>
            <span *ngIf="
                password.hasError('noSpecialCharacter') &&
                !password.hasError('minlength') &&
                !password.hasError('required')
              " class="tooltiptext">Must contain symbol (@#$%)</span>
            <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="confirm">
          Confirm Password
        </label>
        <div class="input-icon-container">
          <input class="form-control" id="confirm" type="password" formControlName="confirm" />
          <div *ngIf="!passwordData.valid && (confirm.dirty || confirm.touched)" class="icon-container confirm-error">
            <span class="tooltiptext">Passwords must match</span>
            <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <input class="btn btn-dark btn-lg add-user-btn" type="submit" value="Add User" [disabled]="!userCreationForm.valid" />
</form>