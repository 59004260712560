import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import moment from 'moment';
import * as _ from 'lodash';
import { Status } from '../../../shared/helpers/status';
import { availableFeatures } from 'src/environments/environment';
import { Features } from 'src/environments/features';
import { ItemAction } from '../file-manager2.service';
@Component({
  selector: 'info-2',
  styleUrls: ['./info-2.component.scss'],
  templateUrl: './info-2.component.html',
})
export class Info2Component implements OnInit {
  public dateCreation: any;
  public dateModification: any;
  public size: any;
  @Input() item;
  @Input() teamsDataSource? = [];
  @Input() country;
  public Status = Status;
  Features = Features;
  availableFeatures = availableFeatures;
  public _ = _;
  @Output() itemAction  = new EventEmitter();
  ItemAction = ItemAction
  teamName

  originalSelectedItemId;

  ngOnInit(): void {
   
    this.size = '0 kB';
    let contentSize = _.get(this.item, 'content_size')
    if (contentSize !== 0) {
      var i = Math.floor(Math.log(contentSize) / Math.log(1000));
      let calc: any = contentSize / Math.pow(1000, i);
      this.size = calc.toFixed(1) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }
    this.dateCreation = this.fromNowOrNow(_.get(this.item, 'date_of_creation'));
    this.dateModification = this.fromNowOrNow(_.get(this.item, 'date_of_modification'));
    setInterval(() => {
      this.dateCreation = this.fromNowOrNow(_.get(this.item, 'date_of_creation'));
      this.dateModification = this.fromNowOrNow(_.get(this.item, 'date_of_modification'));
    }, 10000);
    if (_.get(this.item, 'team_id')){
      let el = _.find(this.teamsDataSource, (o)=>{return _.get(o,'value') === _.get(this.item, 'team_id')})
      this.teamName = _.get(el, 'label')
    }
  }

  fromNowOrNow(date) {
    date = date < moment().unix() ? date : moment().unix();
    return moment.unix(date).fromNow();
  }


  getTooltip(name) {
    if (name === 'upload') {
      if (_.includes(availableFeatures, Features.UploadFile)) {
        return 'Select a metadata file to upload';
      } else {
        return "You don't have the permission to upload";
      }
    }
  }
  get metadata(){
    return _.get(this.item, 'metadata')
  }
  get is_folder(){
    return _.get(this.item, 'is_folder')
  }
  get title(){
    return _.get(this.item, 'title')
  }
  get can_modify(){
    return _.get(this.item, 'capabilities.can_modify')
  }
  get is_shared(){
    return _.get(this.item, 'is_shared')
  }
}
