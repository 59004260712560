<div class=' m-5 d-flex flex-column align-items-center d-flex flex-column align-items-center'>

  <h3>Select the desired formulation</h3>
  <div class='w-75 d-flex align-items-center justify-content-around'>
    <div class='my-4 d-flex flex-column align-items-left w-75'>
      <div class='font-weight-bold my-1'>
        {{PagesPublicName.KnowledgeSearchSimpleKeyword}}
      </div>
      <div> Include up to 5 terms</div>
    </div>
    <div class='btn btn-dark button '
      (click)='knowledgeSearchService.navigateToSelectedFormulation(Formulation.Keywords,knowledgeSearchService.getQueryParams())'>
      <span class='small text-uppercase'> Select </span>
    </div>
  </div>
  <div class='w-75 d-flex align-items-center justify-content-around'>
    <div class='my-4 d-flex flex-column align-items-left w-75'>
      <div class='font-weight-bold my-1'>
        {{PagesPublicName.KnowledgeSearchSingleArticle}}
      </div>
      <div>Search by PMC Id
      </div>
    </div>
    <div class='btn btn-dark button'
      (click)='knowledgeSearchService.navigateToSelectedFormulation(Formulation.Article,knowledgeSearchService.getQueryParams())'>
      <span class='small text-uppercase'> Select </span>
    </div>
  </div>
  <div class='w-75 d-flex align-items-center justify-content-around'>
    <div class='my-4 d-flex flex-column align-items-left w-75'>
      <div class='font-weight-bold my-1'>
        {{PagesPublicName.KnowledgeSearchPicoTherapy}} </div>
      <div>In <span class='font-weight-bold'>[Patient or Population]</span>, what is the effet of <span
          class='font-weight-bold'>[Intervention]</span> on <span class='font-weight-bold'>[Outcome or Result]</span>
        compared with
        <span class='font-weight-bold'>[Comparison or Benchmark]</span> ?
      </div>
    </div>
    <div class='btn btn-dark button '
      (click)='knowledgeSearchService.navigateToSelectedFormulation(Formulation.Therapy,knowledgeSearchService.getQueryParams())'>
      <span class='small text-uppercase'> Select </span>
    </div>
  </div>

  <div class='w-75 d-flex align-items-center justify-content-around'>
    <div class='my-4 d-flex flex-column align-items-left w-75'>
      <div class='font-weight-bold my-1'>
        {{PagesPublicName.KnowledgeSearchPicoPrevention}}
      </div>
      <div>For <span class='font-weight-bold'>[Patient or Population]</span>, does the use of <span
          class='font-weight-bold'>[Intervention]</span> reduce the future risk of <span
          class='font-weight-bold'>[Outcome
          or Result]</span> compared with
        <span class='font-weight-bold'>[Comparison or Benchmark]</span> ?
      </div>
    </div>
    <div class='btn btn-dark button disabled' ngbTooltip="Coming soon" tooltipClass="custom-tooltip"
      (click)='true? "" : knowledgeSearchService.navigateToSelectedFormulation(Formulation.Prevention,knowledgeSearchService.getQueryParams())'>
      <span class='small text-uppercase'> Select </span>
    </div>
  </div>
  <div class='w-75 d-flex align-items-center justify-content-around'>
    <div class='my-4 d-flex flex-column align-items-left w-75'>
      <div class='font-weight-bold my-1'>
        {{PagesPublicName.KnowledgeSearchPicoDiagnosis}}
      </div>
      <div>Is <span class='font-weight-bold'>[Intervention]</span> more accurate in diagnosing <span
          class='font-weight-bold'>[Outcome or Result]</span> compared with <span class='font-weight-bold'>[Comparison
          or
          Benchmark]</span> ?</div>
    </div>
    <div class='btn btn-dark button disabled' ngbTooltip="Coming soon" tooltipClass="custom-tooltip"
      (click)='true? "" : knowledgeSearchService.navigateToSelectedFormulation(Formulation.Diagnosis,knowledgeSearchService.getQueryParams())'>
      <span class='small text-uppercase'> Select </span>
    </div>
  </div>
  <div class='w-75 d-flex align-items-center justify-content-around'>
    <div class='my-4 d-flex flex-column align-items-left w-75'>
      <div class='font-weight-bold my-1'>
        {{PagesPublicName.KnowledgeSearchPicoPrognosis}}
      </div>
      <div>Does <span class='font-weight-bold'>[Intervention]</span> influence <span class='font-weight-bold'>[Outcome
          or
          Result]</span> in patients who have <span class='font-weight-bold'>[Patient or Population]</span> ?</div>
    </div>
    <div class='btn btn-dark disabled button ' ngbTooltip="Coming soon" tooltipClass="custom-tooltip"
      (click)='true? "" : knowledgeSearchService.navigateToSelectedFormulation(Formulation.Prognosis,knowledgeSearchService.getQueryParams())'>
      <span class='small text-uppercase'> Select </span>
    </div>
  </div>
  <div class='w-75 d-flex align-items-center justify-content-around'>
    <div class='my-4 d-flex flex-column align-items-left w-75'>
      <div class='font-weight-bold my-1'>
        {{PagesPublicName.KnowledgeSearchPicoEtiology}}
      </div>
      <div>Are <span class='font-weight-bold'>[Patient or Population]</span> who have <span
          class='font-weight-bold'>[Intervention]</span> at risk for/of <span class='font-weight-bold'>[Outcome or
          Result]</span> compared with/without <span class='font-weight-bold'>[Comparison or Benchmark]</span> ?</div>
    </div>
    <div class=' disabled btn btn-dark button ' ngbTooltip="Coming soon" tooltipClass="custom-tooltip"
      (click)='true? "" : knowledgeSearchService.navigateToSelectedFormulation(Formulation.Etiology,knowledgeSearchService.getQueryParams())'>
      <span class='small text-uppercase '> Select </span>
    </div>
  </div>
</div>
