import { Directive, Input, EventEmitter, Output, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[scrollSpy]'
})
export class ScrollSpyDirective {
    @Input() public spiedTags = [];
    @Output() public sectionChange = new EventEmitter<string>();
    @Output() public subSectionChange = new EventEmitter<string>();
    private currentSection: string;
    private currentSubSection: string;

    constructor(private _el: ElementRef) { }

    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        let currentSection: string;
        let currentSubSection: string;
        const children = this._el.nativeElement.children;
        const scrollTop = event.target.scrollTop;
        const parentOffset = event.target.offsetTop;
        for (let i = 0; i < children.length; i++) {
            const element = children[i];
            const elementChildren = element.children;

            for (let j = 0; j < elementChildren.length; j++) {
                const elementChild = elementChildren[j];
                if (this.spiedTags.some(spiedTag => spiedTag === element.tagName) && elementChild.id) {
                    if ((elementChild.offsetTop - parentOffset) <= scrollTop) {
                        currentSubSection = elementChild.id;
                    }
                }
            }
            if (currentSubSection !== this.currentSection) {
                this.currentSubSection = currentSubSection;
                this.subSectionChange.emit(this.currentSubSection);
            }

            if (this.spiedTags.some(spiedTag => spiedTag === element.tagName) && element.id) {
                if ((element.offsetTop - parentOffset) <= scrollTop) {
                    currentSection = element.id;
                }
            }
        }
        if (currentSection !== this.currentSection) {
            this.currentSection = currentSection;
            this.sectionChange.emit(this.currentSection);
        }
    }

}
