import { Pipe, PipeTransform, OnDestroy, ChangeDetectorRef, NgZone } from '@angular/core';
import momentImported from 'moment';
const moment = momentImported;

@Pipe({ name: 'timeAgo', pure: false })
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  /** The current timer */
  private currentTimer: number;

  /** The converted time units */
  private lastValue: number;

  /** The display text */
  private displayText: string;

  constructor(private _cdr: ChangeDetectorRef, private ngZone: NgZone) {
    momentImported.updateLocale('en', {
      relativeTime: {
        future: "in %s",
        past: "%s",
        s: 'now',
        ss: '%d now',
        m: "1 min ago",
        mm: "%d min ago",
        h: "1 hour ago",
        hh: "%d hours ago",
        d: "yesterday",
        dd: "%d days ago",
        w: "last week",
        ww: "%d weeks ago",
        M: "last month",
        MM: "%d months ago",
        y: "last year",
        yy: "%d years ago"
      }
    });
  }
     

  transform(value: Date | momentImported.Moment | string | number): string {
    if (!value) {
      return '';
    }
    const formatedTime = this.formatTime(value);

    if (this.lastValue !== formatedTime) {
      this.lastValue = formatedTime;
      this.removeTimer();
      this.createTimer();
      this.displayText = moment(value).fromNow()
      // const heightDays = 3600*24*8
      // if (( moment().unix() - formatedTime/1000 ) < heightDays){
      //   this.displayText = moment(value).fromNow();
      // }
      // else{
      //   this.displayText = moment(value).format('YYYY/MM/DD')
      // }
    } else {
      // time didn't change, just go to next timeout
      this.createTimer();
    }
    return this.displayText;
  }

  /**
   * Convert the date to a standard number
   * @param value input time
   */
  private formatTime(value: Date | momentImported.Moment | string | number): number {
    if (moment.isDate(value)) {
      return value.getTime();
    } else if (moment.isMoment(value)) {
      return value.valueOf();
    } else {
      return moment(value).valueOf();
    }
  }

  private removeTimer() {
    if (this.currentTimer) {
      window.clearTimeout(this.currentTimer);
      this.currentTimer = null;
    }
  }

  private createTimer() {
    if (this.currentTimer) {
      return;
    }
    const nextCheckTime = this.getSecondsUntilUpdate(moment(this.lastValue)) * 1000;
    this.currentTimer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.displayText = moment(this.lastValue).fromNow();
          this.currentTimer = null;
          this.ngZone.run(() => this._cdr.markForCheck());
        }, nextCheckTime);
      }
    });
  }

  private getSecondsUntilUpdate(momentInstance: momentImported.Moment) {
    const howOld = Math.abs(moment().diff(momentInstance, 'minute'));
    if (howOld < 1) {
      // less than 1 min, check every 2 seconds
      return 2;
    } else if (howOld < 60) {
      // less than 1 hour, check every 30 secs
      return 30;
    } else if (howOld < 180) {
      // less than 1 day, check every 5 mins
      return 300;
    } else {
      // check every hour
      return 3600;
    }
  }

  ngOnDestroy() {
    this.removeTimer();
  }
}
