<app-header2 [title]="PagesPublicName.Home" iconClass="fa-home-heart fad"> </app-header2>
<app-content [isHeader]="true" [isTab]="false">
  <div class="homepage d-flex flex-column">
    <h2>Solutions</h2>
    <div class="tile-container d-flex" *ngIf="solutionService.solutions.value">
      <div *ngFor="let solution of solutionService.solutions.value">
        <div *ngIf="accessGuidedUserFlow">
          <a class="tile project" routerLink="/doc/solution/{{ solution._key }}">
            <img src="{{ solution.spec.icon }}" />
            <h3>
              {{ solution.spec.title }}
              <div height="1rem" style="text-align: right">
                {{ solution.version }}<br />
                <span style="font-size: 0.8rem"> {{ solution.releaseString }}</span>
              </div>
            </h3>
          </a>
        </div>
      </div>

      <!-- <div *ngIf="accessGuidedUserFlow">
        <a class="tile project" routerLink="/doc/gene-discovery"
          ><img src="/assets/images/icon-genesearch.svg" />
          <h3>Genetic Interaction Discovery</h3>
        </a>
      </div>
      <div *ngIf="accessGuidedUserFlow">
        <a class="tile project" routerLink="/doc/cohort-stratification">
          <img src="/assets/images/icon-popseg.svg" class="popseg" />
          <h3>Cohort Stratification</h3>
        </a>
      </div>
      <div *ngIf="accessGuidedUserFlow">
        <a class="tile project" routerLink="/doc/biomark" (mouseenter)="changeFolderSrc()" (mouseleave)="changeFolderSrc()">
          <img src="/assets/images/icon-biomark.svg" class="biomark" />

          <h3>Biomarker Discovery</h3>
        </a>
      </div> -->
    </div>
    <h2>Quick Actions</h2>

    <div class="tile-container mb-5 d-flex tools">
      <div>
        <a
          class="tile project"
          routerLink="/project-manager"
          (mouseenter)="changeFolderSrc()"
          (mouseleave)="changeFolderSrc()"
          *ngIf="accessProjectManager && canCreateProject"
        >
          <h3>Manage Projects</h3>
        </a>
        <a
          class="tile project"
          routerLink="/project-manager/shared-projects"
          (mouseenter)="changeFolderSrc()"
          (mouseleave)="changeFolderSrc()"
          *ngIf="accessProjectManager && !canCreateProject"
        >
          <h3>Explore Projects</h3>
        </a>
      </div>
      <div>
        <a
          class="tile project"
          [routerLink]="'/' + PagesName.FileManager"
          (mouseenter)="changeFileSrc()"
          (mouseleave)="changeFileSrc()"
          *ngIf="accessFileManager"
        >
          <h3>Manage Files</h3>
        </a>
      </div>
      <div>
        <a
          class="tile project"
          [routerLink]="'/' + PagesName.DataManager"
          (mouseenter)="changeFileSrc()"
          (mouseleave)="changeFileSrc()"
          *ngIf="accessDataManager"
        >
          <h3>Manage Data</h3>
        </a>
      </div>
      <div>
        <a
          class="tile project"
          routerLink="/explore-files"
          (mouseenter)="changeFileSrc()"
          (mouseleave)="changeFileSrc()"
          *ngIf="canExploreFiles"
        >
          <h3>Explore Patients</h3>
        </a>
      </div>
      <div>
        <a
          class="tile project"
          target="_blank"
          href="https://myintmachines.atlassian.net/servicedesk/customer/portals"
          *ngIf="accessGuidedUserFlow"
        >
          <h3>FAQ</h3>
        </a>
      </div>
      <div>
        <a
          class="tile project"
          [routerLink]="'/' + PagesName.KnowledgeSearch + '/' + PagesName.Query"
          (mouseenter)="changeSearchSrc()"
          (mouseleave)="changeSearchSrc()"
          *ngIf="accessLittResearch && _.includes(availableFeatures, Features.KnowledgeSearch)"
        >
          <h3>Search Scientific Litterature</h3>
        </a>
      </div>
    </div>
    <app-user-notifications-page
      *ngIf="accessNotifications && !accessGuidedUserFlow"
      [showRibbon]="false"
      class="notifications-overview"
    ></app-user-notifications-page>
  </div>
</app-content>
