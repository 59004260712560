
import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { angularMaterialRenderers } from '@jsonforms/angular-material';
import { and, or, createAjv, isControl, optionIs, rankWith } from '@jsonforms/core';
import { ProjectsService } from 'src/app/api/projects.service';
import { Files2Service } from 'src/app/api/files2.service';
import { SliderComponent } from './slider-control.component';
import _ from 'lodash';

@Component({
    selector: 'dash-form',
    styleUrls: ['./dash-form.component.scss'],
    templateUrl: './dash-form.component.html',
})
export class DashFormComponent implements OnInit {
    @Input() dataId:string;
    @Input() schemaId:string;
    @Input() uischemaId:string;
    @Input() projectId:string;
    @Input() instanceId:string;
    @Input() formKey:string;
    @Input() title:string;
    @Input() description:string;
    @Input() errorCredit:string;
    @Input() creditCost:undefined;
    @Input() creditBudget:undefined;

    errorCount:number = 0;
    schema:object;
    uischema:object;
    data:object = {};

    @ViewChild('formWrapper', { static: true }) formsContainer: ElementRef;

    ajv = createAjv({
        allErrors: true,
        verbose: true,
    });
    
    renderers = [
        ...angularMaterialRenderers,
        {
            renderer: SliderComponent,
            tester: rankWith(
                6,
                and(
                    isControl,
                    or(
                        optionIs('slider', "true"),
                        optionIs('slider', true)
                    )
                )
            )
        },
    ]

    constructor(public fs : Files2Service, public projectsService: ProjectsService) {}

    ngOnInit(): void {
        this.fetchSchemas();
        this.fetchData();
        setTimeout(() => {
            this.formsContainer.nativeElement.focus();
        });
    }
    

    formIsReady(): boolean {
        return !!this.schema && !!this.uischema;
    }

    fetchSchemas(): void {
        this.fs.downloadBlobFile(this.schemaId).subscribe(
            async (file) => {
                this.schema = JSON.parse(await file.text());
            }, error => console.log(error)
        );
        this.fs.downloadBlobFile(this.uischemaId).subscribe(
            async (file) => {
                this.uischema = JSON.parse(await file.text());
            }, error => console.log(error)
        );
    }
    fetchData(): void {
        this.fs.downloadBlobFile(this.dataId).subscribe(
            async (file) => {
                this.data = JSON.parse(await file.text());
            }, error => console.log(error)
        );
    }

    updateProjectFormData(dataFileId): void {
        this.projectsService.updateFormData(this.projectId, this.instanceId, this.formKey, dataFileId, this.name).subscribe(
            (res) => {
                alert('Operation succeeded');
            },
            (err) => {
                console.log(err);
            }
        )
    }

    uploadFormData(): void {
        let formData = new FormData();
        const blob = new Blob([JSON.stringify(this.data)], { type: 'plain/text' });
        formData.append('file', blob,'output.json');
        let file = formData.get('file');
        this.projectsService.uploadSmallFile(file, "").subscribe(
            (res) => { 
                this.updateProjectFormData(res); 
    
            },
            (err) => { console.log(err);});
    }

    // Event handlers
    onSubmit() {
        if (this.errorCount == 0) {
            this.uploadFormData();
        }
    }

    onFormErrorsChange(errors) {
        this.errorCount = errors.length;
    }

    getErrorCount() {
        return this.errorCount;
    }

    name = "";
    onVersionNameChange(event: any) { // without type info
        this.name = event.target.value;
    }

}