import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CanDeactivateProjectGuard implements CanDeactivate<any> {
  canDeactivate(component): boolean {
    if (!component.submitted) {
      if (
        confirm(
          'Are you sure you want to leave this page? All changes will be lost'
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
