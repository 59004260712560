import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { AdditionalTypes, getFormErrorSentence, InputJson, IsRequired, MaxLength128, MinLength8, ShouldBeIdentic, ShouldHaveSpecialCharacter, ShouldNotHaveSpaces } from 'src/app/shared/helpers/formattedInput';
import { environment } from 'src/environments/environment';
import { LoginHttpService } from '../login/login-http-service/login-http.service';




@Component({
  selector: 'app-new-account',
  templateUrl: './new-account.component.html',
  styleUrls: ['./new-account.component.scss']
})
export class NewAccountComponent implements OnInit {
  passwordForm;
  newPasswordFieldObject;
  newPasswordConfirmationFieldObject;
  xAccessToken;
  activated = false
  apiError = '';
  isPasswordReset = false
  constructor(private route: ActivatedRoute, private router: Router, private loginHttp: LoginHttpService) {
    route.url.subscribe((segments) => {
      if (_.get(segments, '0.path') == 'reset-password') {
        this.isPasswordReset = true;
      }
    });
    
    if (this.route.snapshot.paramMap.get('xAccessToken')) {
      _.set(this, 'xAccessToken', this.route.snapshot.paramMap.get('xAccessToken'));
    }
  }

  ngOnInit(): void {
    this.passwordForm = this.createParentForm()
    this.newPasswordFieldObject = this.createNewPasswordFieldObject();
    this.newPasswordConfirmationFieldObject = this.createNewPasswordConfirmationFieldObject();
  }
  getPlatformName() {
    return _.get(environment, 'platformName', '')
  }
  getFormError() {
    return this.getPasswordFormValidity() ? '' : 'Please verify your form.'
  }
  createParentForm() {
    return new UntypedFormGroup({
      newPassword: new UntypedFormControl(''),
      newPasswordConfirmation: new UntypedFormControl(''),
      acceptPolicies : new UntypedFormControl(false)
    }, ShouldBeIdentic('newPassword', 'newPasswordConfirmation'))
  }

  get newPassword() {
    return this.passwordForm.controls.newPassword
  }
  get newPasswordConfirmation() {
    return this.passwordForm.controls.newPasswordConfirmation
  }
  createNewPasswordFieldObject = (): InputJson => {
    return {
      formControlName: 'newPassword',
      type: AdditionalTypes.Password,
      placeholder: '',
      label: 'Enter a Password',
      showIcon: 'far fa-info-circle',
      validations: [ShouldHaveSpecialCharacter, ShouldNotHaveSpaces, MinLength8, MaxLength128],

    }
  }
  createNewPasswordConfirmationFieldObject = (): InputJson => {
    return {
      formControlName: 'newPasswordConfirmation',
      type: AdditionalTypes.Password,
      placeholder: '',
      label: 'Confirm password',
      showIcon: 'far fa-info-circle',
      validations: [IsRequired]
    }
  }
  getPasswordForm() {
    return _.get(this, 'passwordForm')
  }
  getPasswordFormValidity() {
    return _.get(this, 'passwordForm.status') === 'VALID' && _.get(this, 'passwordForm.controls.acceptPolicies.value')
  }
  getNewPasswordFieldObject() {
    return _.get(this, 'newPasswordFieldObject')
  }
  getNewPasswordConfirmationFieldObject() {
    return _.get(this, 'newPasswordConfirmationFieldObject')
  }
  getFormErrorsList() {
    return _.keys(_.get(this.getPasswordForm(), 'errors'))
  }
  getFormErrorSentence(error) {
    return getFormErrorSentence(error)
  }
  setApiError(error) {
    _.set(this, 'apiError', error);
  }
  getApiError() {
    return _.get(this, 'apiError', '')
  }

  handleActivation() {
    this.loginHttp.activateAccountWithPassword(this.xAccessToken,this.newPassword.value).subscribe((res) => {
      this.activated = true
    }, (error) => {
      if (error.status === 404) {
        this.setApiError('Impossible operation (account not found)')
      }
      else if (error.status === 403) {
        this.setApiError('Forbidden operation (invalid url)')
      }
      else if (error.status === 500 || error.status === 501 || error.status === 502 || error.status === 503 ) {
        this.setApiError('Impossible operation (internal error)')
      }
    })
  }

  handlePasswordReset() {
    this.loginHttp.resetPasswordWithToken(this.xAccessToken,this.newPassword.value).subscribe((res) => {
      this.activated = true
    }, (error) => {
      console.log(error);
      if (error.status === 404) {
        this.setApiError('Impossible operation (account not found)')
      }
      else if (error.status === 403) {
        this.setApiError('Forbidden operation (invalid url)')
      }
      else if (error.status === 500 || error.status === 501 || error.status === 502 || error.status === 503 ) {
        this.setApiError('Impossible operation (internal error)')
      }
    })
  }
  navigateTo(destination) {
    let destinationArray = []
    destinationArray.push(destination)
    this.router.navigate(destinationArray)
  }
  getError() {
    let sentence = ''
    this.getFormErrorsList().forEach(element => {
      sentence = sentence + getFormErrorSentence(element) + '. '
    });
    return sentence
  }

}
