<div [id]="id" class="list">
  <div class="list-container smooth-border row" [class.highlighted]='highlighted'
    [class.tile-selected]="false">
    <div class="item-text-container row mx-0 px-0 col-9 flex-nowrap ">
      <div class="d-flex pl-2 w-100 ">
        <div class="badge icon-container badge-pill d-flex w-auto border align-items-center justify-content-center ">
          <i *ngIf="type === ItemType.Cohort"   class="icon-type fa-solid fa-person-half-dress text-muted" aria-hidden="true"></i>
          <i *ngIf="type === ItemType.FocusGeneList"  class="icon-type fa-solid fa-chart-network text-muted" aria-hidden="true"></i>
        </div>
        <div class="flex-column  d-flex align-item-center weight-bold project-title px-3 ">

          <span *ngIf="type === ItemType.Cohort" >{{ name }}</span>
          <span *ngIf="type === ItemType.FocusGeneList" >{{ title }}</span>

          <div *ngIf="type === ItemType.Cohort" class="text-muted small type-description">
            Cohort
          </div>
          <div  *ngIf="type === ItemType.FocusGeneList" class="text-muted small type-description">
            Focus gene list
          </div>
        </div>

      </div>


    </div>
    <div class="item-icons-container mx-0 px-0 row col-3">
      <div class="item-icon-text px-0 d-flex col">
      </div>

      <div class="item-icons col-md-auto mx-0 px-0">
        <div class="dropdown pr-2">
          <button class="smooth-border btn btn-link border text-dark no-focus" type="button" id="dropdownMenuButton"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [class.bg-dark]="mouseOvered"
            (mouseover)="mouseOvered=true" (mouseout)="mouseOvered=false">
            <i class="far fa-ellipsis" [class.text-light]="mouseOvered" aria-hidden="true"></i>
          </button>
          <div class="dropdown-menu  smooth-border dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <div>
              <a class="dropdown-item noselect" (click)="getInfos()">Get info</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item noselect" (click)="share()">Share</a>
              <div *ngIf='canTrash || canDelete' class="dropdown-divider"></div>
              <a *ngIf='canTrash || canDelete' class="dropdown-item noselect" (click)="delete()">Delete</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
