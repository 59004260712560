import { Component, Input } from '@angular/core';
import { LocalService } from 'src/app/api/local-storage.service';
@Component({
  selector: 'DlProxyComponent',
  templateUrl: './dl-proxy.component.html',
})
export class DlProxyComponent {
  @Input() fileName?;
  @Input() fileId?;
  @Input() resetFunction;
  @Input() fileLink?;

  constructor(private localService: LocalService) {
  }
  ngOnInit() {
    var url;

    if (!this.fileLink) {
      let country =
        this.localService.getFromLocalStorage('country', 'value') || this.localService.getFromLocalStorage('user', 'account_country');
      url =
        this.localService.getSpecificCountryUrl(country) +
        '/mas/fs/files/' +
        this.fileId +
        '/data?access_token=' +
        this.localService.getSpecificCountryAccessToken(country);
    } else {
      url = this.fileLink;
    }
    this.downloadFile(url);
    setTimeout(() => {
      this.resetFunction();
    }, 1);
  }
  downloadFile(link, fileName?) {
    var element = window.document.createElement('a');
    element.setAttribute('href', link);
    element.style.display = 'none';
    window.document.body.appendChild(element);
    element.click();
    window.document.body.removeChild(element);
  }
}
