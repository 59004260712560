import { Component, OnDestroy } from '@angular/core';
import {
  AdditionalTypes,
  ShouldNotContainDoubleQuote,
  MaxLength128,
  InputJson,
  getFormErrorSentence,
  IsRequired,
  ShouldStartWithPMC,
  ShouldHaveNumbersAfterPMC,
} from 'src/app/shared/helpers/formattedInput';
import { Formulation } from '../formulation';
import { KnowledgeSearchService } from '../knowledge-search.service';
import * as _ from 'lodash';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';

@Component({
  selector: 'app-knowledge-search-article',
  templateUrl: './knowledge-search-article.component.html',
  styleUrls: ['./knowledge-search-article.component.scss'],
})
export class KnowledgeSearchArticleComponent implements OnDestroy {
  pmcIDObject: InputJson;
  PagesPublicName = PagesPublicName
  Formulation = Formulation;
  _=_
  getFormErrorSentence = getFormErrorSentence

  constructor(public knowledgeSearchService: KnowledgeSearchService) {

    this.pmcIDObject = {
      formControlName: 'pmcId',
      type: AdditionalTypes.ShortText,
      placeholder: 'PMC Id (ex : PMC7876697)',  
      lined_label: 'Enter the alphanumeric PMC Id of a single article',
      showIcon: 'far fa-info-circle',
      validations: [ShouldNotContainDoubleQuote, MaxLength128, IsRequired, ShouldHaveNumbersAfterPMC, ShouldStartWithPMC],
      fitToContent: true,
    }; 
    
    if (_.get(this.knowledgeSearchService.getQueryParams(),'k0')) {
      _.set(this.pmcIDObject, 'default_value', _.get(this.knowledgeSearchService.getQueryParams(),'k0'));
    }
  }
  ngOnDestroy() {
    this.knowledgeSearchService.resetForm(this.knowledgeSearchService.articleForm);
  }
}
