//HOMEPAGE
import { HomepageComponent } from './pages/homepage/homepage.component';
import { HomepageSaasComponent } from './pages/homepage-saas/homepage-saas.component';

//MENU
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { SidebarButtonComponent } from './shared/sidebar/sidebar-button.component';

// FORMER PROJECT CREATION
import { ProjectCreationComponent } from './pages/project-creation/project-creation.component';
import { ProjectCreationFormComponent } from './pages/project-creation/project-creation-form/project-creation-form.component';
import { AvailableWorkflowsComponent } from './pages/project-creation/available-workflows/available-workflows.component';
import { WorkflowInProgressComponent } from './pages/project-creation/workflow-in-progress/workflow-in-progress.component';
import { ProjectConfigurationComponent } from './pages/project-creation/project-configuration/project-configuration.component';
import { AvailableWorkflowComponent } from './pages/project-creation/available-workflows/available-workflow/available-workflow.component';
import { PreselectedWorkflowComponent } from './pages/project-creation/preselected-workflow/preselected-workflow.component';

// NEW PROJECT MANAGER
import { ProjectManagerComponent } from './pages/project-manager/project-manager.component';
import { ProjectListComponent } from './pages/project-manager/project-list/project-list.component';
import { InfoProjectComponent } from './pages/project-manager/info-project/info-project.component';
import { ShareProjectComponent } from './pages/project-manager/share-project/share-project.component';

// RUNS DASHBOARD
import { RunsDashboardComponent } from './pages/runs-dashboard/runs-dashboard.component';
import { ProgressFormComponent } from './pages/runs-dashboard/progress-modal/progress-modal.component';

// LAYOUT
import { LoggedInLayoutComponent } from './shared/logged-in-layout/logged-in-layout.component';

// ACCOUNT
import { UserCreationComponent } from './pages/user-creation/user-creation.component';
import { UserCreationFormComponent } from './pages/user-creation/user-creation-form/user-creation-form.component';
import { HttpInterceptorService } from './shared/http-interceptor-service/http-interceptor.service';
import { AccountComponent } from './pages/account/account.component';
import { NewAccountComponent } from './pages/new-account/new-account.component';

// EXPLORER
import { ExplorerComponent } from './pages/explorer/explorer.component';
import { ExplorerResponseComponent } from './pages/explorer/explorer-response/explorer-response.component';
import { ExplorerFullResponseComponent } from './pages/explorer/explorer-full-response/explorer-full-response.component';
import { SlideShortListAnswersComponent } from './pages/explorer/slides/slide-short-list-answers/slide-short-list-answers.component';
import { SlideNoQuestionComponent } from './pages/explorer/slides/slide-no-question/slide-no-question.component';
import { SlideAnswerFullDescriptionComponent } from './pages/explorer/slides/slide-answer-full-description/slide-answer-full-description.component';
import { QuestionFormComponent } from './pages/explorer/question-form/question-form.component';
import { ExplorerIconComponent } from './pages/explorer/explorer-icon/explorer-icon.component';
import { ExplorerPreviousQuestionComponent } from './pages/explorer/explorer-previous-question/explorer-previous-question.component';
import { BackComponent } from './pages/explorer/slides/back/back.component';

// LAYOUT
import { HorizontalComponent } from './shared/layout/horizontal/horizontal.component';
import { LayoutModule } from './modules/layout/layout.module';
import { ContentComponent } from './shared/layout/content/content.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';

//SCROLLER
import { ScrollSpyDirective } from './pages/account/scroll-spy.directive';

//NOTIFS
import { UserNotificationsPageComponent } from './pages/user-notifications-page/user-notifications-page.component';
import { removeAgoPipe, UserNotificationComponent } from './pages/user-notifications-page/user-notification/user-notification.component';

//FORMER FORM LIBRARY
import { InputFieldComponent } from './shared/input-field/input-field.component';
import { InputFieldTestComponent } from './shared/devtest/input-field-test/input-field-test.component'

//LOGIN
import { AlreadyLoggedInComponent } from './pages/login/already-logged-in/already-logged-in.component';
import { Login2Component } from './pages/login/login2.component';
import { SecondFactorComponent } from './pages/login/second-factor/second-factor.component';
import { FirstFactorComponent } from './pages/login/first-factor/first-factor.component';
import { QrCodeComponent } from './pages/login/qr-code/qr-code.component';
import { ResetPasswordModalComponent } from './pages/user-creation/reset-password-modal/reset-password-modal.component';

//TEAM
import { MemberAdditionFormComponent } from './pages/teams-manager/team-manager/member-addition-form/member-addition-form.component';
import { NewMemberAdditionFormComponent } from './pages/teams-manager/team-manager/new-member-addition-form/new-member-addition-form.component';
import { TeamSubscriptionFormComponent } from './pages/teams-manager/team-manager/team-subscription-form/team-subscription-form.component';
import { InvitationComponent } from './pages/teams-manager/devtest/invitation/invitation.component';
import { TeamManagerComponent } from './pages/teams-manager/team-manager/team-manager.component';
import { TeamsManagerComponent } from './pages/teams-manager/teams-manager.component';
import { TeamComponent } from './pages/teams-manager/team/team.component';
import { UserCard2Component } from './pages/teams-manager/team-manager/user-card2/user-card2.component';

//SHARED
import { CheckboxComponent } from './shared/checkbox/checkbox.component';
import { FullScreenModalComponent } from './shared/full-screen-modal/full-screen-modal.component';
import { ShareComponent } from './shared/share/share.component';
import { InitialsBubbleModule } from './mims-ui-lib/initials-bubble/initials-bubble.module';
import { Info2Component } from './pages/file-manager2/info-2/info-2.component';
import { Checkbox2Component } from './pages/file-manager2/checkbox-2/checkbox-2.component';
import { PaginationComponent } from './shared/pagination/pagination.component';
import { FormatCellPipe } from './mims-ui-lib/core/pipes/format-cell.pipe';
import { Rename2Component } from './shared/rename copy/rename2.component';
import { AvatarComponent } from './shared/avatar/avatar.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { RenameComponent } from './shared/rename/rename.component';
import { CustomModalComponent } from './shared/custom-modal/custom-modal.component';
import { MarkdownModule } from 'ngx-markdown';
import { ToastComponent } from './shared/toast/toast.component';


//DASHBOARD
import { ProjectDashboard2Component } from './pages/project-overview2/project-dashboard2/project-dashboard2.component';
import { ProjectOverviewNewComponent } from './pages/project-overview-demo/project-overview-new.component';
import { ProjectDashboardComponent } from './pages/project-overview/project-dashboard/project-dashboard.component';
import { VizComponent } from './pages/project-overview/project-dashboard/viz/viz.component';
import { Viz2Component } from './pages/project-overview2/project-dashboard2/viz2/viz2.component';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { ProjectOverviewComponent } from './pages/project-overview2/project-overview.component';
import { VizPlotlyComponent } from './pages/project-overview-demo/viz-types/viz-plotly/viz-plotly.component';
import { DashboardVersionComponent } from './pages/project-overview-demo/dashboard-version/dashboard-version.component';
import { VizMenuComponent } from './pages/project-overview-demo/viz-menu/viz-menu.component';
import { VizTestingSetComponent } from './pages/project-overview-demo/viz-testing-set/viz-testing-set.component';
import { VizImageSelectComponent } from './pages/project-overview-demo/viz-types/viz-image-select/viz-image-select.component';
import { VizSelectComponent } from './pages/project-overview-demo/viz-types/viz-select/viz-select.component';
import { VizSequenceComponent } from './pages/project-overview-demo/viz-types/viz-sequence/viz-sequence.component';
import { VizTableComponent } from './pages/project-overview-demo/viz-types/viz-table/viz-table.component';
import { VizDescriptionComponent } from './pages/project-overview-demo/viz-types/viz-description/viz-description.component';
import { VizTableThumbnailComponent } from './pages/project-overview-demo/viz-types/viz-table/viz-table-thumbnail.component';
import { VizThumbnailContainerComponent } from './pages/project-overview-demo/viz-types/viz-thumbnail-container/viz-thumbnail-container.component';
import { VizPlotlyThumbnailComponent } from './pages/project-overview-demo/viz-types/viz-plotly/viz-plotly-thumbnail.component';
import { VizSequenceThumbnailComponent } from './pages/project-overview-demo/viz-types/viz-sequence/viz-sequence-thumbnail.component';
import { DashboardContentComponent } from './pages/project-overview-demo/dashboard-content/dashboard-content.component';
import { VizContainerComponent } from './pages/project-overview-demo/viz-types/viz-container/viz-container.component';
import { VizAlignmentComponent } from './pages/project-overview-demo/viz-types/viz-alignment/viz-alignment.component';
import { VizAlignmentThumbnailComponent } from './pages/project-overview-demo/viz-types/viz-alignment/viz-alignment-thumbnail.component';
import { VizImageSelectColorscaleComponent } from './pages/project-overview-demo/viz-types/viz-image-select-colorscale/viz-image-select-colorscale.component';
import { VizImageSelectThumbnailComponent } from './pages/project-overview-demo/viz-types/viz-image-select/viz-image-select-thumbnail.component';
import { VizImageSelectColorscaleThumbnailComponent } from './pages/project-overview-demo/viz-types/viz-image-select-colorscale/viz-image-select-colorscale-thumbnail.component';
import { VizTileComponent } from './pages/project-overview/project-dashboard/viz-tile/viz-tile.component';
import { VizSelectBoxComponent } from './pages/project-overview/project-dashboard/viz/viz-select/viz-select-box.component';
import { QualityOverviewComponent } from './pages/file-manager2/quality-overview/quality-overview.component';

//FORMER DATA MANAGER
import { GroupsComponent } from './pages/file-manager2/groups/groups.component';
import { UploadToastComponent } from './pages/file-manager2/upload-toast/upload-toast.component';
import { Path2Component } from './pages/file-manager2/path-2/path-2.component';
import { Files2Component } from './shared/files-2/files-2.component';
import { File2Component } from './pages/file-manager2/file2/file2.component';
import { FileManager2Component } from './pages/file-manager2/file-manager2.component';
import { TopUtilityBarComponent } from './pages/file-manager2/top-utility-bar/top-utility-bar.component';
import { GroupComponent } from './pages/file-manager2/group/group.component';
import { MetadataUploadFormComponent } from './pages/file-manager2/metadata-upload-form/metadata-upload-form.component';
import { GroupCreationFormComponent } from './pages/file-manager2/group-creation-form/group-creation-form.component';
import { GroupContentComponent } from './pages/file-manager2/group/group-content/group-content.component';

//DATA MANAGER
import { DataManagerComponent } from './pages/data-manager/data-manager.component';
import { DataItemComponent } from './pages/data-manager/data-item/data-item.component';
import { SortByComponent } from './pages/data-manager/sort-by/sort-by.component';
import { DataInfoComponent } from './pages/data-manager/data-info/data-info.component';

//LICENCE
import { LicensesComponent } from './pages/licenses/licenses.component';
import { LicenseDetailsComponent } from './pages/licenses/license-details/license-details.component';

//COVSIGHT
import { KnowledgeSearchResultsNavigationPanelComponent } from './pages/knowledge-search/knowledge-search-results/knowledge-search-results-navigation-panel/knowledge-search-results-navigation-panel.component';
import { CardComponent } from './shared/card/card.component';
import { TopLevelCategoryComponent } from './pages/knowledge-search/knowledge-search-results/knowledge-search-results-navigation-panel/top-level-category/top-level-category.component';
import { CategoryListComponent } from './pages/knowledge-search/knowledge-search-results/knowledge-search-results-navigation-panel/category-list/category-list.component';
import { ExpanderButtonComponent } from './pages/knowledge-search/knowledge-search-results/knowledge-search-results-navigation-panel/expander-button/expander-button.component';
import { FilterRadioGroupComponent } from './pages/knowledge-search/knowledge-search-results/knowledge-search-results-navigation-panel/filter-radio-group/filter-radio-group.component';
import { CategoryFilterComponent } from './pages/knowledge-search/knowledge-search-results/knowledge-search-results-navigation-panel/category-filter/category-filter.component';
import { KnowledgeSearchResultsFiltersComponent } from './pages/knowledge-search/knowledge-search-results/knowledge-search-results-filters/knowledge-search-results-filters.component';
import { KnowledgeSearchResultsCategoryTreeComponent } from './pages/knowledge-search/knowledge-search-results-category-tree/knowledge-search-results-category-tree.component';
import { KnowledgeSearchResultsActionToolbarComponent } from './pages/knowledge-search/knowledge-search-results-action-toolbar/knowledge-search-results-action-toolbar.component';
import { KnowledgeSearchArticleComponent } from './pages/knowledge-search/knowledge-search-article/knowledge-search-article.component';
import { KnowledgeSearchComponent } from './pages/knowledge-search/knowledge-search.component';
import { KnowledgeSearchKeywordsComponent } from './pages/knowledge-search/knowledge-search-keywords/knowledge-search-keywords.component';
import { KnowledgeSearchTherapyComponent } from './pages/knowledge-search/knowledge-search-therapy/knowledge-search-therapy.component';
import { KnowledgeSearchFormulationComponent } from './pages/knowledge-search/knowledge-search-formulation/knowledge-search-formulation.component';
import { KnowledgeSearchResultsComponent } from './pages/knowledge-search/knowledge-search-results/knowledge-search-results.component';

//SOLUTION CREATOR
import { SolutionDocComponent } from './pages/documentation/solution/solution.component';
import { DocumentationAtlassian } from './pages/documentation/documentation/documentation-atlassian.component';
import { StepBarComponent } from './pages/project-creation2/step-bar/step-bar.component';
import { ProjectCreation2Component } from './pages/project-creation2/project-creation2.component';
import { CreditAlertsComponent } from './pages/project-creation2/credit-alerts/credit-alerts.component';


//HEADER
import { PageIconComponent } from './shared/layout/header2/page-icon/page-icon.component';
import { Header2Component } from './shared/layout/header2/header2.component';
import { HorizontalNavTab2Component } from './pages/file-manager2/horizontal-nav-tab-2/horizontal-nav-tab-2.component';
import { HorizontalNavTabComponent } from './shared/layout/horizontal-nav-tab/horizontal-nav-tab.component';


//FORMLY COMPONENT
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyFieldHugoInput } from './shared/formly/formly-field-hugo/formly-field-hugo';
import { RepeatTypeComponent } from './shared/formly/formly-field-repeat/formly-field-repeat';
import { FormlyFieldStepper } from './shared/formly/stepper.type';
import { FormlyFileGroupFieldInput } from './shared/formly/filegroup.type';
import { FormlyFileFieldInput } from './shared/formly/file.type';
import { FormlyFieldMultiselectInput } from './shared/formly/formly-field-multiselect/formly-multiselect.type';
import { FormlyFieldFile } from './shared/formly/file-field';
import { FileValueAccessor } from './shared/formly/file-value-accessor';
import { FormlyFieldProjects } from './shared/formly/projects.type';
import { FormlyFieldInstances } from './shared/formly/instances.type';
import { FormlyFieldClustersFile } from './shared/formly/clusters-file.type';
import { FormlyFieldCredits } from './shared/formly/credits.type';
import { FormlyRespondersWrapperInput } from './shared/formly/responders.wrapper.type';

//VALIDATORS 
import { HugoValidator, EmailValidator, HugoSupportedValidator, CsvValidator, UniqueValuesValidator, AdditionalValuesValidator } from './shared/formly/validators';


//OTHER FORMS
import { MatStepperModule } from '@angular/material/stepper';
import { SliderComponent } from './pages/project-overview2/dash-form/slider-control.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { DashFormComponent } from './pages/project-overview2/dash-form/dash-form.component';import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { DropDownModule } from './mims-ui-lib/drop-down/drop-down.module';
import { SharedModule } from './modules/shared/shared.module';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { JsonFormsModule } from '@jsonforms/angular';
import { JsonFormsAngularMaterialModule } from '@jsonforms/angular-material';

//ANGULAR
import { NgModule, Injectable } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiModule } from './api.module';
import { Configuration, ConfigurationParameters } from './configuration';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './mims-ui-lib/core/core.module';
import { LoaderModule, SelectModule } from './mims-ui-lib/public_api';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

//TO SORT




declare var Hammer: any;

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    let mc = new Hammer(element, {
      touchAction: 'auto',
    });
    return mc;
  }
}

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    // set configuration parameters here.
  };

  return new Configuration(params);
}

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HomepageSaasComponent,
    RunsDashboardComponent,
    ProjectManagerComponent,
    SolutionDocComponent,
    DocumentationAtlassian,
    ProjectListComponent,
    ProjectOverviewComponent,
    SidebarComponent,
    LoggedInLayoutComponent,
    UserCreationComponent,
    UserCreationFormComponent,
    GroupComponent,
    AccountComponent,
    ProjectDashboardComponent,
    VizComponent,
    AvatarComponent,
    ExplorerComponent,
    ExplorerResponseComponent,
    ExplorerFullResponseComponent,
    ExplorerIconComponent,
    CreditAlertsComponent,
    SpinnerComponent,
    HorizontalNavTabComponent,
    CustomModalComponent,
    CustomModalComponent,
    ProgressFormComponent,
    MetadataUploadFormComponent,
    SlideShortListAnswersComponent,
    SlideNoQuestionComponent,
    SlideAnswerFullDescriptionComponent,
    GroupCreationFormComponent,
    QuestionFormComponent,
    ExplorerPreviousQuestionComponent,
    BackComponent,
    NotFoundComponent,
    ProjectOverviewNewComponent,
    VizPlotlyComponent,
    VizMenuComponent,
    VizPlotlyThumbnailComponent,
    DashboardVersionComponent,
    VizTestingSetComponent,
    VizImageSelectComponent,
    VizSelectComponent,
    VizSequenceComponent,
    VizSequenceThumbnailComponent,
    VizTableComponent,
    VizTableThumbnailComponent,
    VizDescriptionComponent,
    VizThumbnailContainerComponent,
    CheckboxComponent,
    ProjectOverviewNewComponent,
    VizTableThumbnailComponent,
    VizPlotlyThumbnailComponent,
    DashboardVersionComponent,
    VizImageSelectComponent,
    VizImageSelectColorscaleComponent,
    VizSelectComponent,
    VizSequenceComponent,
    VizSequenceThumbnailComponent,
    VizTableComponent,
    VizTableThumbnailComponent,
    VizDescriptionComponent,
    VizThumbnailContainerComponent,
    CheckboxComponent,
    DashboardContentComponent,
    VizContainerComponent,
    VizAlignmentComponent,
    VizAlignmentThumbnailComponent,
    VizImageSelectThumbnailComponent,
    VizImageSelectColorscaleThumbnailComponent,
    ShareComponent,
    InfoProjectComponent,
    ShareProjectComponent,
    RenameComponent,
    ScrollSpyDirective,
    ProjectDashboardComponent,
    VizComponent,
    ResetPasswordModalComponent,
    VizTileComponent,
    FullScreenModalComponent,
    VizSelectBoxComponent,
    QualityOverviewComponent,
    ContentComponent,
    HorizontalComponent,
    TeamsManagerComponent,
    TeamComponent,
    ContentComponent,
    InvitationComponent,
    TeamManagerComponent,
    MemberAdditionFormComponent,
    GroupsComponent,
    InputFieldComponent,
    SidebarButtonComponent,
    NewAccountComponent,
    NewMemberAdditionFormComponent,
    TeamSubscriptionFormComponent,
    ToastComponent,
    GroupContentComponent,
    UserNotificationsPageComponent,
    UserNotificationComponent,
    removeAgoPipe,
    ProjectDashboard2Component,
    DashFormComponent,
    Viz2Component,
    KnowledgeSearchComponent,
    KnowledgeSearchKeywordsComponent,
    KnowledgeSearchTherapyComponent,
    KnowledgeSearchFormulationComponent,
    KnowledgeSearchArticleComponent,
    FormatCellPipe,
    KnowledgeSearchResultsComponent,
    InputFieldTestComponent,
    Login2Component,
    SecondFactorComponent,
    FirstFactorComponent,
    QrCodeComponent,
    PaginationComponent,
    TopUtilityBarComponent,
    FileManager2Component,
    AlreadyLoggedInComponent,
    HorizontalNavTab2Component,
    Path2Component,
    Files2Component,
    File2Component,
    Checkbox2Component,
    AlreadyLoggedInComponent,
    Info2Component,
    Rename2Component,
    UserCard2Component,
    UploadToastComponent,
    SliderComponent,
    DataManagerComponent,
    Header2Component,
    DataItemComponent,
    SortByComponent,
    DataInfoComponent,
    LicensesComponent,
    LicenseDetailsComponent,
    ProjectCreation2Component,
    FormlyFieldHugoInput,
    RepeatTypeComponent,
    FormlyFieldStepper,
    RepeatTypeComponent,
    // FormlyHugoFieldInput,
    FormlyFileGroupFieldInput,
    FormlyFileFieldInput,
    KnowledgeSearchResultsNavigationPanelComponent,
    CardComponent,
    TopLevelCategoryComponent,
    CategoryListComponent,
    ExpanderButtonComponent,
    FilterRadioGroupComponent,
    CategoryFilterComponent,
    KnowledgeSearchResultsFiltersComponent,
    KnowledgeSearchResultsCategoryTreeComponent,
    KnowledgeSearchResultsActionToolbarComponent,
    PageIconComponent,
    StepBarComponent,
    FormlyFieldMultiselectInput,
    FileValueAccessor,
    FormlyFieldFile,
    FormlyFieldProjects,
    FormlyFieldInstances,
    FormlyFieldClustersFile,
    FormlyFieldCredits,
    FormlyRespondersWrapperInput,
    ProjectCreationComponent,
    ProjectCreationFormComponent,
    AvailableWorkflowsComponent,
    WorkflowInProgressComponent,
    ProjectConfigurationComponent,
    AvailableWorkflowComponent,
    PreselectedWorkflowComponent,
  ],
  imports: [
    CoreModule,
    AppRoutingModule,
    RouterModule,
    ApiModule.forRoot(apiConfigFactory),
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    NgxWebstorageModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    JsonFormsModule,
    JsonFormsAngularMaterialModule,
    FormlyModule.forRoot({
      validators: [
        { name: 'email', validation: EmailValidator },
        { name: 'hugo-supported', validation: HugoSupportedValidator },
        { name: 'hugo', validation: HugoValidator },
        { name: 'csv', validation: CsvValidator },
        { name: 'unique-values', validation: UniqueValuesValidator},
        { name : "additional-values", validation: AdditionalValuesValidator}

      ],
      validationMessages: [
        { name: 'email', message: 'This field must be an email' },
        { name: 'required', message: 'This field is required' },
        { name: 'hugo', message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid HUGO symbol` },
        { name: 'csv', message: "This field must have a CSV format"},
        {
          name: 'hugo-supported',
          message: (error, field: FormlyFieldConfig) =>
            `Sorry we don’t have yet this gene ("${field.formControl.value}") but we will add it soon`,
        },
        {name : "unique-values", message : "All the values must be different"},
        {name : "additional-values", message : "This field must contain different values from the previous field"}
      ],
      types: [
        { name: 'multiselect_with_tags', component: FormlyFieldMultiselectInput, extends: 'select' },
        { name: 'hugo', component: FormlyFieldHugoInput },
        { name: 'repeat', component: RepeatTypeComponent },
        { name: 'stepper', component: FormlyFieldStepper, wrappers: [] },
        { name: 'files', component: FormlyFileFieldInput },
        { name: 'file', component: FormlyFieldFile, wrappers: ['form-field'] },
        { name: 'filegroup', component: FormlyFileGroupFieldInput},
        { name: 'projects', component: FormlyFieldProjects },
        { name: 'instances', component: FormlyFieldInstances },
        { name: 'clusters_file', component: FormlyFieldClustersFile },
        { name: 'credits', component: FormlyFieldCredits },
      ],
      wrappers: [{ name: 'responders', component: FormlyRespondersWrapperInput }],
    }),
    FormlyBootstrapModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatRippleModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    DragDropModule,
    NgbPaginationModule,
    NgbTooltipModule,
    NgbModule,
    AutocompleteLibModule,
    CommonModule,
    SharedModule,
    InitialsBubbleModule,
    LoaderModule,
    DeferLoadModule,
    SelectModule,
    DropDownModule,
    LayoutModule,
    NgxJsonViewerModule,
    NgxSliderModule,
    NgxBootstrapMultiselectModule,
    MatStepperModule,
    FormlyBootstrapModule,
    SelectModule,

    MarkdownModule
      .forRoot
      // {
      // markedOptions: {
      //   provide: MarkedOptions,
      //   useValue: {
      //     sanitize: false, // setting `true` does not sanitize without specifying a sanitizer function
      // },
      // }
      // }
      (),
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
