import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortDirection } from 'src/app/shared/helpers/sortDirection';
import { SortType } from 'src/app/shared/helpers/sortType';
import * as _ from 'lodash';
@Component({
  selector: 'mims-top-utility-bar',
  templateUrl: './top-utility-bar.component.html',
  styleUrls: ['./top-utility-bar.component.scss'],
})
export class TopUtilityBarComponent {
  filtersVisible = false;
  @Input() bulkSelectionLength?: number = 0;
  @Input() bulkSize?: any;
  @Input() visibleBulkLength?: number = 0;
  @Input() canSelect?: boolean = true;

  // @Input() pageSize?: number;
  // @Input() page?: number;
  // @Input() displayedFilesLength?: number;
  // @Input() uploadingFiles?: number = 0;
  // @Input() count?: number;

  @Input() sortingDirection: SortDirection;
  @Input() sortingType: SortType;
  @Input() sortingData;
  _ = _;
  @Output() sortingDirectionChange: EventEmitter<SortDirection> = new EventEmitter<SortDirection>();
  @Output() sortingTypeChange: EventEmitter<SortType> = new EventEmitter<SortType>();

  @Output() selection: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() unselection: EventEmitter<boolean> = new EventEmitter<boolean>();

  SortType = SortType;
  SortDirection = SortDirection;

  filesAreSelected() {
    return this.bulkSelectionLength > 0;
  }
  toggleDirection() {
    if (this.sortingDirection === SortDirection.Asc) {
      this.sortingDirectionChange.emit(SortDirection.Desc);
    } else if (this.sortingDirection === SortDirection.Desc) {
      this.sortingDirectionChange.emit(SortDirection.Asc);
    }
  }
  toggleFilterVisibility() {
    this.filtersVisible = !this.filtersVisible;
  }
}
