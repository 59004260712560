import { takeUntil } from 'rxjs/operators';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  OnDestroy,
  Optional,
  Self,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_OPTION_HEIGHT } from '../drop-down/drop-down-list/drop-down-list.component';
import { IOptionData } from '../drop-down/option.interface';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'mims-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent<T> implements ControlValueAccessor, OnChanges, AfterViewInit, OnDestroy {
  /** unique id of this component */
  id = uuidv4();

  /** The overlay origin */
  @ViewChild('overlayOrigin') overlayOrigin: CdkOverlayOrigin;

  /** The label of select component */
  @Input() label: string;

  /** Indicate dropdown list width */
  @Input() listWidth: number;

  /** Specifies the place holder message if nothing selected */
  @Input() placeholder = 'Please select...';

  /** Specifies the max items in dropdown list */
  @Input() maxOptionsVisibleInList = 6;

  /** Selected option, only works when it's not in a form control (in that case value will determine the selected option) */
  @Input() selectedOption: IOptionData<T>;

  /** Specifies the option height */
  @Input() optionHeight = DEFAULT_OPTION_HEIGHT;

  @Input() set dataSource(datasource: IOptionData<T>[]) {
    this._dataSource = datasource;
    this.updateSelectedOption(this.value);
  }
  get dataSource() {
    return this._dataSource;
  }

  /** The current value: for form control */
  @Input() value: any;

  /** Specifies every time the value has been changed */
  @Output() valueChanged: EventEmitter<IOptionData<T>> = new EventEmitter();

  /** Specifies every time the dropdown has been toggled. boolean specifies if opened */
  @Output() toggled: EventEmitter<boolean> = new EventEmitter();

  /** Holding the data source */
  _dataSource: IOptionData<T>[];

  opened = false;

  /** pattern to destroy all subscriptions */
  private componentDestroyed$: Subject<void> = new Subject();

  /** @ignore callback method when change occurs on the component */
  propagateChange: any = () => {};

  /** @ignore callback method when touch occurs on the component */
  propagateTouched: any = () => {};

  constructor(private _cdr: ChangeDetectorRef, @Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  /** @ignore */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /** @ignore */
  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  /** @ignore */
  setDisabledState?(isDisabled: boolean): void {
    // TODO: support disabled
    throw new Error('Method not implemented.');
  }

  ngOnChanges({ value, selectedOption }: SimpleChanges): void {
    if (value) {
      this.updateSelectedOption(value.currentValue);
    }

    if (selectedOption) {
      // set value if it's not in a form context (in that case it's reactive form's job to writeValue)
      if (!this.ngControl) {
        this.value = selectedOption.currentValue ? selectedOption.currentValue.value : '';
      }
    }
  }

  ngAfterViewInit() {
    fromEvent(this.overlayOrigin.elementRef.nativeElement, 'click')
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((event: MouseEvent) => {
        event.stopPropagation();
        this.opened = !this.opened;
        this._cdr.detectChanges();
      });

    this.updateSelectedOption(this.value);
  }

  onDropdownToggled(status: boolean) {
    this.opened = status;
    this.toggled.emit(this.opened);
  }

  /** value change from form control */
  writeValue(obj: any): void {
    this.value = obj;
    this.updateSelectedOption(obj);
  }

  /** Set the selected option from the specified value */
  updateSelectedOption(value: any) {
    if (this.dataSource && value !== undefined && value !== null) {
      let optionIndex;
      if (typeof value === 'object') {
        optionIndex = this.dataSource.map((o) => JSON.stringify(o.value)).indexOf(JSON.stringify(value));
      } else {
        optionIndex = this.dataSource.map((o) => (o.value ? o.value.toString() : undefined)).indexOf(value.toString());
      }
      this.selectedOption = optionIndex > -1 ? this._dataSource[optionIndex] : undefined;
    } else {
      this.selectedOption = undefined;
    }
    this._cdr.detectChanges();
  }

  onOptionSelected(selectedOption: IOptionData<any>): void {
    this.selectedOption = selectedOption;
    this.opened = false;
    // update the value and propagate the change
    this.value = selectedOption.value;
    this.propagateChange(this.value);
    this.valueChanged.emit({ ...selectedOption });
    this._cdr.detectChanges();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
