export function areArgumentsDefined(args) {
  let result = true
  Array.from(args).forEach((arg) => {
    if (arg === undefined || arg === null || arg === '') {
      result =  false;
      return
    }
  });
  return result;
}
