import { Features } from "./features";

// `ng build -c beta` environment
export const environment = {
  production: true,
  name: 'beta',
  activationPath: 'https://preprod-mims.eastus2.cloudapp.azure.com',
  platformName: 'MIMs Platform (beta)',
  platformUrl: "https://mimsomicbeta.mims.ai",
  apiBasePath: 'https://preprod-mims.eastus2.cloudapp.azure.com',
};
export const availableFeatures = [
  Features.UploadFile,
    // Features.DoubleAuth,
  Features.DeleteFile,
  Features.DeleleMultipleFiles,
  Features.KnowledgeSearch,
  Features.MoveManuallyToGroup
]
