<div [class.hidden]="!show">
  <div [id]="idName" [class.justify-content-between]="layout.mobile" [class.d-flex]="layout.mobile"
    [class.flex-column]="layout.mobile" class="rounded border custom-modal">
    <div class="data-container">

      <div class='modal-header d-flex flex-nowrap align-items-center'>
        
        <div>
          <h5 class="modal-title" >{{ modalTitle }}</h5>
        </div>
        <div class="btn btn-link close" aria-label="Close" (click)="layout.close(idName); executeCancelCallbacks()">
          <i aria-hidden="true" class="far fa-times"></i>
        </div>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
    </div>
    <div class="modal-footer">
    <div *ngIf='buttons && confirmTitle' class="btn-container w-100">
      <div class="btn  text-uppercase border btn-light" (click)="layout.close(idName); executeCancelCallbacks()">
        <small>
          {{cancelTitle? cancelTitle : 'Cancel'}}
        </small>
      </div>
      <div class="btn btn-dark text-uppercase" ngbTooltip="{{getFormError()}}" tooltipClass="custom-tooltip"
        placement="left" [class.disabled]="!getValidator()" (click)="getValidator() ? handleValidation() : ''">
        <small>{{ confirmTitle }} </small>
      </div>
    </div>
    </div>
  </div>
</div>
