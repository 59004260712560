<div (click)="e.initNewQuestion(); e.setQuestionIndex(index); e.copyPreviousQuestionToTextArea(index);"
  *ngIf="text && text.length">
  <div class="my-2 cursor-pointer box d-flex" [class.font-weight-bold]="index === e.indexQuestion">
    <div class="flex-fill response-container ">
      <div class="response">
        <span class="word">{{ text }}</span>
      </div>
    </div>
    <div class=".p-2 date-container d-flex justify-content-end">
      <div class="date">
        {{ readableDate }}
      </div>
    </div>
  </div>
</div>