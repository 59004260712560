import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ItemType } from '../helpers/itemType';
import { Status } from '../helpers/status';
export enum BulkType {
  File = 'file',
  AllFile = 'allFiles',
  Project = 'project',
  AllProject = 'allProjects',
}
@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  BulkType = BulkType;
  @Input() bulkType;
  @Input() status?;
  @Input() title?;
  @Input() fileType;
  @Input() id: string;
  @Input() country?: string;
  @Input() selected;
  Status = Status;
  FileType = ItemType;
  @Input() selectAction?;
  @Input() unSelectAction?;
  @Output() selection: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() unselection: EventEmitter<boolean> = new EventEmitter<boolean>();


  handleClick = () => {
    if (this.selected) {
      this.unselection.emit(true);
    } else {
      this.selection.emit(true);
    }
    if (this.unSelectAction && this.selectAction) {
      if (this.country && this.title) {
        if (this.selected) {
          this.unSelectAction(this.id);
        } else {
          this.selectAction(this.id, this.country, this.title);
        }
      } else {
        if (this.selected) {
          this.unSelectAction(this.id);
        } else {
          this.selectAction(this.id);
        }
      }
    }
  };
  handleAllClick = () => {
    if (this.selected) {
      this.unselection.emit(true);
      if (this.unSelectAction) this.unSelectAction();
    } else {
      this.selection.emit(true);
      if (this.selectAction) this.selectAction();
    }
  };
}
