<app-content class='scrollable'>
  <div class='h-100 w-100 scrollable'>
    <h3 class='m-4'>This is a page aimed to test the mims library for inputs</h3>
    <div class='btn btn-dark m-4' [class.disabled]='dataIsDownloaded' (click)='simulateDataDownload()'> Simulate Data
      Download</div>
    <div class='btn btn-dark m-4 ' [class.disabled]='!dataIsDownloaded' (click)='simulateDataSelection()'> Simulate Data
      Preselection</div>
    <div *ngIf='isFormValid(form)' class='alert alert-success'>Form is VALID</div>
    <div *ngIf='!isFormValid(form)' class='alert alert-danger'>Form is INVALID</div>
    <div class='d-flex  flex-nowrap'>
      <form class="w-50" [formGroup]="form">
        <ul class="m-4 inputs-form list-unstyled">
          <li class="my-5">
            <span *ngFor="let fieldObject of fieldObjects">
              <app-input-field class='my-5' [allowedValueUpdate]="_.get(fieldObject, 'retrievedAllowedValues')"
                [valueUpdate]="_.get(fieldObject, 'retrievedValue')" id='{{_.get(fieldObject, "formControlName")}}'
                (valueSelected)='onCheck(_.get(fieldObject, "formControlName"))' [parentForm]="form" [inputJson]="fieldObject">
              </app-input-field>
              <div [style.minHeight.rem]="1.5"><span *ngIf='fieldObject.formControlName === userInteraction'>User action
                  (event detected)</span></div>
            </span>
          </li>
        </ul>
      </form>
      <div class='bg-dark w-50'>
        <pre class='text-white'>
        <code>
 {{JSON.stringify(fieldObjects, getCircularReplacer(),2)}}
        </code>
        </pre>
      </div>
    </div>
  </div>
</app-content>
