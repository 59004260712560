import { Component, OnInit, Input } from '@angular/core';
import { LocalService } from 'src/app/api/local-storage.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  user;
  @Input() fontSize;
  initials;

  constructor(private localService: LocalService) {
    this.user = this.localService.getFromLocalStorage('user')
   }

  ngOnInit() {
    const name = _.get(this, 'user.first_name', '?') + ' ' + _.get(this, 'user.last_name', '?');
    const matches = name.match(/\b(\w)/g);
    this.initials = matches.join('').toUpperCase();
  }
}
