<div #viewport [style.height]="viewportHeight + 'px'" class="drop-down">
  <div
    *ngFor="let option of dataSource"
    [style.height]="optionHeight + 'px'"
    [ngClass]="{ selected: option === selectedOption }"
    (click)="onClick($event, option)"
    class="option"
    [attr.title]="option.label !== undefined ? option.label : option.value"
  >
    {{ option.label !== undefined ? option.label : option.value }}
  </div>
</div>
