import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
@Component({
  selector: 'mims-initials-bubble',
  templateUrl: './initials-bubble.component.html',
  styleUrls: ['./initials-bubble.component.scss']
})
export class InitialsBubbleComponent  {
  @Input() fullName : string;
  @Input() theme? : string = 'light';

  extractInitialsFromName = (name): string => {
    let initials = ''
    let array = _.words(name);
    if (name === 'Unknown user')
      initials = '?'
    else if (array.length >= 2) {
      initials = _.first(_.first(array)) + _.first(_.last(array))
    }
    else {
      initials = _.first(_.first(array))
    }
    return initials
  }


}
