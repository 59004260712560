import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ExplorerManagerService } from 'src/app/api/explorer-manager.service';
@Component({
  selector: 'app-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: [
    '../../../shared/styles/forms.component.scss',
    './question-form.component.scss',
  ],
})
export class QuestionFormComponent implements OnInit, AfterViewInit {
  @ViewChild('questionFormInput') questionInputForm: ElementRef;
  constructor(public e: ExplorerManagerService) { }
  ngOnInit() {
    this.e.initNewQuestion();
    this.e.questionForm = new UntypedFormGroup({
      questionInput: new UntypedFormControl({ value: '', disabled: false }, [
        Validators.required,
        // this.availabilityValidator
        // this.lengthValidator
      ]),
    });
  }
  keyDownActions(event) {
    const code = event.keyCode || event.which;
    const enter = 13;
    if (code === enter) {
      event.preventDefault();
    }
  }
  ngAfterViewInit() {
    this.e.questionInput = this.questionInputForm;
  }
  get questionInput() {
    return this.e.questionForm.get('questionInput');
  }
  // availabilityValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
  //   return this.e.isBusy() ? { mustWaitUntilAvailable: true } : null;
  // }

  // lengthValidator = (control: FormControl): ValidationErrors | null => {
  //   if (control.value) {
  //     return control.value.length > 255 ? { lengthMustBeShorter: true } : null;
  //   } else {
  //     return null;
  //   }
  // };
}
