import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import * as _ from 'lodash';

import { ProjectsService} from 'src/app/api/projects.service';
import { Files2Service } from 'src/app/api/files2.service';
import { Action, DataManagerService } from 'src/app/pages/data-manager/data-manager.service';

@Component({
  selector: 'formly-field-input',
  styleUrls: ['../../../styles.scss'],
  templateUrl: './file.type.html'
})
export class FormlyFileFieldInput extends FieldType<FieldTypeConfig> {
  isFocusGene = false;
  createOption = 'create_focus_gene_list';
  Action = Action;

  constructor(private fs: Files2Service, 
    public dataManager: DataManagerService,
    public projectsService: ProjectsService) {
    super();
  }
  
  ngOnInit() {
    this.updateSelectorOptions([{ label: 'Loading...', value: ''}], false);
    this.fetchFocusGeneListOptions();
  } 

  fetchFocusGeneListOptions() {
    const filter = { 'data_type' : 'focus_gene_list' };
    this.projectsService.searchFiles({ 'or_filter': filter, 'and_filter': filter })
      .subscribe(searchResponse => {
        this.updateSelectorOptions(this.fetchOptions(searchResponse), true);
      }, err => {
        console.warn(err);
      });
  }

  fetchOptions(searchResponse) {
    let options = [{ label: _.get(this, 'to.placeholder', 'Select'), value: '' }];
    if (_.isEmpty(searchResponse) || _.isEmpty(searchResponse.file_ids)) {
      this.updateSelectorOptions(options, true);
      return
    }

    searchResponse.file_ids.forEach((fileId, fileIndex) => {
      this.fetchOption(fileId.substr(5), fileIndex, options);
    });

    return options;
  }

  fetchOption(fileKey:string, fileIndex:number, options) {
    options.push({label: '', value: ''});
    this.fs.getPathFromFileId(fileKey, this.projectsService.getProjectStorageCountry()).subscribe(
      getPathResponse => {

        const fileName = getPathResponse.parts[getPathResponse.parts.length-1].name;
        options[fileIndex+1].label = fileName;
        options[fileIndex+1].value = fileKey
        this.updateSelectorOptions(options, false);
      },
      err => {
        console.warn(err);
      });
  }

  updateSelectorOptions(options, appendCreateOption:boolean) {
    if (appendCreateOption) {
      options.push({ label: '+ Create focus gene list', value: this.createOption });
    }
    this.to.options = options;
    this.formControl.setValue('');
  }

  onSelectionChange(event) {
    if (event.target.value === this.createOption) {
      this.dataManager.actionEvent.emit({ action: Action.CreateFocusGeneList });
      this.formControl.setValue('');
    }
  }
}
