<div>
  <div class="header">
    Results by subject
    <app-expander-button (clicked)="toggleExpanded()"></app-expander-button>
  </div>
  <div *ngIf="expanded" class="filters-wrapper">
    <div class="filters">
      <div class="filter">
        <input type="radio" name="filter" value="" [(ngModel)]="this.knowledgeSearchService.selectedBucketFilter"
          (change)="this.knowledgeSearchService.search()" />
        <span class="pill">All</span>
      </div>
      <div *ngFor="let bucket of buckets" class="filter">
        <input type="radio" name="filter" [value]="bucket.bucket.join(',')"
          [(ngModel)]="this.knowledgeSearchService.selectedBucketFilter"
          (change)="this.knowledgeSearchService.search()" />
        <span *ngFor="let category of bucket.bucket" class="pill"
          [style.background-color]="getColorForCategory(category) + '4D'">{{ getNameForCategory(category) }}</span>
        <sup class="filter-count">{{ bucket.triple_count }}</sup>
      </div>
    </div>
  </div>
</div>
