<div *ngIf="filteredGroup().length" id='GROUPS' class='m-2 ' [class.footer-margin]='marginBottom'>
    <div>
        <div class="group-utility-bar rounded bg-dark">
            <div [style.height.px]="35" [style.lineHeight.px]="35" class="px-5 text-light font-weight-bold ">
              {{sharedWithMe ? 'File Groups shared with me': 'My File Groups'}} 
             </div>
        </div>
        <div>
            <div *ngFor="let group of filteredGroup(); trackBy: trackByFn; let i = index"
                class='project-list-container'>
                <group *ngIf="!_.get(group, 'capabilities.can_modify')" [activeTab]='activeTab' [country]='country' [group]="group" [index]='i'>
                </group>
                <group *ngIf="_.get(group, 'capabilities.can_modify')" [activeTab]='activeTab' [country]='country'
                 [group]="group" [index]='i'>
            </group>
            </div>
        </div>
    </div>
    <mims-loader [isLoading]="fileGroupsService.isLoading" [enableBackDrop]="true"></mims-loader>
</div>
<app-group-creation-form *ngIf="!sharedWithMe && layout.isGroupModalOpen"></app-group-creation-form>
