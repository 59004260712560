<div class="w-100 my-4"><span class="text-info">This is a test page involving all the different visualizations we
        have
        worked on</span>
</div>

<div class="w-100 my-4"><span class="text-info">Most of the layout is controlled in the front end side</span></div>

<div class="w-100 my-4"><span class="text-info">Cyan is the main color, and the uses 5 others, declined from
        it
        (triad
        combinaison)</span></div>

<div class="w-100 my-4"><span class="text-info"> there is 3 sizes customizables : thumbnail, medium tile and full-size
        tile</span></div>

<app-viz-plotly-thumbnail [size]='thumbnailSize' [title]="getTitle('pie')" [data]="getData('pie')"
    [layout]="getLayout('pie')">
</app-viz-plotly-thumbnail>
<app-viz-plotly-thumbnail [size]='thumbnailSize' [title]="getTitle('scatter2')" [data]="getData('scatter2')"
    [layout]="getLayout('scatter2')">
</app-viz-plotly-thumbnail>
<!-- <app-viz-plotly-thumbnail [size]='thumbnailSize' [title]="getTitle('treemap')" [data]="getData('treemap')"
    [layout]="getLayout('treemap')">
</app-viz-plotly-thumbnail> -->
<app-viz-plotly-thumbnail [size]='thumbnailSize' [title]="getTitle('treemap2')" [data]="getData('treemap2')"
    [layout]="getLayout('treemap2')">
</app-viz-plotly-thumbnail>
<app-viz-plotly-thumbnail [size]='thumbnailSize' [title]="getTitle('bar')" [data]="getData('bar')"
    [layout]="getLayout('bar')">
</app-viz-plotly-thumbnail>
<app-viz-plotly-thumbnail [size]='thumbnailSize' [title]="getTitle('tree2')" [data]="getData('tree2')"
    [layout]="getLayout('tree2')">
</app-viz-plotly-thumbnail>
<app-viz-alignment-thumbnail [size]='thumbnailSize' [dataType]="getDataType('alignment2')"
    [title]="getTitle('alignment2')" [data]="getData('alignment2')" [layout]="getLayout('alignment2')">
</app-viz-alignment-thumbnail>
<app-viz-alignment-thumbnail [size]='thumbnailSize' [dataType]="getDataType('alignment3')"
    [title]="getTitle('alignment3')" [data]="getData('alignment3')" [layout]="getLayout('alignment3')">
</app-viz-alignment-thumbnail>
<app-viz-alignment-thumbnail [size]='thumbnailSize' [dataType]="getDataType('alignment4')"
    [title]="getTitle('alignment4')" [data]="getData('alignment4')" [layout]="getLayout('alignment4')">
</app-viz-alignment-thumbnail>
<app-viz-plotly-thumbnail [size]='thumbnailSize' [title]="getTitle('network2')" [data]="getData('network2')"
    [layout]="getLayout('network2')">
</app-viz-plotly-thumbnail>
<app-viz-table-thumbnail [size]='thumbnailSize' [title]="getTitle('table')" [data]="getData('table')"
    [layout]="getLayout('table')">
</app-viz-table-thumbnail>
<app-viz-sequence-thumbnail [size]='thumbnailSize' [title]="getTitle('sequence2')" [data]="getData('sequence2')"
    [layout]="getLayout('sequence2')">
</app-viz-sequence-thumbnail>
<app-viz-image-select-thumbnail [size]='thumbnailSize' [titleSelect]="getTitleSelect('imgSelect2')"
    [interactions]="getInteractions('imgSelect2')" [title]="getTitle('imgSelect2')" [images]='getImages("imgSelect2")'
    [data]="getData('imgSelect2')" [layout]="getLayout('imgSelect2')">
</app-viz-image-select-thumbnail>
<app-viz-image-select-colorscale-thumbnail [size]='thumbnailSize' [titleSelect]="getTitleSelect('imgSelectColorscale2')"
    [interactions]="getInteractions('imgSelectColorscale2')" [title]="getTitle('imgSelectColorscale2')"
    [images]='getImages("imgSelectColorscale2")' [data]="getData('imgSelectColorscale2')"
    [layout]="getLayout('imgSelectColorscale2')">
</app-viz-image-select-colorscale-thumbnail>
<div class="w-100"></div>

<app-viz-plotly [size]='tileSize' [title]="getTitle('pie')" [data]="getData('pie')" [layout]="getLayout('pie')"
    id='test1'>
</app-viz-plotly>
<app-viz-plotly [size]='tileSize' [title]="getTitle('scatter2')" [data]="getData('scatter2')"
    [layout]="getLayout('scatter2')" id='test2b'>
</app-viz-plotly>
<!-- <app-viz-plotly [size]='tileSize' [title]="getTitle('treemap')" [data]="getData('treemap')"
    [layout]="getLayout('treemap')" id='test3'>
</app-viz-plotly>  -->
<app-viz-plotly [size]='tileSize' [title]="getTitle('treemap2')" [data]="getData('treemap2')"
    [layout]="getLayout('treemap2')" id='test3b'>
</app-viz-plotly>

<app-viz-plotly [size]='tileSize' [title]="getTitle('bar')" [data]="getData('bar')" [layout]="getLayout('bar')"
    id='test4'>
</app-viz-plotly>
<app-viz-plotly [size]='tileSize' [title]="getTitle('tree2')" [data]="getData('tree2')" [layout]="getLayout('tree2')"
    id='test5b'>
</app-viz-plotly>

<app-viz-image-select [size]='tileSize' [titleSelect]="getTitleSelect('imgSelect2')"
    [interactions]="getInteractions('imgSelect2')" [title]="getTitle('imgSelect2')" [images]='getImages("imgSelect2")'
    [data]="getData('imgSelect2')" [layout]="getLayout('imgSelect2')" id='test8f'>
</app-viz-image-select>
<app-viz-image-select-colorscale [size]='tileSize' [images]='getImages("imgSelectColorscale2")'
    [titleSelect]="getTitleSelect('imgSelectColorscale2')" [interactions]="getInteractions('imgSelectColorscale2')"
    [title]="getTitle('imgSelectColorscale2')" [data]="getData('imgSelectColorscale2')"
    [layout]="getLayout('imgSelectColorscale2')" id='test8e'>
</app-viz-image-select-colorscale>
<app-viz-alignment [size]='tileSize' [dataType]="getDataType('alignment2')" [title]="getTitle('alignment2')"
    [data]="getData('alignment2')" [layout]="getLayout('alignment2')" id='test8b'>
</app-viz-alignment>
<app-viz-alignment [size]='tileSize' [dataType]="getDataType('alignment3')" [title]="getTitle('alignment3')"
    [data]="getData('alignment3')" [layout]="getLayout('alignment3')" id='test8c'>
</app-viz-alignment>
<app-viz-alignment [size]='tileSize' [dataType]="getDataType('alignment4')" [title]="getTitle('alignment4')"
    [data]="getData('alignment4')" [layout]="getLayout('alignment4')" id='test8d'>
</app-viz-alignment>
<app-viz-plotly [size]='tileSize' [title]="getTitle('network2')" [data]="getData('network2')"
    [layout]="getLayout('network2')" id='test9b'>
</app-viz-plotly>
<app-viz-table [size]='tileSize' [title]="getTitle('table')" [data]="getData('table')" [layout]="getLayout('table')"
    id='test6'>
</app-viz-table>
<app-viz-sequence [size]='tileSize' [title]="getTitle('sequence2')" [data]="getData('sequence2')"
    [layout]="getLayout('sequence2')" id='test7b'>
</app-viz-sequence>



<div class="w-100"></div>

<app-viz-plotly [size]='tileFullSize' [title]="getTitle('pie')" [data]="getData('pie')" [layout]="getLayout('pie')"
    id='f-test1'>
</app-viz-plotly>
<app-viz-plotly [size]='tileFullSize' [title]="getTitle('scatter2')" [data]="getData('scatter2')"
    [layout]="getLayout('scatter2')" id='f-test2b'>
</app-viz-plotly>
<!-- <app-viz-plotly [size]='tileFullSize' [title]="getTitle('treemap')" [data]="getData('treemap')"
    [layout]="getLayout('treemap')" id='f-test3'>
</app-viz-plotly>  -->
<app-viz-plotly [size]='tileFullSize' [title]="getTitle('treemap2')" [data]="getData('treemap2')"
    [layout]="getLayout('treemap2')" id='f-test3b'>
</app-viz-plotly>

<app-viz-plotly [size]='tileFullSize' [title]="getTitle('bar')" [data]="getData('bar')" [layout]="getLayout('bar')"
    id='f-test4'>
</app-viz-plotly>
<app-viz-plotly [size]='tileFullSize' [title]="getTitle('tree2')" [data]="getData('tree2')"
    [layout]="getLayout('tree2')" id='f-test5b'>
</app-viz-plotly>

<app-viz-image-select [size]='tileFullSize' [titleSelect]="getTitleSelect('imgSelect2')"
    [interactions]="getInteractions('imgSelect2')" [title]="getTitle('imgSelect2')" [images]='getImages("imgSelect2")'
    [data]="getData('imgSelect2')" [layout]="getLayout('imgSelect2')" id='f-test8d'>
</app-viz-image-select>
<app-viz-image-select-colorscale [size]='tileFullSize' [images]='getImages("imgSelectColorscale2")'
    [titleSelect]="getTitleSelect('imgSelectColorscale2')" [interactions]="getInteractions('imgSelectColorscale2')"
    [title]="getTitle('imgSelectColorscale2')" [data]="getData('imgSelectColorscale2')"
    [layout]="getLayout('imgSelectColorscale2')" id='f-test8d'>
</app-viz-image-select-colorscale>
<app-viz-alignment [size]='tileFullSize' [dataType]="getDataType('alignment2')" [title]="getTitle('alignment2')"
    [data]="getData('alignment2')" [layout]="getLayout('alignment2')" id='f-test8b'>
</app-viz-alignment>
<app-viz-alignment [size]='tileFullSize' [dataType]="getDataType('alignment3')" [title]="getTitle('alignment3')"
    [data]="getData('alignment3')" [layout]="getLayout('alignment3')" id='f-test8c'>
</app-viz-alignment>
<app-viz-alignment [size]='tileFullSize' [dataType]="getDataType('alignment4')" [title]="getTitle('alignment4')"
    [data]="getData('alignment4')" [layout]="getLayout('alignment4')" id='f-test8d'>
</app-viz-alignment>
<app-viz-plotly [size]='tileFullSize' [title]="getTitle('network2')" [data]="getData('network2')"
    [layout]="getLayout('network2')" id='f-test9b'>
</app-viz-plotly>
<app-viz-table [size]='tileFullSize' [title]="getTitle('table')" [data]="getData('table')" [layout]="getLayout('table')"
    id='f-test6'>
</app-viz-table>
<app-viz-sequence [size]='tileFullSize' [title]="getTitle('sequence2')" [data]="getData('sequence2')"
    [layout]="getLayout('sequence2')" id='f-test7b'>
</app-viz-sequence>