import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-viz-container',
  templateUrl: './viz-container.component.html',
  styleUrls: ['./viz-container.component.scss', '../viz-style.scss'],
})
export class VizContainerComponent implements OnInit {
  @Input() title;
  @Input() size;


  ngOnInit(): void {}
}
