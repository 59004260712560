<div>
  <app-top-level-category [category]="category" [color]="this.knowledgeSearchService.colorForCategory(category.id)" (expand)="toggleExpanded()">
  </app-top-level-category>
  <ul *ngIf="expanded" class="nested-categories">
    <li *ngFor="let subCategory of category.children">
      <app-knowledge-search-results-category-tree [category]="subCategory" [depth]="1">
      </app-knowledge-search-results-category-tree>
    </li>
  </ul>
</div>
