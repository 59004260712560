import { Component, OnInit } from '@angular/core';
import { Expandable } from 'src/app/shared/expandable/expandable';
import { BucketModel, KnowledgeSearchService } from '../../knowledge-search.service';

@Component({
  selector: 'app-knowledge-search-results-filters',
  templateUrl: './knowledge-search-results-filters.component.html',
  styleUrls: ['./knowledge-search-results-filters.component.scss'],
})
export class KnowledgeSearchResultsFiltersComponent extends Expandable implements OnInit {

  buckets: BucketModel[] = [];

  constructor(public knowledgeSearchService: KnowledgeSearchService) {
    super();
  }

  ngOnInit(): void {
    this.knowledgeSearchService.retrievedResult.subscribe((res) => {
      this.buckets = res?.buckets || [];
    });
  }

  getNameForCategory(id: string) {
    return this.knowledgeSearchService.nameForCategory(id);
  }

  getColorForCategory(id: string) {
    return this.knowledgeSearchService.colorForCategory(id);
  }
}
