<app-viz-container *ngIf='data' [size]='size' [title]="title">
    <div [style.width.%]='100' [style.height.%]='100' class="d-flex flex-column">
        <a (click)="generateHref()" [download]="data[0].title? data[0].title + '.csv' : 'undefined.csv'"
            class="btn btn-light vizButton" [href]="_DomSanitizer.bypassSecurityTrustUrl(octet_stream)"
            [id]="id + '_to_csv'">
            Download CSV
        </a>
        <div class="table-responsive vizTableContainer d-flex">
            <table aria-describedby="table" class="vizTable table-hover table-striped">
                <thead *ngIf="data[0].headers">
                    <tr>
                        <th *ngFor="let header of data[0].headers">
                            {{ header }}
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="data[0].cells">
                    <tr *ngFor="let lines of data[0].cells">
                        <td *ngFor="let cell of lines">
                            {{ cell }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</app-viz-container>