<div class="box-container my-3" *ngIf="opened">
  <div (click)="toggleOpening()" class="pointer-cursor box d-flex">
    <div class=" d-flex flex-column align-items-start p-2 response-container ">
      <div class="response">
        <span class="font-weight-bold">
          {{ results.length | i18nPlural: itemPluralMapping.response }}
        </span>
        <div *ngFor="let result of results; let last = last">
          <div *ngIf="result.length>1">
            <div class="word-result my-3 word-result-opened" *ngFor="let el of result">
              {{ el }}
            </div>
          </div>
          <div *ngIf="result.length === 1">
            <div class="word-result my-3 word-result-opened">
              {{ result }}
            </div>
          </div>

        </div>
      </div>
      <div class="response" *ngIf="focus.length">
        <span class="font-weight-bold">Focus : </span>
        <span class="word word-opened" *ngFor="let el of focus; let last = last">{{ el }}<span
            *ngIf="!last">,</span></span>
      </div>
      <div class="response" *ngIf="unused.length">
        <span class="font-weight-bold">Unused : </span>
        <span class="word word-opened" *ngFor="let el of unused; let last = last">{{ el }}<span
            *ngIf="!last">,</span></span>
      </div>
      <div class="response" *ngIf="!!score">
        <span class="font-weight-bold">Relevance : </span>
        <span class="word word-opened">{{ score }}</span>
      </div>
      <div class="response">
        <span class="font-weight-bold">Time : </span>
        <span class="word word-opened">{{
          moment(date).format('MM/DD/YYYY - HH:mm')
        }}</span>
      </div>
    </div>
  </div>
  <div class="more" (click)="
      e.goToView(e.ChatView.AnswerInFullDescription); e.setActiveAnswer(answer)
    " *ngIf="results.length">
    to select and copy
  </div>
</div>
<div class="box-container my-3" *ngIf="!opened">
  <div (click)="toggleOpening()" class="cursor-pointer row box d-flex">
    <div class="col-9 p-2 response-container ">
      <div class="response">
        <div *ngFor="let result of resultsShortened">
          <div class="word-result my-3">
            {{ result }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-3 .p-2 date-container d-flex justify-content-end">
      <div class="date">
        {{ readableDate }}
        <span class="circle" [class.hidden]="isRead">
          <i class="fas fa-circle text-primary" aria-hidden="true"></i></span>
      </div>
    </div>

  </div>
</div>