import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';

@Component({
  selector: 'app-viz-image-select',
  templateUrl: './viz-image-select.component.html',
  styleUrls: ['./viz-image-select.component.scss', '../viz-style.scss'],
})
export class VizImageSelectComponent implements OnInit {
  @Input() data;
  @Input() layout;
  @Input() id: string;
  @Input() size;
  @Input() title;
  @Input() images;
  @Input() interactions;
  @Input() titleSelect;

  constructor(public _DomSanitizer: DomSanitizer) {}

  onOptionsSelected(event) {
    const value = event.target.value;
  }
  getInteractionTitle(interaction) {
    return _.get(interaction, 'title', '');
  }
  getInteractionChildren(interaction) {
    return _.get(interaction, 'children', []);
  }
  getImage(number) {
    return _.get(this, 'images[' + number + ']', '');
  }

  ngOnInit(): void {}
}
