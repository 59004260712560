import {
  Component,
  Input,
  ViewChild,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import * as _ from 'lodash';
import { DashboardService, ErrorType } from 'src/app/api/dashboard.service';
import { VizType } from 'src/app/shared/helpers/vizType';
import { DomSanitizer } from '@angular/platform-browser';
import { VizSubType } from 'src/app/shared/helpers/vizSubType';
import { VizService, VizMode } from 'src/app/api/viz.service';
declare let Plotly: any;
@Component({
  selector: 'app-viz',
  styleUrls: ['./viz.component.scss'],
  templateUrl: './viz.component.html',
})
export class VizComponent implements OnDestroy, OnChanges, AfterViewInit {
  @Input() viz;
  @Input() vizMode?= VizMode.Regular;
  @ViewChild('vizRef', { static: false }) vizRef;
  VizMode = VizMode;
  contentSubscription;
  interactionSubscription;
  _ = _
  VizType = VizType
  VizSubType = VizSubType;
  octet_stream: any = '';
  config = { responsive: true };
  constructor(
    private dashboardService: DashboardService,
    public _DomSanitizer: DomSanitizer,
    public vizService: VizService) { }
  ngAfterViewInit() {
    if (this.vizMode === VizMode.Regular) {
      setTimeout(() => { this.constructViz() }, 10)
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.vizMode === VizMode.Regular) {
      return;
    }
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (propName === 'viz') {
          setTimeout(() => { this.constructViz() }, 10)
        }
      }
    }
  }
  constructViz() {

    if (this.getVizRef() && _.includes([VizType.Plot, VizType.Treemap, VizType.ColorscaledImage, VizType.Node], this.vizService.getType(this.viz))) {
      const tempLayout = {
        plot_bgcolor: 'rgb(233,233,233)',
        paper_bgcolor: 'rgb(233,233,233)',
        title: '',
        xaxis: {
          showgrid: false,
          showline: false,
          showticklabels: false,
          zeroline: false,
        },
        yaxis: {
          showgrid: false,
          showline: false,
          showticklabels: false,
          zeroline: false,
        }
      }
      Plotly.newPlot(this.getVizRef(), null, tempLayout, this.config)
    }

    if (_.get(this, 'viz.content.value.data')) {
      if (_.includes(this.vizService.getErrorTypes(this.viz), ErrorType.Content)) {
        return
      }
      if (this.getVizRef() && _.includes([VizType.Plot, VizType.Treemap, VizType.ColorscaledImage, VizType.Node], this.vizService.getType(this.viz))) {
        this.drawViz(this.viz);
      }
    } else {
      this.contentSubscription = this.vizService.getVizContent(this.viz).subscribe((res) => {

        if (!res.data || _.includes(this.vizService.getErrorTypes(this.viz), ErrorType.Content)) {
          return
        }
        this.viz = this.vizService.modifyData(this.viz)
        this.viz = this.vizService.modifyLayout(this.viz);

        if (this.getVizRef() && _.includes([VizType.Plot, VizType.Treemap, VizType.ColorscaledImage, VizType.Node], this.vizService.getType(this.viz))) {
          this.drawViz(this.viz);

        }

      })
    }

    if (!_.get(this, 'viz.interactions.value')) {
      this.interactionSubscription = this.vizService.getVizInteractions(this.viz).subscribe((res) => {
        if (!res || _.includes(this.vizService.getErrorTypes(this.viz), ErrorType.Interaction)) {
          return
        }
        if (this.vizService.getInteractivity(this.viz)) {
          let isPlotly = this.vizService.isPlotly(this.viz)
          if (!isPlotly) {
            return
          }
          this.attachEventListeners()
        }
      })
    }
  }
  getVizRef() {
    return _.get(this, 'vizRef.nativeElement', null)
  }
  drawViz = (viz) => {
    let update = { title: { text: this.vizService.getTitle(this.viz), font: { family: 'Montserrat', color: 'white', size: 1 } } }
    Plotly.relayout(this.getVizRef(), update).then(
      setTimeout(() => {
        Plotly.react(this.getVizRef(), this.vizService.getData(viz, 0), this.vizService.getLayout(viz))
      }, 1)
    );

  }
  attachEventListeners = () => {
    if (this.vizService.getJavascript(this.viz)) {
      let stringifiedFunctions = _.join(_.valuesIn(this.vizService.getJavascript(this.viz)), ' ')
      eval(stringifiedFunctions)
    }
    this.getVizRef().on('plotly_click', (event) => {
      let clickedTrace = event.points[0].curveNumber;
      let clickedPoint = event.points[0].pointNumber;
      if (this.vizService.getSubType(this.viz) === VizSubType.Treemap) {
        clickedPoint = clickedPoint - 1
      }
      if (clickedTrace === undefined) {
        clickedTrace = 0
      }
      if (_.includes(this.vizService.getErrorTypes(this.viz), ErrorType.Interaction) || _.isEmpty(this.viz.interactions)) {
        return;
      }
      let childrenIds;
      childrenIds = _.get(this, 'viz.interactions.value[' + clickedTrace + '].points[' + clickedPoint + '].ids', null)

      if (clickedPoint === 0 && !childrenIds) {
        childrenIds = _.get(this, 'viz.interactions.value[' + 0 + '].points[' + clickedTrace + '].ids', null)
      }

      if (!childrenIds) {
        this.dashboardService.showChildrenEvent.emit({ parentId: this.viz.id, ids: [] })
        return;
      }
      this.dashboardService.showChildrenEvent.emit({ parentId: this.viz.id, ids: childrenIds })
    });


  }
  sanitizedUrl(url) {
    let sanitizedUrl = this._DomSanitizer.bypassSecurityTrustUrl(url)
    return sanitizedUrl ? sanitizedUrl : ''
  }
  generateHref(viz) {
    this.octet_stream = 'data:application/octet-stream,' + this.vizService.generateOctetStream(viz);
  }
  setTextLayoutAutoBr(values) {
    let text = '';
    if (values.length) {
      for (let j = 0; j < values.length; j = j + 59) {
        text = text + values.slice(j, j + 59) + '<br>';
      }
    }
    return text;
  }
  ngOnDestroy() {
    if (this.contentSubscription) {
      this.contentSubscription.unsubscribe()
    }
    if (this.interactionSubscription) {
      this.interactionSubscription.unsubscribe()
    }
  }
}
