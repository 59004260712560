import { PermissionsHttpService } from './../../shared/permissions-http-service/permissions-http.service';
import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class WorkflowAuthGuard implements CanActivate {
  constructor(private router: Router, private permissions: PermissionsHttpService) {}

  canActivate(): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    return this.permissions.checkPermission({ permissions: { createWorkflow: {} } }).pipe(
      map((res) => {
        if (res.createWorkflow) {
          return true;
        } else {
          return this.router.createUrlTree(['/']);
        }
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
