import { Component, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { ProjectsService } from 'src/app/api/projects.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
@Component({
  selector: 'app-available-workflow',
  templateUrl: './available-workflow.component.html',
  styleUrls: ['./available-workflow.component.scss'],
})
export class AvailableWorkflowComponent implements OnInit {
  @Input() workflowId;
  @Input() expended? = false;
  @Input() btnTitle? = 'select';
  @Input() selected? = false;
  @Input() filegroupTypes?;
  @Input() isMimsUser? = false
  @Input() filegroupId?;
  file = new BehaviorSubject<any>(null);
  @Output() workflowSelected = new BehaviorSubject<any>(null);
  @Output() workflowDownloaded = new BehaviorSubject<any>(false);
  @Input() subjects? = new BehaviorSubject<any>(null);

  @Input() canSelect? = false;
  workflow;
  dataTypes;
  constructor(private projectsService: ProjectsService, public layout: LayoutOrganizationService) {}
  ngOnInit(): void {
    this.projectsService.getWorkflow(this.workflowId).subscribe(
      (res) => {
        _.set(this, 'workflow', res);
        if (_.includes(this.workflow.name, 'PopSeg')) {
          let fileURL = '../../../../../assets/docs/PopSeg_Solution_21122021/solution.md';
          fetch(fileURL)
            .then((response) => {
              return response.text();
            })
            .then((data) => {
              this.file.next(data);
            });
        }
        this.workflowDownloaded.next(true);
        if (!_.isEmpty(_.get(res, 'inputs'))) {
          for (const [key, value] of Object.entries(_.get(res, 'inputs', {}))) {
            if (
              (key.includes('data_type') || key.includes('dataType')) &&
              _.get(value, 'tags.invisible') === false &&
              _.get(value, 'tags.is_array') &&
              _.get(value, 'tags.multiple_selection')
            ) {
              let allowedValuesDescriptions = _.get(value, 'tags.allowed_values_descriptions');
              let allowedValues = _.get(value, 'tags.allowed_values', []);
              _.set(this, 'dataTypes', allowedValuesDescriptions || allowedValues);
            }
          }
        }
      },
      (error) => {
        this.layout.toast("Solution can't be retrieved.", null, 8000, '', 'danger');
      }
    );
  }
  hasAtLeastOneType = () => {
    let hasAtLeastOneType = false;
    if (this.filegroupTypes) {
      this.filegroupTypes.forEach((element) => {
        if ((element.count.value || 0) > 0) hasAtLeastOneType = true;
      });
    }
    return hasAtLeastOneType;
  };
  switchExpension() {
    this.expended = !this.expended;
  }
  getWorkflowName() {
    return _.get(this, 'workflow.name', null);
  }
  getWorkflowDataTypes() {
    let dataTypes = _.get(this, 'dataTypes', []);
    _.remove(dataTypes, function (el) {
      return el === 'clinical' || el === 'Clinical';
    });
    return dataTypes;
  }
  getWorkflowDescription() {
    return _.get(this, 'workflow.description', null);
  }
  getWorkflowVersion() {
    return _.get(this, 'workflow.version', '');
  }
  getWorkflowId() {
    return _.get(this, 'workflow._id', null);
  }
  getWorkflowGroupId() {
    return _.get(this, 'workflow.workflow_group_id', null);
  }
  getSubjects() {
    return _.get(this, 'subjects.value');
  }
  next() {
    this.workflowSelected.next(this.workflowId);
  }
}
