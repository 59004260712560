import { Component, Input, OnInit } from '@angular/core';
import { Expandable } from 'src/app/shared/expandable/expandable';
import { CategoryModel, KnowledgeSearchService } from '../../../knowledge-search.service';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
})
export class CategoryListComponent extends Expandable implements OnInit {
  @Input() category: CategoryModel;

  constructor(public knowledgeSearchService: KnowledgeSearchService) {
    super();
  }

  ngOnInit(): void {}
}
