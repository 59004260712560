import { Component, OnInit } from '@angular/core';
import { LocalService } from 'src/app/api/local-storage.service';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
import _ from 'lodash';
import { Action, DataManagerService, dataItemAction } from './data-manager.service';
import { ItemType } from 'src/app/shared/helpers/itemType';
import { SortType } from 'src/app/shared/helpers/sortType';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { SortDirection } from 'src/app/shared/helpers/sortDirection';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
@Component({
  selector: 'app-data-manager',
  templateUrl: './data-manager.component.html',
  styleUrls: ['./data-manager.component.scss'],
})
export class DataManagerComponent implements OnInit {
  PagesPublicName = PagesPublicName;
  userName;
  Action = Action;
  ItemType = ItemType;
  _ = _;
  ItemAction = dataItemAction;
  TabsName = TabsName;
  SortType = SortType;
  SortDirection = SortDirection;
  constructor(private localService: LocalService, public dataManager: DataManagerService, public l: LayoutOrganizationService) {
    if (this.localService) {
      this.userName = this.localService.getFromLocalStorage('user', 'name');
    }
  }
  ngOnInit() {
    if (_.get(this, 'dataManager.actionEvent')) {
    this.trigger({ action: Action.InitManager });
    }
  }
  handleSortBy(newSortingType, actualSortDirection, itemType) {
    this.trigger({ action: Action.ChangeSortingType, destination: newSortingType, itemType });
    this.trigger({ action: Action.ChangeSortingDirection, destination: this.toggleDirection(actualSortDirection), itemType });
    this.trigger({ action: Action.Refresh, itemType });
  }
  toggleDirection(value: SortDirection) {
    if (value === SortDirection.Asc) {
      return SortDirection.Desc;
    } else {
      return SortDirection.Asc;
    }
  }
  trigger(event) {
     this.dataManager.actionEvent.emit(event);
  }
  triggerItem(event) {
      this.dataManager.itemActionEvent.emit(event);
  }
  get item() {
    return this.dataManager._getAsync('activeItem')
  }
  get itemType() {
    return _.get(this.item, 'kind');
  }
}
