import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Status } from 'src/app/shared/helpers/status';
import { ItemType } from  'src/app/shared/helpers/itemType'

@Component({
  selector: 'app-checkbox-2',
  templateUrl: './checkbox-2.component.html',
  styleUrls: ['./checkbox-2.component.scss'],
})
export class Checkbox2Component {
  @Input() type : ItemType;
  @Input() status?: Status;
  @Input() selected;
  @Output() onSelectAction: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onUnselectAction: EventEmitter<boolean> = new EventEmitter<boolean>();
  ItemType = ItemType;
  Status = Status;


  handleClick = () => {
    if (this.selected) {
      this.onUnselectAction.emit(true);
    } else {
      this.onSelectAction.emit(true);
    }
  };
}
