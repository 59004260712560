<div class="form-group">
  <label >
    {{_.get(field,'templateOptions.label')}}
    <span *ngIf="_.get(field,'templateOptions.required')">*</span>
  </label>
  <input oninput="this.value = this.value.toUpperCase()"  [placeholder]="_.get(field,'templateOptions.placeholder')"  [class.text-danger]='showError' [class.field-error]='showError' [formControl]="formControl " [formlyAttributes]="field" class="form-control" type="text" required="required">
  <div *ngIf="showError" class="invalid-feedback d-block">
    <formly-validation-message [field]="field"></formly-validation-message>
  </div>
</div>
