<div class="top-level-category">
  <div class="d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center">
        <div class="title">{{ category.name }}</div>
        <sup class="count ml-1">{{ category.count }}</sup>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <app-expander-button [ngStyle]="{ 'visibility': category?.children?.length ? 'visible' : 'hidden' }"
        (clicked)="handleExpand()"></app-expander-button>
    </div>
  </div>
</div>
