import { Component} from '@angular/core';
import { RunsDashboardComponent } from 'src/app/pages/runs-dashboard/runs-dashboard.component';

@Component({
  selector: 'app-progress-form',
  templateUrl: './progress-modal.component.html',
  styleUrls: ['../../../shared/styles/forms.component.scss'],
})
export class ProgressFormComponent {

  constructor(
    private runDashService: RunsDashboardComponent,
  ) {}

  graphwf;
  original;

  ngAfterViewChecked() {

    let c = this.runDashService.getCurrent()
    if (c == undefined) {
      return;
    }
    if (c == this.original) {
      return;
    }
    this.original = c  // Avoid useless multi call

    let idx = c.indexOf("<svg") // detect start of svg content
    this.graphwf = c.substring(idx); // remove befor real svg
    idx = this.graphwf.indexOf("viewBox") // replace default width and height to maximize the view
    this.graphwf = '' + '<svg width="'+ this.runDashService.getWidth() + '" height="'+ this.runDashService.getHeight() + '" '  +  this.graphwf.substring(idx)

    document.getElementById('svg').innerHTML = this.graphwf
  }
}
