import { Injectable, EventEmitter, ComponentRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import { VizType } from 'src/app/shared/helpers/vizType';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModelsProject } from '../model/models';

export interface RawVizDescriptionModel {
  file_id: string;
  graph_id: string;
  wdv_type: VizType;
}
export interface RawDashboardModel {
  graphs: Array<RawVizDescriptionModel>;
  shown_graphs: Array<string>;
  interactions: Array<string>;
}
export interface RawVizModel {
  data: Array<any>;
  layout: any;
  javascript?: any;
}
export interface RawVizInteractionModel {
  interactions: Array<Array<Array<string>>>;
}

export interface VizContentModel {
  data: any;
  layout: any;
  javascript?: any;
}

export enum ErrorType {
  Interaction = 'interaction',
  Content = 'content',
}
export interface ErrorModel {
  type: Array<ErrorType>;
}
export interface VizModel {
  id: string;
  type: VizType;
  parentId: string;
  content: BehaviorSubject<VizContentModel>;
  isInteractive: boolean;
  interactions: BehaviorSubject<Array<TraceModel>>;
  error: ErrorModel;
  appRef: ComponentRef<any>;
}
export interface DashboardModel {
  visualizations: Array<VizModel>;
  isInteractive: boolean;
}

export interface TraceModel {
  points: Array<PointModel>;
}
export interface PointModel {
  ids: Array<string>;
  text?: string;
}

export class Dashboard {
  visualizations;
  isInteractive = false;
  constructor(rawData: RawDashboardModel) {
    let vizArray = [];
    let shown_graphs = _.cloneDeep(rawData.shown_graphs);
    let interactive_graphs = _.cloneDeep(rawData.interactions);
    if (interactive_graphs.length) {
      this.isInteractive = true;
    }
    rawData.graphs.forEach((element) => {
      let index = _.indexOf(shown_graphs, element.graph_id);
      if (index > -1) {
        let isInteractive = _.indexOf(interactive_graphs, element.graph_id) > -1;
        let viz = new Viz(element.graph_id, isInteractive, element.wdv_type);
        vizArray.push(viz);
        shown_graphs.splice(index, 1);
      }
    });
    this.visualizations = vizArray;
  }
}

export class Interactions {
  constructor(rawInteraction, type) {
    let interactions = [];
    if (!rawInteraction || _.isEmpty(rawInteraction)) {
      rawInteraction = [];
    }
    rawInteraction.forEach((trace) => {
      let traceObject = new Trace();
      trace.forEach((point, index) => {
        let pointObject;
        if (_.includes([VizType.Select, VizType.Image, VizType.ColorscaledImage], type)) {
          pointObject = new Point(_.tail(point), _.nth(point, 0));
        } else {
          pointObject = new Point(point);
        }
        if (!_.isEmpty(pointObject.ids)) {
          traceObject.points[index] = pointObject;
        }
      });
      interactions.push(traceObject);
    });

    return interactions as Array<TraceModel>;
  }
}
export class Trace implements TraceModel {
  points;
  constructor() {
    this.points = [];
  }
}

export class Point implements PointModel {
  text?;
  ids;
  constructor(ids, text?) {
    this.ids = _.compact(ids);
    if (text) {
      this.text = text;
    }
  }
}

export class Viz implements VizModel {
  id: string;
  type: VizType;
  content = new BehaviorSubject<VizContentModel>({ data: null, layout: null });
  interactions = new BehaviorSubject<Array<TraceModel>>(null);
  error;
  parentId;
  appRef;
  isInteractive;
  constructor(id, isInteractive, type, parentId?) {
    this.id = id;
    this.type = type;
    this.parentId = parentId ? parentId : null;
    this.error = { type: [] };
    this.isInteractive = isInteractive;
  }
}

export interface ShowChildrenEvent {
  parentId: any;
  ids: Array<string>;
}
type local = boolean | 1 | 0;

@Injectable()
export class DashboardService {
  graphThumbnailUrl;
  qualityDashboard = false;
  public currentProject: ModelsProject;
  public allVizhasBeenSeen = true;
  public isArrowVisible = false;
  //public vizContainer: ElementRef;
  dashboardContainer;
  thumbnailSize = { width: 315, height: 315 };
  dashboardTileContainer;
  rawDashboard: RawDashboardModel;
  dashboard: DashboardModel;
  dashboardIsReady = new EventEmitter<any>(false);
  dashboardHasError = new EventEmitter<boolean>(false);
  viewTab = null;
  tabEvent = new BehaviorSubject<any>(null);
  projectIsReady = new EventEmitter<boolean>(false);
  projectId: string;
  fileId: string;
  dashboardId: string;
  versionId: string;
  showChildrenEvent = new EventEmitter<ShowChildrenEvent>();

  protected basePath = 'XXXX';
  scatterGLPool = 8;

  constructor(private readonly http: HttpClient, public layout: LayoutOrganizationService) {}

  getRawDashboard(local: local, country): Observable<RawDashboardModel> {
    let queryParameters = new HttpParams({});
    if (!!local) {
      queryParameters = queryParameters.set('local', local as any);
    }
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    if (this.getContextVersionId() === '') {
      return new Observable<RawDashboardModel>();
    }

    if (this.qualityDashboard) {
      return this.http.get<RawDashboardModel>(`${this.basePath}/dashboard-genomic/${this.getContextId()}/${this.getContextVersionId()}`, {
        params: queryParameters,
        headers,
      });
    }
    return this.http.get<RawDashboardModel>(`${this.basePath}/dashboard/${this.getContextId()}/${this.getContextVersionId()}`, {
      params: queryParameters,
      headers
    });
  }
  getRawVizContent(graphId: string, local: local, country): Observable<RawVizModel> {
    let queryParameters = new HttpParams({});
    if (!!local) {
      queryParameters = queryParameters.set('local', local as any);
    }
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    const graphIdEncoded = encodeURIComponent(graphId);
    if (this.qualityDashboard) {
      return this.http.get<RawVizModel>(
        `${this.basePath}/dashboard-genomic/${this.getContextId()}/${this.getContextVersionId()}/${graphIdEncoded}`,
        {
          params: queryParameters,
          headers,
        }
      );
    }
    return this.http.get<RawVizModel>(`${this.basePath}/dashboard/${this.getContextId()}/${this.getContextVersionId()}/${graphIdEncoded}`, {
      params: queryParameters,
      headers,
    });
  }
  getRawVizInteractions(graphId: string, local: boolean | 1 | 0, country): Observable<RawVizInteractionModel> {
    let queryParameters = new HttpParams({});
    if (!!local) {
      queryParameters = queryParameters.set('local', local as any);
    }
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    const graphIdEncoded = encodeURIComponent(graphId);
    if (this.qualityDashboard) {
      return this.http.get<RawVizInteractionModel>(
        `${this.basePath}/dashboard-interactions-genomic/${this.getContextId()}/${this.getContextVersionId()}/${graphIdEncoded}`,
        {
          params: queryParameters,
          headers,
        }
      );
    }
    return this.http.get<RawVizInteractionModel>(
      `${this.basePath}/dashboard-interactions/${this.getContextId()}/${this.getContextVersionId()}/${graphIdEncoded}`,
      {
        params: queryParameters,
        headers,
      }
    );
  }
  getContextId = () => {
    if (this.qualityDashboard) {
      return _.get(this, 'fileId', null);
    } else {
      return _.get(this, 'projectId', null);
    }
  };
  setContextId = (value) => {
    if (this.qualityDashboard) {
      return _.set(this, 'fileId', value);
    } else {
      return _.set(this, 'projectId', value);
    }
  };
  getContextVersionId = () => {
    if (this.qualityDashboard) {
      return _.get(this, 'versionId', 1);
    } else {
      return _.get(this, 'dashboardId', '');
    }
  };
  setContextVersionId = (value) => {
    if (this.qualityDashboard) {
      return _.set(this, 'versionId', value);
    } else {
      return _.set(this, 'dashboardId', value);
    }
  };
  downloadVizContent = (id, country?) => {
    let viz = this.getVizFromDashboard(id);
    if (!viz) {
      return;
    }
    this.getRawVizContent(id, undefined, country).subscribe(
      (res) => {
        viz.content.next(res);
      },
      () => {
        viz.error.type.push(ErrorType.Content);
      }
    );
  };
  downloadVizInteractions = (id, country?) => {
    let viz = this.getVizFromDashboard(id);
    if (!viz) {
      return;
    }
    this.getRawVizInteractions(id, undefined, country).subscribe(
      (res) => {
        viz.interactions.next(new Interactions(_.get(res, 'interactions', null), viz.type));
      },
      () => {
        viz.error.type.push(ErrorType.Interaction);
      }
    );
  };
  getDashboard() {
    return _.get(this, 'dashboard', {});
  }
  createDashboard(country?) {
    if (!_.isEmpty(this.getDashboard())) {
      this.getVisualizations().forEach((viz) => {
        this.layout.destroyComponent(viz.appRef);
      });
      delete this.dashboard;
    }
    if (!this.getContextId()) {
      return;
    }
    if (this.viewTab) {
      this.setContextVersionId(this.viewTab);
    }
    this.getRawDashboard(undefined, country).subscribe(
      (res) => {
        this.rawDashboard = res;
        this.dashboard = new Dashboard(this.rawDashboard);
        this.dashboardIsReady.emit(country);
      },
      (error) => {
        this.dashboardHasError.emit(true);
        //alert('Code : ' + error.error.code + '. Message : ' + error.error.message + '. Reason  : ' + error.error.reason + '.')
      }
    );
  }
  getVizFromDashboard(id) {
    return _.find(this.getVisualizations(), { id }, null);
  }
  getVizFromParentIdFromDashboard(parentId) {
    return _.filter(this.getVisualizations(), { parentId }, []);
  }
  getVisualizations() {
    return _.get(this, 'dashboard.visualizations', []);
  }
  getVisualizationsLength() {
    return _.get(this, 'dashboard.visualizations.length', 0);
  }
  getVizFromRawDashboard(id) {
    return _.find(this.rawDashboard.graphs, { graph_id: id }, null);
  }
  getTypeFromRawDashboard(id) {
    return _.get(this.getVizFromRawDashboard(id), 'wdv_type', null);
  }
  getVizIndex(viz) {
    return _.findIndex(this.getVisualizations(), { id: viz.id });
  }
  getNextViz(viz) {
    let index = this.getVizIndex(viz);
    if (index === -1) {
      return;
    }
    if (index === this.getVisualizationsLength() - 1) {
      index = -1;
    }
    return this.getVizFromIndex(index + 1);
  }
  getPreviousViz(viz) {
    let index = this.getVizIndex(viz);
    if (index === -1) {
      return;
    }
    if (index === 0) {
      index = this.getVisualizationsLength();
    }
    return this.getVizFromIndex(index - 1);
  }
  getVizFromIndex(i) {
    return _.nth(this.getVisualizations(), i);
  }

  addNewViz(parentId, id) {
    let type = this.getTypeFromRawDashboard(id);
    if (!type) {
      return;
    }
    let isInteractive = this.getInteractivityFromDashboard();
    let viz = new Viz(id, isInteractive, type, parentId);
    this.getVisualizations().push(viz);

    return viz;
  }

  getInteractivityFromDashboard() {
    return _.get(this, 'dashboard.isInteractive', false);
  }

  removeChildrenViz(parentId) {
    let vizArray = this.getVizFromParentIdFromDashboard(parentId);
    if (!vizArray) {
      return;
    }
    vizArray.forEach((viz) => {
      this.layout.destroyComponent(viz.appRef);
      _.remove(this.getVisualizations(), viz);
      if (this.getVizFromParentIdFromDashboard(viz.id)) {
        this.removeChildrenViz(viz.id);
      }
    });
  }
  checkContent() {
    const self = this;
    self.isArrowVisible = false;
    let content = _.get(this, 'dashboardContainer.nativeElement.children', []);
    self.allVizhasBeenSeen = true;
    for (let el of content) {
      self.checkContentIsSeen(el.children[0]);
    }
    self.isArrowVisible = !self.allVizhasBeenSeen;
  }
  checkContentIsSeen(el) {
    let self = this;
    if (!el || (el && !el.hasAttribute('isSeen'))) {
      setTimeout(function () {
        return self.checkContentIsSeen(el);
      }, 100);
    }
    if (el && el.hasAttribute('isSeen') && el.getAttribute('isSeen') === 'false') {
      let isReached = self.isScrolledIntoView(el);
      if (isReached) {
        el.setAttribute('isSeen', 'true');
      } else {
        self.allVizhasBeenSeen = false;
      }
    }
  }

  onScroll() {
    if (this.dashboardContainer && this.dashboardIsReady) {
      this.checkContent();
    }
  }
  isScrolledIntoView(el) {
    const rect = el.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;
    return elemTop >= 0 && elemBottom <= window.innerHeight;
  }
  getInstanceStatus(index) {
    return _.get(this, 'currentProject.instances[' + index + '].status', null);
  }
}
