import { Component } from '@angular/core';
import { availableFeatures, environment } from '../../../environments/environment';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { PermissionsHttpService } from 'src/app/shared/permissions-http-service/permissions-http.service';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { Features } from 'src/environments/features';
import * as _ from 'lodash';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
@Component({
  selector: 'app-homepage',
  styleUrls: ['./homepage.component.scss'],
  templateUrl: './homepage.component.html',
})
export class HomepageComponent {
  accessProjectManager = false;
  accessFileManager = false;
  accessDataManager = false;
  accessLittResearch = false;
  PagesPublicName = PagesPublicName;
  accessNotifications = false;
  accessGuidedUserFlow = false;
  PagesName = PagesName;
  environment = environment;
  folder = true;
  file = true;
  search = true;
  _ = _;
  Features = Features;
  availableFeatures = availableFeatures;

  constructor(public l: LayoutOrganizationService, private permissions: PermissionsHttpService) {
    this.permissions
      .checkPermission({
        permissions: {
          accessLittResearch: {},
          accessFileManager: {},
          accessProjectManager: {},
          accessNotifications: {},
          accessGuidedUserFlow: {},
          manageFocusGeneLists: {},
        },
      })
      .subscribe((res) => {
        this.accessProjectManager = res.accessProjectManager;
        this.accessFileManager = res.accessFileManager;
        this.accessDataManager = res.manageFocusGeneLists;
        this.accessLittResearch = res.accessLittResearch;
        this.accessNotifications = res.accessNotifications;
        this.accessGuidedUserFlow = res.accessGuidedUserFlow;
      });
  }

  changeFolderSrc() {
    this.folder = !this.folder;
  }
  changeSearchSrc() {
    this.search = !this.search;
  }
  changeFileSrc() {
    this.file = !this.file;
  }
}
