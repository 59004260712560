
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { FileSystemState } from '../pages/login/login-http-service/login-http.service';


@Injectable({ providedIn: 'root' })
export class LocalService {
  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  getFromLocalStorage(name, field?, defaultValue?) {
    let storage = localStorage.getItem("ngx-webstorage|" + name)
    if (storage && this.isJsonString(storage)) {
      let object = _.cloneDeep(JSON.parse(storage))
      if (field) {
        return _.get(object, field, defaultValue ? defaultValue : null)
      }
      return object
    }
    return defaultValue ? defaultValue : null
  }
  editLocalStorage(name, key, value) {
    let storage = this.getFromLocalStorage(name)
    _.set(storage, key, value)
    this.setLocalStorage(name, storage)
  }
  setLocalStorage = (name, value) => {
    return localStorage.setItem("ngx-webstorage|" + name, JSON.stringify(value));
  }
  getSpecificCountryUrl = (country) => {
    const file_system = this.getSpecificFileSystem(country);
    const url = _.get(file_system, 'url');
    if(_.isEmpty(url) && (!file_system || file_system.accessState === FileSystemState.Local)) {
      return this.getFromLocalStorage("session", "apiPath")
    }
    return url; 
  }
  getSpecificFileSystem(country) {
    const file_systems = this.getFromLocalStorage('user', 'file_systems')
    return _.find(file_systems, function (o) { return o.value === country; });
  }
  getSpecificCountryAccessToken = (country) => {
    const file_system = this.getSpecificFileSystem(country)
    const accessToken =_.get(file_system, 'access_token')
    if(_.isEmpty(accessToken) && (!file_system || file_system.accessState === FileSystemState.Local)) {
      return this.getFromLocalStorage("session", "accessToken")
    }
    return accessToken
  }
  getLocalFileSystem = () => {
    const file_systems = this.getFromLocalStorage('user', 'file_systems', [])
    file_systems.forEach(element => {
      if (element.accessState === FileSystemState.Local) {
       return element
      }
    });
  }
  getAvailableCountries = () => {
    const file_systems = this.getFromLocalStorage('user', 'file_systems', [])
    let countryArray = []
    file_systems.forEach(element => {
      if (element.accessState !== FileSystemState.Disabled) {
        countryArray.push(element.value)
      }
    });
    return countryArray
  }


}
