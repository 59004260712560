export const PagesPublicName = {
  FileManager: 'Manage Files',
  DataManager: 'Manage Data', 
  LicenseManager: 'Manage Licenses', 
  ProjectManager: 'Manage Projects',
  TeamsManager: 'Manage Teams',
  WorkflowDebugger: 'Workflow Debugger',
  WorkflowEditor: 'Solution Editor',
  MethodEditor: 'Method Editor',
  Workflow: 'Solution Editor',
  ProjectCreation: 'Project Creation',
  UserCreation: 'Create New Users',
  NotificationsUnread: 'Recent Updates',
  NotificationsAllRead: 'All Caught Up !',
  Notifications: 'Notifications',
  Home: 'My Home',
  Account: 'Profile',
  KnowledgeSearch : 'COVsight',
  KnowledgeSearchLong : 'COVsight Search',
  KnowledgeSearchSimpleKeyword : 'Simple Search',
  KnowledgeSearchPicoTherapy : 'Therapy (PICO)',
  KnowledgeSearchPicoPrevention : 'Prevention (PICO)',
  KnowledgeSearchPicoDiagnosis : 'Diagnosis or Diagnostic Test (PICO)',
  KnowledgeSearchPicoPrognosis : 'Prognosis (PICO)',
  KnowledgeSearchPicoEtiology : 'Etiology (PICO)',
  KnowledgeSearchSingleArticle : 'Single Article Knowledge Extraction',
  RunsDashboard: 'Project runs'
};
