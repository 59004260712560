import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ExplorerManagerService } from '../../api/explorer-manager.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-explorer',
  templateUrl: './explorer.component.html',
  styleUrls: ['./explorer.component.scss'],
})
export class ExplorerComponent implements OnInit, OnDestroy {
  public answerIndex = 1;
  public previousCode: any;
  public debouncedAskCompletion: any = _.debounce(() => {
    this.e.askCompletion();
  }, 500);

  constructor(
    public e: ExplorerManagerService,
    public l: LayoutOrganizationService
  ) { }
  ngOnInit() {
    this.e.chatView = this.e.ChatView.NoQuestion;
    this.e.connectSocket();
  }
  ngOnDestroy() {
    this.e.socket.close();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDownKeyboardEvent(event: KeyboardEvent) {
    const arrowUp = 38;
    const arrowDown = 40;
    const backSpace = 8;
    const deleteKey = 46;
    const enter = 13;
    const spaceBar = 32;
    const code = event.keyCode || event.which;
    const codeIsArrow = code === 38 || code === 40;
    const pageDown = 34;
    const pageUp = 33;
    const escape = 27;

    const answer = this.e.hasAnswers(0);
    const home = 36;
    if (!this.l.explorerIsOpen) {
      if (code === home) {
        this.l.switchExplorerOpenStatus();
      }
    } else {
      //this.e.setIndexSuggestion(code);
      this.e.questionInput.nativeElement.focus();
      switch (code) {
        case pageUp:
          this.l.explorerIsExpanded = true;
          break;
        case pageDown:
          this.l.explorerIsExpanded = false;
          break;
        case escape:
          this.l.switchExplorerOpenStatus();
          break;
      }
      if (this.e.chatView === this.e.ChatView.AnswerInFullDescription) {
        if (code === backSpace) {
          this.e.goToView(this.e.ChatView.ShortListOfAnswers);
        }
      } else if (this.e.chatView === this.e.ChatView.ShortListOfAnswers) {
        if (code === backSpace) {
          if (answer) {
            if (this.e.openedAnswer === null) {
              this.e.goToView(this.e.ChatView.NoQuestion);
              this.e.initNewQuestion();
              this.e.setQuestionInput('');
            } else {
              this.e.openedAnswer = null;
            }
          } else if (this.e.getErrorState() || this.e.getPendingState()) {
            //
          } else {
            this.e.goToView(this.e.ChatView.NoQuestion);
            this.e.initNewQuestion();
            this.e.setQuestionInput('');
          }
        }
        if (answer) {
          if (codeIsArrow) {
            this.e.indexAnswer = this.e.arrowNavigate(
              this.e.getAnswersLength(0),
              this.e.indexAnswer,
              code
            );
          }
          if (code === enter) {
            if (this.e.indexAnswer === this.e.openedAnswer) {
              this.e.setActiveAnswer(this.e.getAnswers(0)[this.e.indexAnswer]);
              this.e.goToView(this.e.ChatView.AnswerInFullDescription);
            } else if (
              this.e.indexAnswer === this.e.selectedAnswer ||
              this.e.indexAnswer !== this.e.openedAnswer
            ) {
              this.e.openedAnswer = this.e.indexAnswer;
            }
          }
        }
      } else if (this.e.chatView === this.e.ChatView.NoQuestion) {
        if (code === backSpace || code === deleteKey) {
          if (this.e.getInput().length >= 3) {
            this.e.deleteSuggestions();
            this.debouncedAskCompletion();
          } else if (this.e.hasEmptyInput()) {
            this.e.resetInteraction();
          } else {
            this.e.deleteSuggestions();
          }
        } else if (code === spaceBar) {
          if (this.previousCode === spaceBar) {
            this.e.deleteSuggestions();
          }
        } else if (code === arrowDown || code === arrowUp) {
          if (this.e.hasPreviousQuestions() && !this.e.hasSuggestions()) {
            this.e.indexQuestion = this.e.arrowNavigate(
              this.e.getPreviousQuestions().length,
              this.e.indexQuestion,
              code
            );
          }
          if (this.e.hasSuggestions()) {
            this.e.indexSuggestion = this.e.arrowNavigate(
              this.e.getSuggestions().length,
              this.e.indexSuggestion,
              code
            );
          }
        } else if (code === enter) {
          if (this.e.indexQuestion !== null) {
            this.e.copyPreviousQuestionToTextArea(this.e.indexQuestion);
          } else if (this.e.indexSuggestion !== null) {
            this.e.copySuggestionToTextArea(this.e.indexSuggestion);
            this.e.deleteSuggestions();
          } else {
            if (!this.e.hasEmptyInput()) {
              this.e.sendQuestion();
            } else {
              event.preventDefault();
            }
          }
        } else {
          if (this.e.getInput().length >= 3) {
            this.e.setQuestionIndex();
            this.e.deleteSuggestions();
            this.debouncedAskCompletion();
          }
        }

        this.previousCode = code;
      }
    }
  }
}
