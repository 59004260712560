<div id="{{ get('id') }}" 
  (dblclick)='canPerform(ItemAction.OpenFolder) ? itemAction.emit([item, ItemAction.OpenFolder] ) : ""'
  class="list">
  <div class="list-container row" [class.tile-selected]="selected" [class.highlighted]='highlighted'
    [class.tile-danger]=" get('status') === Status.Unusable" [class.tile-warning]=" get('status') === Status.Processing"
    [class.tile-selected-danger]="selected && get('status') === Status.Unusable"
    [class.tile-selected-warning]="selected && get('status') === Status.Processing">
    <div class="item-text-container row mx-0 px-0 col-6 flex-nowrap " >
      <app-checkbox-2 [type]="getType()" (onSelectAction)="itemAction.emit([item, ItemAction.ToggleSelect] )"
        (onUnselectAction)="itemAction.emit([item, ItemAction.ToggleSelect] )" [status]="get('status')"
        [selected]="selected">
      </app-checkbox-2>
      <div class="item-text noselect px-0" [class.text-warning]="get('status') === Status.Processing"
        [class.text-danger]="get('status') === Status.Unusable" [class.text-primary]="isRecent">{{ get('title') }}
        <small class="text-uppercase" *ngIf="get('status') === Status.Processing"> – processing </small>
        <small class="text-uppercase" *ngIf="get('status') === Status.Unusable">
          – unusable </small>
        <small class="text-uppercase" *ngIf="isRecent && getType() === ItemType.File ">
          – Recently uploaded </small>
        <small class="text-uppercase" *ngIf="isRecent && getType() === ItemType.Folder ">
          – Recently created </small>
      </div>
    </div>
    <div class="item-icons-container flex-nowrap mx-0 px-0 row col-6">
      <div class="item-icon-text mx-0 px-0 col">
        {{ iconText }}
      </div>

      <div *ngIf="get('trashed')" class="d-flex item-icons col-md-auto mx-0 px-0">
        <span class='mx-2 '><small>
            <span class='text-uppercase text-muted '>deleted : </span>

            <span class='text-muted'>{{moment.unix(get('date_of_trashing')).format('YY/MM/DD - hh:mm A')}}
            </span></small></span>
      </div>

      <div *ngIf="!get('trashed')" class="item-icons mx-0 px-0">
        <div [hidden]='getType() === ItemType.File'  class="btn btn-link hover-active no-focus cursor-auto">
          <div class="icon-blank"></div>
        </div>
        <div [hidden]='getType() !== ItemType.File'  [class.disabled]="!canPerform(ItemAction.Download)" ngbTooltip="{{getTooltip(ItemAction.Download)}}"
          tooltipClass="custom-tooltip" placement="left"
          (click)="canPerform(ItemAction.Download) ? itemAction.emit([item, ItemAction.Download]) : '' "
          class="btn btn-link hover-active" (mouseenter)="setIconText(getIconText(ItemAction.Download))"
          (mouseleave)="setIconText(undefined)">
          <i class="far fa-arrow-alt-to-bottom" [class.disabled]="!canPerform(ItemAction.Download)" aria-hidden="true"></i>
        </div>
        <div [hidden]='getType() === ItemType.File'  class="btn btn-link hover-active no-focus cursor-auto">
          <div class="icon-blank"></div>
        </div>
        <div [hidden]='getType() !== ItemType.File' [class.disabled]="!canPerform(ItemAction.Quality)"
          (click)='canPerform(ItemAction.Quality) ? itemAction.emit([item, ItemAction.Quality]) : "" '
          (mouseenter)="setIconText(getIconText(ItemAction.Quality))" (mouseleave)="setIconText(undefined)"
          class="btn btn-link hover-active no-focus">
          <i aria-hidden='true' class="far fa-check-square"></i>
        </div>
        <div [class.disabled]="!canPerform(ItemAction.SelectShare)"
          (click)="itemAction.emit([item, ItemAction.SelectShare])" class="btn btn-link hover-active"
          (mouseenter)="setIconText(getIconText(ItemAction.SelectShare))" (mouseleave)="setIconText(undefined)">
          <i class="far fa-share-square" aria-hidden="true"></i>
        </div>
        <div [class.disabled]="!canPerform(ItemAction.Info)" (click)="itemAction.emit([item, ItemAction.Info])"
          (mouseenter)="setIconText(getIconText(ItemAction.Info))" (mouseleave)="setIconText(undefined)"
          class="btn btn-link hover-active no-focus">
          <i class="far fa-info-circle" aria-hidden="true"></i>
        </div>
        <div (click)="canPerform(ItemAction.Trash) ? itemAction.emit([item, ItemAction.Trash]) : ''"
          [class.disabled]='!canPerform(ItemAction.Trash)' (mouseleave)="setIconText(undefined)"
          class="btn btn-link hover-active no-focus">
          <i class="far fa-trash-alt" ngbTooltip="{{getTooltip(ItemAction.Trash)}}" tooltipClass="custom-tooltip"
            placement="left" [class.disabled]='!canPerform(ItemAction.Trash)'
            (mouseenter)="setIconText(getIconText(ItemAction.Trash))" aria-hidden="true"></i>
        </div>
       
        <div *ngIf="!_.isEmpty(get('metadata'))" class="btn btn-link hover-active no-focus cursor-auto">
          <div class="icon-blank"></div>
        </div>
        <div class="btn btn-link hover-active no-focus" *ngIf="_.isEmpty(get('metadata')) && getType() === ItemType.File"
          (click)="itemAction.emit([item, ItemAction.Info])"
          (mouseenter)="setIconText('This item has no metadata')" (mouseleave)="setIconText(undefined)"
          [class.text-danger]="_.isEmpty(get('metadata'))">
          <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </div>
</div>
