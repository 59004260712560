import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import {
  template,
  cyan,
  red,
  yellow,
  lime,
  constructLayoutUsingTemplate,
  addCustomColorscale,
} from '../../plotly-templating';
import * as _ from 'lodash';
import { VizSubType } from 'src/app/shared/helpers/vizSubType';
import { DomSanitizer } from '@angular/platform-browser';

declare let Plotly: any;

@Component({
  selector: 'app-viz-image-select-colorscale-thumbnail',
  templateUrl: './viz-image-select-colorscale-thumbnail.component.html',
  styleUrls: [
    './viz-image-select-colorscale-thumbnail.component.scss',
    '../viz-style.scss',
  ],
})
export class VizImageSelectColorscaleThumbnailComponent implements OnInit {
  @Input() data;
  @Input() layout;
  @Input() size;
  @Input() title;
  @Input() images;
  @Input() interactions;
  @Input() titleSelect;
  constructor(public _DomSanitizer: DomSanitizer) {}
  getImage(number) {
    return _.get(this, 'images[' + number + ']', '');
  }

  ngOnInit(): void {}
}
