import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import _ from 'lodash';
import { map } from 'rxjs/operators';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';
@Injectable({
  providedIn: 'root',
})
export class SolutionService {
  protected basePath = 'XXXX';
  solutions: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  multiselectOptions: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  selectOptions : BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  constructor(private readonly http: HttpClient) {
    this.retrieveSolutions().subscribe((response) => {
      this.solutions.next(response);
      this.multiselectOptions.next(this.getSolutionsMultiselectOptions(response))
      this.selectOptions.next(this.getSolutionsOptions(response))

    });
  }
  retrieveSolutions(): Observable<any> {
    return this.http.get(`${this.basePath}/mas/fs/solutions`).pipe(
      map((res) => {
        if (Array.isArray(res)) {
          res.forEach((solution) => {
            switch (solution['release']) {
              case 0:
                solution.releaseString = 'pre-alpha';
                break;
              case 1:
                solution.releaseString = 'alpha';
                break;
              case 2:
                solution.releaseString = 'beta';
                break;
              case 3:
                solution.releaseString = 'pre-release';
                break;
              case 4:
                solution.releaseString = 'GA';
                break;
            }
          });
        }
       

        return res;
      })
    );
  }
  getSolution(solutionId): Observable<any> {
    return this.http.get(`${this.basePath}/mas/fs/solutions/${solutionId}`);
  }
  private getSolutionsOptions(solutions) {
    if (!solutions) {
      return;
    }
    let value = solutions;
    value = _.filter(value, (o) => {
      return o.kind === 'workflow';
    });
    let options = [];
    value.map((el) => {
      let option = { value: el.name, label: el.spec.title };
      options.push(option);
    });
    return options;
  }
  private getSolutionsMultiselectOptions(solutions) {
    if (!solutions) {
      return;
    }
    let value = solutions;
    value = _.filter(value, (o) => {
      return o.kind === 'workflow';
    });
    let options = [];
    value.map((element: any, index: any) => {
      let option: IMultiSelectOption = { id: index, name: _.get(element, 'name') };
      options.push(option);
    });
    return options as IMultiSelectOption[];
  }
}
