import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import moment from 'moment';
import * as _ from 'lodash';
import { dataItemAction } from '../data-manager.service';
import { ItemType } from 'src/app/shared/helpers/itemType';
import { BehaviorSubject } from 'rxjs';
import { TimeAgoPipe } from 'src/app/mims-ui-lib/public_api';

@Component({
  selector: 'app-data-info',
  templateUrl: './data-info.component.html',
  styleUrls: ['./data-info.component.scss'],
})
export class DataInfoComponent implements OnInit {
  @Input() item;
  TimeAgoPipe = TimeAgoPipe;
  @Input() itemType;
  @Input() teamsDataSource? = [];
  @Input() country;
  public _ = _;
  @Output() itemAction = new EventEmitter();
  ItemAction = dataItemAction;
  teamName;
  ItemType = ItemType;
  cohortFiles = new BehaviorSubject<any>(undefined);

  constructor() {}
  ngOnInit(): void {
    // if (this.itemType === ItemType.FileGroups) {
    //   this.filegroupsService.listGroup(this.country.value, this.item.id, 'title,asc', undefined, 100).subscribe((res) => {
    //     this.cohortFiles.next(_.get(res, 'data'));
    //   });
    // }
    // if (_.get(this.item, 'team_id')) {
    //   let el = _.find(this.teamsDataSource, (o) => {
    //     return _.get(o, 'value') === _.get(this.item, 'team_id');
    //   });
    //   this.teamName = _.get(el, 'label');
    // }
  }
 
  get dateModification() {
    const date = _.get(this.item, 'date_of_modification');
    return Math.floor(date * 1000 || 0);
  }
  isRecent(date) {
   return moment().unix() - Math.floor(date / 1000 ) < 44
  }
  get dateCreation() {
    const date = _.get(this.item, 'date_of_creation');
    return Math.floor(date * 1000 || 0);
  }
  get owner() {
    return _.get(this.item, 'owner.name');
  }
  get metadata() {
    return _.get(this.item, 'metadata');
  }
  get title() {
    return _.get(this.item, 'title', _.get(this.item, 'name'));
  }
}
