import { Component, OnInit, Input } from '@angular/core';
import * as example from './viz-data';
import * as _ from 'lodash';
import { DashboardService } from 'src/app/api/dashboard.service';

@Component({
  selector: 'app-viz-testing-set',
  templateUrl: './viz-testing-set.component.html',
  styleUrls: ['./viz-testing-set.component.scss'],
})
export class VizTestingSetComponent implements OnInit {
  example = example;
  thumbnailSize = { width: 315, height: 315 }
  tileFullSize = { width: 1400, height: 800 };
  tileSize = { width: 550, height: 450 };

  constructor(public dashboard: DashboardService) { }

  ngOnInit(): void { }

  getData(name) {
    return _.get(this, 'example.' + name + '.data', []);
  }
  getLayout(name) {
    return _.get(this, 'example.' + name + '.layout', {});
  }
  getDataType(name) {
    return _.get(this, 'example.' + name + '.dataType', 'AA');
  }
  getInteractions(name) {
    return _.get(this, 'example.' + name + '.interactions', []);
  }
  getImages(name) {
    return _.get(this, 'example.' + name + '.images', []);
  }
  getTitleSelect(name) {
    return _.get(this, 'example.' + name + '.titleSelect', 'Select');
  }
  getTitle(name) {
    const layout = _.get(this, 'example.' + name + '.layout', {});
    if (_.isEmpty(layout)) {
      return '';
    }
    if (_.isEmpty(layout.title)) {
      return '';
    }
    if (layout.title && !layout.title.text) {
      return _.cloneDeep(layout.title);
    }
    if (layout.title && layout.title.text) {
      return _.cloneDeep(layout.title.text);
    }
  }
}
