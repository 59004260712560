import { Component, Input, OnInit } from '@angular/core';
import { ProjectItemAction, ProjectManagerService } from 'src/app/api/project-manager.service';
import { ItemType } from  'src/app/shared/helpers/itemType'
import { ShareService } from 'src/app/api/share.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-share-project',
  styleUrls: ['./share-project.component.scss'],
  templateUrl: './share-project.component.html'
})
export class ShareProjectComponent implements OnInit {
  ItemType = ItemType;
  ProjectItemAction = ProjectItemAction
  _= _
  @Input() item: any;
  constructor(
    public projectManagerService: ProjectManagerService,
    public shareService: ShareService
  ) { }
  
  ngOnInit(): void {
    // this.shareService.listTeamsMembers(_.get(this.item, 'host_country') )
    // this.shareService.listUsergroups(_.get(this.item, 'host_country'))
  }
}
