import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Action, ProjectItemAction } from 'src/app/api/project-manager.service';
import moment from 'moment';
import * as _ from 'lodash';
import { ItemType } from 'src/app/shared/helpers/itemType';

@Component({
  selector: 'app-project-list',
  styleUrls: ['../../../shared/layout/managers.scss', './project-list.component.scss'],
  templateUrl: './project-list.component.html',
})
export class ProjectListComponent {
  moment = moment;
  @Input() project: any;
  @Input() selected;
  @Input() highlighted?;
  mouseOvered;
  @Output() itemEvent = new EventEmitter<any>();
  public iconText = '';
  ItemType = ItemType;
  constructor(public router: Router) {}
  isResearchKind = () => {
    return _.get(this, 'project.kind', 'projects') === 'research';
  };
  share() {
    this.itemEvent.emit([this.project, ProjectItemAction.SelectShare]);
  }
  openDashboard() {
    this.itemEvent.emit([this.project, ProjectItemAction.OpenDashboard]);
  }
  openQuery() {
    this.itemEvent.emit([this.project, ProjectItemAction.OpenQuery]);
  }
  getInfos() {
    this.itemEvent.emit([this.project, ProjectItemAction.Info]);
  }
  deletedPermanentlySelected() {
    this.itemEvent.emit([this.project, ProjectItemAction.DeletePermanentlySelected]);
  }
  restoreSelected() {
    this.itemEvent.emit([this.project, ProjectItemAction.RestoreSelected]);
  }
  addToSelection() {
    this.itemEvent.emit([this.project, ProjectItemAction.AddToSelection]);
  }
  removeFromSelection() {
    this.itemEvent.emit([this.project, ProjectItemAction.RemoveFromSelection]);
  }
  trash() {
    this.itemEvent.emit([this.project, ProjectItemAction.Trash]);
  }

  get id() {
    return _.get(this, 'project.id', null);
  }
  get title() {
    return _.get(this, 'project.title', null);
  }
  get lastModified() {
    const date = _.get(this, 'project.date_of_modification', 0);
    return this.formatDate(date)
  }
  formatDate(date){
    if (moment.unix(date).format('YYYY') == moment().format('YYYY')){
      if (moment.unix(date) > moment().subtract(1, 'days')){
        return moment.unix(date).format('MMM Do, h:mm a');
      }else{
        return moment.unix(date).format('MMM Do');
      }
    }
    else {
      return moment.unix(date).format('MMM Do, YYYY');
    }
  }
  get lastTrashed() {
    const date = _.get(this, 'project.date_of_trashing', 0);
    return this.formatDate(date)
  }
  fromNowOrNow(date) {
    date = date < moment().unix() ? date : moment().unix();
    return moment.unix(date).fromNow();
  }
  get status() {
    if (this.isResearchKind()){
      return 'done'
    }
    return _.get(this, 'project.status', '');
  }
  get canTrash() {
    return _.get(this, 'project.capabilities.can_trash', false);
  }
  get isTrashed() {
    return _.get(this, 'project.trashed', false);
  }

  setIconText(text) {
    this.iconText = text;
  }
  unSetIconText() {
    this.iconText = '';
  }
}
