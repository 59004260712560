<div class="w-75 mb-5 mx-auto bg-white d-flex justify-content-around rounded-border border text-center font-weight-bold step-container">
  <div *ngFor="let step of steps; let index = index; let last = last; let first = first" class="flex-fill d-flex flex-nowrap">
    <div *ngIf="!first" [class.hidden]="page !== index" class="arrow-right-container bg-ultra-light-primary" >
      <div class="arrow-right arrow-white"></div>
    </div>
    <div
      [class.bg-ultra-light-primary]="page === index"
      class="flex-fill d-flex flex-column align-items-center justify-content-center"
      [class.disabled]="page !== index"
    >
      <div class=" d-flex flex-nowrap">
        <div class="badge mx-2 text-light badge-pill badge-primary-dark" [class.disabled]="page !== index">
          {{ index + 1 }}
        </div>
        <div class="title">{{ step.title }}</div>
      </div>
    </div>
    <div *ngIf="!last" [class.hidden]="page !== index" class="arrow-right-container">
      <div class="arrow-right"></div>
    </div>
    <div *ngIf="!last" [class.hidden]="page === index || page === index + 1" class="arrow-right-container">
        <div class="arrow-right arrow-white"></div>
      </div>
  </div>
</div>
