import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-viz-thumbnail-container',
  templateUrl: './viz-thumbnail-container.component.html',
  styleUrls: ['./viz-thumbnail-container.component.scss', '../viz-style.scss'],
})
export class VizThumbnailContainerComponent implements OnInit {
  @Input() title;
  @Input() size;


  ngOnInit(): void { }

  getWidth() {
    return _.get(this, 'size.width', 0)
  }
  getHeight() {
    return _.get(this, 'size.height', 0)
  }
}
