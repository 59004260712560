import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-m-logo-btn',
  templateUrl: './m-logo-btn.component.html',
  styleUrls: ['./m-logo-btn.component.scss'],
})
export class MLogoBtnComponent implements OnInit {
  /** Logo path */
  @Input() logoPath = 'assets/images/logo.svg';

  constructor(public router: Router) {}

  ngOnInit(): void {}
}
