import { Component, OnInit, Input, ViewChild, OnDestroy, HostListener, SimpleChanges, OnChanges } from '@angular/core';
import { DashboardService, VizModel } from 'src/app/api/dashboard.service';
import * as _ from 'lodash';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { VizComponent } from './viz/viz.component';
import { Status } from '../../../shared/helpers/status';
import { VizTileComponent } from './viz-tile/viz-tile.component';
import { VizService, VizMode } from 'src/app/api/viz.service';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss'],
})
export class ProjectDashboardComponent implements OnInit, OnDestroy {
  @Input() instanceStatus?= Status.Done;
  @Input() quality?= false;
  @Input() tabIndex?= null;
  @Input() workflowGroupId?;
  isDownloaded = new BehaviorSubject<any>(false)
  @ViewChild('dashboardContainer', { static: false }) dashboardContainer;
  dashboardSubscription;
  dashboardEventSubscription;
  Status = Status
  dashboardHasError = false;
  dashboardErrorSubscription;
  tileView = false;
  VizMode = VizMode;
  legendfile1 = new BehaviorSubject<any>(null)
  legendfile2 = new BehaviorSubject<any>(null)
  legendfile3 = new BehaviorSubject<any>(null)
  workflowGroupIdSubscription;

  constructor(
    public dashboardService: DashboardService, public vizService: VizService, public layout: LayoutOrganizationService
  ) { }

  ngOnInit(): void {
    if (this.workflowGroupId) {
      this.workflowGroupIdSubscription = this.workflowGroupId.subscribe((res) => {
        //if (res === '58358741') { TODO: use documentation call

          let fileURL1 = '../../../../assets/docs/PopSeg_Solution_21122021/dashboard_1.md'
          let fileURL2 = '../../../../assets/docs/PopSeg_Solution_21122021/dashboard_2.md'
          let fileURL3 = '../../../../assets/docs/PopSeg_Solution_21122021/dashboard_3.md'

          fetch(fileURL1)
            .then((response) => {
              return response.text()
            }).then(data => {
              this.legendfile1.next(data)
            })

          fetch(fileURL2)
            .then((response) => {
              return response.text()
            }).then(data => {
              this.legendfile2.next(data)
            })

          fetch(fileURL3).then(res => res.text()).then(data => {
            this.legendfile3.next(data)
          });
      })
    }
    this.dashboardErrorSubscription = this.dashboardService.dashboardHasError.subscribe(() => {
      this.dashboardHasError = true
      return;
    })
    this.dashboardSubscription = this.dashboardService.dashboardIsReady.subscribe((res) => {
      this.isDownloaded.next(true)
      this.dashboardService.dashboardContainer = this.dashboardContainer
      this.dashboardHasError = false
      this.dashboardService.getVisualizations().every((viz, index) => {
        if (this.dashboardService.getVisualizations().length > 100) {
          this.dashboardService.dashboardHasError.emit(true)
          return;
        }
        if (!this.dashboardService.getInteractivityFromDashboard()) {
          this.tileView = true;
          this.createVizContainer(viz, VizTileComponent)
          setTimeout(() => { this.dashboardService.downloadVizContent(viz.id, res); }, index * 50)
        } else {
          this.createVizContainer(viz, VizComponent)
          setTimeout(() => { this.dashboardService.downloadVizContent(viz.id, res); }, index * 250)
        }
        if (viz.isInteractive) {
          setTimeout(() => { this.dashboardService.downloadVizInteractions(viz.id, res) }, 100 + index * 250)
        }
        if (!this.dashboardService.getDashboard()) {
          return false
        }
        else {
          return true
        }
      });

    })
    this.dashboardEventSubscription = this.dashboardService.showChildrenEvent.subscribe((res) => {
      if (!res.parentId || !res.ids) {
        return;
      }
      this.dashboardService.removeChildrenViz(res.parentId)
      res.ids.every((id, index) => {
        let viz = this.dashboardService.addNewViz(res.parentId, id)
        if (viz) {
          this.createVizContainer(viz, VizComponent)
          setTimeout(() => { this.dashboardService.downloadVizContent(viz.id); }, index * 250)
          if (viz.isInteractive) {
            setTimeout(() => { this.dashboardService.downloadVizInteractions(viz.id) }, 100 + index * 250)
          }
        }
        if (!this.dashboardService.dashboard) {
          return false
        }
        else {
          return true
        }
      })
    })



  }

  @HostListener('document:keydown', ['$event'])
  handleVizNavigation(event: KeyboardEvent) {
    if (!this.tileView) {
      return;
    }
    if (!this.layout.isVizModalOpen) {
      return;
    }
    const arrowRight = 39;
    const arrowLeft = 37;
    const code = event.keyCode || event.which;
    if (code === arrowRight) {
      this.navigateRight()
    } else if (code === arrowLeft) {
      this.navigateLeft()
    }
  }


  navigateRight() {
    this.vizService.setSelectedViz(this.dashboardService.getNextViz(this.vizService.getSelectedViz()))
  }
  navigateLeft() {
    this.vizService.setSelectedViz(this.dashboardService.getPreviousViz(this.vizService.getSelectedViz()))
  }

  isArrowVisible() {
    return _.get(this, 'dashboardService.isArrowVisible', false)
  }
  createVizContainer(viz: VizModel, component) {
    if (this.dashboardContainer) {
      let appRef = this.layout.createComponent(component, {
        viz: viz
      })
      viz.appRef = appRef
      this.layout.attachComponent(appRef, this.dashboardContainer.nativeElement)
    }
  }

  ngOnDestroy() {
    if (this.dashboardSubscription)
      this.dashboardSubscription.unsubscribe()
    if (this.dashboardEventSubscription)
      this.dashboardEventSubscription.unsubscribe()
    if (this.workflowGroupIdSubscription)
      this.workflowGroupIdSubscription.unsubscribe()  
    if (this.dashboardService.dashboard)
      delete this.dashboardService.dashboard
  }

}




