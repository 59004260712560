import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalService } from './local-storage.service';

import { ModelsAtlassianDocumentation } from '../model/modelsAtlassianDocumentation';


@Injectable({ providedIn: 'root' })
export class AtlassianHttpService {
    session;

    protected basePath = "XXXX";

    constructor( 
        private localService: LocalService,
        private http: HttpClient ) {
            this.session = this.localService.getFromLocalStorage('session');
        }
    
    getPage(ID: string) {        
        return this.http.get<ModelsAtlassianDocumentation>(`${this.basePath}/mas/fs/documentation/${ID}`);
    }

}

