<app-viz-container *ngIf='data' [size]='size' [title]="">
    <div [style.width.%]='100' [style.height.%]='100' class="scroll d-flex flex-column">
        <a [download]="id" class="vizImageContainer btn vizButton"
            [href]="_DomSanitizer.bypassSecurityTrustUrl(getImage(0))">
            <img alt='Image' [id]="id" class="vizImage" [src]="_DomSanitizer.bypassSecurityTrustUrl(getImage(0))" />
        </a>
        <br />
        <select class='form-control' (change)="onOptionsSelected($event)">
            <option disabled selected value>{{titleSelect}}</option>
            <option *ngFor="let el of interactions" [value]="getInteractionChildren(el)">
                {{getInteractionTitle(el)}}
            </option>
        </select>
    </div>
</app-viz-container>