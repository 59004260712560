import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import {
  template,
  cyan,
  red,
  yellow,
  lime,
  addCustomColorscale,
  constructLayoutUsingTemplate,
} from '../../plotly-templating';
declare let Plotly: any;

@Component({
  selector: 'app-viz-image-select-colorscale',
  templateUrl: './viz-image-select-colorscale.component.html',
  styleUrls: [
    './viz-image-select-colorscale.component.scss',
    '../viz-style.scss',
  ],
})
export class VizImageSelectColorscaleComponent implements OnInit {
  @Input() data;
  @Input() layout;
  @Input() id: string;
  @Input() size;
  @Input() title;
  @Input() images;
  @Input() interactions;
  @Input() titleSelect;
  dataModified;

  constructor(public _DomSanitizer: DomSanitizer) {}

  onOptionsSelected(event) {
    const value = event.target.value;
  }
  getInteractionTitle(interaction) {
    return _.get(interaction, 'title', '');
  }
  getInteractionChildren(interaction) {
    return _.get(interaction, 'children', []);
  }
  getImage(number) {
    return _.get(this, 'images[' + number + ']', '');
  }

  ngOnInit(): void {
    this.processInput();
  }
  processInput() {
    if (this.layout && this.data) {
      this.layout.autosize = true;
      const layoutUsingTemplate = constructLayoutUsingTemplate(
        this.layout,
        template(false, this.data[0].type)
      );
      this.dataModified = addCustomColorscale(this.data, cyan);
      window.addEventListener(
        'load',
        () => {
          Plotly.newPlot(
            this.id + '-plot',
            this.dataModified,
            layoutUsingTemplate,
            { displayModeBar: false }
          );
        },
        false
      );
    }
  }
}
