import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from 'src/app/api/dashboard.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-quality-overview',
  templateUrl: './quality-overview.component.html',
  styleUrls: []
})
export class QualityOverviewComponent implements OnInit {
  @Input() item ?
  @Input() country
  _=_
  constructor(private dashboardService: DashboardService) {
  }
  ngOnInit() {
    this.dashboardService.qualityDashboard = true
    this.dashboardService.setContextId(_.get(this.item, 'id'))
    this.dashboardService.createDashboard(_.get(this.country, 'value') )
  }
  ngOnDestroy() {
    this.dashboardService.qualityDashboard = false
  }

}
