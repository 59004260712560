<div *ngIf="to.options" class="form-group">
    <label *ngIf="to.label">
        {{ to.label }}
        <span *ngIf="to.required">*</span>

        <a *ngIf="to['info-link']"
        [href]="to['info-link']"
        class="cursor-info text-lowercase bg-primary ml-1 border badge badge-pill"
        target="_blank">
        
        <i aria-hidden="true" class="fa-solid text-light fa-info"></i>
        </a>
    </label>

    <select 
        class="form-control bg-white w-100"
        [class.invalid]="formControl.status == 'INVALID'"
        [formControl]="formControl"
        [formlyAttributes]="field"
        (change)="onSelectionChange($event)">

        <option *ngFor="let item of to.options" disabled="{{ item.disabled }}" [value]="item.value">
        {{ item.label }}
        </option>
    </select>

    <div *ngIf="showError" class="invalid-feedback d-block">
        <formly-validation-message [field]="field"></formly-validation-message>
    </div>
</div>