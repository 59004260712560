import { Component } from '@angular/core';
import { ExplorerManagerService } from 'src/app/api/explorer-manager.service';

@Component({
  selector: 'app-slide-no-question',
  templateUrl: './slide-no-question.component.html',
  styleUrls: ['./slide-no-question.component.scss'],
})
export class SlideNoQuestionComponent {
  constructor(public e: ExplorerManagerService) { }
}
