export enum VizType {
  Plot = 'plot',
  Table = 'table',
  TableNoPreview = 'table-no-preview',
  Treemap = 'treemap',
  Image = 'image',
  Select = 'select',
  GenomicSequence = 'genomic_sequence',
  Content = 'content',
  ColorscaledImage = 'colorscaled_image',
  Node = 'node'
}
