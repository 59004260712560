import { Component, OnInit } from '@angular/core';
import { UserCreationHttpService } from './user-creation-http/user-creation-http.service';
import moment from 'moment';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ResetPasswordModalComponent } from './reset-password-modal/reset-password-modal.component';
import { LocalStorage } from 'ngx-webstorage';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';

@Component({
  selector: 'app-user-creation',
  templateUrl: './user-creation.component.html',
  styleUrls: ['./user-creation.component.scss'],
})
export class UserCreationComponent implements OnInit {
  @LocalStorage('user') currentUser;
  users = [];
  PagesPublicName = PagesPublicName;
  constructor(private userCreationHttp: UserCreationHttpService, private modalService: NgbModal, config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.userCreationHttp.listAllUsers().subscribe((users) => {
      this.users = users;
    });
  }

  onUserAdded(user): void {
    this.userCreationHttp.submitUser(user).subscribe((res) => {
      this.userCreationHttp.listAllUsers().subscribe((users) => {
        this.users = users;
      });
    });
  }

  getTimeOfLastActivity(lastActivity) {
    if (!lastActivity || lastActivity === 0) {
      return '';
    }
    let time = moment(lastActivity * 1000).fromNow();
    if (time.includes('in')) {
      return 'just now';
    }
    return time;
  }

  async resetPassword(email) {
    const modalRef = this.modalService.open(ResetPasswordModalComponent);

    try {
      const result = await modalRef.result;

      this.userCreationHttp.resetUserPassword(email, result).subscribe((res) => {
        alert('Successfuly reset password');
      });
    } catch (err) {
      console.log('error', err);
      return;
    }
  }

  activateUser(id) {
    this.userCreationHttp.activateUser(id).subscribe(
      (res) => {
        this.userCreationHttp.listAllUsers().subscribe((users) => {
          this.users = users;
        });
      },
      (error) => console.log(error)
    );
  }

  deactivateUser(id) {
    this.userCreationHttp.deactivateUser(id).subscribe(
      (res) => {
        this.userCreationHttp.listAllUsers().subscribe((users) => {
          this.users = users;
        });
      },
      (error) => console.log(error)
    );
  }
}
