<app-header2>
  <div class="d-flex flex-fill justify-self-start align-items-center">
    <app-page-icon img="{{ _.get(solution, 'spec.icon') }}"></app-page-icon>
    <h3 class="m-0 ml-3">{{ _.get(solution, 'spec.title') }}</h3>
  </div>
</app-header2>
<app-content class="bg-ivory scrollable" [isHeader]="true" [isTab]="false">
  <div class="py-5">
    <app-step-bar *ngIf='steps.length > 1' [steps]="steps" [page]="page"></app-step-bar>
    <div class="w-50 mx-auto">
      <div *ngFor="let step of steps; let index = index; let last = last">
        <form class="d-flex flex-column" [formGroup]="step.form">
          <formly-form [hidden]="page !== index" [form]="step.form" [fields]="step.fields" [options]="step.options" [model]="step.model">
          </formly-form>
        </form>
        <div *ngIf="page === index" class="d-flex flex-nowrap justify-content-between mt-5">
          <div
            [class.visibility-hidden]="page === 0"
            (click)="goToPage(page - 1)"
            id="back"
            class="btn btn-outline-primary font-weight-bold small align-self-end soft-border"
          >
            Back
          </div>
          <div
            *ngIf="!last"
            id="next"
            [class.disabled]="step.form.status !== 'VALID'"
            (click)="step.form.status === 'VALID' ? goToPage(page + 1) : ''"
            class="btn btn-primary font-weight-bold small align-self-end text-light soft-border"
          >
            Next
          </div>
          <div
            *ngIf="last"
            id="execute"
            ngbTooltip="{{ getExecuteTooltip(step) }}"
            [class.disabled]="!canExecute(step)"
            (click)="canExecute(step) ? execute() : ''"
            class="btn btn-primary font-weight-bold small align-self-end text-light soft-border"
          >
            Confirm & Launch
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-group-creation-form *ngIf="layout.isGroupModalOpen"  ></app-group-creation-form>
</app-content>
