import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApplicationScreenSizeService {
  isMobile = new Subject<boolean>();
  isTablet = new Subject<boolean>();
  isDesktop = new Subject<boolean>();

  getMobile() {
    // 768
    // if (window.innerWidth <= 480) {
    //   this.isMobile.next(true);
    // } else {
    //   this.isMobile.next(false);
    // }

    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.isMobile.next(true);
    } else {
      this.isMobile.next(false);
    }
  }

  getTablet() {
    if (480 < window.innerWidth && window.innerWidth < 1024) {
      this.isTablet.next(true);
    } else {
      this.isTablet.next(false);
    }
  }

  getDesktop() {
    if (window.innerWidth >= 1024) {
      this.isDesktop.next(true);
    } else {
      this.isDesktop.next(false);
    }
  }
}
