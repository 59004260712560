import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { supportedSymbols, symbols } from '../hugo';
import _ from 'lodash';
export function EmailValidator(control: FormControl): ValidationErrors {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(control.value).toLowerCase()) ? null : { email: true };
}
export function HugoValidator(control: FormControl): ValidationErrors {
  if (!control.value) {
    return null;
  }
  const symbol = String(control.value).toUpperCase();
  return symbols.includes(symbol) ? null : { hugo: true };
}
export function CsvValidator(control: FormControl): ValidationErrors {
  if (!control.value || typeof control.value === 'string') {
    return null;
  }
  return _.lowerCase(_.last(_.split(_.get(control, 'value[0].name'), '.'))) === 'csv' ? null : { csv: true };
}
export function HugoSupportedValidator(control: FormControl): ValidationErrors {
  if (!control.value) {
    return null;
  }
  const symbol = String(control.value).toUpperCase();
  if (HugoValidator(control) !== null) {
    return null;
  }
  return supportedSymbols.includes(symbol) ? null : { 'hugo-supported': true };
}
export function UniqueValuesValidator(control: FormControl): ValidationErrors {
  if (!control.value) {
    return null;
  }
  let filledValues = _.without(control.value, null, undefined, '') 
  return filledValues.length === _.uniq(filledValues).length ? null : { 'unique-values': true };
}

export function AdditionalValuesValidator(control: AbstractControl) {
  const field1 = _.get(control, 'value.seed_gene');
  const field2 = _.get(control, 'value.optional_genes');

  if (!field1 || !field2) {
    return null;
  }
  if (!_.includes(field2, field1)) {
    return null;
  }
  return { 'additional-values': true };
}
