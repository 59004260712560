<div class='h-100 w-100 d-flex justify-content-around flex-column'>
  <div class="login-title w-100">

  </div>
  <div class='d-flex flex-column justify-content-around flex-fill'>
    <span>You are already logged in as <span class='font-weight-bold'>{{_.get(user, 'email')}}</span></span>
  </div>
  <div class='w-100 d-flex flex-nowrap align-items-center'>
    <div tabindex='0' (click)='changeUser()' class="btn mr-3 flex-fill border login-btn">Change user</div>
    <div tabindex='0' (click)='goToHomePage()' class="btn   flex-fill btn-dark login-btn">Continue to MIMsomic</div>
  </div>
</div>
