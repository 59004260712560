<div [id]="id" class="list">
  <div class="list-container smooth-border row" [class.highlighted]='highlighted'
    [class.tile-selected]="isTrashed ? selected : false">
    <div (click)='isResearchKind() ? openQuery() : openDashboard()'
      class="item-text-container row mx-0 px-0 col-9 flex-nowrap ">
      <div class="d-flex flex-column w-100 align-content-start">
        <div class="cursor-pointer font-weight-bold project-title " [class.px-4]='!isTrashed' [class.ml-3]='isTrashed'>
          <i class="fa-solid text-primary fa-grid-2 mr-2" aria-hidden="true"></i>
          {{ title }}
        </div>
        <div *ngIf='!isTrashed' class="pl-4 cursor-pointer text-muted small">
          last modified : {{lastModified}}
        </div>
        <div *ngIf='isTrashed' class="pl-4 cursor-pointer text-muted small">
          trashed : {{lastTrashed}}
        </div>
      </div>


    </div>
    <div class="item-icons-container mx-0 px-0 row col-3">
      <div class="item-icon-text px-0 d-flex col">
        <div *ngIf='status.length' class="text-capitalize badge h-auto my-auto badge-light bg-white badge-pill  border mr-auto "
          [class.text-primary]="status === 'done'" [class.text-warning]="status === 'running'"
          [class.text-danger]="status === 'failed'"
          
          [class.text-muted]="status !== 'done' && status !== 'running' && status !== 'failed'">
          {{ status }}
        </div>
      </div>

      <div class="item-icons col-md-auto mx-0 px-0">
        <div class="dropdown">
          <button class="smooth-border btn btn-link border text-dark no-focus" type="button" id="dropdownMenuButton"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [class.bg-dark]="mouseOvered"
            (mouseover)="mouseOvered=true" (mouseout)="mouseOvered=false">
            <i class="far fa-ellipsis" [class.text-light]="mouseOvered" aria-hidden="true"></i>
          </button>
          <div class="dropdown-menu  smooth-border dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <div *ngIf="!isTrashed">
              <a class="dropdown-item noselect" (click)="getInfos()">Get info</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item noselect" (click)="share()">Share</a>
              <div *ngIf='canTrash' class="dropdown-divider"></div>
              <a *ngIf='canTrash' class="dropdown-item noselect" (click)="trash()">Trash</a>
            </div>
            <div *ngIf="isTrashed">
              <a class="dropdown-item noselect " (click)="restoreSelected()">Restore</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item noselect" (click)="deletedPermanentlySelected()">Delete permanently</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
