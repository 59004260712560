<div class="list-group-item d-flex flex-column  scrollable h-100" id={{item.id}}>



  <ul>
    <small>
      <li>
        <div class='my-4  text-uppercase text-muted'>name of project : </div>

        <span *ngIf="!_.get(item, 'capabilities.can_modify')">{{_.get(item,"title")}}</span>
        <app-rename-2 *ngIf="_.get(item, 'capabilities.can_modify')" [value]="_.get(item,'title')"
          [itemType]="ItemType.Project"
          (valueChanged)="projectManagerService.itemActionEvent.emit([item, ProjectItemAction.Rename, undefined, $event])"
          class='h5 text-primary'>
        </app-rename-2>
      </li>
      <li class='my-4 text-uppercase '><span class="text-muted">owner : </span><span class='font-weight-bold'>
          {{item.owner.name}}</span>
      </li>
    </small>
  </ul>
  <app-share (itemEvent)='projectManagerService.itemActionEvent.emit($event)' [type]="ItemType.Project" [item]="item"
    [autocompleteData]="shareService.getAutocompleteData()">
  </app-share>
</div>
