import { IOptionData } from './../drop-down/option.interface';
import { takeUntil, debounceTime, map } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  OnDestroy,
  ElementRef,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  Optional,
  Self,
} from '@angular/core';
import { fromEvent, Subject, Observable, merge } from 'rxjs';
import { DropDownComponent } from '../drop-down/drop-down.component';
import { DEFAULT_OPTION_HEIGHT } from '../drop-down/drop-down-list/drop-down-list.component';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

/** When backend search available, this will be helpful
 *  we can defind a function (that will call api) and assign to datasource.
 */
export type AutoCompleteDatasourceFn = (search: string) => Observable<IOptionData<any>[]>;

/**
 * Auto complete: to pick a value from a dropdown list of selectable options.
 * It takes a datasource, when user typing, it does a front-end side search by filtering on the datasource.
 *
 * Configurable width
 * - dropdown list is configurable
 *
 * Option selection
 * - click on an option with change the selected option
 *
 * Improve: add backend search: every time user typing send a call to backend get the result to fill dropdown.
 */
@Component({
  selector: 'mims-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoCompleteComponent<T> implements ControlValueAccessor, OnChanges, AfterViewInit, OnDestroy {
  /** unique id of this component */
  id = uuidv4();

  @ViewChild('inputField', { static: true }) inputField: ElementRef;

  /** Dropdown component */
  @ViewChild('dropdown') dropdownComponent: DropDownComponent;

  /** The label of auto-complete */
  @Input() label: string;

  /** the value of this component */
  @Input() value: any;

  /** Indicate dropdown list width */
  @Input() listWidth: number;

  /** Specifies the max items in dropdown list */
  @Input() maxOptionsVisibleInList = 6;

  /** Specifies the option height */
  @Input() optionHeight = DEFAULT_OPTION_HEIGHT;

  /** Specifies the selected option */
  @Input() selectedOption: IOptionData<T>;

  /** Specifies the place holder message if nothing selected */
  @Input() placeholder = 'Please select...';

  /** Specifies the empty message when search */
  @Input() emptyPlaceholder = 'No options available';

  @Input() set dataSource(datasource: IOptionData<any>[] | AutoCompleteDatasourceFn) {
    this._dataSource = datasource;
    if (typeof datasource === 'function') {
      this.datasourceType = 'async';
    } else {
      this.datasourceType = 'static';
    }
  }
  get dataSource() {
    return this._dataSource;
  }

  /** Specifies every time the value has been changed */
  @Output() valueChanged: EventEmitter<IOptionData<T>> = new EventEmitter();

  /** Specifies every time the dropdown has been toggled. boolean specifies if opened */
  @Output() toggled: EventEmitter<boolean> = new EventEmitter();

  /** Holding the data source */
  _dataSource: IOptionData<T>[] | AutoCompleteDatasourceFn;

  /** Specifies the datasource type */
  datasourceType: 'static' | 'async';

  /** Specifies the dropdown options value */
  filteredOptions: IOptionData<T>[] = [];

  opened = false;

  showCloseIcon$: Subject<boolean> = new Subject();

  /** @ignore pattern to manage unsubscribe */
  private _componentDestroyed$: Subject<void> = new Subject();

  /** @ignore callback method when change occurs on the component */
  propagateChange: any = () => {};

  /** @ignore callback method when touch occurs on the component */
  propagateTouched: any = () => {};

  constructor(private _cdr: ChangeDetectorRef, @Optional() @Self() public ngControl: NgControl) {
    // nice trick to avoid circular dependancy.
    if (this.ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  /** Set value from form API reactive form
   *  SelectedOption will always follow
   */
  writeValue(obj: any): void {
    // if value falsey
    if (!obj) {
      this.selectedOption = undefined;
      this.value = obj;
      this.updateInputDisplayValue(this.value);
      this.valueChanged.emit(this.value);
    } else {
      // if already have datasource, try to find options in array, everything based on datasource
      if (this.datasourceType === 'static') {
        this.updateSelectedOption(obj);
        const isIOptionData = this.selectedOption && this.selectedOption.hasOwnProperty('value');
        this.value = isIOptionData ? this.selectedOption.value : this.selectedOption;
        this.updateInputDisplayValue(isIOptionData ? this.getDisplayValue(this.selectedOption) : this.value);
        this.valueChanged.emit(isIOptionData ? { ...this.selectedOption } : this.value);
      } else {
        // note if datasource is not coming yet, we only assing value, but display value need come from [selectedOption]
        this.value = obj;
        this.updateInputDisplayValue(this.value);
      }
      this._cdr.detectChanges();
    }
  }

  /** @ignore */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /** @ignore */
  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  /** @ignore */
  setDisabledState?(isDisabled: boolean): void {
    // TODO: support disabled
    throw new Error('Method not implemented.');
  }

  ngOnChanges({ value, selectedOption }: SimpleChanges): void {
    // if we have a local datasource, try find the index of selected option
    if (value && this.datasourceType === 'static') {
      this.updateSelectedOption(value.currentValue);
    }

    if (selectedOption) {
      // set value if it's not in a form context (in that case it's reactive form's job to writeValue)
      if (!this.ngControl) {
        this.value = selectedOption.currentValue ? selectedOption.currentValue.value : '';
      }
      if (selectedOption.currentValue && this.inputField) {
        this.updateInputDisplayValue(this.getDisplayValue(this.selectedOption));
      }
    }
  }

  ngAfterViewInit() {
    if (this.datasourceType === 'static') {
      this.filteredOptions = this.dataSource as IOptionData<T>[];
      this.updateSelectedOption(this.value);
      this._cdr.detectChanges();
    }
    if (this.selectedOption) {
      this.updateInputDisplayValue(this.getDisplayValue(this.selectedOption));
    }

    merge(fromEvent(this.inputField.nativeElement, 'input'), fromEvent(this.inputField.nativeElement, 'focus'))
      .pipe(
        debounceTime(200),
        map((evt: KeyboardEvent) => (evt.target as HTMLInputElement).value),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe((evt) => {
        this.filterOptionsByText(evt);
        if (!this.opened) {
          this.opened = true;
        }
        this.showCloseIcon$.next(!!evt ? true : false);
      });

    this.dropdownComponent.clickOutside.pipe(takeUntil(this._componentDestroyed$)).subscribe(() => {
      let resetValue = false;

      if (this.getDisplayValue(this.selectedOption) !== this.inputField.nativeElement.value) {
        resetValue = true;
      } else {
        // if value is the same, check if the filteredoption contains the value
        const isSelectedOptionValid = (this.filteredOptions || []).some((option) => option.value === this.selectedOption?.value);
        resetValue = !isSelectedOptionValid;
      }
      if (resetValue) {
        this.selectedOption = undefined;
        this.setValue();
      }
      this._cdr.detectChanges();
    });
  }

  filterOptionsByText(text: string) {
    if (this.datasourceType === 'static') {
      this.filteredOptions =
        text === ''
          ? (this.dataSource as IOptionData<T>[])
          : (this.dataSource as IOptionData<T>[]).filter((option) => option.label.includes(text));
      // add empty msg
      if (this.filteredOptions?.length === 0) {
        this.filteredOptions.push({ label: this.emptyPlaceholder, value: '' });
      }
    }
  }

  onDropdownToggled(status: boolean) {
    this.opened = status;
    this.toggled.emit(this.opened);
  }

  updateSelectedOption(value: any) {
    if (this.dataSource && !!value) {
      let optionIndex;
      if (typeof value === 'object') {
        optionIndex = (this.dataSource as IOptionData<T>[]).map((o) => JSON.stringify(o.value)).indexOf(JSON.stringify(value));
      } else {
        optionIndex = (this.dataSource as IOptionData<T>[])
          .map((o) => (o.value ? o.value.toString() : undefined))
          .indexOf(value.toString());
      }
      this.selectedOption = optionIndex > -1 ? (this.dataSource as IOptionData<T>[])[optionIndex] : undefined;
    } else {
      this.selectedOption = undefined;
    }
    this._cdr.detectChanges();
  }

  onOptionSelected(selectedOption: IOptionData<T>) {
    if (selectedOption.label !== this.emptyPlaceholder) {
      this.opened = false;
      this.selectedOption = selectedOption;
      this.filterOptionsByText('');
      this.setValue();
    }
  }

  onCloseIconClick($event) {
    $event.stopPropagation();
    this.filterOptionsByText('');
    this.updateInputDisplayValue('');
    this.inputField.nativeElement.focus();
  }

  /** set the value of this component */
  setValue(): void {
    const isOptionData = this.selectedOption && this.selectedOption.hasOwnProperty('value');
    this.value = isOptionData ? this.selectedOption.value : this.selectedOption || '';
    this.updateInputDisplayValue(isOptionData ? this.getDisplayValue(this.selectedOption) : this.value);
    this.propagateChange(this.value);
    this.valueChanged.emit(isOptionData ? { ...this.selectedOption } : this.value);
  }

  /** Return a string representation of option */
  getDisplayValue(selectedOption: IOptionData<T>): string {
    let value = '';
    if (selectedOption) {
      value = selectedOption.label || selectedOption.value;
      if (value && typeof value === 'object') {
        value = JSON.stringify(value);
      }
    }
    return value;
  }

  /** Update input display value */
  updateInputDisplayValue(value: string) {
    this.inputField.nativeElement.value = !!value ? value : '';
    this.showCloseIcon$.next(!!value ? true : false);
    this._cdr.detectChanges();
  }

  ngOnDestroy() {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}
