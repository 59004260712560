import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { LocalService } from 'src/app/api/local-storage.service';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
import { SortDirection } from 'src/app/shared/helpers/sortDirection';
import { SortType } from 'src/app/shared/helpers/sortType';
import { TeamsHttpService } from '../teams-manager/teams-http.service';
import * as _ from 'lodash';
import moment from 'moment';
import { Router } from '@angular/router';
import { PagesName } from 'src/app/shared/helpers/pagesName';
@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss'],
})
export class LicensesComponent implements OnInit {
  PagesPublicName = PagesPublicName;
  headers = ['', 'Company', 'Type of license', 'Credits', 'Expiration date', 'Status', ''];
  SortType = SortType;
  SortDirection = SortDirection;
  page = 1;
  pageSize = 50;
  _ = _;
  PagesName = PagesName;
  moment = moment;
  licenses = new BehaviorSubject<any>(undefined);
  constructor(
    private layout: LayoutOrganizationService,
    private teamHttpService: TeamsHttpService,
    public localService: LocalService,
    public router: Router
  ) {
    if (!localService.getFromLocalStorage('LicensesSortByType')) {
      localService.setLocalStorage('LicensesSortByType', SortType.CompanyName);
    }
    if (!localService.getFromLocalStorage('LicensesSortByDirection')) {
      localService.setLocalStorage('LicensesSortByDirection', SortDirection.Asc);
    }
  }
  openDetails(el?) {
    if (el) {
      return this.router.navigate([PagesName.LicenseManager, PagesName.LicenseDetails, _.get(el, '_key')]);
    }

    return this.router.navigate([PagesName.LicenseManager, PagesName.LicenseDetails]);
  }
  isExpired(el) {
    return _.get(el, 'status') === 'Expired';
  }
  handleDeleteLicense(el) {
    const text ='This license will be permanentely deleted. Are you sure you want to do this ?'
    this.layout.customConfirm(text, () => {
      this.teamHttpService.deleteTeam(_.get(el, '_key')).subscribe(
        (res) => {
          this.layout.toast('License <b>' + _.get(el, 'name') + '</b> has been successfully deleted', null, 8000, '', 'success');
          this.retrieveLicenses();
        },
        () => {
          this.layout.toast('<b>Error</b> while deleting license  <b>' + _.get(el, 'name') + '</b>', null, 8000, '', 'danger');
        }
      );
    });
  }
  ngOnInit(): void {
    this.retrieveLicenses();
  }
  handleSortBy(type, direction) {
    this.localService.setLocalStorage('LicensesSortByType', type);
    this.localService.setLocalStorage('LicensesSortByDirection', this.toggleDirection(direction));
    this.licenses.next(this.orderLicenses(this.licenses.value));
  }
  toggleDirection(value: SortDirection) {
    if (value === SortDirection.Asc) {
      return SortDirection.Desc;
    } else {
      return SortDirection.Asc;
    }
  }
  orderLicenses(licenses) {
    const direction = this.localService.getFromLocalStorage('LicensesSortByDirection');
    const type = this.localService.getFromLocalStorage('LicensesSortByType');
    return _.orderBy(
      licenses,
      [
        function (o) {
          return _.toLower(_.get(o, type));
        },
      ],
      [direction]
    );
  }
  retrieveLicenses() {
    this.teamHttpService.getTeams().subscribe(
      (res) => {
        res.forEach((o) => {

          const isExpired = _.get(o, 'expiration_date', 0) < moment().unix();
          _.set(o, 'status', isExpired ? 'Expired' : 'Active');
          _.set(o, 'credits', 'N/A');
        });
        this.licenses.next(this.orderLicenses(res));
      },
      () => {
        this.layout.toast('<b>Error</b> while retrieving the list of teams', null, 8000, '', 'danger');
      }
    );
  }
}
