import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdditionalTypes, IdHelp, IsRequired } from 'src/app/shared/helpers/formattedInput';

@Component({
  selector: 'app-member-addition-form',
  templateUrl: './member-addition-form.component.html',
  styleUrls: []
})
export class MemberAdditionFormComponent {
  @Input() form 
  @Output() formChanged = new EventEmitter()

   idFieldObject = {
    formControlName: 'memberInput',
    type: AdditionalTypes.ShortText,
    placeholder: "",
    showIcon: 'far fa-info-circle',
    validations: [IsRequired, IdHelp]

  }


  get memberInput() {
    return this.form.get('memberInput');
  }
}
