import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { LocalService } from 'src/app/api/local-storage.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as _ from 'lodash';

export enum FileSystemState {
  Local = 'local',
  External = 'external',
  Disabled = 'disabled',
}

interface UserObject {
  email: string;
  first_name: string;
  id: string;
  kind: string;
  last_name: string;
  links: { self: string };
  name: string;
  organization: string;
  file_hosting_country: string;
}

interface LegacyLoginResponse {
  token: string;
  userID: string;
}
interface LoginResponse {
  first_factor_token: string;
}

interface SecondFactorResponse {
  access_token: string;
  authorized: boolean;
}

interface VerifyResponse {
  user_id: string;
  expiration: number;
}
interface ExternalStorageAccessTokenRequest {
  target_country: string;
}

@Injectable({ providedIn: 'root' })
export class LoginHttpService {
  activeStep = 0;
  qrCodeImage: string = '';

  protected basePath = 'XXXX';
  constructor(private http: HttpClient, private localService: LocalService) {}
  activateAccountWithPassword(token, password): Observable<any> {
    let body = {};
    body['activation_token'] = token;
    body['password'] = password;
    return this.http.post<any>(`${environment.activationPath}/mas/auth/activate-with-new-password`, body, {withCredentials: true});
  }
  resetPasswordWithToken(token, password): Observable<any> {
    let body = {};
    body['token'] = token;
    body['password'] = password;
    return this.http.post<any>(`${environment.activationPath}/mas/auth/reset-password-with-token`, body, {withCredentials: true});
  }
  resetOwnPassword(oldPassword: string, newPassword: string): Observable<any> {
    let body = {};
    body['old_password'] = oldPassword;
    body['new_password'] = newPassword;
    return this.http.post<any>(`${this.basePath}/mas/auth/reset-own-password`, body);
  }
  constructFileSystem(account_country, external_user_ids?) {
    let file_systems = [
      {
        name: 'Canada',
        value: 'CA',
      },
      { name: 'France', value: 'FR' },
      { name: 'United States', value: 'US' },
    ];
    if (_.isEmpty(external_user_ids) || external_user_ids === null) {
      let file_system = _.find(file_systems, (o) => {
        return o.value === account_country;
      });
      _.set(file_system, 'accessState', FileSystemState.Local);
      let result = [];
      result.push(file_system);
      return result;
    } else {
      const externalCountries = _.keys(external_user_ids);
      file_systems.forEach((element) => {
        if (element.value === account_country) {
          _.set(element, 'accessState', FileSystemState.Local);
        } else if (_.includes(externalCountries, element.value)) {
          _.set(element, 'accessState', FileSystemState.External);
        } else {
          _.set(element, 'accessState', FileSystemState.Disabled);
        }
      });
      return file_systems;
    }
  }
  getExternalStorageAccessToken(targetCountry): Observable<any> {
    let body = { target_country: targetCountry };
    return this.http.post<any>(`${this.basePath}/mas/auth/get-external-storage-access-token`,
     body);
  }
  getUser(userId, withCredentials?): Observable<UserObject> {
      return this.http.get<UserObject>(`${this.basePath}/mas/users/${userId}`, { withCredentials });
  }

  setUserSessionData(user) {
    if (!_.get(user, 'account_country')) {
      _.set(user, 'account_country', 'US');
    }
    if (!_.get(user, 'file_hosting_country')) {
      _.set(user, 'file_hosting_country', 'US');
    }
    const external_user_ids = _.get(user, 'external_user_ids', {});
    const account_country = _.get(user, 'account_country');
    let file_systems = this.constructFileSystem(account_country, external_user_ids);
    _.set(user, 'file_systems', file_systems);
    this.localService.setLocalStorage('user', user);
    this.localService.setLocalStorage(
      'country',
      _.find(file_systems, (o) => {
        return o.accessState === FileSystemState.Local;
      })
    );

    file_systems.forEach((element) => {
      if (element.accessState === FileSystemState.Local) {
        const session = this.localService.getFromLocalStorage('session')
        _.set(element, 'url', _.get(session, 'apiPath'));
        _.set(element, 'access_token',_.get(session, 'accessToken'));
        this.localService.editLocalStorage('user', 'file_systems', file_systems);
      }
      if (element.accessState === FileSystemState.External) {
        this.getExternalStorageAccessToken(element.value).subscribe((response2) => {
          _.set(element, 'url', _.get(response2, 'url'));
          _.set(element, 'access_token', _.get(response2, 'access_token'));
          this.localService.editLocalStorage('user', 'file_systems', file_systems);
        });
      }
    });
  }

  verifyAccess(): Observable<VerifyResponse> {
    return this.http.get<VerifyResponse>(`${this.basePath}/mas/auth/verify`, { withCredentials: true });
  }

  // loginWithout2fa(email: string, password: string): Observable<LegacyLoginResponse> {
  //   return this.http
  //     .post<LegacyLoginResponse>(
  //       `${this._basePath}/mas/auth/login`,
  //       {
  //         email,
  //         password,
  //       },
  //       { withCredentials: true }
  //     )
  //     .pipe(catchError(this.handleError));
  // }

  loginWithout2fa(email: string, password: string): Observable<HttpResponse<LegacyLoginResponse>> {
    return this.http.post<LegacyLoginResponse>(
      `${environment.apiBasePath}/mas/auth/login`,
      {
        email,
        password,
      },
      { withCredentials: true, observe: 'response' }
    );
  }

  
  login(email: string, password: string): Observable<HttpResponse<LoginResponse>> {
    return this.http
      .post<LoginResponse>(
        `${this.basePath}/mas/auth/login/v2`,
        {
          email,
          password,
        },
        { withCredentials: true, observe: 'response' }
      )
      // .pipe(catchError(this.handleError));
  }

  validateSecondFactor(code: string): Observable<SecondFactorResponse> {
    return this.http.post<SecondFactorResponse>(
      `${this.basePath}/mas/auth/validate-second-factor`,
      {
        token: code,
      },
      { withCredentials: true }
    );
  }
  deleteAccount(id: string): Observable<any> {
    return this.http.delete<any>(`${this.basePath}/mas/users/${id}`);
  }

  getQRCode(): Observable<Blob> {
    return this.http
      .get(`${this.basePath}/mas/auth/get-google-qrcode`, {
        responseType: 'blob',
        withCredentials: true 
      }, )
      .pipe(catchError(this.handleError));
  }

  private handleError(errorRes: HttpErrorResponse) {
    return throwError(errorRes);
  }

  sendPasswordResetEmail(email: string): Observable<any> {
    console.log(email);
    return this.http.post<any>(`${this.basePath}/mas/auth/send-password-reset-email`, {email}, { withCredentials: true, observe: 'response' });
  }
}
