import { Component, Input, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-content',
  host: {
    '[class.with-header-with-nav]': 'isTab && isHeader'
  },
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements AfterViewInit {
  @Input() isTab?= false;
  @Input() isHeader?= false;

  constructor(private _ref: ElementRef) { }

  ngAfterViewInit() {
    const host = this._ref.nativeElement;
    if (this.isTab && this.isHeader) {
      host.style.height = 'calc(100% - 5rem - 3.25rem)';
    } else if (!this.isTab && this.isHeader) {
      host.style.height = 'calc(100% - 5rem)';
    } else if (this.isTab && !this.isHeader) {
      host.style.height = 'calc(100% - 3.25rem)';
    } else {
      host.style.height = '100%';
    }

  }
}
