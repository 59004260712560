import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss'],
})
export class ResetPasswordModalComponent implements OnInit {
  resetPasswordForm: UntypedFormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.resetPasswordForm = this.formBuilder.group(
      {
        password: [
          null,
          [
            Validators.required,
            Validators.minLength(8),
            this.noSpacesValidator,
            this.specialCharacterValidator,
          ],
        ],
        confirm: [null, Validators.required],
      },
      { validators: this.passwordsMatchValidator }
    );
  }

  get password() {
    return this.resetPasswordForm.get('password');
  }

  get confirm() {
    return this.resetPasswordForm.get('confirm');
  }

  noSpacesValidator(control: UntypedFormControl): ValidationErrors | null {
    if (control.value) {
      if (control.value.includes(' ')) {
        return { shouldNotHaveSpaces: true };
      }
    }

    return null;
  }

  specialCharacterValidator(control: UntypedFormControl): ValidationErrors | null {
    const regex = /[^a-zA-Z0-9]+/g;
    const value = control.value;

    if (value && value.match(regex)) {
      return null;
    } else {
      return { noSpecialCharacter: true };
    }
  }

  passwordsMatchValidator(control: UntypedFormGroup): ValidationErrors | null {
    const password = control.get('password');
    const confirm = control.get('confirm');
    return password.value !== confirm.value ? { mustMatch: true } : null;
  }

  submitForm() {
    this.activeModal.close(this.resetPasswordForm.value.password);
  }

  closeModal() {
    this.activeModal.dismiss();
  }
}
