import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LocalService } from 'src/app/api/local-storage.service';
import { AdditionalTypes, InputJson, IsRequired } from 'src/app/shared/helpers/formattedInput';
import { LoginHttpService } from '../login-http-service/login-http.service';
import * as _ from 'lodash';
import { availableFeatures  } from 'src/environments/environment';
import { Features } from 'src/environments/features';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { Router } from '@angular/router';

@Component({
  selector: 'app-first-factor',
  templateUrl: './first-factor.component.html',
  styleUrls: ['./first-factor.component.scss'],
})
export class FirstFactorComponent implements OnInit {
  loginForm: UntypedFormGroup;
  passwordResetForm: UntypedFormGroup;
  emailField: InputJson;
  passwordField: InputJson;
  backendError = undefined;
  showPasswordResetForm: boolean;
  passwordResetMailSent: boolean;

  constructor(private loginHttp: LoginHttpService, private localService: LocalService,  private router: Router) {
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl(null),
      password: new UntypedFormControl(null),
    });
  
    this.passwordResetForm = new UntypedFormGroup({
      email: new UntypedFormControl(null),
    });

    this.emailField = {
      formControlName: 'email',
      type: AdditionalTypes.ShortText,
      label: 'Email',
      placeholder: '',
      fitToContent: true,
      validations: [IsRequired],
    };
    this.passwordField = {
      formControlName: 'password',
      type: AdditionalTypes.Password,
      label: 'Password',
      fitToContent: true,
      placeholder: '',
      validations: [IsRequired],
    };
  }
  ngOnInit() {
    this.loginHttp.qrCodeImage = undefined;
  }
  loginFormIsValid() {
    return this.loginForm.status === 'VALID' && !this.backendError;
  }
  fieldIsEdited() {
    this.backendError = undefined;
  }
  @HostListener('document:keydown', ['$event'])
  loginWithEnter(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.loginFormIsValid()) this.login();
  }
  login() {
    const email = this.loginForm.value.email;
    const password = this.loginForm.value.password;

    if (_.includes(availableFeatures, Features.DoubleAuth)) {
      this.loginHttp.login(email, password).subscribe(
        (response) => {
          const basePath = response.url.substring(0, _.get(response, 'url.length') - _.get('/mas/auth/login/v2', 'length'));
          let session = {
            accessToken: response.body.first_factor_token,
            apiPath: basePath,
          };
          this.localService.setLocalStorage('session', session);

          this.loginHttp.getQRCode().subscribe(
            (res) => {
              var objectURL = URL.createObjectURL(res);
              this.loginHttp.qrCodeImage = objectURL;
              this.loginHttp.activeStep = 1;
            },
            (error) => {
              if (error.status === 403) this.loginHttp.activeStep = 2;
            }
          );


        },
        (error) => {
          const message = _.get(error, 'error.message');
          if (message === 'invalid credentials') {
            this.backendError = 'Email or password is incorrect';
          }
        }
      );
    } else {
      this.loginHttp.loginWithout2fa(email, password).subscribe((response) => {
        const basePath = response.url.substring(0, _.get(response, 'url.length') - _.get('/mas/auth/login', 'length'));
        let session = {
          accessToken: response.body.token,
          apiPath: basePath,
        };
        this.localService.setLocalStorage('session', session);
        this.loginHttp.getUser(_.get(response.body, 'userID'), true).subscribe(
          (response) => {
            this.loginHttp.setUserSessionData(response)
            setTimeout(() => { this.router.navigate([PagesName.Home]); }, 500)
          },
          () => {
            this.backendError = 'User not found';
          }
        );

      },
      (error) => {
        const message = _.get(error, 'error.message');
        if (message === 'invalid credentials') {
          this.backendError = 'Email or password is incorrect';
        }
      })
    }
  }
  resetPassword() {
    this.loginHttp.sendPasswordResetEmail(this.passwordResetForm.value.email).subscribe((res) => {console.log(res)}, (err) => {console.log(err)});
    this.passwordResetMailSent = true;
  }
  showResetPasswordForm() {
    this.showPasswordResetForm = true;
  }
  isFormValid() {
    if(this.showPasswordResetForm) {
      return this.passwordResetForm.status === 'VALID' && !this.backendError;
    } else {
      return this.loginForm.status === 'VALID' && !this.backendError;
    }
  }

  getCurrentForm() {
    return this.showPasswordResetForm ? this.passwordResetForm : this.loginForm; 
  }
}
