<div
    *ngIf="instanceStatus === Status.Running || instanceStatus === Status.InTreatment || instanceStatus === Status.NotStarted">
    <app-spinner></app-spinner>
    <div class="my-3 text-center">
        <i class="far fa-exclamation-circle" aria-hidden="true"></i>
        In process.
    </div>
</div>

<div *ngIf="instanceStatus === Status.Failed">
    <div class="my-3 text-center">
        <i class="far fa-exclamation-circle" aria-hidden="true"></i>
        An error occurred during the processing.
    </div>
</div>
<div *ngIf="instanceStatus === Status.Done && dashboardHasError && !dashboardService.qualityDashboard">
    <div class="my-3 text-center">
        <i class="far fa-exclamation-circle" aria-hidden="true"></i>
        Processing is done. Impossible to get the dashboard.
    </div>
</div>
<div *ngIf="dashboardHasError && dashboardService.qualityDashboard">
    <div class="my-3 text-center">
        <i aria-hidden="true" class="far fa-exclamation-circle"></i>
        No quality dashboard found for this file.
    </div>
</div>
<mims-loader *ngIf='!isDownloaded.value' [isLoading]='!isDownloaded.value'></mims-loader>

<div *ngIf="instanceStatus === Status.Done && !dashboardHasError" #dashboardContainer class='d-flex'
    [ngClass]="{'flex-wrap': tileView,'dashboardTileContainer': tileView, 'p-4': tileView, 'flex-column': !tileView, 'align-items-center': !tileView }">
</div>
<app-full-screen-modal (click)='dashboardService.getVizIndex(vizService.getSelectedViz())'
    *ngIf='layout.isVizModalOpen && tileView && vizService.getSelectedViz()' idName='modalViz'
    [title]='vizService.getTitle(vizService.getSelectedViz())' class='d-flex flex-column'>
    <app-viz [vizMode]='VizMode.FullScreen' class='w-100 p-4 flex-grow-1 m-0' [viz]='vizService.getSelectedViz()'>
    </app-viz>
    <div class='title-container align-items-center d-flex'>
        <div class='w-100 text-center text-wrap'>
            <div class='btn' (click)='navigateLeft()'><i class="far mx-2 fa-chevron-left" aria-hidden="true"></i></div>
            <div class='btn' (click)='navigateRight()'> <i class="far mx-2 fa-chevron-right" aria-hidden="true"></i>
            </div>
        </div>
    </div>
</app-full-screen-modal>


<app-full-screen-modal 
    *ngIf='layout.isLegendModalOpen' idName='modalLegend'
   class='d-flex flex-column'>
    <div class='w-100 h-100 px-5 scrollable'>
<markdown class='w-100' *ngIf='tabIndex.value === 0 || tabIndex.value === null' [data]='legendfile1.value'> </markdown>
<markdown class='w-100' *ngIf='tabIndex.value === 1'  [data]='legendfile2.value'> </markdown>
<markdown class='w-100' *ngIf='tabIndex.value === 2'  [data]='legendfile3.value'> </markdown>
</div>
</app-full-screen-modal>

<div class="scroll-arrow-down arrow bounce" [class.hidden]="!isArrowVisible()">
    <div class="fa fa-arrow-down text-info fa-2x"></div>
</div>