import { Component, Input } from '@angular/core';
import { ExplorerManagerService } from '../../../../api/explorer-manager.service';
import moment from 'moment';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss'],
})
export class BackComponent {
  public moment = moment;
  public format = 'MM/DD/Y - HH:mm';
  @Input() reset: boolean;
  @Input() text: string;
  @Input() goToView: string;

  constructor(public e: ExplorerManagerService) {}
}
