import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { ProjectsService } from 'src/app/api/projects.service';
import _ from 'lodash';
import moment from 'moment';
import { CreditAlertsService } from 'src/app/pages/project-creation2/credit-alerts/credit-alerts.service';
@Component({
  selector: 'formly-field-instances',
  styleUrls: ['../../../styles.scss'],
  template: `
    <div *ngIf="to.options" class="form-group">
      <label *ngIf="_.get(field, 'templateOptions.label.length')">
        {{ _.get(field, 'templateOptions.label') }}
        <span *ngIf="_.get(field, 'templateOptions.required')">*</span>
      </label>
      <select
        class="form-control bg-white w-100"
        [formControl]="formControl"
        [formlyAttributes]="field"
        (change)="onChange($event)"
        [class.invalid]="formControl.status == 'INVALID'"
      >
        <option *ngFor="let item of to.options" disabled="{{ _.get(item, 'disabled') }}" [value]="_.get(item, 'value')">
          {{ _.get(item, 'label') }}
        </option>
      </select>
      <div *ngIf="showError" class="invalid-feedback d-block">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>
    </div>
  `,
})
export class FormlyFieldInstances extends FieldType implements OnInit {
  _ = _;
  constructor(private projectsService: ProjectsService, private creditAlertsService : CreditAlertsService) {
    super();
  }
  onChange(event) {
    this.creditAlertsService.updateCost.next(undefined)
  }
  ngOnInit() {
    const projects = this.field.parent.formControl.get('project_id');
    projects.valueChanges.subscribe((projectId) => {
      if (projectId) {
        this.to.options = [];

        this.projectsService.projectGet(projectId).subscribe((res) => {
          let options = _.reverse(_.get(res, 'instances')).map((el) => {
            if (el.status === 'done') {
              return {
                label: el.name ? el.name : moment.unix(_.get(el, 'date_of_creation', 0)).format('YYYY/MM/DD - HH:mm'),
                value: el.id,
              };
            }
          });
          options = _.without(options, undefined);
          options.unshift({ label: _.get(this, 'to.placeholder', 'Select'), value: '', disabled: true });
          this.to.options = options;
          this.formControl.reset()
          this.formControl.setValue('')
        });
      }
    });
  }
}
