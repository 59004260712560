<div class="knowledge-search-results-action-toolbar">
  <div ngbTooltip="Save" container="body">
    <button class="action-button" container="body" [ngbPopover]="saveContent" triggers="manual" #p="ngbPopover" (click)="p.open()" [autoClose]="'outside'"><i
        class="fa-regular fa-bookmark"></i></button>
  </div>
</div>

<ng-template #saveContent>
  <div class="d-flex flex-column">
    <div class="font-weight-bold mb-1">Project name</div>
    <textarea class="mb-2" row="3" cols="50" placeholder="Project name" [(ngModel)]="projectTitle"></textarea>
    <label *ngIf="hasCurrentProject()" class="label d-flex align-items-center">
      Create new project
      <input type="checkbox" (click)="toggleSaveMode()" class="ml-2" />
    </label>
    <button class="save-button" (click)="save(p)">{{ saveMode === 'new' ? 'Create new project' : 'Update project' }}</button>
  </div>

</ng-template>
