
<app-header2 [title]="PagesPublicName.LicenseManager"  [titleRedirection]="[PagesName.LicenseManager]" iconClass='fa-solid  fa-flip-horizontal fa-key-skeleton ' iconContainerStyle="display: inline-block; --fa-rotate-angle: -45deg;">
 
</app-header2>
<app-content class='bg-ivory px-5 scrollable' [isTab]="false" [isHeader]="true">
  <div class='w-100  d-flex flex-column p-5 '>
    <button (click)="openDetails()"
      class="smooth-border button mb-5 align-self-end btn text-center btn-primary text-light">
      <span class="cursor-info">Create license
      </span>
    </button>
    <div class="mb-3  d-flex justify-content-between align-items-end">
      <div class="font-weight-bold title text-dark">
        <span class=" text-uppercase">Licenses</span>
        <span class="badge ml-2 text-light badge-pill    badge-primary">{{_.get(licenses, 'value.length')}}</span>
      </div>
      <div class='d-flex flex-nowrap'>
        <app-sort-by class='mr-2 cursor-pointer' name="Company"
          [selected]="localService.getFromLocalStorage('LicensesSortByType') === SortType.CompanyName"
          [direction]="localService.getFromLocalStorage('LicensesSortByDirection')"
          (onClick)="handleSortBy(SortType.CompanyName, localService.getFromLocalStorage('LicensesSortByDirection'))">
        </app-sort-by>
        <app-sort-by class='mr-2 cursor-pointer' name="Type of license"
          [selected]="localService.getFromLocalStorage('LicensesSortByType') === SortType.Type"
          [direction]="localService.getFromLocalStorage('LicensesSortByDirection')"
          (onClick)="handleSortBy(SortType.Type, localService.getFromLocalStorage('LicensesSortByDirection'))">
        </app-sort-by>
        <app-sort-by class='mr-2 cursor-pointer' name="Status"
          [selected]="localService.getFromLocalStorage('LicensesSortByType') === SortType.Status"
          [direction]="localService.getFromLocalStorage('LicensesSortByDirection')"
          (onClick)="handleSortBy(SortType.Status, localService.getFromLocalStorage('LicensesSortByDirection'))">
        </app-sort-by>
      </div>

    </div>
    <table *ngIf='licenses.value' aria-describedby="table" class="table table-design-rounded w-100 mx-auto my-4 ">
      <thead class="table-header">
        <tr>
          <th *ngFor="let header of headers">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let el of licenses.value | slice: (page - 1) * pageSize:page * pageSize;">
          <td class="cursor-pointer" (click)="openDetails(el)">
            <div class="d-flex justify-content-end w-100">
              <mims-initials-bubble ngbTooltip="{{el.name}}" theme='dark' tooltipClass="custom-tooltip" placement="top"
                [fullName]='el.name'></mims-initials-bubble>
            </div>
          </td>
          <td class="cursor-pointer" (click)="openDetails(el)">
            {{_.get(el, 'name')}}
          </td>
          <td class="cursor-pointer text-capitalize" (click)="openDetails(el)">
            {{_.get(el, 'license_type')}}
          </td>
          <td class="cursor-pointer" (click)="openDetails(el)">
            {{_.get(el, 'credits')}}
          </td>
          <td class="cursor-pointer"  (click)="openDetails(el)">
            <span class="font-italic text-muted" *ngIf='_.get(el, "expiration_date") === 0'>
              {{'Not defined'}}
            </span>
            <span *ngIf='_.get(el, "expiration_date") !== 0'>
              {{moment(_.get(el, 'expiration_date') * 1000).format('YYYY/MM/DD')}}
            </span>
          </td>
          <td class="cursor-pointer" (click)="openDetails(el)">
            <i class="fa-solid fa-circle-small text-primary small mr-2" [class.text-danger]="isExpired(el)"></i>
            <span [class.text-danger]="isExpired(el)">
              {{_.get(el, 'status')}}
            </span>
          </td>
          <td class="d-flex justify-content-start">
            <div class="item-icons col-md-auto mx-0 px-0">
              <div class="dropdown pr-2">
                <button class="smooth-border btn btn-link border no-focus dropdown" type="button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  <i class=" far fa-ellipsis" aria-hidden="true"></i>
                </button>
                <div class="dropdown-menu  smooth-border dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                  <div>
                    <a (click)='handleDeleteLicense(el)' class="dropdown-item noselect">Delete</a>
                  </div>
                </div>
              </div>
            </div>
          </td>

        </tr>
      </tbody>
    </table>

    <ngb-pagination *ngIf="_.get(licenses, 'value.length',0) > pageSize"
      class="my-3 d-flex justify-content-center" [(page)]="page" [pageSize]="pageSize"
      [collectionSize]="licenses.value.length">
      <ng-template ngbPaginationPrevious><i class="far fa-chevron-left" aria-hidden="true"></i></ng-template>
      <ng-template ngbPaginationNext><i class="far fa-chevron-right" aria-hidden="true"></i> </ng-template>
    </ngb-pagination>
  </div>
</app-content>
