import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import * as _ from 'lodash';
import { ProjectsService } from 'src/app/api/projects.service';
import { LocalService } from 'src/app/api/local-storage.service';
import { environment } from 'src/environments/environment';

// This interceptor adds the access token to every http request but the login one.
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private localService: LocalService, private projectsService: ProjectsService) {}
  defineCountry(req) {
    let country;
    const user = this.localService.getFromLocalStorage('user');
    country = _.get(user, 'account_country');
    if (this.concernProjects(req) && !req.headers.has('country')) {
      country = this.projectsService.getProjectStorageCountry();
    }

  
    return country;
  }
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let finalRequest = req.clone();
    const session = this.localService.getFromLocalStorage('session');
    if (this.mustBeIntercepted(req)) {
      if (!_.get(req, 'withCredentials')) {
        let country;
        if (req.headers.has('country')) {
          country = req.headers.get('country');
          req = req.clone({ headers: req.headers.delete('country') });
        } else {
          country = this.defineCountry(req);
        }
        let finalURL = req.url.replace('XXXX', this.localService.getSpecificCountryUrl(country));
        finalRequest = req.clone({
          headers: req.headers.append('X-Access-Token', this.localService.getSpecificCountryAccessToken(country)),
          url: finalURL,
        });
      } else if (_.get(req, 'withCredentials') && session) {
        let finalURL = req.url.replace('XXXX', session.apiPath);
        finalRequest = req.clone({
          headers: req.headers.append('X-Access-Token', session.accessToken),
          url: finalURL,
        });
      } else if (_.get(req, 'withCredentials') && !session) {
        let finalURL = req.url.replace('XXXX', environment.apiBasePath);
        finalRequest = req.clone({
          url: finalURL,
        });
      }
    }

    return next.handle(finalRequest).pipe(
      catchError((err, caught: Observable<HttpEvent<any>>) => {
        if (err instanceof HttpErrorResponse && err.status === 403) {
          if (err.error.message === 'invalid access token') {
            localStorage.clear();
            window.location.reload();
          }
          return throwError(err);
        }
        throw err;
      })
    );
  }
  mustBeIntercepted(req) {
    return !req.url.includes('/last_deployed.txt');
  }
  concernProjects(req) {
    return (
      (req.url.includes('/fs/') &&
        req.url.includes('projects') &&
        !req.url.includes('filegroups') &&
        !req.url.includes('usergroups') &&
        !req.url.includes('litt-research')) ||
      req.url.includes('/dashboard/') ||
      req.url.includes('/dashboard-interactions/') ||
      (window.location.href.includes('/project-creation/') && req.url.includes('workflow'))
    );
  }

}
