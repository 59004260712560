<div class='h-100 w-100 d-flex justify-content-around flex-column'>
  <div class="login-title w-100"></div>
  <form *ngIf="!passwordResetMailSent" [formGroup]="getCurrentForm()">
    <div *ngIf="showPasswordResetForm">
      <br />
      <p><strong>Forgot your password?</strong></p>
      <p>That's okay, enter your email address below and click on "Reset Password".</p>
    </div>
    <app-input-field *ngIf="!showPasswordResetForm" class='my-2' [backendError]='backendError' (valueSelected)='fieldIsEdited()'
      [parentForm]='getCurrentForm()' [inputJson]='emailField'></app-input-field>
      <app-input-field *ngIf="showPasswordResetForm" class='my-2' [backendError]='backendError' (valueSelected)='fieldIsEdited()'
      [parentForm]='getCurrentForm()' [inputJson]='emailField'></app-input-field>
    <app-input-field *ngIf="!showPasswordResetForm" class='my-2' [backendError]='backendError' (valueSelected)='fieldIsEdited()'
      [parentForm]='getCurrentForm()' [inputJson]='passwordField'></app-input-field>

    <div class='w-100 d-flex flex-nowrap align-items-center'>
      <div class='flex-fill'>
        <div class='text-danger '>{{backendError ? backendError : '&nbsp;'}}</div>
        <a *ngIf="!showPasswordResetForm" href="login#" (click)='showResetPasswordForm()'>Forgot password?</a>
        <a *ngIf="showPasswordResetForm" href="login#" (click)='showPasswordResetForm=false'>Back</a>
      </div>
      <div *ngIf="!showPasswordResetForm" (click)='isFormValid()? login() : ""' id='login' ngbTooltip="{{!isFormValid() ? 'Verify your form' : ''}}"
        tooltipClass="custom-tooltip" placement="right" [class.disabled]="!isFormValid()" tabindex='0'
        class="btn btn-dark login-btn">Login</div>
        <div *ngIf="showPasswordResetForm" (click)='isFormValid()? resetPassword() : ""' ngbTooltip="{{!isFormValid() ? 'Verify your form' : ''}}"
        tooltipClass="custom-tooltip" placement="right" [class.disabled]="!isFormValid()" tabindex='0'
        class="btn btn-dark login-btn">Reset Password</div>
    </div>
  </form>
  <div *ngIf="passwordResetMailSent">
    <br />
    <p><strong>Request reveived!</strong></p>
    <p>Check your email inbox for password reset instructions.</p>
    <p><a *ngIf="showPasswordResetForm" href="login#" (click)='showPasswordResetForm=false;passwordResetMailSent=false'>Back to login</a></p>
  </div>
</div>
