<div class="modal-header">
  <h4 class="modal-title">
    Reset Password
  </h4>
</div>
<form [formGroup]="resetPasswordForm" (ngSubmit)="submitForm()">
  <div class="modal-body">
    <div class="input-icon-container">
      <div class="input-container">
        <label>Password: </label>
        <input type="password" formControlName="password" />
      </div>
      <div *ngIf="!password.valid && (password.dirty || password.touched)" class="icon-container password-error">
        <span *ngIf="password.hasError('required')" class="tooltiptext">This field is required</span>
        <span *ngIf="password.hasError('minlength')" class="tooltiptext">Minimum 8 characters</span>
        <span *ngIf="password.hasError('shouldNotHaveSpaces')" class="tooltiptext">May not contain spaces</span>
        <span *ngIf="
            password.hasError('noSpecialCharacter') &&
            !password.hasError('minlength') &&
            !password.hasError('required')
          " class="tooltiptext">Must contain symbol (@#$%)</span>
        <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
      </div>
    </div>
    <div class="input-icon-container">
      <div class="input-container">
        <label>Confirm Password: </label>
        <input type="password" formControlName="confirm" />
      </div>
      <div *ngIf="
          resetPasswordForm.hasError('mustMatch') &&
          (confirm.dirty || confirm.touched)
        " class="icon-container confirm-error">
        <span class="tooltiptext">Passwords must match</span>
        <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()">Cancel</button>
    <button [disabled]="!resetPasswordForm.valid">
      Submit New Password
    </button>
  </div>
</form>