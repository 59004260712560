<div class="scroll-container" tabindex="-1" #scrollContainer>
  <mims-loader *ngIf='!isDownloaded.value' [isLoading]='!isDownloaded.value && !inProgress'></mims-loader>
  <div *ngIf="error">
    <div class="my-3 text-center">
      <i class="far fa-exclamation-circle" aria-hidden="true"></i>
      Process is done. Impossible to get the dashboard.
    </div>
  </div>
  <div *ngIf="inProgress && !error">
    <div class="my-3 text-center">
      <i class="far fa-exclamation-circle" aria-hidden="true"></i>
      Process is in progress.
    </div>
  </div>
  <div *ngIf="!error && !inProgress" class="mt-5 flex-wrap justify-content-center w-100 h-100 d-flex">

    <section *ngFor='let el of sectionArray; let index = index' (deferLoad)="el.show = true" class="dynamic"
      [class.thin]="_.get(el, 'layout', Layouts.Wide) === Layouts.Thin" [class.small]="_.get(el, 'layout', Layouts.Wide) === Layouts.Small">

      <div class='viz-container m-auto ' [class.viz-container-full-size]="_.get(el, 'layout', Layouts.Wide) !== Layouts.Wide">
        <app-viz2 *ngIf="el.show" [country]='country' [graphId]='el.graphId'
          (webGlContexts)='[handleWebGlContexts($event),updateScatterGlPool()]' [version]='version'
          (showChildren)='[handleShowChildren($event),updateScatterGlPool()]' [projectTitle]="projectTitle"
          [projectId]='projectId' [dashboardId]='dashboardId' (parentLayout)='handleSectionChange($event, index)'
          [dashboardTitle]="dashboardTitle"></app-viz2>
      </div>
    </section>
  </div>
</div>
