<ul class="list-unstyled my-3 ">
  <li class='my-1' *ngFor='let el of uploadingFiles'>
    <div class='d-flex flex-nowrap align-items-center' >
      <span class='progressPercent mx-2'> ({{el.reportChunkProgress}}%) </span>
      <span class='file-name font-weight-bold'> {{el.title}}</span>
      <div *ngIf='el.status === Status.NotStarted || el.status === Status.Pending || el.status === Status.Uploading' class="mx-3 spinner-border text-primary text-muted smallSpinner " role="status"></div>
      <i *ngIf='el.status === Status.Error' class="far mx-3 text-danger fa-exclamation-triangle" aria-hidden="true"></i>
      <i *ngIf='el.status === Status.WaitingForRefresh' (click)="handleOpenFolder(el)" aria-hidden='true' ngbTooltip="Open containing folder" tooltipClass="custom-tooltip"
      placement="bottom" class="far btn text-primary fa-folder-open"></i>
    </div>
  </li>
</ul>
