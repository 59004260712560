import { Component, OnInit, Input } from '@angular/core';
import { VizType } from 'src/app/shared/helpers/vizType';
import * as _ from 'lodash';

@Component({
  selector: 'app-dashboard-content',
  templateUrl: './dashboard-content.component.html',
  styleUrls: ['./dashboard-content.component.scss'],
})
export class DashboardContentComponent implements OnInit {
  dashboardJson = null;
  visualizations: Array<any>;
  VizType = VizType;


  ngOnInit(): void {
  }



}
