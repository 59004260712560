import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import {
  AdditionalTypes,
  InputJson,
  IsRequired,
  MaxLength128,
  ShouldBeADigit,
  ShouldNotContainDoubleQuote,
} from '../../helpers/formattedInput';
import * as _ from 'lodash';
import { RawInputTypes } from 'src/app/api/projects.service';

var fieldObjects: Array<InputJson> = [
  {
    formControlName: '0',
    type: AdditionalTypes.Object,
    description: 'multiselect',
    fitToContent: false,
    is_array: true,
    multiple_selection: true,
    retrievedValue: new BehaviorSubject<any>(undefined),
    retrievedAllowedValues: new BehaviorSubject<any>(undefined),
    max_select_height: 3,
    showIcon: 'fas fa-info-circle',
    validations: [Validators.required],
  },
  {
    formControlName: '1',
    type: AdditionalTypes.Object,
    description: 'single select',
    fitToContent: false,
    is_array: true,
    multiple_selection: false,
    retrievedAllowedValues: new BehaviorSubject<any>(undefined),
    retrievedValue: new BehaviorSubject<any>(undefined),
    showIcon: 'fas fa-info-circle',
    validations: [Validators.required],
  },
  {
    formControlName: '2',
    placeholder: 'describe your project',
    type: AdditionalTypes.Paragraph,
    description: 'textarea',
    fitToContent: false,
    showIcon: 'fas fa-info-circle',
    validations: [Validators.required],
    retrievedValue: new BehaviorSubject<any>(undefined),
  },
  {
    formControlName: '3',
    placeholder: 'enter your title',
    type: AdditionalTypes.Title,
    description: 'title',
    fitToContent: false,
    showIcon: 'fas fa-info-circle',
    validations: [Validators.required],
    retrievedValue: new BehaviorSubject<any>(undefined),
  },
  {
    formControlName: '4',
    type: AdditionalTypes.ShortText,
    placeholder: 'Inline short text',
    lined_label: 'Terms (between 2 and 5)',
    showIcon: 'far fa-info-circle',
    validations: [Validators.required, ShouldNotContainDoubleQuote, MaxLength128],
    fitToContent: true,
    retrievedValue: new BehaviorSubject<any>(undefined),
  },
  {
    formControlName: '5',
    type: AdditionalTypes.ShortText,
    placeholder: 'term 1',
    description: 'short text',
    showIcon: 'far fa-info-circle',
    validations: [Validators.required, ShouldNotContainDoubleQuote, MaxLength128],
    fitToContent: true,
    retrievedValue: new BehaviorSubject<any>(undefined),
  },
  {
    formControlName: '6',
    type: AdditionalTypes.Password,
    placeholder: '',
    description: 'password',
    showIcon: 'far fa-info-circle',
    validations: [Validators.required],
    fitToContent: true,
    retrievedValue: new BehaviorSubject<any>(undefined),
  },
  {
    formControlName: '7',
    type: RawInputTypes.Bool,
    is_array: true,
    allowed_values: ['test1', 'test2', 'test3'],
    allowed_values_descriptions: ['test 1', 'test 2', 'test 3'],
    multiple_selection: true,
    description: 'checkboxes',
    showIcon: 'far fa-info-circle',
    fitToContent: true,
    retrievedValue: new BehaviorSubject<any>(undefined),
  },
  {
    formControlName: '8',
    type: RawInputTypes.Float,
    is_array: true,
    allowed_values: [0.1, 0.2, 0.3],
    allowed_values_descriptions: ['0.1 g', '0.2 g', '0.3 g'],
    multiple_selection: false,
    description: 'radio',
    showIcon: 'far fa-info-circle',
    validations: [Validators.required],
    fitToContent: true,
    retrievedValue: new BehaviorSubject<any>(undefined),
  },
  {
    formControlName: '9',
    type: AdditionalTypes.File,
    description: 'Select files',
    fitToContent: true,
    multiple_selection: true,
  },
  {
    formControlName: '10',
    type: RawInputTypes.Bool,
    is_array: false,
    multiple_selection: false,
    description: 'checkbox',
    showIcon: 'far fa-info-circle',
    validations: [Validators.required],
    fitToContent: true,
    retrievedValue: new BehaviorSubject<any>(undefined),
  },
  {
    formControlName: '11',
    type: RawInputTypes.Float,
    is_array: true,
    allowed_values: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7],
    allowed_values_descriptions: ['0.1 g', '0.2 g', '0.3 g', '0.4 g', '0.5 g', '0.6 g', '0.7 g'],
    multiple_selection: false,
    description: 'select float',
    showIcon: 'far fa-info-circle',
    validations: [Validators.required],
    fitToContent: true,
    retrievedValue: new BehaviorSubject<any>(undefined),
  },
  {
    formControlName: '12',
    type: RawInputTypes.String,
    useSelect: false,
    is_array: true,
    allowed_values: ['0.1 g', '0.2 g', '0.3 g', '0.4 g', '0.5 g', '0.6 g', '0.7 g'],
    allowed_values_descriptions: ['0.1 g', '0.2 g', '0.3 g', '0.4 g', '0.5 g', '0.6 g', '0.7 g'],
    multiple_selection: true,
    description: 'force checkbox',
    showIcon: 'far fa-info-circle',
    validations: [Validators.required],
    fitToContent: true,
    retrievedValue: new BehaviorSubject<any>(undefined),
  },
  {
    formControlName: '13',
    type: RawInputTypes.String,
    useSelect: true,
    is_array: true,
    allowed_values: ['0.1g', '0.2g', '0.3g'],
    allowed_values_descriptions: ['0.1 g', '0.2 g', '0.3 g'],
    multiple_selection: true,
    description: 'force multiselect',
    showIcon: 'far fa-info-circle',
    validations: [Validators.required],
    fitToContent: true,
    retrievedValue: new BehaviorSubject<any>(undefined),
    max_select_height: 3,
  },
  {
    formControlName: '14',
    type: RawInputTypes.String,
    useSelect: true,
    is_array: true,
    allowed_values: ['0.1g', '0.2g', '0.3g'],
    allowed_values_descriptions: ['0.1 g', '0.2 g', '0.3 g'],
    multiple_selection: false,
    description: 'force select',
    showIcon: 'far fa-info-circle',
    validations: [Validators.required],
    fitToContent: true,
    retrievedValue: new BehaviorSubject<any>(undefined),
  },
  {
    formControlName: 'digit',
    type: RawInputTypes.String,
    charMax: 1,
    fitToContent: true,
    placeholder: '',
    validations: [IsRequired, ShouldBeADigit],
  },
];

function createForm(fieldObjects) {
  let form: UntypedFormGroup = new UntypedFormGroup({});
  fieldObjects.forEach((el) => {
    form.addControl(el.formControlName, new UntypedFormControl());
  });
  return form;
}

@Component({
  selector: 'app-input-field-test',
  templateUrl: './input-field-test.component.html',
  styleUrls: ['./input-field-test.component.scss'],
})
export class InputFieldTestComponent {
  _ = _;
  form = createForm(fieldObjects);
  fieldObjects = fieldObjects;
  JSON = JSON;
  userInteraction = undefined;
  dataIsDownloaded = false;
  options = [
    { name: 'option1', value: 1 },
    { name: 'option2', value: 2 },
    { name: 'option3', value: 3 },
    { name: 'option4', value: 4 },
  ];
  onCheck(formControlName) {
    this.userInteraction = formControlName;
    setTimeout(() => {
      this.userInteraction = undefined;
    }, 1000);
  }
  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (key === 'retrievedValue') {
        return _.get(value, 'value', 'undefined');
      }
      if (key === 'retrievedAllowedValues') {
        return _.get(value, 'value', 'undefined');
      }
      if (key === 'validations') {
        return value.map((func) => func.name);
      }
      return value;
    };
  };
  simulateDataDownload() {
    if (this.dataIsDownloaded) return;
    fieldObjects[0].retrievedAllowedValues.next(this.options);
    fieldObjects[1].retrievedAllowedValues.next(this.options);
    this.dataIsDownloaded = true;
  }
  simulateDataSelection() {
    if (!this.dataIsDownloaded) return;
    this.getFieldObject('0').retrievedValue.next([this.options[0], this.options[2]]);
    this.getFieldObject('1').retrievedValue.next(this.options[1]);
    this.getFieldObject('2').retrievedValue.next('Lorem ipsum dolor sit amet, consectetur adipiscing elit');
    this.getFieldObject('3').retrievedValue.next('Lorem ipsum');
    this.getFieldObject('4').retrievedValue.next('Lorem ipsum');
    this.getFieldObject('5').retrievedValue.next('Lorem ipsum');
    this.getFieldObject('6').retrievedValue.next('1234');
    this.getFieldObject('7').retrievedValue.next(['test1', 'test2']);
    this.getFieldObject('8').retrievedValue.next(0.2);
    this.getFieldObject('10').retrievedValue.next(true);
    this.getFieldObject('11').retrievedValue.next(0.2);
    this.getFieldObject('12').retrievedValue.next('0.2 g');
    this.getFieldObject('13').retrievedValue.next(['0.2g', '0.3g']);
    this.getFieldObject('14').retrievedValue.next('0.2g');
  }
  getFieldObject(name) {
    return _.find(fieldObjects, (o) => {
      return o.formControlName === name;
    });
  }
  isFormValid(form) {
    return _.get(form, 'status') === 'VALID';
  }
}
