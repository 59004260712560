import { Component, OnInit, Input } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-explorer-full-response',
  templateUrl: './explorer-full-response.component.html',
  styleUrls: ['./explorer-full-response.component.scss'],
})
export class ExplorerFullResponseComponent implements OnInit {
  @Input() results;
  @Input() score;
  @Input() focus;
  @Input() unused;
  @Input() date;
  public moment = moment;
  public itemPluralMapping = {
    response: {
      '=0': 'No answer available for this question.',
      '=1': 'Response ',
      other: 'Responses ',
    },
  };

  ngOnInit() {
    this.date = this.fixDate(this.date);
  }

  fixDate = (date) => {
    return date < moment() ? date : moment();
  };
}
