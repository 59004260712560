import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-viz-sequence-thumbnail',
  templateUrl: './viz-sequence-thumbnail.component.html',
  styleUrls: ['./viz-sequence.scss', '../viz-style.scss'],
})
export class VizSequenceThumbnailComponent implements OnInit {
  @Input() data;
  @Input() layout;
  @Input() size;
  @Input() title;


  ngOnInit(): void {}
  setTextLayoutAutoBr(values) {
    let text = '';
    let j = 0;
    if (values.length) {
      for (j; j < values.length; j = j + 59) {
        text = text + values.slice(j, j + 59) + '<br>';
      }
    }
    return text;
  }
}
