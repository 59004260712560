<app-custom-modal
  modalTitle="Create Cohort"
  idName="modalGroup"
  [show]="l.isGroupModalOpen"
  confirmTitle="Save"
  (confirmEvent)="saveNewGroup()"
  [confirmValidator]="
    (groupsManagerService.groupCreationForm?.controls?.groupInput
      ? groupsManagerService.groupCreationForm.controls.groupInput.valid
      : false) && patientCount > 0
  "
  (cancelEvent)="groupsManagerService.resetGroupCreationForm()"
  *ngIf="!isExploringOnly && !isUsingGuidedUserFlow"
>
  <div class="p-0" style="padding: 0">
    <form style="width: 500px" [formGroup]="groupsManagerService.groupCreationForm" class="d-flex flex-column p-0">
      <div>
        <input class="form-control" id="groupInput" type="text" formControlName="groupInput" placeholder="Enter Cohort Name" />
      </div>
      <span class="correct" *ngIf="groupInput.valid && (groupInput.dirty || groupInput.touched)">&nbsp; &#10003;</span>
      <span class="incorrect" *ngIf="!groupInput.valid && (groupInput.dirty || groupInput.touched)">&nbsp; &#10005; This field is required</span>

      <mims-loader id="left-loader" [isLoading]="leftSideIsLoading" [enableBackDrop]="true"></mims-loader>
      <mims-loader id="right-loader" [isLoading]="rightSideIsLoading && rightSideHasBeenClicked" [enableBackDrop]="true"></mims-loader>
      <table>
        <td>
          <table id="by-metadata" role="presentation">
            <tr>
              <td>Disease</td>
              <td>
                <label>Select Values (OR)</label>
                <select style="padding: 0.3125rem" multiple size="4" (change)="onValueSelect(0, $event)">
                  <option *ngFor="let opt of values[0]" [ngValue]="opt.value" [selected]="opt.selected">
                    {{ opt.value }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td>
                DataType
                <mims-select style="visibility: hidden"></mims-select>
                <br /><br /><br />
              </td>
              <td>
                <label>Select Required Values (AND)</label>
                <select style="padding: 0.3125rem" multiple size="4" (change)="onValueSelect(1, $event)">
                  <option *ngFor="let opt of values[1]" [ngValue]="opt.value" [selected]="opt.selected">
                    {{ opt.value }}
                  </option>
                </select>
                <label>Select Optional Values</label>
                <select style="padding: 0.3125rem" multiple size="4" (change)="onValueSelect(2, $event)">
                  <option *ngFor="let opt of values[2]" [ngValue]="opt.value" [selected]="opt.selected">
                    {{ opt.value }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <p>Number of patients : <br />Number of files :</p>
              </td>
              <td>
                <p>
                  <strong *ngIf="patientCount >= 0">{{ patientCount }}</strong>
                  <strong *ngIf="patientCount == -1">Loading...</strong>
                  <br />
                  <strong *ngIf="filesToAppend?.length > 0 && patientCount != -1">{{ filesToAppend.length }}</strong>
                </p>
              </td>
            </tr>
          </table>
        </td>
      </table>
    </form>
  </div>
</app-custom-modal>

<app-custom-modal
  modalTitle="Create Cohort"
  idName="modalGroup"
  [show]="l.isGroupModalOpen"
  confirmTitle="Save"
  (confirmEvent)="saveNewGroup()"
  [confirmValidator]="
    (groupsManagerService.groupCreationForm?.controls?.groupInput
      ? groupsManagerService.groupCreationForm.controls.groupInput.valid
      : false) && patientCount > 0
  "
  (cancelEvent)="groupsManagerService.resetGroupCreationForm()"
  *ngIf="!isExploringOnly && isUsingGuidedUserFlow"
>
  <div class="p-0 d-flex flex-column">
    <form class="d-flex flex-column" [formGroup]="groupsManagerService.groupCreationForm">
      <div class="name-display">
        <mims-loader id="left-loader" [isLoading]="leftSideIsLoading" [enableBackDrop]="true"></mims-loader>
        <input id="groupInput" type="text" formControlName="groupInput" class="border" placeholder="Enter Cohort Name" />
        <span class="correct" *ngIf="groupInput.valid && (groupInput.dirty || groupInput.touched)">&nbsp; &#10003;</span>
        <span div class="incorrect" *ngIf="!groupInput.valid && (groupInput.dirty || groupInput.touched)">&nbsp; &#10005; This field is required</span>  
      </div>
      <br />
       </form>
    <label>Indication</label>
    <ngx-bootstrap-multiselect
      name="indication"
      [options]="values[0]"
      [settings]="selectConfig"
      [(ngModel)]="selection[0]"
      (ngModelChange)="onSelectionChange()"
    ></ngx-bootstrap-multiselect>
    <br />
    <label>ICD-10 Code</label>
    <ngx-bootstrap-multiselect
      name="code"
      [options]="values[3]"
      [settings]="selectConfig"
      [(ngModel)]="selection[3]"
      (ngModelChange)="onSelectionChange()"
    ></ngx-bootstrap-multiselect>
    <br />
    <label>Age</label>
    <ngx-bootstrap-multiselect
      name="age"
      [options]="values[1]"
      [settings]="selectConfig"
      [(ngModel)]="selection[1]"
      (ngModelChange)="onSelectionChange()"
    ></ngx-bootstrap-multiselect>
    <br />
    <label>Sex</label>
    <ngx-bootstrap-multiselect
      name="gender"
      [options]="values[2]"
      [settings]="{ dynamicTitleMaxItems: 1, showUncheckAll: true }"
      [(ngModel)]="selection[2]"
      (ngModelChange)="onSelectionChange()"
    ></ngx-bootstrap-multiselect>
    <br />
    <div class="patient-display">
      <span *ngIf="patientCount == 0"><strong>0 patients</strong> selected</span>
      <span *ngIf="patientCount >= 1"
        ><strong>{{ patientCount }} patients</strong> selected</span
      >
      <app-spinner *ngIf="patientCount == -1"></app-spinner>
    </div>
  </div>
</app-custom-modal>

<div class="explore" *ngIf="isExploringOnly">
  <div style="float: right; font-size: 1.25em">
    <span *ngIf="patientCount == 0"><strong>0 patients</strong> selected</span>
    <span *ngIf="patientCount >= 1"
      ><strong>{{ patientCount }} patients</strong> selected</span
    >
    <app-spinner *ngIf="patientCount == -1"></app-spinner>
  </div>
  <h2>Explore Patients</h2>
  <br /><br />
  <form class="d-flex flex-column p-0">
    <mims-loader id="left-loader" [isLoading]="leftSideIsLoading" [enableBackDrop]="true"></mims-loader>
    <label>Indication</label>
    <ngx-bootstrap-multiselect
      name="indication"
      [options]="values[0]"
      [settings]="selectConfig"
      [(ngModel)]="selection[0]"
      (ngModelChange)="onSelectionChange()"
    ></ngx-bootstrap-multiselect>
    <br />
    <label>ICD-10 Code</label>
    <ngx-bootstrap-multiselect
      name="code"
      [options]="values[3]"
      [settings]="selectConfig"
      [(ngModel)]="selection[3]"
      (ngModelChange)="onSelectionChange()"
    ></ngx-bootstrap-multiselect>
    <br />
    <label>Age</label>
    <ngx-bootstrap-multiselect
      name="age"
      [options]="values[1]"
      [settings]="selectConfig"
      [(ngModel)]="selection[1]"
      (ngModelChange)="onSelectionChange()"
    ></ngx-bootstrap-multiselect>
    <br />
    <label>Sex</label>
    <ngx-bootstrap-multiselect
      name="gender"
      [options]="values[2]"
      [settings]="{ dynamicTitleMaxItems: 1, showUncheckAll: true }"
      [(ngModel)]="selection[2]"
      (ngModelChange)="onSelectionChange()"
    ></ngx-bootstrap-multiselect>
    <br />
  </form>
</div>
