import { Pipe } from '@angular/core';

@Pipe({ name: 'formatNumber' })
export class FormatNumberPipe {
  transform(value): string {
    let nf = new Intl.NumberFormat('en-US', {maximumFractionDigits:20});
    return  nf.format(value);
  }
}
@Pipe({ name: 'formatCell' })
export class FormatCellPipe {
  transform(value) {
    if (typeof value === 'number' ) {
      return new FormatNumberPipe().transform(value);
    }
    else {
      return value;
    }
  }
}
