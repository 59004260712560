import { AfterViewInit, Component, HostListener } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RawInputTypes } from 'src/app/api/projects.service';
import { InputJson, IsRequired, ShouldBeADigit } from 'src/app/shared/helpers/formattedInput';
import * as _ from 'lodash';
import { LoginHttpService } from '../login-http-service/login-http.service';
import { LocalService } from 'src/app/api/local-storage.service';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { Router } from '@angular/router';

@Component({
  selector: 'app-second-factor',
  templateUrl: './second-factor.component.html',
  styleUrls: ['./second-factor.component.scss'],
})
export class SecondFactorComponent implements AfterViewInit {
  digitFields: Array<InputJson> = [];
  digitForm: UntypedFormGroup;
  backendError = undefined;
  userIsVerified = false;
  constructor(private loginHttp: LoginHttpService, private localService: LocalService, private router: Router) {

    ['digit1', 'digit2', 'digit3', 'digit4', 'digit5', 'digit6'].forEach((el) => {
      this.digitFields.push({
        formControlName: el,
        type: RawInputTypes.String,
        charMax: 1,
        fitToContent: true,
        placeholder: '',
        validations: [IsRequired, ShouldBeADigit],
      });
    });
        
    this.digitForm = this.createForm(this.digitFields)
  }
  createForm(fieldObjects) {
    let form: UntypedFormGroup = new UntypedFormGroup({});
    fieldObjects.forEach((el) => {
      form.addControl(el.formControlName, new UntypedFormControl());
    });
    return form;
  }
  ngAfterViewInit(): void {
    if (document.getElementById('digit1')) document.getElementById('digit1').focus();
  }
  digitsFormIsValid() {
    return this.digitForm.status === 'VALID' && !this.backendError;
  }
  triggerAutoTab(event, index) {
    this.backendError = undefined;
    if (_.get(event, 'key') < 10 && _.get(event, 'key') >= 0) {
      this.autoTab(index);
    }
  }
  reset() {
    this.digitForm.reset();
    this.backendError = undefined;
    this.digitForm.markAsPristine();
    this.digitForm.markAsUntouched();
    this.digitForm.updateValueAndValidity();
  }

  autoTab(index) {
    if (!this.digitForm.controls['digit' + (index + 1)].valid) {
      return;
    }
    let el = document.getElementById('digit' + (index + 2));
    if (el) {
      el.focus();
    } else {
      if (this.digitsFormIsValid()) this.verify();
    }
  }
  @HostListener('document:keydown', ['$event'])
  keyboardListener(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.digitsFormIsValid()) this.verify();
    if (event.key === 'Backspace') {
      let elementId = _.get(document.activeElement, 'id');
      let index = Number(elementId.slice(-1));
      if (this.digitForm.controls[elementId].value === '' && document.getElementById('digit' + (index - 1))) {
        document.getElementById('digit' + (index - 1)).focus();
      }
    }
  }
  previous() {
    if (this.loginHttp.qrCodeImage) {
      this.loginHttp.activeStep = 1;
    } else this.loginHttp.activeStep = 0;
  }

  verify() {
    let secondFactor = '';
    Object.values(this.digitForm.controls).forEach((res) => {
      secondFactor = secondFactor + res.value;
    });
    this.loginHttp.validateSecondFactor(secondFactor).subscribe(
      (res) => {
        this.userIsVerified = true;
        this.localService.editLocalStorage('session', 'accessToken', res.access_token);
        this.loginHttp.verifyAccess().subscribe(
          (res) => {
            this.loginHttp.getUser(_.get(res, 'user_id'), true).subscribe(
              (response) => {
                this.loginHttp.setUserSessionData(response)
                setTimeout(() => { this.router.navigate([PagesName.Home]); }, 500)
              },
              () => {
                this.backendError = 'User not found';
              }
            );
          },
          () => {
            this.backendError = 'Access denied';
          }
        );
      },
      (error) => {
        const code = _.get(error, 'error.code');
        const message = _.get(error, 'error.message');
        if (message === 'Invalid first factor token') {
          this.loginHttp.activeStep = 0;
          return;
        }
        if (message === 'Invalid factor') {
          this.backendError = 'Invalid number';
          return;
        }
        if (code === 500) {
          this.backendError = 'Internal Error';
        }
      }
    );
  }
}
