import { Component, OnInit, Injectable, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
import { ProjectsService } from 'src/app/api/projects.service';
import { LocalService } from 'src/app/api/local-storage.service';
import { ActivatedRoute } from '@angular/router';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import * as _ from 'lodash';

export interface RunElement {
  key: string;
  title: string;
  description: string;
  wf_name: string;
  wf_version: string;
  email: string;
  start: string;
  end: string;
  status: string;
  shared: boolean;
  env: string;
}

@Component({
  selector: 'runs-dashboard',
  styleUrls: ['../../shared/layout/managers.scss', './runs-dashboard.component.scss'],
  templateUrl: './runs-dashboard.component.html',
})

@Injectable({
  // declares that this service should be created
  // by the root application injector.
  providedIn: 'root',
})

export class RunsDashboardComponent implements OnInit, AfterViewInit{
  PagesPublicName = PagesPublicName;

  runs = [];

  @ViewChild('runscontainer')
  runscontainer: ElementRef;

  constructor(
    private projectsService:  ProjectsService,
    private localService: LocalService,
    private route: ActivatedRoute,
    public layout: LayoutOrganizationService
  ) {
    if (this.route.snapshot.paramMap.get('country')) {
      _.set(this.projectsService, 'projectStorageCountry', this.route.snapshot.paramMap.get('country'));
    } else {
      _.set(this.projectsService, 'projectStorageCountry', this.localService.getFromLocalStorage('user', 'account_country'));
    }

  }

  ngOnInit() {
    this.projectsService.getlistAllRuns().subscribe((runs) => {
      this.runs = runs     
    });
  }

  private width;
  ngAfterViewInit() {
    this.width = this.runscontainer.nativeElement.offsetWidth;
  }

  graphwf;
  openModal(run, shared) {
    if (!shared) {
      return;
    }

    if (run == undefined) {
      return;
    }

    this.projectsService.getProjectStatus(run).subscribe((graph) => {
      this.graphwf = graph
      this.layout.open('modalProgress');
    });
  }

  getCurrent() {
    return this.graphwf;
  }

  getWidth() {
    return Math.floor(this.width * 0.8);
  }

  getHeight() {
    return Math.floor(window.innerHeight * 0.7);
  }

  columns = [
    {
      columnDef: 'title',
      header: 'Project Name',
      cell: (element: RunElement) => `${element.title}`,
    },
    {
      columnDef: 'description',
      header: 'Description',
      cell: (element: RunElement) => `${element.title}`,
    },
    {
      columnDef: 'wf_name',
      header: 'Workflow Name',
      cell: (element: RunElement) => `${element.wf_name}`,
    },
    {
      columnDef: 'wf_version',
      header: 'Version',
      cell: (element: RunElement) => `${element.wf_version}`,
    },
    {
      columnDef: 'email',
      header: 'Email',
      cell: (element: RunElement) => `${element.email}`,
    },
    {
      columnDef: 'start',
      header: 'Start',
      cell: (element: RunElement) => `${element.start}`,
    },
    {
      columnDef: 'end',
      header: 'End',
      cell: (element: RunElement) => `${element.end}`,
    },
    {
      columnDef: 'progress',
      header: 'Progress',
    },
    {
      columnDef: 'status',
      header: 'Status',
      cell: (element: RunElement) => `${element.status}`,
    },
  ];


}



