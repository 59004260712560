import { Component, Input } from '@angular/core';
import { ExplorerManagerService } from '../../../../api/explorer-manager.service';
import moment from 'moment';

@Component({
  selector: 'app-slide-short-list-answers',
  templateUrl: './slide-short-list-answers.component.html',
  styleUrls: ['./slide-short-list-answers.component.scss'],
})
export class SlideShortListAnswersComponent {
  public moment = moment;
  @Input() answers;
  constructor(public e: ExplorerManagerService) {}
}
