<label *ngIf="label">{{ label }}</label>
<div class="input-container" #overlayOrigin="cdkOverlayOrigin" cdkOverlayOrigin>
  <input
    readonly
    [id]="id"
    type="text"
    autocomplete="off"
    [value]="selectedOption?.label || selectedOption?.value || ''"
    [placeholder]="placeholder"
  />
  <i *ngIf="!opened" class="fas fa-chevron-right arrow" aria-hidden="true"></i>
  <i *ngIf="opened" class="fas fa-chevron-down arrow" aria-hidden="true"></i>
</div>
<mims-dropdown
  #dropdown
  [minWidth]="listWidth"
  [width]="listWidth"
  [overlayOrigin]="overlayOrigin"
  [opened]="opened"
  (toggled)="onDropdownToggled($event)"
>
  <mims-dropdown-list
    [dataSource]="dataSource"
    [selectedOption]="selectedOption"
    [maxOptionsVisibleInList]="maxOptionsVisibleInList"
    [optionHeight]="optionHeight"
    (optionSelected)="onOptionSelected($event)"
  ></mims-dropdown-list>
</mims-dropdown>
