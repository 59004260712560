import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ItemType } from 'src/app/shared/helpers/itemType';
import _ from 'lodash';
import { dataItemAction } from '../data-manager.service';
@Component({
  selector: 'app-data-item',
  templateUrl: './data-item.component.html',
  styleUrls: ['./data-item.component.scss']
})
export class DataItemComponent  {
  @Input() item: any;
  @Input() selected; 
   @Input() type;

  @Input() highlighted?;
  mouseOvered;
  @Output() itemEvent = new EventEmitter<any>();
  ItemType = ItemType;

  constructor() {}

  share() {
    this.itemEvent.emit({item: this.item, itemAction: dataItemAction.SelectShare});
  }
  getInfos() {
    this.itemEvent.emit({item: this.item, itemAction: dataItemAction.Info});
  }
  deletedPermanentlySelected() {
    this.itemEvent.emit({item: this.item, itemAction: dataItemAction.DeletePermanentlySelected});
  }
  restoreSelected() {
    this.itemEvent.emit({item: this.item, itemAction: dataItemAction.RestoreSelected});
  }
  delete() {
    this.itemEvent.emit({item: this.item, itemAction: dataItemAction.DeletePermanentlySelected});
  }

  get id() {
    return _.get(this, 'item.id', null);
  }
  get name() {
    return _.get(this, 'item.name', null);
  }
  get title() {
    return _.get(this, 'item.title', null);
  }
  get status() {
    return _.get(this, 'item.status', '');
  }
  get canTrash() {
    return _.get(this, 'item.capabilities.can_trash', false);
  }
  get canDelete() {
    return _.get(this, 'item.capabilities.can_delete', false);
  }


}
