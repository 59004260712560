import { Component, OnInit, Input } from '@angular/core';
import {
  template,
  cyan,
  red,
  yellow,
  lime,
  addCustomColorscale,
  constructLayoutUsingTemplate,
} from '../../plotly-templating';
import * as _ from 'lodash';

declare let Plotly: any;

@Component({
  selector: 'app-viz-plotly',
  templateUrl: './viz-plotly.component.html',
  styleUrls: ['./viz-plotly.component.scss', '../viz-style.scss'],
})
export class VizPlotlyComponent implements OnInit {
  @Input() data;
  @Input() layout;
  @Input() title;
  @Input() id: string;
  @Input() size;


  ngOnInit(): void {
    if (this.data) {
      this.processInput();
    }
  }

  processInput() {
    this.layout.autosize = true;
    const layoutUsingTemplate = constructLayoutUsingTemplate(
      this.layout,
      template(false, this.data[0].type)
    );
    this.data = addCustomColorscale(this.data, cyan);

    window.addEventListener(
      'load',
      () => {
        //Plotly.newPlot(this.id + '-plot', this.data, layoutUsingTemplate)

        Plotly.newPlot(this.id + '-plot', this.data, layoutUsingTemplate, {
          displayModeBar: false,
        });
        // if (this.data[0].type === 'scatter') {
        //   const update = {
        //     'marker.size': 7,
        //     'marker.sizemode': 'area',
        //     'marker.symbol': 'square'
        //   };
        //   Plotly.restyle(this.id + '-plot', update);
        // }
      },
      false
    );
  }
}
