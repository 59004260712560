import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModelsFileShortList } from '../model/modelsFileShortList';
import { ModelsNewID } from '../model/modelsNewID';
import { ModelsPostGroup } from '../model/modelsPostGroup';
import { ModelsGroupShortList } from '../model/modelsGroupShortList';
import { LocalService } from './local-storage.service';
import { MulticountryService } from './multicountry.service';

export interface PathPartsModel {
  name: string;
  id: string;
}
export interface PathModel {
  path: string;
  parts: Array<PathPartsModel>;
}

@Injectable({ providedIn: 'root' })
export class FileGroupsService {
  public basePath = 'XXXX';
  onFileGroupCreated = new EventEmitter(undefined);
  public isLoading = false;
  user;
  constructor(private readonly http: HttpClient, private localService: LocalService, private multicountryService: MulticountryService) {
    this.user = this.localService.getFromLocalStorage('user');
    
  }
  filegroupPatch(country, fileId: string, newValue, field): Observable<any> {
    let body = {};
    body[field] = newValue;
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.patch<any>(`${this.basePath}/mas/fs/filegroups/${fileId}`, body, { headers });
  }
  addFilesToGroup(groupId: string, file_ids: string[],country): Observable<any> {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    //nothing?
    return this.http.post<any>(`${this.basePath}/mas/fs/filegroups/${groupId}/files/nothing`, { file_ids }, {headers});
  }

  postGroup(body: ModelsPostGroup, observe: any, country): Observable<any> {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.post<ModelsNewID>(`${this.basePath}/mas/fs/filegroups`, body, {headers});
  }

  deleteGroup(groupId: string,country): Observable<any> {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.delete<any>(`${this.basePath}/mas/fs/filegroups/${groupId}`, {headers});
  }

  removeFileFromGroup(country, groupId: string, fileId: string): Observable<any> {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.delete<any>(`${this.basePath}/mas/fs/filegroups/${groupId}/files/${fileId}`, {headers});
  }

  listGroup(country, groupId: string, sortBy?: string, filters?: string, limit?: number): Observable<ModelsFileShortList> {
    let queryParameters = new HttpParams({});

    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', sortBy as any);
    }

    if (filters !== undefined && filters !== null) {
      queryParameters = queryParameters.set('filters', filters as any);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', limit as any);
    }
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.get<ModelsFileShortList>(`${this.basePath}/mas/fs/filegroups/${groupId}/files`, {
      params: queryParameters,
      headers
    });
  }
  retrieveGroup(groupId: string, country: string): Observable<ModelsFileShortList>{
    let queryParameters = new HttpParams({});
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.get<ModelsFileShortList>(`${this.basePath}/mas/fs/filegroups/${groupId}`, {
      params: queryParameters,
      headers
    });
  }

  listAllGroups(sortBy?: string, filters?: string, limit?: number): Observable<any> {
    return this.multicountryService.asyncListing(
      this._listAllGroups,
      [sortBy ? sortBy : undefined, filters ? filters : undefined, limit ? limit : undefined],
      'all'
    );
  }

  _listAllGroups = (sortBy?: string, filters?: string, limit?: number, country?: string): Observable<any> => {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);

    let queryParameters = new HttpParams({});
    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', sortBy as any);
    }
    if (filters !== undefined && filters !== null) {
      queryParameters = queryParameters.set('filters', filters as any);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', limit as any);
    }
    return this.http.get<any>(`XXXX/mas/fs/filegroups`, {
      params: queryParameters,
      headers,
    });
  };
  listGroups(country?, sortBy?: string, filters?: string, limit?: number): Observable<any> {
    let queryParameters = new HttpParams({});

    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', sortBy as any);
    }

    if (filters !== undefined && filters !== null) {
      queryParameters = queryParameters.set('filters', filters as any);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', limit as any);
    }
    if (country) {
      let headers = new HttpHeaders({});
      headers = headers.set('country', country);
      return this.http.get<ModelsGroupShortList>(`${this.basePath}/mas/fs/filegroups`, {
        params: queryParameters,
        headers,
      });
    }
    return this.http.get<ModelsGroupShortList>(`${this.basePath}/mas/fs/filegroups`, {
      params: queryParameters,
    });
  }
  listGroupFromUrl(url): Observable<ModelsFileShortList> {
    return this.http.get<ModelsFileShortList>(url);
  }

}
