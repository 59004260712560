import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApplicationScreenSizeService } from 'src/app/shared/application-screen-size-service/application-screen-size.service';

@Component({
  selector: 'app-viz-table',
  templateUrl: './viz-table.component.html',
  styleUrls: ['./viz-tables.scss', '../viz-style.scss'],
})
export class VizTableComponent implements OnInit {
  @Input() data;
  @Input() layout;
  @Input() id: string;
  @Input() size;
  @Input() title;
  octet_stream;
  constructor(public _DomSanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  generateHref() {
    this.octet_stream =
      'data:application/octet-stream,' + this.generateOctetStream();
  }
  generateHeadersRow(input_data) {
    let result = '';
    for (let i = 0; i < input_data.length; ++i)
      result += this.addCSVValue(input_data[i]);
    return result.slice(0, -1) + '\n';
  }
  generateCSVRows(input_data) {
    const first_entries = input_data[0];
    const nbr_cols = first_entries.length;
    if (nbr_cols === 0 || !Array.isArray(input_data)) {
      return '';
    }
    const nbr_rows = input_data.length;
    let result = '';
    for (let row_index = 0; row_index < nbr_rows; ++row_index) {
      for (let col_index = 0; col_index < nbr_cols; ++col_index) {
        result += this.addCSVValue(input_data[row_index][col_index]);
      }
      result = result.slice(0, -1) + '\n';
    }
    return result;
  }
  generateOctetStream() {
    let csv_data = this.generateHeadersRow(this.data[0].headers);
    csv_data += this.generateCSVRows(this.data[0].cells);
    return encodeURI(csv_data);
  }
  addCSVValue(data_value) {
    if (typeof data_value === 'string') {
      data_value = this.sanitizeString(data_value);
    }
    return data_value + ',';
  }
  sanitizeString(input) {
    input = input.replace('\r', '');
    input = input.replace('\t', '');
    input = '"' + input + '"';
    return input;
  }
}
