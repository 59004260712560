<div class='h-100 w-100 p-4'>
    <div class='btn btn-light border' type="button" (click)='previous()'>
        <span class='text-uppercase small'><i aria-hidden='true' class="text-muted far fa-arrow-to-left mr-1"></i>
            previous</span>
    </div>
    <div class='my-4'>
        <app-spinner *ngIf='!workflowDownloaded.value'></app-spinner>
        <span *ngIf='workflowDownloaded.value'> Please <span class='font-weight-bold'>confirm</span> your choice.</span>
    </div>
    <div>
        <app-available-workflow (workflowDownloaded)='workflowIsDownloaded($event)' [subjects]='subjects' btnTitle='Confirm' [expended]='true' (workflowSelected)='next($event)'
            [canSelect]='true' [workflowId]='workflowId' [filegroupTypes]='_.toArray(dataTypes)' >
        </app-available-workflow>
    </div>

</div>