
export enum Formulation {
    Keywords = 'keywords',
    Therapy = 'therapy',
    Prevention = 'prevention',
    Diagnosis = 'diagnosis',
    Prognosis = 'prognosis',
    Etiology = 'etiology',
    Article = 'article'

  }