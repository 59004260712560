import { EventEmitter, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { FileGroupsService } from './groups.service';
import { LayoutOrganizationService } from './layout-organization.service';
import { Observable } from 'rxjs';
import { TabsName } from '../shared/helpers/tabsName';
import { InfiniteListingService } from './infiniteListing.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ShareService } from './share.service';
import { Files2Service } from './files2.service';
import { ItemType } from '../shared/helpers/itemType';
import { map } from 'rxjs/operators';

export enum FileGroupAction {
  SelectShare,
  Share,
  Info,
  Trash,
  Rename,
  RemovePermission,
}
@Injectable()
export class GroupsManagerService {
  groups: Array<any> = [];
  groupsTemp: Array<any> = [];
  groupTemp: Array<any> = [];
  groupCreationForm;
  // openedGroupIndex;
  openedGroupId;
  groupSelectElement;
  page = 1;
  pageSize = 5;
  finishedListing;
  filegroupsStorageCountry = null;
  itemsWithInfoDisplayed = [];
  groupsFromAllCountries: Array<any> = [];
  bulkSelection = [];
  groupItemEvent : EventEmitter<any> = new EventEmitter();
  eventRefreshGroup : EventEmitter<any> = new EventEmitter();
  eventRefreshGroups : EventEmitter<any> = new EventEmitter();
  FileGroupAction = FileGroupAction;
  activeItem;
  basePath = 'XXXX';
  constructor(
    private fileGroupsService: FileGroupsService,
    private filesService2: Files2Service,
    private layout: LayoutOrganizationService,
    private infiniteListingService: InfiniteListingService,
    private readonly http: HttpClient,
    private shareService: ShareService
  ) {
    this.groupItemEvent.subscribe((event) => {
      this.handleItemAction(event);
    });
  }
  removePermissionFromFileGroup(permId: string, fileId, country): Observable<any> {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.delete<any>(`${this.basePath}/mas/fs/filegroups/${fileId}/permissions/${permId}`, { headers });
  }
  handleItemAction(event) {
    const item = _.get(event, 'item');
    const fileGroupAction = _.get(event, 'fileGroupAction');
    const destination = _.get(event, 'destination');
    const value = _.get(event, 'value');
    const country = _.get(event, 'country');
    const tab = _.get(event, 'tab');

    switch (fileGroupAction) {
      case FileGroupAction.RemovePermission:
        this.removePermissionFromFileGroup(value, _.get(item, 'id'), country.value).subscribe(
          () => {
            this.layout.toast('Permission <b>successfully</b> removed from file group.', null, 8000, 'Profile');
            this.refreshItem(item, country).then((res) => {
              this.activeItem = res;
            });
          },
          (error) => {
            this.layout.toast('Impossible operation', null, 8000, 'Profile', 'danger');
          }
        );
        break;
      case FileGroupAction.Rename:
        this.handleRenameAction(item, value, country).then(() => {
          this.eventRefreshGroups.next(true);
        });
        break;
      case FileGroupAction.Share:
        this.shareService.shareToUsers(ItemType.FileGroup, _.get(item, 'id'), value, country.value).then(() => {
          this.refreshItem(item, country).then((res) => {
            this.activeItem = res;
          });
        });
        break;
      case FileGroupAction.SelectShare:
        this.refreshItem(item, country).then((res) => {
          this.activeItem = res;
          this.layout.close();
          this.layout.open('modalGroupsShare');
          _.set(this.layout.fmPannelIsOpened, tab.value, true);
        });
        break;
    }
  }
  modifyFilegroup(itemId, newItem, country) {
    let headers = new HttpHeaders({});
    headers = headers.set('country', country);
    return this.http.patch<any>(`${this.basePath}/mas/fs/filegroups/${itemId}`, newItem, { headers });
  }
  handleRenameAction(item, value, country) {
    return new Promise((resolve) => {
      if (_.get(item, 'id')) {
        return this.modifyFilegroup(_.get(item, 'id'), { name: value }, country.value).subscribe(
          () => {
            resolve(true);
          },
          () => {
            this.layout.toast('Error when renaming the item', null, 5000, '', 'danger');
          }
        );
      }
    });
  }

  async *getGroup(country, group, limit?) {
    let response = {};
    await new Promise((resolve) => {
      this.fileGroupsService
        .listGroup(country, group.id, 'title,asc', 'ownedByMe=true,sharedWithMe=true,sharedWithMyUserGroups=true', limit || 10)
        .subscribe(
          (res) => {
            response = res;
            resolve(true);
          },
          () => {
            this.layout.toast('Impossible to <b>list</b> filegroup <b>' + group.name + '</b> content', null, 5000, '', 'danger');
            resolve(true);
          }
        );
    });
    yield response;
    while (_.get(response, 'links.next')) {
      await new Promise((resolve) => {
        this.fileGroupsService.listGroupFromUrl(_.get(response, 'links.next')).subscribe(
          (res2) => {
            _.set(response, 'data', _.concat(_.get(response, 'data'), res2.data));
            _.set(response, 'links.next', _.get(res2, 'links.next'));
            _.set(response, 'has_more', _.get(res2, 'has_more'));
            resolve(true);
          },
          () => {
            this.layout.toast('Impossible to <b>list more</b> content of filegroup <b>' + group.name + '</b> ', null, 5000, '', 'danger');
            resolve(true);
          }
        );
      });
      yield response;
    }
  }
  getUsersSelected() {
    return _.get(this.shareService, 'usersSelected', []);
  }
  refreshItem = (item, country) => {
    return new Promise((resolve) => {
      return this.retrieveInfo(item, country).then((res) => {
        resolve(res);
      });
    });
  };
  retrieveInfo = (item, country) => {
    return new Promise((resolve) => {
      return this.filesService2.retrieveFileGroup(_.get(item, 'id'), _.get(country, 'value')).subscribe(
        (res) => {
          resolve(res);
        },
        () => {
          this.layout.toast('Error when retrieving the description of the item', null, 5000, '', 'danger');
        }
      );
    });
  };

  getFilegroupsStorageCountry() {
    return _.get(this, 'filegroupsStorageCountry', 'all');
  }
  setFilegroupsStorageCountry(country) {
    _.set(this, 'filegroupsStorageCountry', country);
  }

  listGroups = (tabsName?: TabsName, country?, sortBy?, filters?) => {
    return new Promise((resolve) => {
      const limit = 100;
      if(!filters){
      if (tabsName === TabsName.MyFiles || tabsName === TabsName.MyData) {
        filters = 'ownedByMe=true,sharedWithMe=false,sharedWithMyUserGroups=false';
      } else if (tabsName === TabsName.SharedFiles || tabsName === TabsName.SharedData) {
        filters = 'ownedByMe=true,sharedWithMe=true,sharedWithMyUserGroups=true';
      } else {
        resolve(undefined);
        return;
      }
    }
      const func = () => {
        return this.fileGroupsService.listGroups(_.get(country, 'value', undefined), sortBy ? sortBy :  'date_of_creation', filters, limit)};
      return this.infiniteListingService.infiniteListing(func, country.value).then((res) => {
        resolve(res);
      });
    });
  };

  updateMaxPage = () => {
    if (this.page > Math.ceil(this.groupsTemp.length / this.pageSize)) {
      _.set(this, ['page'], Math.ceil(this.groupsTemp.length / this.pageSize));
    }
  };

  resetGroupCreationForm = () => {
    if (this.groupCreationForm) {
      this.groupCreationForm.reset();
    }
  };
  handleRemoveFromGroup = (country, groupId, fileId, groupTitle?, itemTitle?) => {
    return new Promise((resolve, reject) => {
      this.fileGroupsService.removeFileFromGroup(_.get(country, 'value'), groupId, fileId).subscribe(
        (res) => {
          this.layout.toast(
            'Item  <b>' + itemTitle + '</b> sucessfully <b>removed</b> from group <b>' + groupTitle + '</b>.',
            null,
            5000,
            '',
            'success'
          );
          resolve(true);
        },
        (error) => {
          this.layout.toast(
            'Impossible to <b>removed</b> item  <b>' + itemTitle + '</b> from group <b>' + groupTitle + '</b>.',
            null,
            5000,
            '',
            'danger'
          );
          reject();
        }
      );
    });
  };

  handleGroupDelete(id, country) {
    return new Promise((resolve) => {
      let text = 'This group will be deleted. Are you sure ?';
      let self = this;
      this.layout.customConfirm(text, function () {
        self.fileGroupsService.deleteGroup(id, country).subscribe(
          () => {
            resolve(true);
          },
          (error) => {
            if (error.status === 422) {
              alert('This group can not be deleted.');
            } else {
              alert(error.error.message);
            }
          }
        );
      });
    });
  }
  allowDrop(event) {
    event.preventDefault();
  }
  rename = (country, itemId, value, field) => {
    return new Observable((observer) => {
      this.fileGroupsService.filegroupPatch(country, itemId, value, field).subscribe(
        (res) => {
          observer.next(res);
        },
        (error) => {
          observer.next(error);
        }
      );
    });
  };
}
