import { DlProxyComponent } from './proxy/dl-proxy.component';
import { HeaderComponent } from '../../shared/layout/header/header.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MLogoBtnComponent } from '../../shared/sidebar/no-menu-btn/m-logo-btn.component';

@NgModule({
  declarations: [HeaderComponent, MLogoBtnComponent, DlProxyComponent],
  imports: [CommonModule],
  exports: [HeaderComponent, MLogoBtnComponent, DlProxyComponent],
})
export class SharedModule {}
