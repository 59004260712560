import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitialsBubbleComponent } from './initials-bubble.component';

@NgModule({
  declarations: [InitialsBubbleComponent],
  imports: [CommonModule],
  exports: [InitialsBubbleComponent],
})
export class InitialsBubbleModule {}
