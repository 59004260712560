import { Component } from '@angular/core';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
import { Formulation } from '../formulation';
import { KnowledgeSearchService } from '../knowledge-search.service';

@Component({
  selector: 'app-knowledge-search-formulation',
  templateUrl: './knowledge-search-formulation.component.html',
  styleUrls: ['./knowledge-search-formulation.component.scss']
})
export class KnowledgeSearchFormulationComponent{
  Formulation = Formulation
  PagesPublicName = PagesPublicName
  constructor(public knowledgeSearchService : KnowledgeSearchService) {
   }

}
