<div class="h-100 d-flex flex-column">
  <div class="mx-2 back text-danger" *ngIf="!e.isConnected || e.getErrorState()">
    Internal Error.
  </div>
  <div class="question-visualization previous-question">
    <app-explorer-previous-question *ngFor="let el of e.getPreviousQuestions(); let i = index" [date]="el.date"
      [text]="el.text" [index]="i">
    </app-explorer-previous-question>
  </div>

  <div class="question-visualization">
    <span class="font-weight-bold">
      {{ e.getInput() }}
    </span>
    <span class="text-primary font-italic" *ngIf="e.hasSuggestions()">[...]</span>
    <span *ngIf="e.getInputLength() !== 0" class="text-primary font-italic">
      -
      {{ 255 - e.getInputLength() }}
      characters left</span>
  </div>
  <div class="suggestion-visualization">
    <div class="word " *ngFor="let suggestion of e.getSuggestions(); let i = index"
      (click)="e.setSuggestionIndex(i); e.copySuggestionToTextArea(i)">
      <span class="px-2 text-white badge badge-primary">{{
        suggestion.score
      }}</span>
      <span class="mx-2" [class.font-weight-bold]="i === e.indexSuggestion">{{
        suggestion.value
      }}</span>
    </div>
  </div>
</div>