  <!-- plot -->
  <div (window:resize)="debouncedRelayout()" *ngIf='type === VizType.Plot'>
    <div class='title text-center w-100'>
      <h3 [innerHTML]='title'></h3>
    </div>
    <div class='w-100 h-100 viz-place-holder ' [id]='graphId' #vizRef> </div>
    <div *ngIf='interactive' class=" footer text-center w-100">
      <span *ngIf='getSubType() === VizSubType.Treemap' class='font-italic'> (Click on a tile for more details.)</span>
      <span *ngIf='getSubType() === VizSubType.Scatter || getSubType() === VizSubType.ScatterGl ' class='font-italic'>
        (Click on a point for more details.)</span>
      <span *ngIf='getSubType() === VizSubType.Bar' class='font-italic'> (Click on a bar for more details.)</span>
    </div>
  </div>
  <!-- select -->
  <div *ngIf='type === VizType.Select && formattedInteractions && formattedInteractions.value'
    class='w-100 h-100 mb-3 center-content'>
    <div class='d-flex flex-column align-items-center w-100'>
      <h4 class='text-center w-100 mb-4 text-lowercase capitalize-first font-weight-bold' [innerHTML]='title'></h4>
      <mims-select [placeholder]="getTitleSelect()" [dataSource]="formattedInteractions.value"
        (valueChanged)="handleSelect($event)"></mims-select>
    </div>
  </div>
  <!-- image -->
  <div *ngIf="type === VizType.Image" class='h-100 w-100 d-flex flex-column align-items-center'>
    <h3 class='text-center w-100' [innerHTML]='title'></h3>
    <div class=' flex-fill definedHeight text-center w-100'>
      <a title="click to download image" class='colorscaleLink' [href]="sanitizedUrl(getValues())" [download]="graphId">
        <img alt='Image' class="image  mw-100 mh-100" [src]="sanitizedUrl(getValues())" />
      </a>
    </div>
    <div *ngIf='formattedInteractions && formattedInteractions.value'>
      <mims-select [placeholder]="getTitleSelect()" [dataSource]="formattedInteractions.value"
        (valueChanged)="handleSelect($event)"></mims-select>
    </div>
  </div>

  <!-- colorscale images -->
  <div *ngIf="type === VizType.ColorscaledImage" class='h-100 w-100 colorscale'>
    <h3 class='a' [innerHTML]='title'></h3>
    <div class='b d-flex w-100 flex-nowrap justify-content-center align-items-center'>
      <div class='h-100 w-100 d-flex flex-nowrap justify-content-center '>

        <!-- <div class="flex-fill text-center"> -->
        <a class='colorscaleLink ' title="click to download image" [href]="sanitizedUrl(getValues())"
          [download]="graphId">
          <img [src]="sanitizedUrl(getValues())" class='image mw-100 mh-100' alt="image" />
        </a>
        <!-- </div> -->
        <div class=" vizColorscale" #vizRef>
        </div>
      </div>
    </div>
    <div class='c mt-4 w-100 content-center' *ngIf='formattedInteractions && formattedInteractions.value'>
      <mims-select class='w-50 mx-auto' [placeholder]="getTitleSelect()" [dataSource]="formattedInteractions.value"
        (valueChanged)="handleSelect($event)"></mims-select>
    </div>
  </div>

  <!-- table -->
  <div *ngIf="type === VizType.Table" class='h-100 w-100 d-flex flex-column justify-content-center align-items-center'>
    <h3 class=' text-center w-100' [innerHTML]='title'></h3>
    <a (click)="generateHref()" [download]="getCsvFileTitle()" class="btn btn-light my-3"
      [href]="_DomSanitizer.bypassSecurityTrustUrl(octet_stream)" [id]="graphId + '_to_csv'">
      {{getTableDownloadButtonDescription()}}
    </a>
    <div *ngIf='getTableDisplay() !== "no-preview"' class="table-responsive flex-grow-1 vizTableContent" (scroll)="onTableScroll($event)">
      <table aria-describedby="table" class="vizTable table-hover table-striped" >
        <thead>
          <tr>
            <th [scope]='header' *ngFor="let header of getTableHeaders()" [innerHTML]='header'>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let column of dataSource">
            <td *ngFor="let cell of column" [style.minWidth.ch]='23' [innerHTML]='cell | formatCell' >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- table content -->
  <div *ngIf="type === VizType.Content" class='w-100 h-100 center-content'>
    <div class='w-100 viz-place-holder d-flex flex-column align-items-center'>
      <h3 class=' text-center w-100' [innerHTML]='title'></h3>
      <div class="table-responsive ">
        <table role='presentation' aria-describedby="table" class="vizTable vizTableInfo table-hover">
          <tbody>
            <tr *ngFor="let column of getTableContentValues()">
              <td *ngFor="let cell of column" [innerHTML]='cell | formatCell'>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- loader -->
  <div [class.transparent]='isPloted' *ngIf='!destroyLoader'
    class='w-100 h-100 loading-place-holder d-flex align-items-center flex-column justify-content-center'>
    <i class='icon-place-holder fa-chart-bar far fa-10x' aria-hidden="true"></i>
    <div class='animation-place-holder'>
      <div class='dot-flashing'>
      </div>
    </div>
  </div>
