<div id="top-utility-bar" class="rounded hidden-on-mobile">
  <div [style.min-height.px]="35" class='d-flex flex-wrap' [class.justify-content-between]='canSelect'  [class.justify-content-end]='!canSelect' >
    <span *ngIf="canSelect" class='flex-nowrap'>
      <span class="cursor-info"
        [ngbTooltip]="visibleBulkLength ? 'Unselect all visible files' : 'Select all visible files'"
        tooltipClass="custom-tooltip" placement="bottom">
        <app-checkbox [id]="" [fileType]='null' (selection)='selection.emit(true)'
          (unselection)='unselection.emit(true)' [selected]="visibleBulkLength" bulkType='allFiles'>
        </app-checkbox>

      </span>

      <span class="px-3 text-light" [class.font-weight-bold]='!visibleBulkLength'>
        Check all</span>
      |
      <span class="px-4  text-light" [class.font-weight-bold]='visibleBulkLength'>Uncheck
        all</span>
      <span *ngIf="bulkSelectionLength">|</span>
      <span class="px-3 text-light" *ngIf="bulkSelectionLength" click=""><span
          class="font-weight-bold">{{bulkSelectionLength}}</span>
        item{{bulkSelectionLength>1 ? 's':''}}
        selected <span class='mx-2'> -
        </span><strong>{{bulkSize[0]}}</strong>
        {{bulkSize[1]}}</span>
      <!-- <span *ngIf='count' class="px-3  text-light">
          <b>{{(page- 1) * pageSize + 1}}</b>
          —
          <b>{{(page- 1) * pageSize + uploadingFilesLength + displayedFilesLength}}</b>
          on <b>{{count}}</b> results</span> -->

    </span>

    <div *ngIf="filtersVisible" class="d-flex flex-nowrap">

      <button *ngFor="let buttonData of sortingData; let index = index"
        (click)='sortingTypeChange.emit(_.get(buttonData, "sortType"));toggleDirection()' class="btn">
        <span class="text-light">Sort By {{_.get(buttonData, "sortName")}} <i class="fas"
            *ngIf='sortingType ===_.get(buttonData, "sortType")'
            [class.fa-sort-up]="sortingDirection === SortDirection.Asc"
            [class.fa-sort-down]="sortingDirection === SortDirection.Desc" aria-hidden="true"></i></span>
      </button>

      <!-- <button (click)='sortingTypeChange.emit(SortType.DateCreation);toggleDirection()' class="btn">
        <span class="text-light">Sort By Date <i class="fas" *ngIf='sortingType === SortType.DateCreation'
            [class.fa-sort-up]="sortingDirection === SortDirection.Asc"
            [class.fa-sort-down]="sortingDirection === SortDirection.Desc" aria-hidden="true"></i></span>
      </button> -->

    </div>
    <div class="flex-nowrap">
      <button *ngIf="!filtersVisible" (click)='toggleFilterVisibility()' class="btn">
        <span class="text-light font-weight-bold">Show filters</span>
      </button>
      <button *ngIf="filtersVisible" (click)='toggleFilterVisibility()' class="btn">
        <span class="text-light font-weight-bold">Hide filters</span>
      </button>
    </div>
  </div>
</div>
