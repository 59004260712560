import {Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import * as _ from 'lodash';

@Component({
  selector: 'formly-field-repeat',
  templateUrl: './formly-field-repeat.html',
  styleUrls: ['./formly-field-repeat.scss', '../../../../styles.scss'],
})
export class RepeatTypeComponent extends FieldArrayType implements OnInit{
  _ = _;
  ngOnInit() {
    this.to.add = this.add.bind(this);
    this.to.remove = this.remove.bind(this);
    setTimeout(() => {
      _.get(this, 'field.templateOptions').add();
    },1)
  }
  

  getControl(index) {
    return _.nth(_.get(this, 'formControl.controls'), index);
  }

  hasError(index?) {
    if (index !== undefined) {
      const control = this.getControl(index);
      return !control.valid && (control.dirty || control.touched);
    } else {
      let oneFieldIsInvalid = false
      this.formControl.controls.forEach((control) => {
        if (control.status !== 'VALID') {
          oneFieldIsInvalid = true
        }
      });
      if (oneFieldIsInvalid){
        return false
      }
      return this.formControl.status !== 'VALID';
    }
  }
  addField() {
    this.field.templateOptions.add();
  }
}
