import { Injectable, EventEmitter, ElementRef } from '@angular/core';
import { ExplorerService } from './explorer.service';
import * as _ from 'lodash';
import moment from 'moment';
import { UntypedFormGroup } from '@angular/forms';
import { LayoutOrganizationService } from './layout-organization.service';
import { LocalStorage } from 'ngx-webstorage';

export enum ChatView {
  NoQuestion,
  ShortListOfAnswers,
  AnswerInFullDescription,
}

export enum GasEventType {
  NewQueryToken = 'newquerytoken',
  Explore = 'explore',
  Done = 'done',
  Complete = 'complete',
}
export interface GasTokenQuery {
  event: GasEventType;
  header: {};
  body: '';
}
export interface GasQuery {
  event: GasEventType;
  header: object;
  body: { message: string };
}
export interface GasAnswer {
  Result: Array<string>;
  Focus: Array<string>;
  Unused: Array<string>;
  Score: string;
}
export interface GasError {
  cause: string;
}
export interface GasHeader {
  'Status-Code': Array<string>;
  Time: Array<string>;
  'Time-Location': Array<string>;
  'Query-Token': Array<string>;
}
export interface GasResponse {
  event: string;
  header: GasHeader;
  body: Array<GasAnswer>;
}
export interface GasResponseError {
  event: string;
  header: GasHeader;
  body: GasError;
}
export interface Suggestion {
  value: string;
  score: number;
}
export interface UserInteraction {
  text: string;
  queryTime: any;
  messages: Array<Message>;
  suggestions: Array<Suggestion>;
  done: boolean;
  pending: boolean;
  errorState: boolean;
  errorCause: string;
  sent: boolean;
}
export interface Message {
  statusCode: Array<string>;
  answers: Array<GasAnswer>;
  time: Array<string>;
}
export enum StatusCode {
  Success = '200',
  Error = '400',
}
@Injectable({
  providedIn: 'root',
})
export class ExplorerManagerService {
  public chatView: ChatView;
  public StatusCode = StatusCode;
  public ChatView = ChatView;
  public socket: any;

  @LocalStorage() public previousquestions;
  public answers: Array<GasAnswer> = [];
  public token = '';
  public GasEventType = GasEventType;
  public questionForm: UntypedFormGroup;
  public tokenReceived = new EventEmitter();
  public errorReceived = new EventEmitter();
  public selectedAnswer: any;
  public emptyInteraction = {
    text: '',
    queryTime: '',
    messages: [],
    suggestions: [],
    done: false,
    pending: false,
    errorState: false,
    errorCause: '',
    sent: false,
  };
  @LocalStorage() interaction: UserInteraction = this.emptyInteraction;
  public openedAnswer = null;
  public indexSuggestion = null;
  public indexAnswer = 0;
  public indexQuestion = null;
  public isConnected = false;
  public isPending = false;

  public questionInput: ElementRef;
  public previousCode: any;
  constructor(
    public explorerService: ExplorerService,
    public l: LayoutOrganizationService
  ) {}
  connectSocket = () => {
    const self = this;
    self.socket = this.explorerService.createSocket();
    self.socket.onopen = () => {
      self.isConnected = true;
      if (this.chatView === this.ChatView.NoQuestion) {
        this.resetInteraction();
      }
    };
    self.socket.onclose = () => {
      self.isConnected = false;
    };
    self.socket.onerror = (event) => {
      self.isConnected = false;
      console.error('WebSocket error observed:', event);
    };
    self.socket.onmessage = (res) => {
      const parsedData = JSON.parse(res.data);
      if (parsedData.header['Status-Code'][0] === StatusCode.Success) {
        const response: GasResponse = parsedData;
        self.resetError();
        if (response.event === GasEventType.Explore) {
          self.storeAnswer(response);
        }
        if (response.event === GasEventType.Complete) {
          self.storeSuggestions(response);
        }
        if (response.event === GasEventType.Done) {
          self.deleteToken();
          self.interaction.pending = false;
        }
        if (response.event === GasEventType.NewQueryToken) {
          self.token = self.extractQueryToken(response);
          self.tokenReceived.emit(self.token);
        }
      } else if (parsedData.header['Status-Code'][0] === StatusCode.Error) {
        const response: GasResponseError = parsedData;
        self.interaction.pending = false;
        self.interaction.errorState = true;
        self.interaction.errorCause = response.body.cause;
      }
    };
  };
  sendQueryTokenDemand = () => {
    const tokenQuery: GasTokenQuery = {
      event: GasEventType.NewQueryToken,
      header: {},
      body: '',
    };
    this.socket.send(JSON.stringify(tokenQuery));
  };
  getQueryToken = (): Promise<any> => {
    const self = this;
    return new Promise((resolve, reject) => {
      self.tokenReceived.subscribe((res) => {
        if (res) {
          resolve(res);
        } else {
          reject(Error('No Token has been received'));
        }
      });
    });
  };
  resetError() {
    this.interaction.errorCause = '';
    this.interaction.errorState = false;
  }

  extractQueryToken = (response): string => {
    return response.body.QueryToken;
  };
  extractQueryTokenFromHeader = (response) => {
    return response.header['Query-Token'][0];
  };
  sendQuestion = (text?) => {
    const question = this.getInput();
    const self = this;
    if (question.length > 0) {
      const serverIsAvailable = self.isConnected && !self.getPendingState();
      if (serverIsAvailable) {
        self.sendQueryTokenDemand();
        self.getQueryToken().then((res) => {
          const query: GasQuery = {
            event: GasEventType.Explore,
            header: {
              'Query-Token': [res],
            },
            body: {
              message: text
                ? text
                : self.questionForm.get('questionInput').value,
            },
          };
          this.interaction.pending = true;
          this.interaction.queryTime = moment();
          self.socket.send(JSON.stringify(query));
          self.storeQuestion();
          self.goToView(self.ChatView.ShortListOfAnswers);
        });
      } else if (self.getPendingState()) {
        alert(
          'Our knowledge explorer is currently unavailable. Please try again in few minutes.'
        );
      }
    }
  };
  SaveQuestionToLocalStorage(data) {
    this.previousquestions = _.concat(this.previousquestions || [], data);
    if (this.previousquestions.length > 3) {
      this.previousquestions = _.takeRight(this.previousquestions, 3);
    }
  }
  storeQuestion = () => {
    const text = this.questionForm.get('questionInput').value;
    const newQuestion = {
      text,
      date: moment(),
    };
    this.SaveQuestionToLocalStorage(newQuestion);
    this.interaction.text = text;
  };
  storeAnswer = (response) => {
    const statusCode = response.header['Status-Code'];
    const answers = this.extractAnswer(response);
    const time = response.header.Time;
    this.interaction.messages.push({ statusCode, answers, time });
    this.storeDone();
  };
  extractAnswer = (response): [GasAnswer] => {
    return _.orderBy(response.body.message, ['Score'], ['desc']);
  };
  storeDone = () => {
    this.interaction.done = true;
  };
  resetInteraction() {
    this.interaction = _.cloneDeep(this.emptyInteraction);
  }
  initNewQuestion() {
    this.resetInteraction();
    this.deleteToken();
    this.setQuestionIndex();
    this.setSuggestionIndex();
    this.setOpenedAnswer();
    this.setAnswerIndex();
    this.setActiveAnswer();
  }
  askCompletion = () => {
    const self = this;
    if (
      self.questionForm.get('questionInput').value &&
      self.questionForm.get('questionInput').value.length
    ) {
      self.sendQueryTokenDemand();
      self.getQueryToken().then((res) => {
        const query: GasQuery = {
          event: GasEventType.Complete,
          header: {
            'Query-Token': [res],
          },
          body: {
            message: self.questionForm.get('questionInput').value,
          },
        };
        self.socket.send(JSON.stringify(query));
      });
    }
  };
  storeSuggestions = (response) => {
    if (response.body.suggestions) {
      this.interaction.suggestions = response.body.suggestions;
    } else {
      this.interaction.suggestions = [];
    }
  };
  deleteSuggestions() {
    if (this.hasSuggestions()) {
      this.interaction.suggestions = [];
    }
  }

  getSuggestions = () => {
    return _.get(this, 'interaction.suggestions', []);
  };
  hasSuggestions = () => {
    return !!_.get(this, 'interaction.suggestions.length', false);
  };

  getAnswersLength = (x) => {
    return _.get(this, 'interaction.messages[' + x + '].answers.length', 0);
  };
  getAnswers = (x) => {
    return _.get(this, 'interaction.messages[' + x + '].answers', []);
  };
  hasAnswers = (x) => {
    return !!_.get(
      this,
      'interaction.messages[' + x + '].answers.length',
      false
    );
  };

  getInput = () => {
    return _.get(this, 'questionInput.nativeElement.value', '');
  };

  hasEmptyInput = () => {
    return !_.get(this, 'questionInput.nativeElement.value.length', false);
  };
  getInputLength = () => {
    return _.get(this, 'questionInput.nativeElement.value.length', 0);
  };

  getPreviousQuestions = () => {
    return _.get(this, 'previousquestions', []);
  };
  hasPreviousQuestions = () => {
    return !!_.get(this, 'previousquestions.length', false);
  };
  getErrorState = () => {
    return _.get(this, 'interaction.errorState', true);
  };
  getPendingState = () => {
    return _.get(this, 'interaction.pending', true);
  };

  getTime = (x) => {
    return _.get(this, 'interaction.messages[' + x + '].time', 0);
  };

  setOpenedAnswer(i?) {
    if (i) {
      this.openedAnswer = i;
    } else {
      this.openedAnswer = null;
    }
  }

  setAnswerIndex(i?) {
    if (i) {
      this.indexAnswer = i;
    } else {
      this.indexAnswer = 0;
    }
  }

  setActiveAnswer(answer?) {
    if (answer) {
      this.selectedAnswer = answer;
    } else {
      this.selectedAnswer = null;
    }
  }

  deleteToken = () => {
    this.token = '';
  };
  goToView(slideView) {
    if (
      this.chatView === this.ChatView.ShortListOfAnswers &&
      slideView === this.ChatView.NoQuestion
    ) {
      this.initNewQuestion();
      this.setQuestionInput('');
    }
    this.chatView = slideView;
  }
  setQuestionInput(newValue) {
    this.questionForm.get('questionInput').setValue(newValue);
  }
  copySuggestionToTextArea(i) {
    if (this.hasSuggestions()) {
      const suggestions = this.getSuggestions();
      this.setQuestionInput(
        _.truncate(suggestions[i].value + ' ', {
          length: 255,
        })
      );
      this.setSuggestionIndex();
      this.questionInput.nativeElement.focus();
      this.questionInput.nativeElement.setSelectionRange(-1, -1);
    }
  }
  copyPreviousQuestionToTextArea(i) {
    this.setQuestionInput(
      _.truncate(this.previousquestions[i].text, {
        length: 255,
      })
    );
    this.setQuestionIndex();
    this.questionInput.nativeElement.focus();
    this.questionInput.nativeElement.setSelectionRange(-1, -1);
  }

  setSuggestionIndex(i?) {
    if (i) {
      this.indexSuggestion = i;
    } else {
      this.indexSuggestion = null;
    }
  }

  setQuestionIndex(i?) {
    if (i) {
      this.indexQuestion = i;
    } else {
      this.indexQuestion = null;
    }
  }

  arrowNavigate(maxLength, variable, code): number {
    const codeIsArrowDown = code === 40;
    const codeIsArrowUp = code === 38;
    if (maxLength === 1) {
      return 0;
    }
    function shiftDown(v) {
      if (v === null) {
        return 0;
      } else if (v === maxLength - 1) {
        return 0;
      } else {
        v++;
        return v;
      }
    }
    function shiftUp(v) {
      if (v === null) {
        return maxLength - 1;
      } else if (v === 0) {
        return maxLength - 1;
      } else {
        v--;
        return v;
      }
    }
    if (codeIsArrowDown) {
      variable = shiftDown(variable);
      return variable;
    } else if (codeIsArrowUp) {
      variable = shiftUp(variable);
      return variable;
    }
  }
}
