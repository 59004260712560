import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { DropDownComponent } from './drop-down.component';
import { DropDownListComponent } from './drop-down-list/drop-down-list.component';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [DropDownComponent, DropDownListComponent],
  imports: [CommonModule, OverlayModule, ScrollingModule],
  exports: [DropDownComponent, DropDownListComponent, OverlayModule],
})
export class DropDownModule {}
