import { Component, Input, HostListener, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { ItemType } from 'src/app/shared/helpers/itemType';
import { TeamActions } from '../teams.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['../../../shared/layout/managers.scss', './team.component.scss'],
})
export class TeamComponent {
  @Input() team;
  ItemType = ItemType;
  showName = true;
  _ = _;
  @Output() teamEvent = new EventEmitter();

  
  @HostListener('document:keydown', ['$event'])
  handleKeyDownKeyboardEvent(event: KeyboardEvent) {
    if (!this.showName) {
      const code = event.key;
      switch (code) {
        case 'Enter':
          _.set(this, 'showName', false);
          break;
      }
    }
  }
  handleRename(event) {
    this.teamEvent.emit({ team: this.team, value: event, action: TeamActions.Rename });
  }

  hasSubscription = () => {
    return _.get(this, 'team.subscription_id.length', 0) > 0;
  };

  manageSubscription = () => {
    this.teamEvent.emit({ team: this.team, action: TeamActions.Manage });
  };
  addSubscription = () => {
    this.teamEvent.emit({ team: this.team, action: TeamActions.Manage });
  };
  open = () => {
    this.teamEvent.emit({ id: _.get(this, 'team._key'), action: TeamActions.Open });
  };
  handleDelete() {
    this.teamEvent.emit({ team: this.team, action: TeamActions.Delete });
  }
}
