<div class='h-100 w-100 d-flex justify-content-between flex-column'>
  <div>Since it's your first time logging in, you need to <span class='font-weight-bold'>register</span> the following
    QR Code in the
    <span class='font-weight-bold'> Google Authenticator app</span>.
  </div>
  <div class='w-100 my-4 qr-code-container '>
    <div *ngIf='!loginHttp.qrCodeImage' class='spinner-container w-50'>
      <app-spinner ></app-spinner>
    </div>
    <img class="w-50" *ngIf='loginHttp.qrCodeImage'
      [src]="_DomSanitizer.bypassSecurityTrustUrl(loginHttp.qrCodeImage)" alt="qr-code-image" />
  </div>
  <div class='font-weight-bold'>When done, click 'Next' </div>
  <div class='d-flex flex-nowrap justify-content-around w-100 mt-5 '>
    <div class="btn btn-light border flex-fill mx-2" (click)='previous()'>Previous</div>
    <div class="btn btn-dark flex-fill mx-2"  (click)='next()' tabindex='0'>Next</div>
  </div>
</div>
