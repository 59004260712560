import { Component, OnInit } from '@angular/core';
import { ProjectsService } from 'src/app/api/projects.service';
import _ from 'lodash';
import { FieldType } from '@ngx-formly/core';
import { merge } from 'rxjs';
import { areArgumentsDefined } from '../helpers/checkArgumentsValidity';
@Component({
  selector: 'formly-field-clusters-file',
  template: ` <span class="text-danger" *ngIf="formControl.status == 'INVALID'">{{ formControl.errors.message }}</span>`,
})
export class FormlyFieldClustersFile extends FieldType implements OnInit {
  _ = _;
  constructor(private projectsService: ProjectsService) {
    super();
  }
  get projects() {
    return this.field.parent.formControl.get('pso_path.project_id');
  }
  get instances() {
    return this.field.parent.formControl.get('pso_path.instance_id');
  }
  get step_name() {
    return this.field.parent.formControl.get('pso_path.step_name');
  }
  get filename() {
    return this.field.parent.formControl.get('pso_path.filename');
  }
  ngOnInit() {

    merge(this.projects.valueChanges, this.instances.valueChanges).subscribe(() => {
      
      setTimeout(() => {
        this.getClusterFileId(this.projects.value, this.instances.value, this.filename.value, this.step_name.value);
      }, 300);
    });

  }
  getClusterFileId(projectId, instanceId, filename, step_name) {
    if (!areArgumentsDefined(arguments)) {
      return;
    }
    this.projectsService.getClusterComposition(projectId, instanceId, filename, step_name).subscribe(
      (result) => {
        this.formControl.reset();
        this.formControl.setValue(result.cluster_file_id);
      },
      (error) => {
        this.formControl.setErrors({ message: 'No cluster file found' });
      }
    );
  }
}
