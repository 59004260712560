import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FileGroupsService } from 'src/app/api/groups.service';
import { MatDialog } from '@angular/material/dialog';
import { GroupCreationFormComponent } from 'src/app/pages/file-manager2/group-creation-form/group-creation-form.component';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import * as _ from 'lodash';
import { ProjectsService } from 'src/app/api/projects.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CreditAlertsService } from 'src/app/pages/project-creation2/credit-alerts/credit-alerts.service';

@Component({
  selector: 'formly-field-input',
  styleUrls: ['../../../styles.scss'],
  template: `
    <div *ngIf="to.options" class="form-group">
      <label *ngIf="_.get(field, 'templateOptions.label.length')">
        {{ _.get(field, 'templateOptions.label') }}
        <span *ngIf="_.get(field, 'templateOptions.required')">*</span>
      </label>
      <select
        class="form-control bg-white w-100"
        [formControl]="formControl"
        [formlyAttributes]="field"
        (change)="onChange($event)"
        [class.invalid]="formControl.status == 'INVALID'"
      >
        <option *ngFor="let item of to.options" disabled="{{ _.get(item, 'disabled') }}" [value]="item.value">{{ item.label }}</option>
      </select>
      <div *ngIf="showError" class="invalid-feedback d-block">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>
      <div class="my-3 small text-uppercase" [style.minHeight.rem]='1.25'>
        <span *ngIf="formControl.value && patientCount.value !== undefined"
          >Number of patients in cohort :
          <b class="mx-1 text-dark">{{ patientCount.value }}</b>
        </span>
      </div>
    </div>
  `,
})
export class FormlyFileGroupFieldInput extends FieldType<FieldTypeConfig> {
  _ = _;
  searchSubscription: Subscription;
  patientCount = new BehaviorSubject<number>(undefined);
  fileGroupsSubscription;
  constructor(
    private projectsService: ProjectsService,
    private fileGroupsService: FileGroupsService,
    public dialog: MatDialog,
    public layout: LayoutOrganizationService,
    private creditAlertsService: CreditAlertsService
  ) {
    super();
  }

  ngOnInit() {
    this.refreshFileGroupList();
    this.fileGroupsSubscription = this.fileGroupsService.onFileGroupCreated.subscribe((value)=>{
      if(value){
        this.refreshFileGroupList();
      }
    })
  }
  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if(this.fileGroupsSubscription){
      this.fileGroupsSubscription.unsubscribe();
    }
  }

  refreshFileGroupList() {
    // _.set(this, 'to.options', []);
    this.fileGroupsService.listGroups(undefined, _.get(this, 'to.sortBy'), _.get(this, 'to.filters'), 100).subscribe(
      (res) => {
        let options = _.without(res.data, (o) => {
          return o.name === 'Autogenerated';
        }).map((el) => {
          if (!el.owned_by_me) {
            return { label: el.name + ' –– shared with me  ', value: el.id };
          }
          return { label: el.name, value: el.id };
        });
        options.unshift({ label: _.get(this, 'to.placeholder', 'Select'), value: '' });
        options.push({ label: 'Create new cohort...', value: 'Create new cohort...' });
        this.to.options = options;
        this.formControl.setValue('');
      },
      (error) => {
        console.log(error);
        this.layout.toast('Impossible to get file groups', null, 8000, '', 'warning');
      }
    );
  }
  onChange(event) {
    this.patientCount.next(undefined);
    this.creditAlertsService.creditCost.next(undefined);
    this.creditAlertsService.error.next('noFileGroupId');
    console.log(this.model)
    if (event.target.value === 'Create new cohort...') {
      this.layout.open('modalGroup');
      this.formControl.setValue('');
    } else if (event.target.value !== 'undefined' && event.target.value !== '') {
      this.searchSubscription = this.projectsService.searchFiles({ filegroup_id: event.target.value }).subscribe((res) => {
        this.patientCount.next(_.get(res, 'counts.patients'));
      });
      this.creditAlertsService.updateCost.next(event.target.value);
    }
  }
}
